import { ErrorResponse, Uuid } from 'ValueObjects';
import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
} from '@tanstack/react-query';
import { Connection, Package } from 'Models';
import dayjs from 'dayjs';

export type UpdateConnectionMutationProps = {
  connectionId: Uuid;
  organizationId: Uuid;
  connectionName: string;
  active: boolean;
  packages: Package[];
  synchronizationStartDate: Date;
};

export const UpdateConnectionMutation = async ({
  synchronizationStartDate,
  ...values
}: UpdateConnectionMutationProps): Promise<AxiosResponse<Connection>> =>
  axios.put(`api/connections/${values.connectionId}`, {
    ...values,
    synchronizationStartDate: dayjs(synchronizationStartDate).format(
      'YYYY-MM-DD'
    ),
  });

export const useUpdateConnectionMutation = (
  options: UseMutationOptions<
    AxiosResponse<Connection>,
    AxiosError<ErrorResponse>,
    UpdateConnectionMutationProps
  > = {}
): UseMutationResult<
  AxiosResponse<Connection>,
  AxiosError<ErrorResponse>,
  UpdateConnectionMutationProps
> =>
  useMutation<
    AxiosResponse<Connection>,
    AxiosError<ErrorResponse>,
    UpdateConnectionMutationProps
  >(UpdateConnectionMutation, {
    useErrorBoundary: false,
    ...options,
  });

import axios, { AxiosError, AxiosResponse } from 'axios';
import { ErrorResponse } from 'ValueObjects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';

export type VerifyResetPasswordMutationProps = {
  token: string;
  newPassword: string;
};

export type VerifyResetPasswordMutationResponse = Record<string, never>;

export const VerifyResetPasswordMutation = async ({
  token,
  newPassword,
}: VerifyResetPasswordMutationProps): Promise<
  AxiosResponse<VerifyResetPasswordMutationResponse>
> =>
  axios.post(`api/users/reset-password/verify/${token}`, {
    token,
    newPassword,
  });

export const useVerifyResetPasswordMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    VerifyResetPasswordMutationProps
  >
): UseMutationResult<
  AxiosResponse<VerifyResetPasswordMutationResponse>,
  AxiosError<ErrorResponse>,
  VerifyResetPasswordMutationProps
> =>
  useMutation<
    AxiosResponse<VerifyResetPasswordMutationResponse>,
    AxiosError<ErrorResponse>,
    VerifyResetPasswordMutationProps
  >(VerifyResetPasswordMutation, {
    ...options,
    useErrorBoundary: (error) => (error?.response?.status || 500) >= 500,
  });

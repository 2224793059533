import {
  DialogProps,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { cloneElement, FunctionComponent, ReactElement, useState } from 'react';
import { Icon } from 'Atoms/Icon';
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import { BootstrapTextField } from 'Atoms/BootstrapTextField';

export type TextFieldProps = MuiTextFieldProps & {
  helpComponent?: ReactElement<DialogProps>;
  bootstrap?: boolean;
};

const TextField: FunctionComponent<TextFieldProps> = ({
  helpComponent,
  InputProps,
  bootstrap,
  ...props
}: TextFieldProps) => {
  const [helpOpen, setHelpOpen] = useState<boolean>(false);
  let endAdornment = InputProps?.endAdornment;
  if (helpComponent) {
    endAdornment = (
      <Icon
        onClick={() => setHelpOpen(true)}
        icon={faCircleInfo}
        className="Db-TextField-Help"
      />
    );
  }

  let content = null;
  if (bootstrap === true) {
    content = (
      <BootstrapTextField
        InputProps={{ ...InputProps, endAdornment }}
        {...props}
      />
    );
  } else {
    content = (
      <MuiTextField {...props} InputProps={{ ...InputProps, endAdornment }} />
    );
  }

  if (!helpComponent) {
    return content;
  }

  return (
    <>
      {content}
      {helpOpen &&
        cloneElement(helpComponent, {
          open: helpOpen,
          onClose: () => setHelpOpen(false),
        })}
    </>
  );
};

TextField.defaultProps = {
  variant: 'outlined',
  bootstrap: true,
};

export default styled(TextField)(
  () => `
  .Db-TextField-Help {
    color: rgba(0, 0, 0, 0.26);
    cursor: pointer;
  }
  .Mui-disabled {
    .MuiOutlinedInput-notchedOutline {
      border: 1px dashed rgba(0, 0, 0, 0.23);
    }
  }
`
);

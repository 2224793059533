import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from '@mui/x-date-pickers';
import {
  styled,
  TextFieldProps as MuiTextFieldPropsType,
  TextField,
} from '@mui/material';
import { ReactElement } from 'react';
import { TextFieldProps } from 'Atoms/TextField';

export type DatePickerProps<TInputDate, TDate> = Omit<
  MuiDatePickerProps<TInputDate, TDate>,
  'renderInput'
> & {
  renderInput?: (props: MuiTextFieldPropsType) => ReactElement;
  textFieldProps?: TextFieldProps;
};

const DatePicker = <TInputDate, TDate>({
  renderInput,
  textFieldProps,
  ...props
}: DatePickerProps<TInputDate, TDate>) => (
  <MuiDatePicker
    {...props}
    renderInput={
      renderInput ||
      (({ inputProps: paramInputProps, ...params }) => (
        <TextField
          {...params}
          {...textFieldProps}
          inputProps={{
            ...textFieldProps?.inputProps,
            ...paramInputProps,
          }}
        />
      ))
    }
  />
);

DatePicker.defaultProps = {
  openTo: 'day',
  inputFormat: 'DD-MM-YYYY',
  mask: '__-__-____',
};

export default styled(DatePicker)(
  () => `
`
);

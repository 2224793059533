import { useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { useQueryClient } from '@tanstack/react-query';
import { AuthState, UserState } from 'State';
import { subscribeToMercure } from 'Utils';
import { queryInvalidators } from 'Requests';
import { Uuid } from 'ValueObjects';
import { Organization } from 'Models';

const handleOrganization = (organization: Organization): Uuid[] => [
  organization.organizationId,
  ...(organization.childrenOrganizations || []).map(handleOrganization).flat(),
];

const MercureSubscriber = (): null => {
  const [authState] = useRecoilState(AuthState);
  const [userState] = useRecoilState(UserState);
  const queryClient = useQueryClient();

  const organizationIds = useMemo(() => {
    const result: Uuid[] = [];
    userState?.accessToOrganizations.forEach(({ organization }) => {
      result.push(...handleOrganization(organization));
    });

    return result;
  }, [userState?.accessToOrganizations]);

  useEffect(() => {
    let eventSource: EventSource | null = null;
    if (authState.mercureToken && userState?.userId) {
      eventSource = subscribeToMercure(authState.mercureToken, organizationIds);

      eventSource.onmessage = (message) => {
        const data = JSON.parse(message.data);
        // eslint-disable-next-line no-console
        console.log('Got a new mercure message', message, data);
        queryInvalidators.forEach((queryInvalidator) =>
          queryInvalidator(data, queryClient)
        );
      };
    }

    return () => {
      if (eventSource) {
        eventSource.close();
      }
    };
  }, [authState.mercureToken, organizationIds, queryClient, userState?.userId]);

  return null;
};

export default MercureSubscriber;

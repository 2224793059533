import { Uuid } from 'ValueObjects';
import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { Collection, Pagination } from 'Requests';
import { ExactOnlineGeneralLedger } from 'Models/Synchronizer/Package/ExactOnline';

type Response = AxiosResponse<Collection<ExactOnlineGeneralLedger>>;

export type ExactOnlineGeneralLedgersByCredentialIdRequestProps = Pagination & {
  credentialId: Uuid;
  division: string | number;
};

export const ExactOnlineGeneralLedgersByCredentialIdRequest = async ({
  credentialId,
  division,
}: ExactOnlineGeneralLedgersByCredentialIdRequestProps): Promise<Response> =>
  axios.get(
    `api/${credentialId}/data/exact-online/${division}/general-ledgers`
  );

export const useExactOnlineGeneralLedgersByCredentialIdRequestQuery = (
  request: ExactOnlineGeneralLedgersByCredentialIdRequestProps,
  options: UseQueryOptions<Response, AxiosError, Response> = {}
): UseQueryResult<Response, AxiosError> =>
  useQuery<Response, AxiosError, Response>({
    ...options,
    queryFn: async () =>
      ExactOnlineGeneralLedgersByCredentialIdRequest(request),
    queryKey: [
      'exact-online',
      'general-ledgers',
      'list',
      request.credentialId,
      request.division,
      JSON.stringify({ perPage: request.perPage, page: request.page }),
    ],
  });

import {
  DialogActions as MuiDialogActions,
  DialogActionsProps as MuiDialogActionsProps,
  styled,
} from '@mui/material';
import { ReactElement } from 'react';
import colors from 'App/colors';

export type DialogActionsProps = MuiDialogActionsProps;
const DialogActions = ({ ...props }: DialogActionsProps): ReactElement => (
  <MuiDialogActions {...props} />
);

const StyledDialog = styled(DialogActions)(`
  border-top: 1px solid ${colors.coolGray[200]};
  padding-top: 24px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
`);

const FinalDialog = ({ ...props }: DialogActionsProps) => (
  <StyledDialog {...props} />
);

export default FinalDialog;

import { ErrorResponse, Uuid } from 'ValueObjects';
import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
} from '@tanstack/react-query';

export type ForceRunConnectionMutationProps = {
  connectionId: Uuid;
};

export const ForceRunConnectionMutation = async ({
  ...values
}: ForceRunConnectionMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/connections/${values.connectionId}/force-run`, {
    ...values,
  });

export const useForceRunConnectionMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ForceRunConnectionMutationProps
  > = {}
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ForceRunConnectionMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ForceRunConnectionMutationProps
  >(ForceRunConnectionMutation, {
    useErrorBoundary: false,
    ...options,
  });

import { ErrorResponse, Uuid } from 'ValueObjects';
import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
} from '@tanstack/react-query';

export type ChangeOrganizationLogoMutationProps = {
  organizationId: Uuid;
  logoId?: Uuid | null;
};
export const ChangeOrganizationLogoMutation = async ({
  ...values
}: ChangeOrganizationLogoMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/organizations/${values.organizationId}/change-logo`, {
    ...values,
  });

export const useChangeOrganizationLogoMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeOrganizationLogoMutationProps
  > = {}
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ChangeOrganizationLogoMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeOrganizationLogoMutationProps
  >(ChangeOrganizationLogoMutation, {
    useErrorBoundary: false,
    ...options,
  });

import { ReactElement } from 'react';
import { DataGridPro, DataGridProProps } from '@mui/x-data-grid-pro';
import { styled } from '@mui/material';
import colors from 'App/colors';

export type DataGridProps = DataGridProProps;

const DataGrid = ({ components, ...props }: DataGridProps): ReactElement => (
  <DataGridPro
    components={{
      ...components,
    }}
    {...props}
  />
);

export default styled(DataGrid)(
  ({ theme }) => `
border: none;
border-radius: 0;

.MuiDataGrid-main {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-bottom: 107px;
    .MuiDataGrid-columnHeaders {
        border-bottom-color: ${colors.coolGray[200]};
    }
    
    .MuiDataGrid-columnHeader {
        background: ${colors.coolGray[50]};
        color: ${colors.coolGray[500]};
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.14px;
        text-transform: uppercase;
        padding: ${theme.spacing(2)};
    }
    
    .MuiDataGrid-cell {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.17px;
        padding: ${theme.spacing(2)};
        border-bottom-color: ${colors.coolGray[200]};
        color: ${colors.grey[500]};
    }
    
    .MuiDataGrid-columnSeparator {
        display: none;
    }
}

.MuiDataGrid-footerContainer {
    border: none;
    border-radius: 0;
}
`
);

import { ReactElement, useMemo } from 'react';
import { ConnectionStatus, CredentialStatus } from 'Models';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faPauseCircle,
} from '@fortawesome/pro-light-svg-icons';
import { Chip } from 'Atoms';
import { styled } from '@mui/material';
import colors from 'App/colors';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';

export type StatusChipProps = {
  status?: 'error' | 'warning' | 'running' | 'active' | 'paused';
  connectionStatus?: ConnectionStatus;
  credentialsStatus?: CredentialStatus;
  className?: string;
};

const colorMapping: {
  [key: string]:
    | 'default'
    | 'success'
    | 'secondary'
    | 'error'
    | 'warning'
    | 'primary'
    | 'info'
    | undefined;
} = {
  active: 'success',
  running: 'info',
  paused: 'default',
  error: 'error',
  warning: 'warning',
};

// TODO this one is not used anymore
const StatusChip = ({
  status,
  connectionStatus,
  credentialsStatus,
  className,
}: StatusChipProps): ReactElement => {
  const realStatus = useMemo(() => {
    if (status) {
      return status;
    }

    if (
      connectionStatus === ConnectionStatus.ERROR ||
      connectionStatus === ConnectionStatus.ALERT ||
      credentialsStatus === CredentialStatus.ERROR
    ) {
      return 'error';
    }

    if (
      connectionStatus === ConnectionStatus.WARNING ||
      credentialsStatus === CredentialStatus.WARNING ||
      credentialsStatus === CredentialStatus.EXPIRED ||
      credentialsStatus === CredentialStatus.INSUFFICIENT_PERMISSIONS ||
      credentialsStatus === CredentialStatus.INVALID
    ) {
      return 'warning';
    }

    if (connectionStatus === ConnectionStatus.PAUSED) {
      return 'paused';
    }

    return 'active';
  }, [connectionStatus, credentialsStatus, status]);

  const icon = useMemo(() => {
    if (realStatus === 'running') {
      return <FontAwesomeIcon icon={faSpinnerThird} spin />;
    }
    if (realStatus === 'active') {
      return <FontAwesomeIcon icon={faCheckCircle} />;
    }
    if (realStatus === 'paused') {
      return <FontAwesomeIcon icon={faPauseCircle} />;
    }
    if (realStatus === 'error') {
      return <FontAwesomeIcon icon={faExclamationCircle} />;
    }
    if (realStatus === 'warning') {
      return <FontAwesomeIcon icon={faExclamationTriangle} />;
    }

    return undefined;
  }, [realStatus]);

  return (
    <Chip
      label={<FormattedMessage id={`label.${realStatus}`} />}
      variant="filled"
      color={colorMapping[realStatus] || 'default'}
      icon={icon}
      className={className}
    />
  );
};

export default styled(StatusChip)(`
padding: 1px 10px;
border-radius: 12px;
height: 22px;
box-sizing: border-box;

.MuiChip-label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.14px;
  text-transform: initial;
}

&.MuiChip-filledError {
  color: ${colors.white};
  background: ${colors.error[500]};
}

&.MuiChip-filledSuccess {
  color: ${colors.white};
  background: ${colors.success[500]};
}
&.MuiChip-filledWarning {
  color: ${colors.white};
  background: ${colors.warning[500]};
}
`);

import { ReactElement } from 'react';
import { ListItem, ListItemIcon, ListItemProps } from '@mui/material';
import { Icon } from 'Atoms/Icon';
import { faCircle } from '@fortawesome/pro-light-svg-icons';

const BulletListItem = ({ children, ...args }: ListItemProps): ReactElement => (
  <ListItem disablePadding {...args}>
    <ListItemIcon
      sx={{
        fontSize: 7,
        color: (theme) => theme.palette.primary.main,
        minWidth: 'unset',
        pr: 1,
      }}
    >
      <Icon icon={faCircle} />
    </ListItemIcon>
    {children}
  </ListItem>
);

export default BulletListItem;

import { ErrorResponse, Uuid } from 'ValueObjects';
import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
} from '@tanstack/react-query';

export type EnableTwoFactorMutationProps = {
  userId: Uuid;
  code: string;
};

export const EnableTwoFactorMutation = async ({
  ...values
}: EnableTwoFactorMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/users/${values.userId}/enable-two-factor`, {
    ...values,
  });

export const useEnableTwoFactorMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    EnableTwoFactorMutationProps
  > = {}
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  EnableTwoFactorMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    EnableTwoFactorMutationProps
  >(EnableTwoFactorMutation, {
    useErrorBoundary: false,
    ...options,
  });

import { isAbstractCredential, OAuth2Credential } from 'Models';

export interface ExactOnlineCredential extends OAuth2Credential {
  extraData: {
    country?: string;
  };
}

export function isExactOnlineCredential(
  object: unknown
): object is ExactOnlineCredential {
  return (
    isAbstractCredential(object) && object['@type'] === 'ExactOnlineCredential'
  );
}

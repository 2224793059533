import { ReactElement } from 'react';
import * as Yup from 'yup';
import { PackageType } from 'Models';
import { FinishExactOnlinePaypalConnectionStep } from 'Forms';

type PackagesFinishOnboardingMapping = {
  handles: (packages: PackageType[]) => boolean;
  element: ReactElement;
  validation?: () => Yup.AnyObjectSchema;
};

const getFinishOnboardingMapping = (): PackagesFinishOnboardingMapping[] => [
  {
    handles: (packages) =>
      packages.includes(PackageType.PAYPAL) &&
      packages.includes(PackageType.EXACT_ONLINE),
    element: <FinishExactOnlinePaypalConnectionStep />,
  },
];

// eslint-disable-next-line import/prefer-default-export
export const getFinishOnboardingForm = (
  packages: PackageType[]
): PackagesFinishOnboardingMapping[] => {
  const mapping = getFinishOnboardingMapping();

  return mapping.filter(({ handles }) => handles(packages));
};

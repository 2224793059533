import { useRecoilState } from 'recoil';
import { UserState } from 'State';
import { useMemo } from 'react';
import { Role } from 'Models';

// eslint-disable-next-line import/prefer-default-export
export const useIsSuperAdmin = () => {
  const [userState] = useRecoilState(UserState);

  return useMemo(
    () => userState?.roles?.includes(Role.SUPER_ADMIN),
    [userState]
  );
};

import { ReactElement, useCallback, useState } from 'react';
import { OverviewDataGridPageTemplate } from 'Templates/OverviewDataGridPageTemplate';
import { useOrganizationsListRequest } from 'Requests';
import useDataGridDataWithReactQuery from 'Utils/useDataGridDataWithReactQuery';
import { Organization } from 'Models';
import { FormattedMessage } from 'react-intl';
import { Grid, IconButton, Link, Typography } from '@mui/material';
import { faPencil, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { Icon } from 'Atoms';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import colors from 'App/colors';
import {
  useCreateOrganizationMutation,
  useDeleteOrganizationMutation,
} from 'Mutations';
import ConfirmDialog from 'Organisms/ConfirmDialog/ConfirmDialog';
import { useIsSuperAdmin } from 'Utils';
import { CreateOrganizationFormDialog } from 'Dialogs';
import { CreateOrganizationInputType } from 'InputTypes';
import { v4 } from 'uuid';

const OrganizationOverviewPage = (): ReactElement => {
  const navigate = useNavigate();
  const isSuperAdmin = useIsSuperAdmin();
  const [isCreatingOrganization, setIsCreatingOrganization] =
    useState<boolean>(false);

  const { mutateAsync: deleteOrganizationMutation } =
    useDeleteOrganizationMutation();
  const { mutateAsync: createOrganization } = useCreateOrganizationMutation();
  const [deleteOrganization, setDeletingOrganization] =
    useState<Organization>();

  const { refetch, ...gridData } = useDataGridDataWithReactQuery<Organization>({
    overviewPage: true,
    reactQuery: useOrganizationsListRequest,
    getRowId: (row) => row.organizationId,
    columns: [
      {
        field: 'organizationCode',
        headerName: 'label.organizationCode',
        flex: 1,
        renderCell: ({ row, value }) => (
          <Link
            component={RouterLink}
            to={`/organizations/${row.organizationId}`}
            underline="none"
            color={colors.coolGray[900]}
          >
            {value}
          </Link>
        ),
      },
      {
        field: 'organizationName',
        headerName: 'label.organization',
        flex: 1,
        renderCell: ({ row, value }) => (
          <Link
            component={RouterLink}
            to={`/organizations/${row.organizationId}`}
            underline="none"
            color={colors.coolGray[900]}
          >
            {value}
          </Link>
        ),
      },
      {
        field: 'reseller.organizationName',
        headerName: 'label.reseller',
        flex: 1,
        renderCell: ({ row }) => row.reseller?.organizationName,
      },
      {
        field: 'actions',
        headerName: 'label.actions',
        type: 'actions',
        headerAlign: 'left',
        flex: 0.7,
        renderCell: ({ row }) => (
          <Grid container spacing={2}>
            <Grid item>
              <IconButton
                onClick={() => setDeletingOrganization(row)}
                disableRipple
                disableFocusRipple
                disableTouchRipple
                size="small"
                sx={{ fontSize: 13.5 }}
              >
                <Icon icon={faTrashCan} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                onClick={() => navigate(`${row.organizationId}`)}
                disableRipple
                disableFocusRipple
                disableTouchRipple
                size="small"
                sx={{ fontSize: 13.5 }}
              >
                <Icon icon={faPencil} />
              </IconButton>
            </Grid>
          </Grid>
        ),
      },
    ],
  });

  const handleStartDeleting = useCallback(async () => {
    if (deleteOrganization?.organizationId) {
      await deleteOrganizationMutation({
        organizationId: deleteOrganization.organizationId,
      });
      await refetch();
      setDeletingOrganization(undefined);
    }
  }, [deleteOrganization?.organizationId, deleteOrganizationMutation, refetch]);

  const handleCreateOrganization = useCallback(
    async (values: CreateOrganizationInputType) => {
      const organizationId = v4();
      await createOrganization({
        organizationId,
        resellerId: values.reseller?.organizationId,
        ...values,
      });

      navigate(`/organizations/${organizationId}`);
    },
    [createOrganization, navigate]
  );

  return (
    <OverviewDataGridPageTemplate
      actions={
        isSuperAdmin
          ? [
              { value: 'overview', children: 'overview', to: '/organizations' },
              {
                value: 'create',
                children: 'create a new organization',
                onClick: () => setIsCreatingOrganization(true),
              },
            ]
          : [{ value: 'overview', children: 'overview', to: '/organizations' }]
      }
      onSearch={gridData.handleSearch}
      breadCrumbs={[
        <Typography key="organizations" color="text.primary">
          <FormattedMessage id="label.organizations" />
        </Typography>,
        <Link component={RouterLink} key="overview" to="/organizations">
          <FormattedMessage id="label.overview" />
        </Link>,
      ]}
      {...gridData}
    >
      {deleteOrganization && (
        <ConfirmDialog
          open={!!deleteOrganization}
          onCancel={() => setDeletingOrganization(undefined)}
          onClose={() => setDeletingOrganization(undefined)}
          onConfirm={handleStartDeleting}
          dangerous
          title={
            <FormattedMessage
              id="label.deleteOrganization"
              values={{ name: deleteOrganization.organizationName }}
            />
          }
          content={
            <FormattedMessage id="label.areYouSureYouWantToDeleteThisOrganization" />
          }
        />
      )}
      {isCreatingOrganization && (
        <CreateOrganizationFormDialog
          open={isCreatingOrganization}
          onClose={() => setIsCreatingOrganization(false)}
          onSubmit={handleCreateOrganization}
        />
      )}
    </OverviewDataGridPageTemplate>
  );
};

export default OrganizationOverviewPage;

import { ReactElement, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useConnectionByConnectionIdRequestQuery } from 'Requests';
import { Box, BoxProps, styled } from '@mui/material';
import colors from 'App/colors';
import { ConnectionDetailsHeader } from 'Organisms';
import { useConnectionAlerts } from 'Utils';

const ConnectionInsightPage = ({ ...props }: BoxProps): ReactElement => {
  const { connectionId } = useParams();

  const { data, isInitialLoading } = useConnectionByConnectionIdRequestQuery(
    {
      connectionId: connectionId || '',
    },
    {
      enabled: !!connectionId,
    }
  );

  const connection = useMemo(() => data?.data, [data]);
  const alerts = useConnectionAlerts({ connection });

  if (!connection || isInitialLoading) {
    return <div />;
  }

  return (
    <Box {...props}>
      <ConnectionDetailsHeader
        connection={connection}
        alerts={alerts}
        showSyncStatus
        currentPage="insights"
      />
      <Box className="Db-ConnectionInsightPage-Content">wauw</Box>
    </Box>
  );
};

export default styled(ConnectionInsightPage)(`
display: flex;
flex-direction: column;
width: 100%;

.Db-ConnectionInsightPage-Content {
  background: ${colors.white};
  margin-top: 32px;
  padding: 16px 32px;
  padding-bottom: 64px;

  & > .MuiGrid-container {
    height: 750px;
    .MuiDataGrid-main {
      margin-bottom: 16px;
    }
  }

  .MuiTypography-h6 {
    margin-left: 10px;
    margin-bottom: 18px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
  }

  .Db-ConnectionInsightPage-Content-Bold {
    font-weight: bold;
  }
}

.Db-ConnectionInsightPage-Footer { 
  height: 32px;
}

.MuiButtonGroup-root .MuiButton-sizeLarge {
  background: ${colors.white};
}
`);

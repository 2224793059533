import { ReactElement } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { ConnectWithExactOnlineStepStepInput } from 'Forms/OnboardingWizard/CredentialSteps';
import { useExactOnlineDivisionsByCredentialIdRequestQuery } from 'Requests/Synchronizer/Package/ExactOnline';
import { ReactHookFormTextField } from 'Atoms';
import { FormattedMessage } from 'react-intl';
import { Grid, MenuItem } from '@mui/material';

export type ExactOnlineDivisionSettingsFormProps = {
  disabled?: boolean;
};

const ExactOnlineDivisionSettingsForm = ({
  disabled,
}: ExactOnlineDivisionSettingsFormProps): ReactElement => {
  const credentialId = useWatch<
    ConnectWithExactOnlineStepStepInput,
    'credentials.exact-online.credentialId'
  >({
    name: 'credentials.exact-online.credentialId',
  });

  const { data: divisionsData } =
    useExactOnlineDivisionsByCredentialIdRequestQuery(
      {
        credentialId,
        perPage: 9999,
      },
      {
        refetchInterval: false,
        refetchIntervalInBackground: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );

  const divisions = divisionsData?.data['hydra:member'] || [];

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Controller
          name="settings.exact-online.division"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={<FormattedMessage id="label.division" />}
              select
              disabled={disabled}
              aria-readonly={disabled}
            >
              {divisions.map(({ Code, Description, Hid }) => (
                <MenuItem value={Code} key={Code}>
                  {`${Description} (${Hid})`}
                </MenuItem>
              ))}
            </ReactHookFormTextField>
          )}
        />
      </Grid>
    </Grid>
  );
};

export default ExactOnlineDivisionSettingsForm;

import { ReactElement } from 'react';
import { FormDialog } from 'Organisms';
import { FormattedMessage } from 'react-intl';
import {
  AddOrganizationAccessInputType,
  AddOrganizationAccessInputTypeDefaultValues,
  AddOrganizationAccessInputTypeValidationBuilder,
} from 'InputTypes';
import { Button, Icon, ReactHookFormOrganizationAutocomplete } from 'Atoms';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { Grid } from '@mui/material';
import { Controller } from 'react-hook-form';

export type AddOrganizationAccessFormDialogProps = {
  className?: string;
  open: boolean;
  onClose: () => void;
  onSubmit: (values: AddOrganizationAccessInputType) => void | Promise<void>;
};

const AddOrganizationAccessFormDialog = ({
  ...props
}: AddOrganizationAccessFormDialogProps): ReactElement => (
  <FormDialog<AddOrganizationAccessInputType>
    name="add-organization-access"
    title={<FormattedMessage id="label.addOrganizationAccess" />}
    defaultValues={{
      ...AddOrganizationAccessInputTypeDefaultValues(),
    }}
    validationSchema={AddOrganizationAccessInputTypeValidationBuilder()}
    closeButtonLast
    dialogActions={[
      <Button type="submit" key="save">
        <Icon icon={faCheck} />
        &nbsp;
        <FormattedMessage id="button.save" />
      </Button>,
    ]}
    fullWidth
    maxWidth="sm"
    useProvider
    hideBackendErrors
    {...props}
  >
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Controller
          name="organization"
          render={(registered) => (
            <ReactHookFormOrganizationAutocomplete
              {...registered}
              fullWidth
              label={<FormattedMessage id="label.organization" />}
            />
          )}
        />
      </Grid>
    </Grid>
  </FormDialog>
);

export default AddOrganizationAccessFormDialog;

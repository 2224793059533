import { ReactElement } from 'react';
import { Box, Grid, styled, Typography } from '@mui/material';
import { Organization, User } from 'Models';
import {
  Avatar,
  Button,
  Chip,
  FormControlLabel,
  Icon,
  IosSwitch,
  ToggleButtonProps,
} from 'Atoms';
import { useGetProfileImage } from 'Utils';
import { FormattedDate, FormattedMessage } from 'react-intl';
import colors from 'App/colors';
import { DetailsWithActionsPageTemplate } from 'Templates/DetailsWithActionsPageTemplate';
import { AvatarSizeType } from 'Atoms/Avatar/Avatar';
import { UserProfileForm } from 'Forms';
import {
  faCheck,
  faPlusCircle,
  faTimes,
} from '@fortawesome/pro-light-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Uuid } from 'ValueObjects';

export type UserProfilePageTemplateProps = {
  className?: string;
  disableTwoFactorAuthentication?: boolean;
  hidePassword?: boolean;
  allowEditingOrganizations?: boolean | Uuid[];
  allowEditingRole?: boolean;
  allowLogout?: boolean;
  allowRemovingUser?: boolean;
  user: User;
  actions?: ToggleButtonProps[];
  onUpdateProfileImage: () => void;
  onChangeTwoFactorAuthentication: () => void;
  onRemoveOrganization?: (organization: Organization) => void;
  onStartAddingOrganization?: () => void;
  onRemoveUser?: () => void;
};

const UserProfilePageTemplate = ({
  className,
  user,
  allowEditingOrganizations,
  allowEditingRole,
  allowLogout,
  allowRemovingUser,
  disableTwoFactorAuthentication,
  hidePassword,
  onUpdateProfileImage,
  onChangeTwoFactorAuthentication,
  onRemoveOrganization,
  onStartAddingOrganization,
  onRemoveUser,
  actions,
}: UserProfilePageTemplateProps): ReactElement => {
  const profileImage = useGetProfileImage(user);
  const navigate = useNavigate();

  return (
    <DetailsWithActionsPageTemplate
      actions={actions}
      className={className}
      currentPage="overview"
    >
      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          <Box className="Db-UserProfilePage-Block">
            <Grid
              container
              columnSpacing={4}
              rowSpacing={3}
              alignItems="center"
            >
              <Grid item xs={6}>
                <Typography variant="h5">
                  <FormattedMessage id="label.profile" />
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                justifyContent="flex-end"
                display="flex"
                container
                columnSpacing={1}
              >
                {allowLogout && (
                  <Grid item>
                    <Button
                      onClick={() => navigate('/logout')}
                      variant="contained"
                      color="quaternary"
                    >
                      <FormattedMessage id="button.logout" />
                    </Button>
                  </Grid>
                )}
                {allowRemovingUser && onRemoveUser && (
                  <Grid item>
                    <Button
                      onClick={onRemoveUser}
                      variant="contained"
                      color="error"
                    >
                      <FormattedMessage id="button.removeUser" />
                    </Button>
                  </Grid>
                )}
                <Grid item>
                  <Button type="submit" variant="contained" color="secondary">
                    <Icon icon={faCheck} />
                    &nbsp;
                    <FormattedMessage id="button.save" />
                  </Button>
                </Grid>
              </Grid>
              <Grid item>
                <Avatar
                  src={profileImage}
                  size={AvatarSizeType.BIG}
                  onClick={onUpdateProfileImage}
                />
              </Grid>
              <Grid item>
                <Typography variant="h5">{user.personName.fullName}</Typography>
                <Typography variant="caption">
                  <FormattedMessage
                    id="label.activeSince"
                    values={{
                      date: (
                        <FormattedDate
                          value={user.createdAt}
                          dateStyle="short"
                        />
                      ),
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className="Db-UserProfilePage-Block">
            <Typography variant="subtitle2">
              <FormattedMessage id="label.account" />
            </Typography>
            <Box maxWidth={816} width="100%" pt={3}>
              <UserProfileForm
                hidePassword={hidePassword}
                allowEditingRole={allowEditingRole}
              />
            </Box>
            <Box className="Db-UserProfilePage-SubBlock" pt={3}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="subtitle2">
                    <FormattedMessage id="label.access" />
                  </Typography>
                </Grid>

                {(allowEditingOrganizations === true ||
                  Array.isArray(allowEditingOrganizations)) && (
                  <Grid item xs={6} justifyContent="flex-end" display="flex">
                    <Button
                      color="tertiary"
                      onClick={onStartAddingOrganization}
                    >
                      <Icon icon={faPlusCircle} />
                      &nbsp;
                      <FormattedMessage id="button.addOrganization" />
                    </Button>
                  </Grid>
                )}
              </Grid>
              <Typography className="Db-UserProfilePage-SubBlock-Text">
                <FormattedMessage id="label.organizations" />
              </Typography>
              <Box className="Db-UserProfilePage-Organizations">
                {user.accessToOrganizations.map(({ organization }) => {
                  const isEditable =
                    allowEditingOrganizations === true ||
                    (Array.isArray(allowEditingOrganizations) &&
                      allowEditingOrganizations.includes(
                        organization.organizationId
                      ));
                  return (
                    <Chip
                      label={
                        <span>
                          {organization.organizationName}
                          {isEditable && onRemoveOrganization && (
                            <span>
                              &nbsp;
                              <Icon icon={faTimes} />
                            </span>
                          )}
                        </span>
                      }
                      variant="filled"
                      key={organization.organizationId}
                      className="Db-UserProfilePage-Organizations-Organization"
                      onClick={
                        isEditable && onRemoveOrganization
                          ? () => onRemoveOrganization(organization)
                          : undefined
                      }
                    />
                  );
                })}
              </Box>
            </Box>
            <Box className="Db-UserProfilePage-SubBlock" pt={3}>
              <Typography variant="subtitle2">
                <FormattedMessage id="label.security" />
              </Typography>
              <Typography className="Db-UserProfilePage-SubBlock-Text">
                <FormattedMessage id="label.twoFactorAuthentication" />
              </Typography>
              <FormControlLabel
                sx={{ paddingTop: '12px', marginLeft: 0 }}
                disabled={disableTwoFactorAuthentication}
                control={
                  <IosSwitch
                    disabled={disableTwoFactorAuthentication}
                    sx={{ marginRight: '12px' }}
                    checked={user.totpAuthenticationEnabled}
                    onChange={() => onChangeTwoFactorAuthentication()}
                  />
                }
                label={<FormattedMessage id="label.active" />}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </DetailsWithActionsPageTemplate>
  );
};

export default styled(UserProfilePageTemplate)`
  width: 100%;

  .Db-UserProfilePage-Block {
    padding: 24px;
    background: ${colors.white};
  }

  .Db-UserProfilePage-SubBlock {
    &:before {
      display: block;
      content: '';
      height: 2px;
      width: 100%;
      background: #eaf2f9;
      margin-top: 4px;
      margin-bottom: 36px;
    }
  }

  .Db-UserProfilePage-SubBlock-Text {
    padding-top: 27px;
  }

  .Db-UserProfilePage-Organizations {
    padding-top: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    .Db-UserProfilePage-Organizations-Organization {
      background: #e4e4e7;
      border-radius: 90px;
      padding: 5px 15px;

      .MuiChip-label {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #18181b;
      }
    }
  }
`;

import { CopyRight } from 'Molecules/CopyRight';
import { styled } from '@mui/material';
import colors from 'App/colors';

const Footer = ({ ...props }) => <CopyRight {...props} />;

Footer.defaultProps = {};

export default styled(Footer)(
  () => `
    color: ${colors.gray[500]};
    margin-left: 32px;
    margin-bottom: 32px;
    font-size: 14px;
    line-height: 20px;
`
);

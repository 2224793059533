import { ErrorResponse, Uuid } from 'ValueObjects';
import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
} from '@tanstack/react-query';

export type ForceDisableTwoFactorMutationProps = {
  userId: Uuid;
  password: string;
};

export const ForceDisableTwoFactorMutation = async ({
  ...values
}: ForceDisableTwoFactorMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/users/${values.userId}/force-disable-two-factor`, {
    ...values,
  });

export const useForceDisableTwoFactorMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ForceDisableTwoFactorMutationProps
  > = {}
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ForceDisableTwoFactorMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ForceDisableTwoFactorMutationProps
  >(ForceDisableTwoFactorMutation, {
    useErrorBoundary: false,
    ...options,
  });

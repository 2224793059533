import { ReactNode } from 'react';
import printValue from 'yup/lib/util/printValue';

type LocaleObject = Record<
  string,
  Record<
    string,
    string | ((args: Record<string, string | ReactNode>) => unknown)
  >
>;

const strReg = /\${\s*(\w+)\s*}/g;

const YupTranslatePath = (
  yupLocale: LocaleObject,
  formatMessage: (descriptor: { id: string }) => string
): LocaleObject => {
  const newLocale = { ...yupLocale };

  Object.keys(newLocale).forEach((key) => {
    // @ts-ignore
    const localeObject = newLocale[key];
    if (typeof newLocale === 'object' && typeof localeObject === 'object') {
      Object.keys(localeObject).forEach((action) => {
        const originalAction = localeObject[action];
        localeObject[action] = (args: Record<string, string | ReactNode>) => {
          const pathName = typeof args.path === 'string' ? args.path : 'this';
          const path = formatMessage({ id: pathName });

          if (typeof originalAction === 'string') {
            return originalAction
              .replace(
                // eslint-disable-next-line no-template-curly-in-string
                '${path}',
                path
              )
              .replace(strReg, (_, g) => printValue(args[g]));
          }
          if (typeof originalAction === 'function') {
            return originalAction({ ...args, path });
          }

          return localeObject[action];
        };
      });
    }
  });

  return newLocale;
};

export default YupTranslatePath;

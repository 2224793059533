import { ReactElement, useMemo } from 'react';
import { CredentialStatus } from 'Models';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faPauseCircle,
} from '@fortawesome/pro-light-svg-icons';
import { Chip } from 'Atoms';
import { styled } from '@mui/material';

export type CredentialStatusChipProps = {
  credentialStatus: CredentialStatus;
  className?: string;
};

const colorMapping: {
  [key: string]:
    | 'default'
    | 'success'
    | 'secondary'
    | 'error'
    | 'warning'
    | 'primary'
    | 'info'
    | undefined;
} = {
  [CredentialStatus.ACTIVE]: 'success',
  [CredentialStatus.INVALID]: 'default',
  [CredentialStatus.EXPIRED]: 'warning',
  [CredentialStatus.ERROR]: 'error',
  [CredentialStatus.INSUFFICIENT_PERMISSIONS]: 'warning',
  [CredentialStatus.WARNING]: 'warning',
};
const CredentialStatusChip = ({
  credentialStatus,
  className,
}: CredentialStatusChipProps): ReactElement => {
  const icon = useMemo(() => {
    if (credentialStatus === CredentialStatus.ACTIVE) {
      return <FontAwesomeIcon icon={faCheckCircle} />;
    }

    if (credentialStatus === CredentialStatus.ERROR) {
      return <FontAwesomeIcon icon={faExclamationCircle} />;
    }

    if (
      credentialStatus === CredentialStatus.EXPIRED ||
      credentialStatus === CredentialStatus.WARNING ||
      credentialStatus === CredentialStatus.INVALID ||
      credentialStatus === CredentialStatus.INSUFFICIENT_PERMISSIONS
    ) {
      return <FontAwesomeIcon icon={faExclamationTriangle} />;
    }

    return undefined;
  }, [credentialStatus]);
  return (
    <Chip
      label={<FormattedMessage id={`credentialStatus.${credentialStatus}`} />}
      variant="filled"
      color={colorMapping[credentialStatus] || 'default'}
      icon={icon}
      className={className}
    />
  );
};

export default styled(CredentialStatusChip)(`
`);

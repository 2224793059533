import {
  Box,
  Collapse,
  ListItemButtonProps as MuiListItemButtonProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ListItemButton } from 'Atoms';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { ReactNode, useState } from 'react';
import clsx from 'clsx';

export type CollapsibleListItemButtonProps = MuiListItemButtonProps & {
  header: string | ReactNode;
  className?: string;
};

const CollapsibleListItemButton = ({
  // open,
  header,
  children,
  className,
  ...props
}: CollapsibleListItemButtonProps) => {
  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Box className={className}>
      <ListItemButton
        {...props}
        className={clsx('Db-CollapsibleListItemButton-Header', {
          'Db-CollapsibleListItemButton-Header-Closed': !open,
        })}
        onClick={handleClick}
      >
        {header}
        {open ? (
          <ExpandLess className="Db-CollapsibleListItemButton-Header-Icon" />
        ) : (
          <ExpandMore className="Db-CollapsibleListItemButton-Header-Icon" />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </Box>
  );
};

CollapsibleListItemButton.defaultProps = {
  header: undefined,
  className: undefined,
};

export default styled(CollapsibleListItemButton)(
  ({ theme }) => `
  .Db-CollapsibleListItemButton-Header {
    background-color: ${theme.palette.primary.main};
    color: white;
    
    &.Db-CollapsibleListItemButton-Header-Closed {
        background-color: ${theme.palette.primary.light};
    }
    
    &.Mui-selected {
        background-color: ${theme.palette.primary.main};
    }
    

    
    &:hover {
        background-color: ${theme.palette.primary.light} !important;
    }
  }
  
    .Db-CollapsibleListItemButton-Header-Icon {
        color: #2196F3;
    }
  
  .MuiList-root {
    &.MuiList-padding {
        padding-top: 0px;
        padding-bottom:0px;
    }
  }
`
);

import { ErrorResponse, Uuid } from 'ValueObjects';
import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
} from '@tanstack/react-query';

export type SetupTwoFactorMutationProps = {
  userId: Uuid;
  password: string;
  twoFactorMethod: string;
};

export type Response = {
  qrCodeImage: string;
};
export const SetupTwoFactorMutation = async ({
  ...values
}: SetupTwoFactorMutationProps): Promise<AxiosResponse<Response>> =>
  axios.put(`api/users/${values.userId}/setup-two-factor`, {
    ...values,
  });

export const useSetupTwoFactorMutation = (
  options: UseMutationOptions<
    AxiosResponse<Response>,
    AxiosError<ErrorResponse>,
    SetupTwoFactorMutationProps
  > = {}
): UseMutationResult<
  AxiosResponse<Response>,
  AxiosError<ErrorResponse>,
  SetupTwoFactorMutationProps
> =>
  useMutation<
    AxiosResponse<Response>,
    AxiosError<ErrorResponse>,
    SetupTwoFactorMutationProps
  >(SetupTwoFactorMutation, {
    useErrorBoundary: false,
    ...options,
  });

import { ErrorResponse, Uuid } from 'ValueObjects';
import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
} from '@tanstack/react-query';

export type UpdateOrganizationMutationProps = {
  organizationId: Uuid;
  resellerId?: Uuid;
  organizationName: string;
  address: {
    street?: string;
    houseNumberWithAddition?: string;
    city?: string;
    postalCode?: string;
    country?: string;
  };
  vatNumber?: string;
  invoiceContactName?: string;
  invoiceEmailAddress?: string;
  reference?: string;
};

export const UpdateOrganizationMutation = async ({
  ...values
}: UpdateOrganizationMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/organizations/${values.organizationId}`, {
    ...values,
  });

export const useUpdateOrganizationMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateOrganizationMutationProps
  > = {}
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  UpdateOrganizationMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateOrganizationMutationProps
  >(UpdateOrganizationMutation, {
    useErrorBoundary: false,
    ...options,
  });

import { ReactElement, ReactNode, useCallback } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  styled,
  Typography,
} from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { Icon } from 'Atoms';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

export type HelpDialogProps = DialogProps & {
  title: string | ReactNode;
  helpContent: string | ReactNode;
  backdrop: boolean;
  centered: boolean;
};

const HelpDialog = ({
  title,
  helpContent,
  onClose,
  backdrop,
  centered,
  ...args
}: HelpDialogProps): ReactElement => {
  const renderTypography = useCallback(
    ({ node: { tagName }, ...props }: { node: { tagName: string } }) => {
      let variant = tagName;
      if (variant === 'p') {
        variant = 'body';
      }

      // @ts-ignore
      return <Typography variant={variant} {...props} />;
    },
    []
  );

  let fullClassName = args.className;
  fullClassName += backdrop ? '' : ' Db-Dialog-Backdrop-Disabled';
  fullClassName += centered ? ' Db-Dialog-Centered' : '';

  return (
    <Dialog {...args} onClose={onClose} className={fullClassName}>
      {onClose && (
        <Box className="Db-Dialog-Close">
          <Icon icon={faTimes} onClick={() => onClose({}, 'escapeKeyDown')} />
        </Box>
      )}
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {typeof helpContent === 'string' ? (
          <ReactMarkdown
            components={{
              h1: renderTypography,
              h2: renderTypography,
              h3: renderTypography,
              h4: renderTypography,
              h5: renderTypography,
              h6: renderTypography,
              p: renderTypography,
            }}
          >
            {helpContent}
          </ReactMarkdown>
        ) : (
          helpContent
        )}
      </DialogContent>
    </Dialog>
  );
};

HelpDialog.defaultProps = {
  backdrop: true,
  centered: false,
};

export default styled(HelpDialog)(
  ({ theme }) => `
  .MuiDialog-paper {
    width: 729px;
    box-sizing: border-box;
    position: absolute;
    margin: 0;
    top: 10px;
    bottom: 10px;
    right: 10px;
    max-height: unset;
    max-width: unset;
    overflow: visible;
    border-radius: 8px;
    box-shadow: 0px 20px 50px rgba(29, 53, 71, 0.3);
    
    .Db-Dialog-Close {
      position: absolute;
      left: -50px;
      background: #fff;
      color: ${theme.palette.secondary.main};
      font-size: 20px;
      height: 40px;
      width: 40px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
  
  &.Db-Dialog-Backdrop-Disabled 
    .MuiBackdrop-root {
      display:none;
    }
    .Db-Dialog-Close {
      box-shadow: 0px 20px 50px rgba(29, 53, 71, 0.3);
    }
  }

  &.Db-Dialog-Centered {
    .MuiDialog-paper {
      position: fixed;
      left: 50vw;
      top: 50vh;
      height: 60vh;
      width: 60vw;
      transform: translate(-50%, -50%);
      margin-left: 0;
      margin-top: 0;
      
      .MuiDialogContent-root * {
        word-break:break-all;
        white-space: pre-wrap;
      }
    }
  }
`
);

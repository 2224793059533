import { User } from 'Models';
import { ReactElement, useRef, useState } from 'react';
import {
  AppBar,
  AppBarProps,
  Avatar,
  MenuPopper,
  MenuItem,
  MenuList,
  Icon,
} from 'Atoms';
import {
  Grid,
  ListItemIcon,
  ListItemText,
  styled,
  Typography,
} from '@mui/material';
import logo from 'Images/logo.svg';
import { FormattedMessage } from 'react-intl';
import gravatar from 'gravatar';
import {
  faRightFromBracket,
  faUserCircle,
} from '@fortawesome/pro-light-svg-icons';

export type TopBarProps = AppBarProps & {
  currentUser?: User;
};

const TopBar = ({ currentUser, ...args }: TopBarProps): ReactElement => {
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const userMenuRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <AppBar elevation={0} {...args}>
        <Grid container alignItems="center">
          <Grid item>
            <img src={logo} className="Db-TopBar-Logo" alt="logo" />
          </Grid>
          <Grid
            item
            className="Db-TopBar-User"
            onClick={() => setUserMenuOpen((val) => !val)}
            ref={userMenuRef}
          >
            {currentUser && (
              <Grid container alignItems="center">
                <Grid item>
                  <Typography variant="subtitle1">
                    <FormattedMessage
                      id="label.helloUser"
                      values={{ name: currentUser.personName.fullName }}
                    />
                  </Typography>
                </Grid>
                <Grid item ml="10px">
                  <Avatar>
                    <img
                      src={gravatar.url(currentUser.emailAddress)}
                      alt="profile"
                    />
                  </Avatar>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </AppBar>
      <MenuPopper
        open={userMenuOpen}
        anchorEl={userMenuRef.current}
        onClose={() => setUserMenuOpen(false)}
      >
        <MenuList>
          <MenuItem>
            <ListItemIcon>
              <Icon icon={faUserCircle} />
            </ListItemIcon>
            <ListItemText>
              <FormattedMessage id="button.profile" />
            </ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemIcon>
              <Icon icon={faRightFromBracket} />
            </ListItemIcon>
            <ListItemText>
              <FormattedMessage id="button.logout" />
            </ListItemText>
          </MenuItem>
        </MenuList>
      </MenuPopper>
    </>
  );
};

export default styled(TopBar)(
  () => `
  background: linear-gradient(90deg, #5A2CD1 0%, #0E009F 100%);
  min-height: 80px;
  justify-content: center;
  align-items: flex-start;
  padding: 0 20px 0 40px;

  .Db-TopBar-Logo {
    height: 40px;
  }

  .Db-TopBar-User {
    margin-left: auto;
    cursor: pointer;
    padding: 5px 0;
  }

`
);

import {
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { FunctionComponent } from 'react';

export type AppBarProps = MuiAppBarProps;

const AppBar: FunctionComponent<AppBarProps> = ({ ...props }: AppBarProps) => (
  <MuiAppBar {...props} />
);

AppBar.defaultProps = {};

export default styled(AppBar)(() => ``);

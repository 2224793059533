export default {
  white: '#fff',
  black: '#000',
  blue: {
    600: '#1C64F2',
    700: '#1A56DB',
  },
  primary: {
    main: '#5A2CD1',
    700: '#6941C6',
  },
  grey: {
    500: '#9E9E9E',
    900: '#212121',
  },
  gray: {
    50: '#FAFAFA',
    100: '#7E858E',
    300: '#D0D5DD',
    400: '#BDBDBD',
    500: '#667085',
    600: '#757575',
    700: '#344054',
    900: '#101828',
  },
  coolGray: {
    50: '#F9FAFB',
    100: '#F3F4F6',
    200: '#E5E7EB',
    400: '#9CA3AF',
    500: '#6B7280',
    700: '#374151',
    900: '#111928',
  },
  orange: {
    100: '#FCD9BD',
    800: '#8A2C0D',
  },
  green: {
    100: '#DEF7EC',
    800: '#03543F',
  },
  red: {
    100: '#FDE8E8',
    800: '#9B1C1C',
  },
  error: {
    500: '#F04438',
  },
  success: {
    500: '#12B76A',
  },
  warning: {
    500: '#F5A623',
  },
  info: {
    500: '#1C64F2',
  },
};

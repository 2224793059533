import { isErrorResponse } from 'ValueObjects';
import { AxiosError } from 'axios';
import { UseFormReturn, FieldValues } from 'react-hook-form';

export interface ValidationStatus extends FieldValues {
  status?: string;
}

export const reactHookFormBackendErrorHandler = <
  T extends ValidationStatus = FieldValues
>(
  exception: AxiosError<unknown>,
  form: UseFormReturn<T>
): void => {
  let handled = false;
  if (isErrorResponse(exception.response?.data)) {
    if (exception.response?.data.message) {
      form.setError(
        // @ts-ignore
        'status',
        {
          type: 'manual',
          message: exception.response?.data.message,
        },
        { shouldFocus: false }
      );

      handled = true;
    }

    if (exception.response?.data.violations) {
      exception.response?.data.violations.forEach((violation) => {
        form.setError(
          // @ts-ignore
          violation.propertyPath,
          { type: 'manual', message: violation.message },
          {
            shouldFocus: false,
          }
        );
      });

      handled = true;
    }
  }

  if (!handled) {
    throw exception;
  }
};

import { Violation } from './Violation';

export interface ErrorResponse {
  code: number;
  message: string;
  violations?: Violation[];
}

export function isErrorResponse(object: unknown): object is ErrorResponse {
  return (
    !!object &&
    Object.prototype.hasOwnProperty.call(object, 'code') &&
    Object.prototype.hasOwnProperty.call(object, 'message')
  );
}

import {
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { AnyObjectSchema } from 'yup';
import {
  DefaultValues,
  SubmitHandler,
  UseFormReturn,
  FormProvider,
  FieldValues,
  useForm,
} from 'react-hook-form';
import { SwitchTransition } from 'react-transition-group';
import { Form } from 'Atoms';
import { yupResolver } from '@hookform/resolvers/yup';
import { usePersistForm } from 'Utils';
import { Box, Slide } from '@mui/material';

export type OverrideSubmitType<FormInput extends FieldValues = FieldValues> =
  | ((form: UseFormReturn<FormInput>) => SubmitHandler<FormInput>)
  | undefined;

export type ReactHookWizardStepActions<
  FormInput extends FieldValues = FieldValues
> = {
  handleNext: (disableOverriddenSubmit?: boolean, force?: boolean) => void;
  handlePrev: () => void;
  setBlockNext: Dispatch<SetStateAction<boolean>>;
  overrideSubmit: Dispatch<SetStateAction<OverrideSubmitType<FormInput>>>;
};

export type ReactHookWizardStepProps<
  FormInput extends FieldValues = FieldValues
> = {
  form: UseFormReturn<FormInput>;
  actions: ReactHookWizardStepActions<FormInput>;
  currentStepIndex: number;
  currentStep: {
    title: string | ReactNode;
    category: string;
    categoryTitle: string | ReactNode;
  };
  wizardSteps: {
    title: string | ReactNode;
    category: string;
    categoryTitle: string | ReactNode;
  }[];
};

export type ReactHookWizardStep<FormInput extends FieldValues = FieldValues> = {
  title: string | ReactNode;
  category: string;
  categoryTitle: string | ReactNode;
  validationSchema?: AnyObjectSchema;
  render: ({
    form,
    actions,
  }: ReactHookWizardStepProps<FormInput>) => ReactElement;
};

export type ReactHookWizardRenderProps<
  FormInput extends FieldValues = FieldValues
> = {
  children: ReactNode | ReactNode[];
  form: UseFormReturn<FormInput>;
  isFirstStep: boolean;
  isLastStep: boolean;
  currentStepIndex: number;
  currentStep: ReactHookWizardStep<FormInput>;
  handleNext: () => void;
  handlePrev: () => void;
};

export type ReactHookWizardProps<FormInput extends FieldValues = FieldValues> =
  {
    wizardSteps: ReactHookWizardStep<FormInput>[];
    initialValues: DefaultValues<FormInput>;
    onSubmit: SubmitHandler<FormInput>;
    initialStepIndex: number;
    children: (props: ReactHookWizardRenderProps<FormInput>) => ReactNode;
    useProvider: boolean;
    persist?: string;
    name: string;
    container?: Element | null;
  };

export const ReactHookWizard = <FormInput extends FieldValues = FieldValues>({
  wizardSteps,
  initialValues,
  onSubmit,
  initialStepIndex,
  children,
  name,
  useProvider,
  persist,
  container,
}: ReactHookWizardProps<FormInput>): ReactElement => {
  const [currentStepIndex, setCurrentStepIndex] =
    useState<number>(initialStepIndex);
  const [blockNext, setBlockNext] = useState<boolean>(false);
  const [changingStep, setChangingStep] = useState<boolean>(false);
  const [temporarySubmit, overrideSubmit] =
    useState<OverrideSubmitType<FormInput>>(undefined);

  const currentStep = useMemo(
    () => wizardSteps[currentStepIndex],
    [wizardSteps, currentStepIndex]
  );

  const isFirstStep = currentStepIndex === 0;
  const isLastStep = currentStepIndex + 1 === wizardSteps.length;

  const handleNextStep = async () => {
    setCurrentStepIndex((value) => value + 1);
  };

  let resolver;
  if (currentStep.validationSchema) {
    resolver = yupResolver(currentStep.validationSchema);
  }

  const storedValues = useMemo(() => {
    const storage = window.localStorage;
    if (persist) {
      const storedData = storage.getItem(persist);
      if (storedData) {
        const result = { ...initialValues, ...JSON.parse(storedData) };
        if (typeof result.currentStepIndex !== 'undefined') {
          setCurrentStepIndex(result.currentStepIndex);
        }

        return result;
      }
    }

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persist]);

  const form = useForm<FormInput>({
    resolver,
    mode: 'all',
    defaultValues: storedValues || initialValues,
  });

  usePersistForm({
    watch: form.watch,
    persist,
    extraData: { currentStepIndex },
  });

  const handleNext = useCallback(
    async (disableOverriddenSubmit = false, force = false) => {
      if ((!force && changingStep) || blockNext) {
        return;
      }

      setChangingStep(true);
      let submitFunction: SubmitHandler<FormInput> = isLastStep
        ? onSubmit
        : handleNextStep;

      if (!disableOverriddenSubmit && temporarySubmit) {
        submitFunction = temporarySubmit(form);
      }

      await form.handleSubmit(submitFunction)();
      setChangingStep(false);
    },
    [changingStep, blockNext, isLastStep, onSubmit, temporarySubmit, form]
  );

  const handlePrev = useCallback(async () => {
    if (changingStep || isFirstStep || form.formState.isSubmitting) {
      return;
    }

    setChangingStep(true);
    setCurrentStepIndex((value) => value - 1);
    setChangingStep(false);
    form.clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    changingStep,
    form.formState.isSubmitting,
    form.clearErrors,
    isFirstStep,
  ]);

  let content = (
    <>
      {children({
        children: (
          <span>
            <SwitchTransition mode="out-in">
              <Slide timeout={175} key={currentStepIndex} container={container}>
                <Box className="step">
                  {currentStep.render({
                    form,
                    currentStepIndex,
                    currentStep,
                    wizardSteps,
                    actions: {
                      handleNext,
                      handlePrev,
                      setBlockNext,
                      overrideSubmit,
                    },
                  })}
                </Box>
              </Slide>
            </SwitchTransition>
          </span>
        ),
        form,
        isFirstStep,
        isLastStep,
        currentStepIndex,
        currentStep,
        handleNext: () => handleNext(),
        handlePrev: () => handlePrev(),
      })}
    </>
  );

  if (useProvider) {
    content = <FormProvider {...form}>{content}</FormProvider>;
  }

  return (
    <Form onSubmit={() => handleNext()} name={name}>
      {content}
    </Form>
  );
};

export default ReactHookWizard;

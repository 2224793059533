import {
  ClickAwayListener,
  Fade,
  Paper,
  Popper as MuiPopper,
  PopperProps as MuiPopperProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactElement, ReactNode } from 'react';

export type MenuPopperProps = MuiPopperProps & {
  onClose: () => void;
  children: ReactNode | ReactNode[];
};

const MenuPopper = ({
  children,
  onClose,
  ...props
}: MenuPopperProps): ReactElement => (
  <MuiPopper {...props} transition>
    {({ TransitionProps }) => (
      <ClickAwayListener onClickAway={onClose}>
        <Fade {...TransitionProps} timeout={350}>
          <Paper className="MuiMenuPopper-paper">{children}</Paper>
        </Fade>
      </ClickAwayListener>
    )}
  </MuiPopper>
);

MenuPopper.defaultProps = {};

export default styled(MenuPopper)(
  ({ theme }) => `
  z-index: 1300;
  .MuiMenuPopper-paper {
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    box-shadow: 0px 20px 50px rgba(29, 53, 71, 0.1);
    padding: ${theme.spacing(3)};
    border-radius: 8px;
    width: 210px;
    box-sizing: border-box;
  }
`
);

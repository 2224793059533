import { ReactElement } from 'react';
import { Log } from 'Models';
import {
  Dialog,
  DialogProps,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'Molecules';
import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Button, Icon } from 'Atoms';
import { faPhone, faTimes } from '@fortawesome/pro-solid-svg-icons';
import colors from 'App/colors';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { useIsSuperAdmin } from 'Utils';

export type LogInsightsModalProps = Omit<DialogProps, 'title' | 'children'> & {
  log: Log;
};

const LogInsightsModal = ({
  log,
  ...props
}: LogInsightsModalProps): ReactElement => {
  const isSuperAdmin = useIsSuperAdmin();
  return (
    <Dialog maxWidth="md" fullWidth {...props}>
      <DialogTitle>
        <FormattedMessage id={`packageTypes.${log.packageType}`} />
        {`: ${log.message}`}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ width: '100%', overflow: 'hidden' }}>
          <Table sx={{ tableLayout: 'fixed' }}>
            <TableBody>
              <TableRow>
                <TableCell variant="head">
                  <FormattedMessage id="label.logId" />:
                </TableCell>
                <TableCell>{log.mongoId}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">
                  <FormattedMessage id="label.packageType" />:
                </TableCell>
                <TableCell>
                  <FormattedMessage id={`packageTypes.${log.packageType}`} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">
                  <FormattedMessage id="label.error" />:
                </TableCell>
                <TableCell>{log.message}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">
                  <FormattedMessage id="label.date" />:
                </TableCell>
                <TableCell>
                  <FormattedDate value={log.createdAt} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">
                  <FormattedMessage id="label.solution" />:
                </TableCell>
                <TableCell>
                  {log.solution || (
                    <FormattedMessage id="label.contactSupport" />
                  )}
                </TableCell>
              </TableRow>
              {log.wiki && (
                <TableRow>
                  <TableCell variant="head">
                    <FormattedMessage id="label.wikiLink" />
                  </TableCell>
                  <TableCell>
                    <a
                      href={log.wiki}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {log.wiki}
                    </a>
                  </TableCell>
                </TableRow>
              )}
              {isSuperAdmin && (
                <>
                  {log.internal && (
                    <TableRow>
                      <TableCell>
                        <FormattedMessage id="label.internalLog" />
                      </TableCell>
                      <TableCell>
                        <SyntaxHighlighter language="json" style={atomOneDark}>
                          {JSON.stringify(log.internal, null, 2)}
                        </SyntaxHighlighter>
                      </TableCell>
                    </TableRow>
                  )}
                  {log.request && (
                    <TableRow>
                      <TableCell>
                        <FormattedMessage id="label.request" />
                      </TableCell>
                      <TableCell>
                        <SyntaxHighlighter language="json" style={atomOneDark}>
                          {JSON.stringify(log.request, null, 2)}
                        </SyntaxHighlighter>
                      </TableCell>
                    </TableRow>
                  )}
                  {log.response && (
                    <TableRow>
                      <TableCell>
                        <FormattedMessage id="label.response" />
                      </TableCell>
                      <TableCell>
                        <SyntaxHighlighter language="json" style={atomOneDark}>
                          {JSON.stringify(log.response, null, 2)}
                        </SyntaxHighlighter>
                      </TableCell>
                    </TableRow>
                  )}
                  {log.extraData && (
                    <TableRow>
                      <TableCell>
                        <FormattedMessage id="label.extraData" />
                      </TableCell>
                      <TableCell>
                        <SyntaxHighlighter language="json" style={atomOneDark}>
                          {JSON.stringify(log.extraData, null, 2)}
                        </SyntaxHighlighter>
                      </TableCell>
                    </TableRow>
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          size="medium"
          onClick={() => {
            window.open(process.env.REACT_APP_SUPPORT_URL, '_blank');
          }}
        >
          <Icon icon={faPhone} />
          &nbsp; <FormattedMessage id="button.contactSupport" />
        </Button>
        <Button
          color="quaternary"
          size="medium"
          onClick={() => props.onClose?.({}, 'escapeKeyDown')}
        >
          <Icon icon={faTimes} />
          &nbsp; <FormattedMessage id="button.close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default styled(LogInsightsModal)(`
.MuiTableCell-head {
    height: 54px;
    width: 113px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.17px;
    color: ${colors.coolGray[900]};
}
`);

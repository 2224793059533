import { Uuid } from 'ValueObjects';
import { Organization } from 'Models';
import { isObjectResponse } from 'Requests';
import { TaskStatus } from './TaskStatus';

export interface AbstractTask {
  taskId: Uuid;
  organization: Organization;
  taskStatus: TaskStatus;
  parentTask?: AbstractTask;
  data?: unknown;
  createdAt: Date;
  startedAt?: Date;
  finishedAt?: Date;
  connectionId?: Uuid;
}

export function isAbstractTask(object: unknown): object is AbstractTask {
  return (
    !!object &&
    isObjectResponse(object) &&
    ['ModuleTask', 'SubModuleTask'].includes(object['@type'])
  );
}

import * as Yup from 'yup';
import { Organization } from 'Models';

export interface AddOrganizationAccessInputType {
  organization?: Organization | null;
}

export const AddOrganizationAccessInputTypeValidationBuilder =
  (): Yup.SchemaOf<AddOrganizationAccessInputType> =>
    // @ts-ignore
    Yup.object({
      organization: Yup.object({
        organizationId: Yup.string().required().label('label.organization'),
      }).required(),
    });

export const AddOrganizationAccessInputTypeDefaultValues =
  (): AddOrganizationAccessInputType => ({
    organization: null,
  });

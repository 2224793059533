import { ReactElement, ReactNode } from 'react';
import { Box, styled } from '@mui/material';
import clsx from 'clsx';
import { TwoSidesTemplate } from 'Templates';
import loginLeftSideBackground from 'Images/Background/LoginRightSide.svg';
import { Footer } from 'Organisms';
import logoBlue from 'Images/logo-blue.svg';
import colors from 'App/colors';

export type AuthenticationTemplateProps = {
  className?: string;
  children: ReactNode | ReactNode[];
};

const AuthenticationTemplate = ({
  className,
  children,
}: AuthenticationTemplateProps): ReactElement => (
  <TwoSidesTemplate
    className={clsx('Db-AuthenticationTemplate', className)}
    leftSide={
      <>
        <img
          src={logoBlue}
          alt="logo"
          className="Db-AuthenticationTemplate-Logo"
        />
        <Box className="Db-AuthenticationTemplate-Container">{children}</Box>
        <Footer className="Db-AuthenticationTemplate-Footer" />
      </>
    }
  />
);

export default styled(AuthenticationTemplate)(
  ({ theme }) => `
  display: flex;
  height: 100%;
  .Db-AuthenticationTemplate-Logo {
    position: fixed;
    top: ${theme.spacing(4)};
    left: ${theme.spacing(4)};
  }
  

  .Db-AuthenticationTemplate-Container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.coolGray[50]};
  }  
  
  .Db-AuthenticationTemplate-Footer {
      position: fixed;
      left: 0;
      bottom: 0;
      color:#667085;
      width:inherit;
  }
  
  .Db-TwoSidesTemplate-RightSide {
    background-image: url(${loginLeftSideBackground});
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .Db-AuthenticationTemplate-RightSide {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .MuiTypography-root {
      text-align: center;
      color: ${theme.palette.common.white};
    }
  }
`
);

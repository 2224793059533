import { BaseSyntheticEvent, useCallback, useState } from 'react';
import { FieldValues, SubmitHandler, UseFormReturn } from 'react-hook-form';
import axios from 'axios';
import { isBackendViolations } from 'Mutations';
import { SnackbarKey, useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';

// eslint-disable-next-line import/prefer-default-export
export const useFormBackendHandler = <
  TFieldValues extends FieldValues = FieldValues
>(
  formHandler: SubmitHandler<TFieldValues>,
  form: UseFormReturn<TFieldValues>
): SubmitHandler<TFieldValues> => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { formatMessage } = useIntl();
  const [snackId, setSnackId] = useState<SnackbarKey>();

  // @ts-ignore
  return useCallback(
    async (
      data: TFieldValues,
      event?: BaseSyntheticEvent
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Promise<any> => {
      if (snackId) {
        closeSnackbar(snackId);
        setSnackId(undefined);
      }

      try {
        return await formHandler(data, event);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const response = error.response?.data;
          if (isBackendViolations(response)) {
            response.violations.forEach((violation) => {
              // @ts-ignore
              form.setError(violation.propertyPath, {
                type: 'manual',
                message: violation.message,
              });
            });

            // @ts-ignore
            form.setError('backendErrors', {
              type: 'manual',
              message: response.violations.map((violation) => {
                const name = formatMessage({
                  id: `label.${violation.propertyPath}`,
                });

                return `${name}: ${violation.message}`;
              }),
            });

            return false;
          }
        }

        const newId = enqueueSnackbar(
          formatMessage({ id: 'label.somethingWentWrongDuringSaving' }),
          {
            variant: 'error',
          }
        );

        setSnackId(newId);

        throw error;
      }
    },
    [closeSnackbar, enqueueSnackbar, form, formHandler, formatMessage, snackId]
  );
};

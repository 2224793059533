import { FunctionComponent, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { AuthState, AuthStateDefaultValue, UserState } from 'State';
import { useNavigate } from 'react-router-dom';

const LogoutPage: FunctionComponent = () => {
  const setAuthState = useSetRecoilState(AuthState);
  const setUserState = useSetRecoilState(UserState);
  const navigate = useNavigate();

  useEffect(() => {
    setAuthState(AuthStateDefaultValue);
    setUserState(undefined);
    navigate('/login');
  }, [navigate, setAuthState, setUserState]);

  return <span />;
};

export default LogoutPage;

import { ReactElement, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  useConnectionByConnectionIdRequestQuery,
  useLogsByConnectionIdRequest,
  useModulesByConnectionIdRequest,
  useTasksByConnectionIdRequest,
} from 'Requests';
import {
  Box,
  BoxProps,
  Grid,
  IconButton,
  styled,
  Typography,
} from '@mui/material';
import { Button, Icon } from 'Atoms';
import { faInfoCircle, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import colors from 'App/colors';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { LogLevelChip, TaskStatusChip } from 'Molecules';
import { DataGridTemplate } from 'Templates/DataGridTemplate';
import useDataGridDataWithReactQuery from 'Utils/useDataGridDataWithReactQuery';
import { AbstractTask, Log } from 'Models';
import { Uuid } from 'ValueObjects';
import dayjs from 'dayjs';
import { ConnectionDetailsHeader, LogInsightsModal } from 'Organisms';
import { useConnectionAlerts } from 'Utils';
import { isModuleTask } from 'Models/Synchronizer/Task/ModuleTask';
import { isSubModuleTask } from 'Models/Synchronizer/Task/SubModuleTask';
import { ConfirmDialog } from 'Organisms/ConfirmDialog';
import {
  useClearLogsForConnectionMutation,
  useClearTasksForConnectionMutation,
} from 'Mutations';

const ConnectionDetailsPage = ({ ...props }: BoxProps): ReactElement => {
  const { connectionId } = useParams();
  const [logInsights, setShowInsights] = useState<Log>();
  const [isClearingTasks, setIsClearingTasks] = useState<boolean>(false);
  const [isClearingLogs, setIsClearingLogs] = useState<boolean>(false);

  const { mutateAsync: clearTasksMutation } =
    useClearTasksForConnectionMutation();
  const { mutateAsync: clearLogsMutation } =
    useClearLogsForConnectionMutation();
  const { data: modulesData } = useModulesByConnectionIdRequest(
    { connectionId: connectionId || '' },
    { enabled: !!connectionId }
  );

  const modules = useMemo(
    () => modulesData?.data?.['hydra:member'] || [],
    [modulesData?.data]
  );

  const { data, isInitialLoading } = useConnectionByConnectionIdRequestQuery(
    {
      connectionId: connectionId || '',
    },
    {
      enabled: !!connectionId,
    }
  );

  const tasksGridData = useDataGridDataWithReactQuery<
    AbstractTask,
    { connectionId: Uuid }
  >({
    overviewPage: true,
    request: {
      connectionId: connectionId || '',
    },
    requestOptions: {
      enabled: !!connectionId,
    },
    reactQuery: useTasksByConnectionIdRequest,
    autoHeight: false,
    getRowId: (row) => row.taskId,
    columns: [
      {
        field: 'taskId',
        headerName: 'label.name',
        minWidth: 350,
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => {
          if (isModuleTask(row) || isSubModuleTask(row)) {
            const module = modules.find((m) => m.moduleId === row.moduleId);

            return module ? (
              <FormattedMessage
                id={`moduleTypes.${module.packageType}.${module.moduleName}`}
                defaultMessage={module.moduleName}
              />
            ) : (
              <FormattedMessage id="label.unknown" />
            );
          }

          return <FormattedMessage id="label.unknown" />;
        },
      },
      {
        field: 'taskStatus.value',
        headerName: 'label.status',
        minWidth: 200,
        flex: 1,

        renderCell: ({ row }) => <TaskStatusChip status={row.taskStatus} />,
      },
      {
        field: 'createdAt',
        headerName: 'label.initiated',
        flex: 1,
        minWidth: 200,
        renderCell: ({ row }) =>
          row.createdAt && (
            <FormattedDate
              value={dayjs(row.createdAt).toDate()}
              dateStyle="short"
              timeStyle="medium"
            />
          ),
      },
      {
        field: 'startedAt',
        headerName: 'label.started',
        flex: 1,
        minWidth: 200,
        renderCell: ({ row }) =>
          row.startedAt && (
            <FormattedDate
              value={dayjs(row.startedAt).toDate()}
              dateStyle="short"
              timeStyle="medium"
            />
          ),
      },
      {
        field: 'finishedAt',
        headerName: 'label.finished',
        flex: 1,
        minWidth: 200,
        renderCell: ({ row }) =>
          row.finishedAt && (
            <FormattedDate
              value={dayjs(row.finishedAt).toDate()}
              dateStyle="short"
              timeStyle="short"
            />
          ),
      },
      {
        field: 'actions',
        type: 'actions',
        renderHeader: () => (
          <IconButton onClick={() => setIsClearingTasks(true)} size="small">
            <Icon icon={faTrashCan} />
          </IconButton>
        ),
        flex: 1,
        minWidth: 60,
        sortable: false,
        renderCell: () => <span />,
        getActions: () => [<span />],
      },
    ],
  });

  const logsGridData = useDataGridDataWithReactQuery<
    Log,
    { connectionId: Uuid }
  >({
    overviewPage: true,
    request: {
      connectionId: connectionId || '',
    },
    requestOptions: {
      enabled: !!connectionId,
    },
    reactQuery: useLogsByConnectionIdRequest,
    getRowId: (row) => row.mongoId,
    autoHeight: false,
    columns: [
      {
        field: 'message',
        headerName: 'label.message',
        flex: 1,
        minWidth: 350,
        renderCell: ({ row }) => (
          <span>
            {row.packageType && (
              <span>
                <FormattedMessage id={`packageTypes.${row.packageType}`} />{' '}
              </span>
            )}
            {row.message}
          </span>
        ),
      },
      {
        field: 'logLevel',
        headerName: 'label.status',
        minWidth: 200,
        flex: 1,
        renderCell: ({ row }) => <LogLevelChip logLevel={row.logLevel} />,
      },
      {
        field: 'createdAt',
        headerName: 'label.date',
        flex: 1,
        minWidth: 200,
        renderCell: ({ row }) =>
          row.createdAt && (
            <FormattedDate
              value={dayjs(row.createdAt).toDate()}
              dateStyle="short"
              timeStyle="medium"
            />
          ),
      },
      {
        field: 'mongoId',
        flex: 1,
        headerName: 'label.howToFix',
        minWidth: 125,
        sortable: false,
        renderCell: ({ row }) => (
          <Button
            variant="contained"
            color="tertiary"
            size="small"
            onClick={() => setShowInsights(row)}
          >
            <Icon icon={faInfoCircle} />
            &nbsp;
            <FormattedMessage id="button.insights" />
          </Button>
        ),
      },
      {
        field: 'actions',
        type: 'actions',
        renderHeader: () => (
          <IconButton onClick={() => setIsClearingLogs(true)} size="small">
            <Icon icon={faTrashCan} />
          </IconButton>
        ),
        flex: 1,
        minWidth: 60,
        sortable: false,
        renderCell: () => <span />,
        getActions: () => [<span />],
      },
    ],
  });

  const connection = useMemo(() => data?.data, [data]);
  const alerts = useConnectionAlerts({ connection });

  const handleClearTasks = useCallback(
    async (force = false) => {
      if (connectionId) {
        await clearTasksMutation({
          connectionId,
          force,
        });
        setIsClearingTasks(false);
      }
    },
    [clearTasksMutation, connectionId]
  );

  const handleClearLogs = useCallback(async () => {
    if (connectionId) {
      await clearLogsMutation({
        connectionId,
      });

      setIsClearingLogs(false);
    }
  }, [clearLogsMutation, connectionId]);

  if (!connection || isInitialLoading) {
    return <div />;
  }

  return (
    <Box {...props}>
      <ConnectionDetailsHeader
        connection={connection}
        alerts={alerts}
        currentPage="details"
      />
      <Box className="Db-ConnectionDetailsPage-Content">
        <Grid
          container
          spacing={{
            xs: 3,
            md: 9,
          }}
        >
          <Grid item xs={6}>
            <Typography variant="h6">
              <FormattedMessage id="label.connection" />
              &nbsp;
              <span className="Db-ConnectionDetailsPage-Content-Bold">
                <FormattedMessage id="label.tasks" />
              </span>
            </Typography>
            <DataGridTemplate {...tasksGridData} />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6">
              <FormattedMessage id="label.connection" />
              &nbsp;
              <span className="Db-ConnectionDetailsPage-Content-Bold">
                <FormattedMessage id="label.logs" />
              </span>
            </Typography>
            <DataGridTemplate {...logsGridData} />
          </Grid>
        </Grid>
      </Box>
      <Box className="Db-ConnectionDetailsPage-Footer" />
      {logInsights && (
        <LogInsightsModal
          log={logInsights}
          open={!!logInsights}
          onClose={() => setShowInsights(undefined)}
        />
      )}
      {isClearingTasks && (
        <ConfirmDialog
          open={isClearingTasks}
          onCancel={() => setIsClearingTasks(false)}
          onClose={() => setIsClearingTasks(false)}
          onConfirm={async () => handleClearTasks(false)}
          confirmLabel={<FormattedMessage id="label.clearOldAndFailedTasks" />}
          title={<FormattedMessage id="label.clearTasks" />}
          content={<FormattedMessage id="label.clearTasksConfirmation" />}
          extraActions={
            <Button
              onClick={async () => handleClearTasks(true)}
              autoFocus
              color="primary"
            >
              <FormattedMessage id="label.clearAllTasks" />
            </Button>
          }
        />
      )}
      {isClearingLogs && (
        <ConfirmDialog
          open={isClearingLogs}
          onCancel={() => setIsClearingLogs(false)}
          onClose={() => setIsClearingLogs(false)}
          onConfirm={async () => handleClearLogs()}
          title={<FormattedMessage id="label.clearLogs" />}
          content={<FormattedMessage id="label.clearLogsConfirmation" />}
        />
      )}
    </Box>
  );
};

export default styled(ConnectionDetailsPage)(`
display: flex;
flex-direction: column;
width: 100%;

.Db-ConnectionDetailsPage-Content {
  background: ${colors.white};
  margin-top: 32px;
  padding: 16px 32px;
  padding-bottom: 64px;

  & > .MuiGrid-container {
    height: 750px;
    .MuiDataGrid-main {
      margin-bottom: 16px;
    }
  }

  .MuiTypography-h6 {
    margin-left: 10px;
    margin-bottom: 18px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
  }

  .Db-ConnectionDetailsPage-Content-Bold {
    font-weight: bold;
  }
}

.Db-ConnectionDetailsPage-Footer { 
  height: 32px;
}

.MuiButtonGroup-root .MuiButton-sizeLarge {
  background: ${colors.white};
}
`);

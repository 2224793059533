import {
  DialogTitle as MuiDialogTitle,
  DialogTitleProps as MuiDialogTitleProps,
  styled,
} from '@mui/material';
import { ReactElement } from 'react';
import colors from 'App/colors';

export type DialogTitleProps = MuiDialogTitleProps;
const DialogTitle = ({ ...props }: DialogTitleProps): ReactElement => (
  <MuiDialogTitle {...props} />
);

const StyledDialog = styled(DialogTitle)(
  () => `
  color: ${colors.grey[900]};
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
  
  padding: 24px 16px;
  
  border-bottom: 1px solid ${colors.coolGray[200]};
  
  &+.MuiDialogContent-root {
    padding-top: 8px !important;
  }
`
);

const FinalDialog = ({ ...props }: DialogTitleProps) => (
  <StyledDialog {...props} />
);

export default FinalDialog;

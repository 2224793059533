import { ReactElement, useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserByUserIdRequestQuery } from 'Requests';
import { ReactHookForm } from 'Atoms';
import {
  DisableTwoFactorAuthenticationInputType,
  UserProfileInputType,
  UserProfileInputTypeDefaultValues,
  UserProfileInputTypeValidationBuilder,
  AddOrganizationAccessInputType,
  UploadProfilePictureInputType,
} from 'InputTypes';
import { UserProfilePageTemplate } from 'Templates';
import {
  AddOrganizationAccessFormDialog,
  DisableTwoFactorAuthenticationFormDialog,
} from 'Dialogs';
import { useRecoilState } from 'recoil';
import { UserState } from 'State';
import {
  useDeleteUserMutation,
  useForceDisableTwoFactorMutation,
  useRemoveAccessToOrganizationsMutation,
  useUpdateUserMutation,
  useUpdateUserRoleMutation,
  useAddAccessToOrganizationsMutation,
  useUpdateUserProfilePictureMutation,
} from 'Mutations';
import { useIsSuperAdmin } from 'Utils';
import { Organization } from 'Models';
import { ConfirmDialog } from 'Organisms/ConfirmDialog';
import { FormattedMessage } from 'react-intl';
import { UploadProfilePictureFormDialog } from 'Organisms';

const UserDetailsPage = (): ReactElement => {
  const { userId } = useParams();
  const [updatingProfileImage, setUpdatingProfileImage] =
    useState<boolean>(false);

  const [disablingTwoFactor, setDisablingTwoFactor] = useState<boolean>(false);
  const [addingOrganization, setAddingOrganization] = useState<boolean>(false);
  const [removingOrganization, setRemovingOrganization] =
    useState<Organization>();
  const [removingUser, setRemovingUser] = useState<boolean>(false);

  const { mutateAsync: updateUser } = useUpdateUserMutation();
  const { mutateAsync: disableTwoFactor } = useForceDisableTwoFactorMutation();
  const { mutateAsync: updateUserRole } = useUpdateUserRoleMutation();
  const { mutateAsync: changeProfilePicture } =
    useUpdateUserProfilePictureMutation();

  const { mutateAsync: deleteUser } = useDeleteUserMutation();
  const { mutateAsync: removeAccessToOrganization } =
    useRemoveAccessToOrganizationsMutation();
  const { mutateAsync: addAccessToOrganization } =
    useAddAccessToOrganizationsMutation();

  const navigate = useNavigate();
  const isSuperAdmin = useIsSuperAdmin();
  const [currentUser] = useRecoilState(UserState);

  const { data, refetch } = useUserByUserIdRequestQuery(
    {
      userId: userId || '',
    },
    {
      enabled: !!userId,
    }
  );

  const user = useMemo(() => data?.data, [data]);
  const isCurrentUser = useMemo(
    () => data?.data?.userId === currentUser?.userId,
    [currentUser?.userId, data?.data?.userId]
  );

  const handleUpdateProfile = useCallback(
    async (values: UserProfileInputType) => {
      if (!user) {
        return;
      }

      if (isSuperAdmin && values.role) {
        await updateUserRole({
          userId: user.userId,
          role: values.role,
        });
      }

      if (
        values.emailAddress !== user.emailAddress ||
        values.firstName !== user.personName.firstName ||
        values.middleName !== user.personName.middleName ||
        values.lastName !== user.personName.lastName
      ) {
        await updateUser({
          userId: user.userId,
          firstName: values.firstName,
          middleName: values.middleName,
          lastName: values.lastName,
          emailAddress: values.emailAddress,
        });
      }

      if (isCurrentUser) {
        navigate('/logout');
      }
      await refetch();
    },
    [
      isCurrentUser,
      isSuperAdmin,
      navigate,
      refetch,
      updateUser,
      updateUserRole,
      user,
    ]
  );

  const handleRemoveUser = useCallback(async () => {
    if (userId) {
      await deleteUser({
        userId,
      });
    }

    navigate('/users');
  }, [deleteUser, navigate, userId]);

  const handleRemoveOrganizationAccess = useCallback(async () => {
    if (removingOrganization && userId) {
      await removeAccessToOrganization({
        userId,
        organizationsIds: [removingOrganization?.organizationId],
      });
    }
    await refetch();
    setRemovingOrganization(undefined);
  }, [refetch, removeAccessToOrganization, removingOrganization, userId]);

  const handleUpdateProfilePicture = useCallback(
    async ({ fileId }: UploadProfilePictureInputType) => {
      await changeProfilePicture({
        userId: userId || '',
        profilePictureId: fileId,
      });
      await refetch();
      setUpdatingProfileImage(false);
    },
    [changeProfilePicture, userId, refetch]
  );

  const handleAddOrganizationAccess = useCallback(
    async (values: AddOrganizationAccessInputType) => {
      if (values.organization && userId) {
        await addAccessToOrganization({
          userId,
          organizationsIds: [values.organization.organizationId],
        });
      }
      await refetch();
      setAddingOrganization(false);
    },
    [refetch, addAccessToOrganization, userId]
  );

  const handleDisableTwoFactor = useCallback(
    async (values: DisableTwoFactorAuthenticationInputType) => {
      await disableTwoFactor({
        userId: user?.userId || '',
        password: values.password || '',
      });

      await refetch();
      setDisablingTwoFactor(false);
    },
    [refetch, disableTwoFactor, user?.userId]
  );

  if (!user) {
    return <span />;
  }

  return (
    <ReactHookForm<UserProfileInputType>
      defaultValues={{
        ...UserProfileInputTypeDefaultValues(),
        firstName: user.personName.firstName,
        middleName: user.personName.middleName,
        lastName: user.personName.lastName,
        emailAddress: user.emailAddress,
        role: user.roles[0],
      }}
      validationSchema={UserProfileInputTypeValidationBuilder()}
      hideBackendErrors
      useProvider
      name="profile"
      onSubmit={handleUpdateProfile}
    >
      <UserProfilePageTemplate
        user={user}
        onUpdateProfileImage={() => setUpdatingProfileImage(true)}
        onStartAddingOrganization={() => setAddingOrganization(true)}
        onChangeTwoFactorAuthentication={() =>
          user?.totpAuthenticationEnabled
            ? setDisablingTwoFactor(true)
            : undefined
        }
        onRemoveOrganization={setRemovingOrganization}
        allowEditingOrganizations={
          isSuperAdmin
            ? true
            : [
                ...(currentUser?.accessToOrganizations || []).map(
                  ({ organization }) => organization.organizationId
                ),
              ]
        }
        allowEditingRole={isSuperAdmin}
        allowRemovingUser={isSuperAdmin}
        onRemoveUser={() => setRemovingUser(true)}
        disableTwoFactorAuthentication={!user?.totpAuthenticationEnabled}
        hidePassword
      />
      {disablingTwoFactor && (
        <DisableTwoFactorAuthenticationFormDialog
          open={disablingTwoFactor}
          onClose={() => setDisablingTwoFactor(false)}
          onSubmit={handleDisableTwoFactor}
          asSuperAdmin={isSuperAdmin}
        />
      )}
      {removingUser && (
        <ConfirmDialog
          open={removingUser}
          onCancel={() => setRemovingUser(false)}
          onClose={() => setRemovingUser(false)}
          onConfirm={handleRemoveUser}
          dangerous
          title={
            <FormattedMessage
              id="label.deleteUser"
              values={{ name: user.personName.fullName }}
            />
          }
          content={
            <FormattedMessage
              id="label.areYouSureYouWantToDeleteThisUser"
              values={{ name: user.personName.fullName }}
            />
          }
        />
      )}
      {removingOrganization && (
        <ConfirmDialog
          open={!!removingOrganization}
          onCancel={() => setRemovingOrganization(undefined)}
          onClose={() => setRemovingOrganization(undefined)}
          onConfirm={handleRemoveOrganizationAccess}
          dangerous
          title={
            <FormattedMessage
              id="label.removingOrganizationAccess"
              values={{ name: removingOrganization.organizationName }}
            />
          }
          content={
            <FormattedMessage
              id="label.areYouSureYouWantToRemovingOrganizationAccessDescription"
              values={{ name: removingOrganization.organizationName }}
            />
          }
        />
      )}
      {addingOrganization && (
        <AddOrganizationAccessFormDialog
          open={addingOrganization}
          onClose={() => setAddingOrganization(false)}
          onSubmit={handleAddOrganizationAccess}
        />
      )}
      {updatingProfileImage && (
        <UploadProfilePictureFormDialog
          open={updatingProfileImage}
          onSubmit={handleUpdateProfilePicture}
          onClose={() => setUpdatingProfileImage(false)}
        />
      )}
    </ReactHookForm>
  );
};

export default UserDetailsPage;

import { styled } from '@mui/material';

import {
  TabList as MuiTabList,
  TabListProps as MuiTabListProps,
} from '@mui/lab';

import { ReactElement } from 'react';

export type TabListProps = MuiTabListProps;

const TabList = ({ children, ...props }: TabListProps): ReactElement => (
  <MuiTabList {...props}>{children}</MuiTabList>
);

TabList.defaultProps = {};

export default styled(TabList)(
  () => `
  &.MuiTabs-root {
    .MuiTabs-scroller {
      .MuiTabs-indicator {
        height: 100%;
        top: 0;
        bottom: 0;
        background: transparent;
        transition: none;
        
        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          background: currentColor;
          height: 4px;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }
        
        &:after {
          content: '';
          display: block;
          background: white;
          position: absolute;
          border-left: 1px solid #C4C4C4;
          border-right: 1px solid #C4C4C4;
          left: 0;
          right: 0;
          bottom: 0;
          height: 1px;
        }
      }   
    } 
  }  
`
);

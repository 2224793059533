import { Step as MuiStep, StepProps as MuiStepProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FunctionComponent } from 'react';

export type StepProps = MuiStepProps;

const Step: FunctionComponent<StepProps> = ({ ...props }: StepProps) => (
  <MuiStep {...props} />
);

Step.defaultProps = {};

export default styled(Step)(() => ``);

import { ReactElement, useState } from 'react';
import { Box, IconButton, ListItemIcon, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import logoBlue from 'Images/logo-blue.svg';
import logoBadge from 'Images/logo-badge.svg';
import { Avatar, List, SidebarListItem } from 'Atoms';
import { User } from 'Models';
import clsx from 'clsx';
import colors from 'App/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBuilding,
  faChevronLeft,
  faChevronRight,
  faCircleNodes,
  faShield,
  faUsers,
} from '@fortawesome/pro-light-svg-icons';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useGetProfileImage } from 'Utils';

export type SidebarProps = {
  currentUser: User;
  className?: string;
};

const Sidebar = ({
  currentUser,
  className,
  ...props
}: SidebarProps): ReactElement => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const profileImage = useGetProfileImage(currentUser);

  const handleClickDrawer = () => {
    setOpen(!open);
  };

  return (
    <Box {...props} className={clsx(className, { 'Db-Sidebar-Open': open })}>
      <Box className="Db-Sidebar-Background" />
      <Box className="Db-Sidebar-Content">
        <Box className="DB-Sidebar-DrawerHeader">
          <IconButton
            disableFocusRipple
            disableTouchRipple
            disableRipple
            onClick={handleClickDrawer}
            className="DB-Sidebar-DrawerHeader-Icon"
          >
            {open ? (
              <FontAwesomeIcon icon={faChevronLeft} />
            ) : (
              <FontAwesomeIcon icon={faChevronRight} />
            )}
          </IconButton>

          <img
            src={open ? logoBlue : logoBadge}
            alt="logo"
            className="Db-Sidebar-Logo"
          />
        </Box>

        {!open && (
          <List className="Db-Sidebar-ClosedList">
            <SidebarListItem closedMenu disablePadding to="/connections">
              <ListItemIcon>
                <FontAwesomeIcon icon={faCircleNodes} />
              </ListItemIcon>
            </SidebarListItem>
            <SidebarListItem closedMenu disablePadding to="/credentials">
              <ListItemIcon>
                <FontAwesomeIcon icon={faShield} />
              </ListItemIcon>
            </SidebarListItem>
            <SidebarListItem closedMenu disablePadding to="/organizations">
              <ListItemIcon>
                <FontAwesomeIcon icon={faBuilding} />
              </ListItemIcon>
            </SidebarListItem>
            <SidebarListItem closedMenu disablePadding to="/users">
              <ListItemIcon>
                <FontAwesomeIcon icon={faUsers} />
              </ListItemIcon>
            </SidebarListItem>
          </List>
        )}

        {open && (
          <Box className="Db-Sidebar-Menu">
            <List className="Db-Sidebar-OpenList" disablePadding>
              <SidebarListItem to="/connections">
                <FontAwesomeIcon icon={faCircleNodes} />
                <FormattedMessage id="label.connections" />
              </SidebarListItem>
              <SidebarListItem to="/credentials">
                <FontAwesomeIcon icon={faShield} />
                <FormattedMessage id="label.credentials" />
              </SidebarListItem>
              <SidebarListItem to="/organizations">
                <FontAwesomeIcon icon={faBuilding} />
                <FormattedMessage id="label.organizations" />
              </SidebarListItem>
              <SidebarListItem to="/users">
                <FontAwesomeIcon icon={faUsers} />
                <FormattedMessage id="label.users" />
              </SidebarListItem>
            </List>
          </Box>
        )}

        <Box className="Db-Sidebar-User">
          <IconButton
            disableFocusRipple
            disableRipple
            disableTouchRipple
            onClick={() => navigate('/profile')}
          >
            <Avatar className="Db-Sidebar-User-Avatar" src={profileImage} />
          </IconButton>
          <Box className="Db-Sidebar-User-Info">
            <Typography className="Db-Sidebar-User-Info-Name">
              {currentUser.personName?.firstName}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

Sidebar.defaultProps = {
  className: undefined,
};

export default styled(Sidebar)(
  ({ theme }) => `
  width: 114px;
  box-sizing: border-box;
  border-radius: 0px 20px 20px 0px;
  overflow: hidden;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  transition: width 0.5s;
  height: 100%;
  min-height: 100vh;
  
  .Db-Sidebar-Background {
    background: linear-gradient(90deg, #5A2CD1 0%, #0E009F 100%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: opacity 0.5s;
    opacity: 1;
  }
  
  .Db-Sidebar-Content {
    padding-top: ${theme.spacing(4)};
    height: 100%;
    box-sizing: border-box;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .DB-Sidebar-DrawerHeader {
    display: flex;
    flex-direction: column;
    .Db-Sidebar-Logo {
      z-index: 1;
      height: 47px;
    }
    .DB-Sidebar-DrawerHeader-Icon {
      color: ${colors.white};
      font-size: 10px;
      margin-bottom:21px;
    }
  }
  
  .Db-Sidebar-User {
    position: absolute;
    bottom: 30px;
    transition: width 0.5s, height 0.5s, background-color 0.5s;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .MuiAvatar-root {
      height: 48px;
      width: 48px;
    }
    .Db-Sidebar-User-Info {
      display: none;
    }
  }
  
  .Db-Sidebar-ClosedList {
    margin-top: 20px;
  }
  
  &.Db-Sidebar-Open {
    background: ${colors.white};
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);

    .Db-Sidebar-Background {
      opacity: 0;
    }
    width: 276px;
    .DB-Sidebar-DrawerHeader {
      width: 100%;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
      padding-left: 30px;
      padding-right: 15.5px;
      box-sizing: border-box;
      padding-bottom: 40px;
      
      .Db-Sidebar-Logo {
        height: 47px;
        width: 181px; 
      }
      
      .DB-Sidebar-DrawerHeader-Icon {
        color: ${colors.gray[300]};
        font-size: 16px;
        margin-bottom: 0;
      }
    }
    
    .Db-Sidebar-Menu {
      width: 100%;
      .Db-Sidebar-OpenList {
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      }
      .Db-Sidebar-SecondList {
        width: 100%;
        .MuiButtonBase-root {
          padding-top: 12px;
          padding-bottom: 12px;
          padding-left: 44px;
        }
      }      
    }
    
    .Db-Sidebar-User {
      width: 100%;
      height: 104px;
      overflow: hidden;
      background: #F3F5F5;
      padding: 20px 30px;
      box-sizing: border-box;
      bottom: 0;
      
      .MuiAvatar-root {
        height: 64px;
        width: 64px;
        margin-right: 26px;
      }
      
      .Db-Sidebar-User-Info {
        display: flex;
        flex-direction: column;
        .Db-Sidebar-User-Info-Name {
          color: rgba(0, 0, 0, 0.87);          
        }
        .Db-Sidebar-User-Info-Email {
          color: rgba(68, 73, 80, 0.6);
          font-size: 14px; 
        }
      }
    }
  }
  
`
);

import { ReactElement, useEffect } from 'react';
import * as Yup from 'yup';
import { Grid, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { ReactHookFormTextField, ReactHookWizardStepActions } from 'Atoms';
import { OnboardingWizardInput } from 'Pages';
import { v4 } from 'uuid';
import {
  PayPalCredentialDto,
  useCreateCredentialMutation,
  useUpdateCredentialMutation,
} from 'Mutations';
import { PackageType } from 'Models';

export type ConnectWithPaypalStepInput = {
  credentials: {
    paypal: {
      credentialId?: string;
      name: string;
      clientId: string;
      clientSecret: string;
    };
  };
};

export const ConnectWithPaypalStepValidation =
  (): Yup.SchemaOf<ConnectWithPaypalStepInput> =>
    Yup.object({
      credentials: Yup.object({
        paypal: Yup.object({
          credentialId: Yup.string().label('label.credentialId'),
          name: Yup.string().required().label('label.name'),
          clientId: Yup.string().required().label('label.clientId'),
          clientSecret: Yup.string().required().label('label.clientSecret'),
        }),
      }),
    });

export type ConnectWithPaypalStep = {
  actions: ReactHookWizardStepActions<OnboardingWizardInput>;
};

const ConnectWithPaypalStep = ({
  actions: { overrideSubmit, handleNext },
}: ConnectWithPaypalStep): ReactElement => {
  const organizationId = useWatch({ name: 'organizationId' });
  const { setValue } = useFormContext();
  const createNewCredential = useCreateCredentialMutation();
  const updateCredential = useUpdateCredentialMutation();

  useEffect(() => {
    overrideSubmit(
      () =>
        () =>
        async (values: OnboardingWizardInput<ConnectWithPaypalStepInput>) => {
          const { credentialId, name, clientId, clientSecret } =
            values.credentials.paypal || {};
          const newCredential: PayPalCredentialDto = {
            name,
            clientId,
            clientSecret,
          };
          if (credentialId) {
            await updateCredential.mutateAsync({
              credentialId,
              organizationId,
              package: {
                packageType: PackageType.PAYPAL,
                credentialRequest: null,
                credential: newCredential,
                settings: [],
              },
            });
          } else {
            const result = await createNewCredential.mutateAsync({
              credentialId: v4(),
              organizationId,
              package: {
                packageType: PackageType.PAYPAL,
                credentialRequest: null,
                credential: newCredential,
                settings: [],
              },
            });

            setValue(
              'credentials.paypal.credentialId',
              result.data.credentialId,
              {
                shouldValidate: true,
              }
            );
          }
          overrideSubmit(undefined);
          handleNext(true, true);
        }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h4" color="primary">
          <FormattedMessage
            id="onboarding.steps.connect"
            values={{
              packageType: <FormattedMessage id="packageTypes.paypal" />,
            }}
          />
        </Typography>
      </Grid>
      <Grid item xs={12} container spacing={3}>
        <Grid item xs={12}>
          <Controller
            name="credentials.paypal.name"
            render={(registered) => (
              <ReactHookFormTextField
                {...registered}
                fullWidth
                label={<FormattedMessage id="label.name" />}
                autoFocus
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="credentials.paypal.clientId"
            render={(registered) => (
              <ReactHookFormTextField
                {...registered}
                fullWidth
                label={<FormattedMessage id="label.clientId" />}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="credentials.paypal.clientSecret"
            render={(registered) => (
              <ReactHookFormTextField
                {...registered}
                fullWidth
                label={<FormattedMessage id="label.clientSecret" />}
              />
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ConnectWithPaypalStep;

import { Uuid } from 'ValueObjects';
import {
  isObjectResponse,
  ObjectResponse,
  Organization,
  PackageType,
} from 'Models';
import { CredentialStatus } from './CredentialStatus';

export interface AbstractCredential extends ObjectResponse {
  credentialId: Uuid;
  name: string;
  organization: Organization;
  createdAt: Date;
  packageType: PackageType;
  connected: boolean;
  credentialStatus: CredentialStatus;
}

export function isAbstractCredential(
  object: unknown
): object is AbstractCredential {
  return (
    isObjectResponse(object) &&
    Object.prototype.hasOwnProperty.call(object, 'credentialId') &&
    Object.prototype.hasOwnProperty.call(object, 'packageType') &&
    Object.prototype.hasOwnProperty.call(object, 'organization') &&
    Object.prototype.hasOwnProperty.call(object, 'name')
  );
}

import { ReactElement } from 'react';
import { CenteredLayoutTemplate } from 'Templates';
import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const DidNotConnectedToOAuthPage = (): ReactElement => (
  <CenteredLayoutTemplate>
    <Typography mb={8} variant="h4">
      <FormattedMessage id="oauth.didNotConnected.title" />
    </Typography>
    <Typography>
      <FormattedMessage id="oauth.didNotConnected.description" />
    </Typography>
  </CenteredLayoutTemplate>
);

export default DidNotConnectedToOAuthPage;

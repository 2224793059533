import { ErrorResponse, Uuid } from 'ValueObjects';
import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
} from '@tanstack/react-query';
import { Connection, Package } from 'Models';
import dayjs from 'dayjs';

export type CreateConnectionMutationProps = {
  connectionId: Uuid;
  organizationId: Uuid;
  connectionName: string;
  packages: Package[];
  synchronizationStartDate: Date;
};

export const CreateConnectionMutation = async ({
  synchronizationStartDate,
  ...values
}: CreateConnectionMutationProps): Promise<AxiosResponse<Connection>> =>
  axios.post(`api/connections`, {
    ...values,
    synchronizationStartDate: dayjs(synchronizationStartDate).format(
      'YYYY-MM-DD'
    ),
  });

export const useCreateConnectionMutation = (
  options: UseMutationOptions<
    AxiosResponse<Connection>,
    AxiosError<ErrorResponse>,
    CreateConnectionMutationProps
  > = {}
): UseMutationResult<
  AxiosResponse<Connection>,
  AxiosError<ErrorResponse>,
  CreateConnectionMutationProps
> =>
  useMutation<
    AxiosResponse<Connection>,
    AxiosError<ErrorResponse>,
    CreateConnectionMutationProps
  >(CreateConnectionMutation, {
    useErrorBoundary: false,
    ...options,
  });

import { createTheme, Palette, ThemeOptions } from '@mui/material/styles';
import * as locales from '@mui/material/locale';
import { alpha } from '@mui/material';
import colors from 'App/colors';

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: Palette['primary'];
    quaternary: Palette['primary'];
  }

  interface PaletteOptions {
    tertiary?: PaletteOptions['primary'];
    quaternary?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true;
    quaternary: true;
  }
}

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#5A2CD1',
      light: '#925BFF',
      dark: '#0E009F',
      contrastText: '#fff',
    },
    secondary: {
      main: '#0079FF',
      light: '#6AA7FF',
      dark: '#004ECB',
    },
    success: {
      main: '#58BC5C',
      light: '#8BEF8B',
      dark: '#1E8B2F',
      contrastText: '#ffffff',
    },
    error: {
      main: '#F31100',
    },
    background: {
      paper: '#FFFFFF',
      default: '#F2F2F2',
    },
    tertiary: {
      main: colors.coolGray[50],
    },
    quaternary: {
      main: colors.coolGray[200],
    },
  },
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
    subtitle1: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '24px',
    },
    subtitle2: {
      fontFamily: 'Source Sans Pro',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '25px',
    },
    h3: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '36px',
      lineHeight: '44px',
      letterSpacing: '-0.02em',
    },
    h4: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '32px',
      lineHeight: '48px',
    },
    h5: {
      fontFamily: 'Source Sans Pro',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '26px',
      lineHeight: '32px',
    },
    h6: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '24px',
      lineHeight: '32px',
    },
    caption: {
      fontFamily: 'Source Sans Pro',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '32px',
      color: '#52525B', // Designers did another double colors code, long live design systems
    },
  },
};
const theme = createTheme(themeOptions, locales.enUS);

const muiColors: (keyof Palette | 'inherit')[] = [
  'inherit',
  'primary',
  'secondary',
  'success',
  'error',
  'info',
  'warning',
];
const containedColorStyles: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
} = {};
const textColorStyles: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
} = {};
const outlinedColorStyles: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
} = {};
function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

muiColors.forEach((color) => {
  containedColorStyles[`&.MuiButton-contained${capitalizeFirstLetter(color)}`] =
    color === 'inherit'
      ? {
          backgroundColor: theme.palette.grey[300],
          color: theme.palette.getContrastText(theme.palette.grey[300]),
        }
      : {
          // @ts-ignore
          backgroundColor: theme.palette[color].main,
          // @ts-ignore
          color: theme.palette[color].contrastText,
        };
  textColorStyles[`&.MuiButton-text${capitalizeFirstLetter(color)}`] =
    color === 'inherit'
      ? {
          color: 'inherit',
        }
      : {
          // @ts-ignore
          color: theme.palette[color].main,
        };
  outlinedColorStyles[`&.MuiButton-outlined${capitalizeFirstLetter(color)}`] =
    color === 'inherit'
      ? {
          color: 'inherit',
          borderColor:
            theme.palette.mode === 'light'
              ? 'rgba(0, 0, 0, 0.23)'
              : 'rgba(255, 255, 255, 0.23)',
        }
      : {
          // @ts-ignore
          color: theme.palette[color].main,
          // @ts-ignore
          borderColor: `${alpha(theme.palette[color].main, 0.5)}`,
        };
});

export default createTheme(
  {
    ...themeOptions,
    components: {
      MuiBackdrop: {
        styleOverrides: {
          root: {
            background: 'rgba(0, 0, 0, 0.26)',
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontFamily: 'Inter',
            color: colors.gray[700],
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '20px',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            fontFamily: 'Roboto',
          },
          notchedOutline: {
            fontFamily: 'Roboto',
            lineHeight: '12px',
            letterSpacing: '0.15px',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              ...containedColorStyles,
              ...textColorStyles,
              ...outlinedColorStyles,
              opacity: 0.3,
            },
          },
        },
      },
    },
  },
  locales.enUS
);

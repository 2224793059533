import { ReactElement, useCallback, useMemo, useState } from 'react';
import useDataGridDataWithReactQuery from 'Utils/useDataGridDataWithReactQuery';
import { User } from 'Models';
import {
  useOrganizationByOrganizationIdRequestQuery,
  useUsersListRequest,
} from 'Requests';
import {
  Box,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@mui/material';
import { Link as RouterLink, useParams } from 'react-router-dom';
import colors from 'App/colors';
import { FormattedDate, FormattedList, FormattedMessage } from 'react-intl';
import { Icon } from 'Atoms';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { OrganizationDetailsPageTemplate } from 'Templates';
import { DataGridTemplate } from 'Templates/DataGridTemplate';
import { ConfirmDialog } from 'Organisms/ConfirmDialog';
import { useRemoveAccessToOrganizationsMutation } from 'Mutations';

const OrganizationUsersPage = (): ReactElement => {
  const { organizationId } = useParams();
  const [deletingAccessForUser, setDeletingAccessForUser] = useState<User>();

  const { mutateAsync: removeAccessToOrganization } =
    useRemoveAccessToOrganizationsMutation();

  const { refetch, ...gridData } = useDataGridDataWithReactQuery<User>({
    overviewPage: true,
    reactQuery: useUsersListRequest,
    getRowId: (row) => row.userId,
    request: {
      organizationId,
    },
    columns: [
      {
        field: 'personName.firstName',
        headerName: 'label.name',
        flex: 1.5,
        renderCell: ({ row }) => (
          <Link
            component={RouterLink}
            to={`/users/${row.userId}`}
            underline="none"
            color={colors.coolGray[900]}
          >
            {row.personName.fullName}
          </Link>
        ),
      },
      {
        field: 'email.emailAddress',
        headerName: 'label.emailAddress',
        flex: 1.5,
        renderCell: ({ row }) => row.emailAddress,
      },
      {
        field: 'accessToOrganizations',
        headerName: 'label.accessToOrganizations',
        sortable: false,
        flex: 3,
        renderCell: ({ row }) => (
          <FormattedList
            type="unit"
            value={row.accessToOrganizations.map(
              ({ organization }) => organization.organizationName
            )}
          />
        ),
      },
      {
        field: 'createdAt',
        headerName: 'label.registrationDate',
        flex: 1,
        renderCell: ({ row }) => <FormattedDate value={row.createdAt} />,
      },
      {
        field: 'actions',
        headerName: 'label.actions',
        type: 'actions',
        headerAlign: 'left',
        flex: 0.7,
        sortable: false,
        renderCell: ({ row }) => (
          <Grid container spacing={2}>
            <Grid item>
              <Tooltip
                title={
                  <FormattedMessage id="label.removeAccessToOrganization" />
                }
              >
                <span>
                  <IconButton
                    onClick={() => setDeletingAccessForUser(row)}
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    size="small"
                    sx={{ fontSize: 13.5 }}
                  >
                    <Icon icon={faTimes} />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        ),
      },
    ],
  });
  const { data } = useOrganizationByOrganizationIdRequestQuery(
    {
      organizationId: organizationId || '',
    },
    {
      enabled: !!organizationId,
    }
  );

  const handleStartDeleting = useCallback(async () => {
    if (organizationId && deletingAccessForUser) {
      await removeAccessToOrganization({
        userId: deletingAccessForUser.userId,
        organizationsIds: [organizationId],
      });
    }
    await refetch();
    setDeletingAccessForUser(undefined);
  }, [
    deletingAccessForUser,
    organizationId,
    refetch,
    removeAccessToOrganization,
  ]);

  const organization = useMemo(() => data?.data, [data]);

  if (!organization) {
    return <span />;
  }

  return (
    <OrganizationDetailsPageTemplate
      currentPage="users"
      organization={organization}
    >
      <Box className="Db-OrganizationDetailsPage-Block">
        <Typography variant="subtitle2">
          <FormattedMessage id="label.users" />
        </Typography>
        <Box pt={3}>
          <DataGridTemplate {...gridData} />
        </Box>
      </Box>
      {!!deletingAccessForUser && (
        <ConfirmDialog
          open={!!deletingAccessForUser}
          onCancel={() => setDeletingAccessForUser(undefined)}
          onClose={() => setDeletingAccessForUser(undefined)}
          onConfirm={handleStartDeleting}
          dangerous
          title={
            <FormattedMessage
              id="label.removingOrganizationAccessForUser"
              values={{
                name: organization.organizationName,
                user: deletingAccessForUser.personName.fullName,
              }}
            />
          }
          content={
            <FormattedMessage
              id="label.areYouSureYouWantToRemovingOrganizationAccessDescriptionForUser"
              values={{
                name: organization.organizationName,
                user: deletingAccessForUser.personName.fullName,
              }}
            />
          }
        />
      )}
    </OrganizationDetailsPageTemplate>
  );
};
export default OrganizationUsersPage;

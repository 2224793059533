import { BackendViolation } from './BackendViolation';

export interface BackendViolations {
  'hydra:description': string;
  'hydra:title': string;
  violations: BackendViolation[];
}

export function isBackendViolations(
  object: unknown
): object is BackendViolations {
  return (
    !!object &&
    typeof object === 'object' &&
    Object.prototype.hasOwnProperty.call(object, 'violations')
  );
}

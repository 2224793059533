import { ReactElement } from 'react';
import { FormDialog } from 'Organisms/FormDialog';
import { UploadProfilePictureForm } from 'Forms';
import {
  UploadProfilePictureInputType,
  UploadProfilePictureInputTypeDefaultValues,
  UploadProfilePictureInputTypeValidationBuilder,
} from 'InputTypes';
import { DialogProps } from 'Molecules/Dialog';
import { SubmitHandler } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Button } from 'Atoms';

export type UploadProfilePictureFormDialogProps = Omit<
  DialogProps,
  'onSubmit'
> & {
  onSubmit: SubmitHandler<UploadProfilePictureInputType>;
};

const UploadProfilePictureFormDialog = ({
  onSubmit,
  onClose,
  ...args
}: UploadProfilePictureFormDialogProps): ReactElement => (
  <FormDialog
    {...args}
    maxWidth="sm"
    fullWidth
    onClose={onClose}
    defaultValues={UploadProfilePictureInputTypeDefaultValues()}
    validationSchema={UploadProfilePictureInputTypeValidationBuilder()}
    name="upload-employee-image"
    useProvider
    title={<FormattedMessage id="label.uploadProfilePicture" />}
    onSubmit={onSubmit}
    hideCloseButton
    dialogActions={[
      <Button type="submit" key="save">
        <FormattedMessage id="button.save" />
      </Button>,
    ]}
  >
    <UploadProfilePictureForm />
  </FormDialog>
);

export default UploadProfilePictureFormDialog;

import { Uuid } from 'ValueObjects';
import { isObjectResponse } from 'Requests';
import { PackageType } from 'Models/Synchronizer/Connection/PackageType';

export interface AbstractModule {
  moduleId: Uuid;
  moduleName: string;
  packageType: PackageType;
  startData: Date;
}

export function isAbstractModule(object: unknown): object is AbstractModule {
  return !!object && isObjectResponse(object) && object['@type'] === 'module';
}

import { Box, BoxProps, Grid, Link, styled } from '@mui/material';
import { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';
import { Button, ReactHookFormCheckbox, ReactHookFormTextField } from 'Atoms';
import { Controller } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import colors from 'App/colors';

export type LoginFormValidationSchemaBuilderProps = {
  requireTwoFactorCode: boolean;
};

export type LoginFormProps = BoxProps & {
  requireTwoFactorCode: boolean;
};

export type LoginInput = {
  emailAddress: string;
  password: string;
  twoFactorCode?: number;
  status?: string;
};

export const LoginFormValidationSchemaBuilder = ({
  requireTwoFactorCode,
}: LoginFormValidationSchemaBuilderProps): Yup.SchemaOf<LoginInput> => {
  const base = Yup.object({
    emailAddress: Yup.string().email().required().label('label.emailAddress'),
    password: Yup.string().required().label('label.password'),
    twoFactorCode: Yup.number().optional().label('label.twoFactorCode'),
    status: Yup.string().optional(),
  });

  if (!requireTwoFactorCode) {
    return base;
  }

  return base.concat(
    Yup.object({
      twoFactorCode: Yup.number().required(),
    })
  );
};

export const LoginFormInitialValues: LoginInput = {
  emailAddress: '',
  password: '',
  twoFactorCode: undefined,
};

const LoginForm: FunctionComponent<LoginFormProps> = ({
  requireTwoFactorCode,
  ...args
}: LoginFormProps) => {
  const intl = useIntl();

  return (
    <Box {...args}>
      <Grid container rowSpacing={3}>
        <Grid container item xs={12}>
          <Controller
            name="emailAddress"
            render={(registered) => (
              <ReactHookFormTextField
                {...registered}
                fullWidth
                type="email"
                label={intl.formatMessage({ id: 'label.emailAddress' })}
                placeholder={intl.formatMessage({
                  id: 'placeholder.emailAddress',
                })}
                autoFocus
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="password"
            render={(registered) => (
              <ReactHookFormTextField
                {...registered}
                fullWidth
                type="password"
                label={intl.formatMessage({ id: 'label.password' })}
                placeholder={intl.formatMessage({
                  id: 'placeholder.password',
                })}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            <Controller
              name="remember"
              render={(registered) => (
                <ReactHookFormCheckbox
                  {...registered}
                  label={<FormattedMessage id="label.rememberFor30Days" />}
                />
              )}
            />
          </Grid>
          <Grid item>
            <Link
              className="Db-LoginForm-ForgetPassword"
              component={RouterLink}
              to="/reset-password"
            >
              <FormattedMessage id="login.forgotPassword" />
            </Link>
          </Grid>
        </Grid>
        {requireTwoFactorCode && (
          <Grid item xs={12}>
            <Controller
              name="twoFactorCode"
              render={(registered) => (
                <ReactHookFormTextField
                  {...registered}
                  type="number"
                  label={<FormattedMessage id="label.twoFactorCode" />}
                  inputMode="numeric"
                  autoComplete="one-time-code"
                  fullWidth
                />
              )}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

LoginForm.defaultProps = {
  requireTwoFactorCode: false,
};

export default styled(LoginForm)(
  () => `
  
  .Db-LoginForm-ForgetPassword {
    color: ${colors.primary[700]};
    margin-left: 4px;
    font-size: 14px;
    line-height: 20px;
    text-decoration: none;
    font-weight: 500;
  }
`
);

import { ReactElement, useCallback, useState } from 'react';
import { OverviewDataGridPageTemplate } from 'Templates/OverviewDataGridPageTemplate';
import { useUsersListRequest } from 'Requests';
import useDataGridDataWithReactQuery from 'Utils/useDataGridDataWithReactQuery';
import { User } from 'Models';
import { FormattedDate, FormattedList, FormattedMessage } from 'react-intl';
import { Grid, IconButton, Link, Typography } from '@mui/material';
import { faPencil, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { Icon } from 'Atoms';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import colors from 'App/colors';
import { useIsSuperAdmin } from 'Utils';
import { useCreateUserMutation, useDeleteUserMutation } from 'Mutations';
import { ConfirmDialog } from 'Organisms/ConfirmDialog';
import { CreateUserFormDialog } from 'Dialogs';
import { CreateUserInputType } from 'InputTypes';
import { v4 } from 'uuid';

const UserOverviewPage = (): ReactElement => {
  const isSuperAdmin = useIsSuperAdmin();
  const navigate = useNavigate();
  const [deletingUser, setDeletingUser] = useState<User>();
  const [isCreatingUser, setIsCreatingUser] = useState<boolean>(false);

  const { mutateAsync: deleteUser } = useDeleteUserMutation();
  const { mutateAsync: createUser } = useCreateUserMutation();

  const { refetch, ...gridData } = useDataGridDataWithReactQuery<User>({
    overviewPage: true,
    reactQuery: useUsersListRequest,
    getRowId: (row) => row.userId,
    columns: [
      {
        field: 'personName.firstName',
        headerName: 'label.name',
        flex: 1.5,
        renderCell: ({ row }) => (
          <Link
            component={RouterLink}
            to={`/users/${row.userId}`}
            underline="none"
            color={colors.coolGray[900]}
          >
            {row.personName.fullName}
          </Link>
        ),
      },
      {
        field: 'email.emailAddress',
        headerName: 'label.emailAddress',
        flex: 1.5,
        renderCell: ({ row }) => row.emailAddress,
      },
      {
        field: 'roles',
        headerName: 'label.roles',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (
          <FormattedList
            value={row.roles.map((role) => (
              <FormattedMessage id={`userRoles.${role}`} />
            ))}
          />
        ),
      },
      {
        field: 'accessToOrganizations',
        headerName: 'label.accessToOrganizations',
        sortable: false,
        flex: 3,
        renderCell: ({ row }) => (
          <FormattedList
            type="unit"
            value={row.accessToOrganizations.map(
              ({ organization }) => organization.organizationName
            )}
          />
        ),
      },
      {
        field: 'createdAt',
        headerName: 'label.registrationDate',
        flex: 1,
        type: 'date',
        renderCell: ({ row }) => <FormattedDate value={row.createdAt} />,
      },
      {
        field: 'actions',
        headerName: 'label.actions',
        type: 'actions',
        headerAlign: 'left',
        flex: 0.7,
        sortable: false,
        renderCell: ({ row }) => (
          <Grid container spacing={2}>
            <Grid item>
              <IconButton
                onClick={() => setDeletingUser(row)}
                disableRipple
                disableFocusRipple
                disableTouchRipple
                size="small"
                sx={{ fontSize: 13.5 }}
              >
                <Icon icon={faTrashCan} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                onClick={() => navigate(`/users/${row.userId}`)}
                disableRipple
                disableFocusRipple
                disableTouchRipple
                size="small"
                sx={{ fontSize: 13.5 }}
              >
                <Icon icon={faPencil} />
              </IconButton>
            </Grid>
          </Grid>
        ),
      },
    ],
  });

  const handleDelete = useCallback(async () => {
    if (!deletingUser) {
      return;
    }

    await deleteUser({ userId: deletingUser?.userId });
    await refetch();
    setDeletingUser(undefined);
  }, [deleteUser, deletingUser, refetch]);

  const handleCreateUser = useCallback(
    async (values: CreateUserInputType) => {
      const userId = v4();

      await createUser({
        userId,
        ...values,
        // @ts-ignore
        accessToOrganizations: values.accessToOrganizations
          .map(({ organizationId }) => organizationId)
          .filter((val) => typeof val === 'string' && val !== undefined),
      });
      navigate(`/users/${userId}`);
      setIsCreatingUser(false);
    },
    [createUser, navigate]
  );

  return (
    <OverviewDataGridPageTemplate
      actions={
        isSuperAdmin
          ? [
              { value: 'overview', children: 'overview', to: '/users' },
              {
                value: 'create',
                children: 'create a new user',
                onClick: () => setIsCreatingUser(true),
              },
            ]
          : [{ value: 'overview', children: 'overview', to: '/users' }]
      }
      onSearch={gridData.handleSearch}
      breadCrumbs={[
        <Typography key="users" color="text.primary">
          <FormattedMessage id="label.users" />
        </Typography>,
        <Link component={RouterLink} key="overview" to="/users">
          <FormattedMessage id="label.overview" />
        </Link>,
      ]}
      {...gridData}
    >
      {isCreatingUser && (
        <CreateUserFormDialog
          open={isCreatingUser}
          onClose={() => setIsCreatingUser(false)}
          onSubmit={handleCreateUser}
        />
      )}
      {!!deletingUser && (
        <ConfirmDialog
          open={!!deletingUser}
          onCancel={() => setDeletingUser(undefined)}
          onClose={() => setDeletingUser(undefined)}
          onConfirm={handleDelete}
          dangerous
          title={
            <FormattedMessage
              id="label.deleteUser"
              values={{ name: deletingUser.personName.fullName }}
            />
          }
          content={
            <FormattedMessage
              id="label.areYouSureYouWantToDeleteThisUser"
              values={{ name: deletingUser.personName.fullName }}
            />
          }
        />
      )}
    </OverviewDataGridPageTemplate>
  );
};

export default UserOverviewPage;

import {
  Popper as MuiPopper,
  PopperProps as MuiPopperProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { FunctionComponent } from 'react';

export type AutocompletePopperProps = MuiPopperProps;

const AutocompletePopper: FunctionComponent<AutocompletePopperProps> = ({
  ...props
}: AutocompletePopperProps) => <MuiPopper {...props} />;

AutocompletePopper.defaultProps = {};

export default styled(AutocompletePopper)(() => ``);

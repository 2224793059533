import { Fragment, ReactElement } from 'react';
import { Box, Grid, styled } from '@mui/material';
import { PackageType } from 'Models';
import { Icon, PackageLogo } from 'Atoms';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-light-svg-icons';

export type ConnectingPackageLogosProps = {
  packageTypes: PackageType[];
};

const ConnectingPackageLogos = ({
  packageTypes,
  ...args
}: ConnectingPackageLogosProps): ReactElement => (
  <Grid
    container
    sx={{ width: '100%' }}
    justifyContent="space-between"
    {...args}
  >
    {packageTypes.map((packageType, index) => (
      <Fragment key={packageType}>
        <Grid item flexGrow={1}>
          <PackageLogo packageType={packageType} />
        </Grid>
        {packageTypes.length !== index + 1 && (
          <Grid
            item
            xs="auto"
            container
            spacing="6px"
            justifyContent="space-between"
            alignItems="center"
            pl={2}
            pr={2}
          >
            <Grid item>
              <Box className="Db-ConnectingPackageLogos-Arrow">
                <Icon icon={faArrowLeft} />
              </Box>
            </Grid>
            <Grid item>
              <Box className="Db-ConnectingPackageLogos-Arrow">
                <Icon icon={faArrowRight} />
              </Box>
            </Grid>
          </Grid>
        )}
      </Fragment>
    ))}
  </Grid>
);

export default styled(ConnectingPackageLogos)(
  ({ theme }) => `
  .Db-ConnectingPackageLogos-Arrow {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #2196F3;
    height: 38px;
    width: 38px;
    border-radius: 30px;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${theme.palette.primary.main};
  }
`
);

import { Uuid } from 'ValueObjects';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Organization } from 'Models';
import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

export type OrganizationByOrganizationIdRequestProps = {
  organizationId: Uuid;
};

export const OrganizationByOrganizationIdRequest = async ({
  organizationId,
}: OrganizationByOrganizationIdRequestProps): Promise<
  AxiosResponse<Organization>
> => axios.get(`api/organizations/${organizationId}`);

export const useOrganizationByOrganizationIdRequestQuery = (
  request: OrganizationByOrganizationIdRequestProps,
  options: UseQueryOptions<
    AxiosResponse<Organization>,
    AxiosError,
    AxiosResponse<Organization>
  > = {}
): UseQueryResult<AxiosResponse<Organization>, AxiosError> =>
  useQuery<
    AxiosResponse<Organization>,
    AxiosError,
    AxiosResponse<Organization>
  >({
    ...options,
    queryFn: async () => OrganizationByOrganizationIdRequest(request),
    queryKey: ['organizations', 'detail', request.organizationId],
  });

import {
  styled,
  Tabs as MuiTabs,
  TabsProps as MuiTabsProps,
} from '@mui/material';
import { ReactElement } from 'react';

export type TabsProps = MuiTabsProps;

const Tabs = ({ children, ...props }: TabsProps): ReactElement => (
  <MuiTabs {...props}>{children}</MuiTabs>
);

Tabs.defaultProps = {};

export default styled(Tabs)(
  () => `
  &.MuiTabs-root {
    .MuiTabs-scroller {
      .MuiTabs-indicator {
        top: 0;
        height: 4px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }   
    } 
  }  
`
);

import {
  Skeleton,
  Avatar as MuiAvatar,
  AvatarProps as MuiAvatarProps,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ReactElement } from 'react';
import clsx from 'clsx';
import { Icon } from 'Atoms/Icon';
import { faUpload } from '@fortawesome/pro-light-svg-icons';
import colors from 'App/colors';

export enum AvatarSizeType {
  SMALL = 'small',
  BIG = 'big',
}

export type AvatarProps = MuiAvatarProps & {
  loading?: boolean;
  name?: string | null;
  icon?: IconProp;
  size: AvatarSizeType;
};

const stringAvatar = (name: string) => {
  const splittedName = name.split(' ');
  if (splittedName.length === 1) {
    return `${splittedName[0][0]}${splittedName[0][1]}`;
  }

  return `${splittedName[0][0]}${splittedName[1][0]}`;
};

const Avatar = ({
  name,
  loading,
  icon,
  className,
  onClick,
  size,
  ...props
}: AvatarProps): ReactElement =>
  loading ? (
    <Skeleton className={className} variant="circular" />
  ) : (
    <Box className={clsx(className, { 'Db-Avatar-Uploadable': !!onClick })}>
      {!!onClick && (
        <MuiAvatar
          onClick={onClick}
          className={clsx(
            'Db-UploadingAvatar-root',
            { 'Db-Avatar-Small': size === AvatarSizeType.SMALL },
            { 'Db-Avatar-Large': size === AvatarSizeType.BIG }
          )}
        >
          <Icon className="Db-UploadingAvatar-UploadingIcon" icon={faUpload} />
        </MuiAvatar>
      )}
      <MuiAvatar
        className={clsx(
          'Db-Avatar-root',
          { 'Db-Avatar-Small': size === AvatarSizeType.SMALL },
          { 'Db-Avatar-Large': size === AvatarSizeType.BIG },
          { 'Db-Avatar-HasIcon': !!icon },
          { 'Db-Avatar-Loading': loading }
        )}
        {...props}
      >
        {icon && <Icon icon={icon} />}
        {name && stringAvatar(name)}
      </MuiAvatar>
    </Box>
  );

Avatar.defaultProps = {
  loading: false,
  size: undefined,
  name: null,
  shortName: null,
};

export default styled(Avatar)(
  () => `
  position: relative;
  
  .Db-UploadingAvatar-root {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    transition: opacity 0.3s ease;
    cursor: pointer;
    background: ${colors.coolGray[200]};
    color: ${colors.gray[700]};
    
    &.Db-Avatar-Small {
      height: 48px;
      width: 48px;
    }
    
    &.Db-Avatar-Large {
      height: 100px;
      width: 100px;
    }
  }
  
  &.Db-Avatar-Uploadable:hover {
    .Db-Avatar-root {
      opacity: 0;
    }
    
    .Db-UploadingAvatar-root {
      opacity: 1;       
    }
  }
  
  .Db-Avatar-root {
    background: ${colors.gray[700]};
    color: ${colors.white};
    transition: background 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity 0.3s ease;
    
    &.Db-Avatar-Loading, &.Db-Avatar-HasIcon {
      background: ${colors.coolGray[200]};
      color: ${colors.gray[700]};
    }
    
    &.Db-Avatar-Small {
      height: 48px;
      width: 48px;
    }
    
    &.Db-Avatar-Large {
      height: 100px;
      width: 100px;
    }
  }
  
  `
);

import { ReactElement, useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import {
  ExactOnlineDivisionSettingsForm,
  PaypalSettingsForm,
  PaypalSettingsFormInput,
  PaypalSettingsFormValidation,
  StandardConnectionSettingsForm,
} from 'Forms/OnboardingWizard/Parts';
import {
  ConnectWithExactOnlineStepStepInput,
  ExactOnlineCredentialSettingsFormInput,
  ExactOnlineCredentialSettingsValidation,
} from 'Forms/OnboardingWizard/CredentialSteps';
import { useWatch } from 'react-hook-form';
import {
  useExactOnlineBankAccountsByCredentialIdRequestQuery,
  useExactOnlineGeneralLedgersByCredentialIdRequestQuery,
} from 'Requests/Synchronizer/Package/ExactOnline';

export type PayPalExactOnlineConnectionSettingsStepFormInput = {
  settings: {
    paypal: PaypalSettingsFormInput;
    'exact-online': ExactOnlineCredentialSettingsFormInput;
  };
};

export const PayPalExactOnlineConnectionSettingsStepFormValidation =
  (): Yup.SchemaOf<PayPalExactOnlineConnectionSettingsStepFormInput> =>
    Yup.object({
      settings: Yup.object({
        paypal: PaypalSettingsFormValidation(),
        'exact-online': ExactOnlineCredentialSettingsValidation(),
      }),
    });

const PayPalExactOnlineConnectionSettingsStepForm = (): ReactElement => {
  const credentialId = useWatch<
    ConnectWithExactOnlineStepStepInput,
    'credentials.exact-online.credentialId'
  >({
    name: 'credentials.exact-online.credentialId',
  });

  const division = useWatch<
    PayPalExactOnlineConnectionSettingsStepFormInput,
    'settings.exact-online.division'
  >({
    name: 'settings.exact-online.division',
  });

  const {
    data: generalLedgersData,
    isLoading: isLoadingGeneralLedgers,
    isError: isErrorGeneralLedgers,
  } = useExactOnlineGeneralLedgersByCredentialIdRequestQuery(
    {
      credentialId,
      division,
      perPage: 9999,
    },
    {
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  const {
    data: bankAccountsData,
    isLoading: isLoadingBankAccounts,
    isError: isErrorBankAccounts,
  } = useExactOnlineBankAccountsByCredentialIdRequestQuery(
    {
      credentialId,
      division,
      perPage: 9999,
    },
    {
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  const generalLedgers = useMemo(
    () =>
      (generalLedgersData?.data?.['hydra:member'] || []).map(
        ({ ID, Description, Code }) => ({
          externalId: ID,
          label: `${Description} (${Code})`,
        })
      ),
    [generalLedgersData?.data]
  );

  const bankAccounts = useMemo(
    () =>
      (bankAccountsData?.data?.['hydra:member'] || []).map(
        ({ ID, Description }) => ({
          externalId: ID,
          label: Description,
        })
      ),
    [bankAccountsData?.data]
  );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h4" color="primary">
          <FormattedMessage id="onboarding.steps.settings" />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" color="primary" pb="10px">
          <FormattedMessage id="packageTypes.exact-online" />
        </Typography>
        <ExactOnlineDivisionSettingsForm disabled />
      </Grid>
      <Grid item xs={12}>
        <StandardConnectionSettingsForm />
      </Grid>
      <Grid item xs={12}>
        <PaypalSettingsForm
          generalLedgers={generalLedgers}
          bankJournals={bankAccounts}
          isLoading={isLoadingBankAccounts || isLoadingGeneralLedgers}
          hasFailedLoading={isErrorBankAccounts || isErrorGeneralLedgers}
        />
      </Grid>
    </Grid>
  );
};

export default PayPalExactOnlineConnectionSettingsStepForm;

import { ReactElement, useMemo } from 'react';
import useDataGridDataWithReactQuery from 'Utils/useDataGridDataWithReactQuery';
import { Connection } from 'Models';
import {
  useConnectionsListRequest,
  useCredentialByCredentialIdRequestQuery,
} from 'Requests';
import { Box, Link, Typography } from '@mui/material';
import { Link as RouterLink, useParams } from 'react-router-dom';
import colors from 'App/colors';
import { FormattedList, FormattedMessage } from 'react-intl';
import { DataGridTemplate } from 'Templates/DataGridTemplate';
import { ConnectionStatusChip } from 'Molecules';
import CredentialDetailsPageTemplate from '../../../Templates/CredentialDetailsPageTemplate/CredentialDetailsPageTemplate';

const CredentialConnectionsPage = (): ReactElement => {
  const { credentialId } = useParams();

  const gridData = useDataGridDataWithReactQuery<Connection>({
    overviewPage: true,
    reactQuery: useConnectionsListRequest,
    request: {
      credentialId,
    },
    getRowId: (row) => row.connectionId,
    columns: [
      {
        field: 'connectionName',
        headerName: 'label.connection',
        flex: 1,
        renderCell: ({ row, value }) => (
          <Link
            component={RouterLink}
            to={`/connections/${row.connectionId}`}
            underline="none"
            color={colors.coolGray[900]}
          >
            {value}
          </Link>
        ),
      },
      {
        field: 'organization.organizationName',
        headerName: 'label.organization',
        valueGetter: (params) => params.row.organization.organizationName,
        flex: 1,
      },
      {
        field: 'packages',
        headerName: 'label.packages',
        sortable: false,
        flex: 1,
        renderCell: (params) => (
          <FormattedList
            type="unit"
            value={params.row.packages.map((p) => (
              <FormattedMessage id={`packageTypes.${p.packageType}`} />
            ))}
          />
        ),
      },
      {
        field: 'status.value',
        headerName: 'label.status',
        flex: 0.5,
        renderCell: (params) => (
          <ConnectionStatusChip
            connectionStatus={params.row.connectionStatus}
          />
        ),
      },
    ],
  });

  const { data } = useCredentialByCredentialIdRequestQuery(
    {
      credentialId: credentialId || '',
    },
    {
      enabled: !!credentialId,
    }
  );

  const credential = useMemo(() => data?.data, [data]);

  if (!credential) {
    return <span />;
  }

  return (
    <CredentialDetailsPageTemplate
      currentPage="connections"
      credential={credential}
    >
      <Box className="Db-CredentialDetailsPage-Block">
        <Typography variant="subtitle2">
          <FormattedMessage id="label.connections" />
        </Typography>
        <Box pt={3}>
          <DataGridTemplate {...gridData} />
        </Box>
      </Box>
    </CredentialDetailsPageTemplate>
  );
};
export default CredentialConnectionsPage;

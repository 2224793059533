import axios, { AxiosError, AxiosResponse } from 'axios';
import { Log, isLog } from 'Models';
import { Pagination } from 'Requests/Pagination';
import { Collection } from 'Requests/Collection';
import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { ErrorResponse, Uuid } from 'ValueObjects';
import Qs from 'qs';
import { Filterable } from 'Requests/Filterable';
import { QueryInvalidator } from 'Requests/QueryInvalidator';

export type LogsByConnectionIdRequestProps = Pagination &
  Filterable & {
    connectionId: Uuid;
  };

type Response = AxiosResponse<Collection<Log>>;

export const LogsByConnectionIdRequest = async ({
  connectionId,
  ...params
}: LogsByConnectionIdRequestProps): Promise<Response> =>
  axios.get(`api/${connectionId}/log-entries`, {
    params: { ...params },
    paramsSerializer: {
      encode: (param) => Qs.parse(param),
      serialize: (param) => Qs.stringify(param, { arrayFormat: 'brackets' }),
    },
  });

export const useLogsByConnectionIdRequest = (
  request: LogsByConnectionIdRequestProps,
  options: UseQueryOptions<Response, AxiosError<ErrorResponse>, Response> = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => LogsByConnectionIdRequest(request),
    queryKey: ['logs', 'list', request.connectionId, ...Object.values(request)],
  });

export const LogsByConnectionIdRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isLog(data)) {
    queryClient.invalidateQueries(['logs', 'list', data.connectionId]);
  }
};

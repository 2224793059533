import { ReactElement } from 'react';
import { FormDialog } from 'Organisms';
import { FormattedMessage } from 'react-intl';
import {
  CreateOrganizationInputType,
  CreateOrganizationInputTypeDefaultValues,
  CreateOrganizationInputTypeValidationBuilder,
} from 'InputTypes';
import { Button, Icon } from 'Atoms';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import CreateOrganizationForm from 'Forms/CreateOrganizationForm/CreateOrganizationForm';

export type CreateOrganizationFormDialogProps = {
  className?: string;
  open: boolean;
  onClose: () => void;
  onSubmit: (values: CreateOrganizationInputType) => void | Promise<void>;
};

const CreateOrganizationFormDialog = ({
  ...props
}: CreateOrganizationFormDialogProps): ReactElement => (
  <FormDialog<CreateOrganizationInputType>
    name="create-user"
    title={<FormattedMessage id="label.createOrganization" />}
    defaultValues={{
      ...CreateOrganizationInputTypeDefaultValues(),
    }}
    validationSchema={CreateOrganizationInputTypeValidationBuilder()}
    closeButtonLast
    dialogActions={[
      <Button type="submit" key="create">
        <Icon icon={faCheck} />
        &nbsp;
        <FormattedMessage id="button.create" />
      </Button>,
    ]}
    fullWidth
    maxWidth="sm"
    useProvider
    hideBackendErrors
    {...props}
  >
    <CreateOrganizationForm />
  </FormDialog>
);

export default CreateOrganizationFormDialog;

import { ErrorResponse, Uuid } from 'ValueObjects';
import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
} from '@tanstack/react-query';

export type DeleteConnectionMutationrops = {
  connectionId: Uuid;
};

export const DeleteConnectionMutation = async ({
  ...values
}: DeleteConnectionMutationrops): Promise<AxiosResponse> =>
  axios.put(`api/connections/${values.connectionId}/delete`, {
    ...values,
  });

export const useDeleteConnectionMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    DeleteConnectionMutationrops
  > = {}
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  DeleteConnectionMutationrops
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    DeleteConnectionMutationrops
  >(DeleteConnectionMutation, {
    useErrorBoundary: false,
    ...options,
  });

import { AbstractTask } from 'Models/Synchronizer/Task/AbstractTask';
import { Uuid } from 'ValueObjects';
import { isObjectResponse } from 'Requests';

export interface SubModuleTask extends AbstractTask {
  moduleId: Uuid;
}

export function isSubModuleTask(object: unknown): object is SubModuleTask {
  return (
    !!object && isObjectResponse(object) && object['@type'] === 'SubModuleTask'
  );
}

import {
  AbstractCredentialDto,
  isAbstractCredentialDto,
} from './AbstractCredentialDto';

export interface RecrasCredentialDto extends AbstractCredentialDto {
  subDomain: string;
  token: string;
}

export function isRecrasCredentialDto(
  object: unknown
): object is RecrasCredentialDto {
  return (
    isAbstractCredentialDto(object) &&
    'subDomain' in object &&
    typeof object.subDomain === 'string' &&
    'token' in object &&
    typeof object.token === 'string'
  );
}

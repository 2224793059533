import { ReactElement, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
} from '@fortawesome/pro-light-svg-icons';
import { Chip, ChipProps } from 'Atoms';

export type LogLevelChipProps = ChipProps & {
  logLevel: number;
  className?: string;
};

const colorMapping: {
  [key: string]:
    | 'default'
    | 'success'
    | 'secondary'
    | 'error'
    | 'warning'
    | 'primary'
    | 'info'
    | undefined;
} = {
  100: 'default',
  200: 'info',
  250: 'info',
  300: 'warning',
  400: 'error',
  500: 'error',
  550: 'error',
  600: 'error',
};

const LogLevelChip = ({
  logLevel,
  className,
  ...props
}: LogLevelChipProps): ReactElement => {
  const icon = useMemo(() => {
    if (logLevel === 100) {
      return <FontAwesomeIcon icon={faCheckCircle} />;
    }

    if (logLevel >= 200 && logLevel < 300) {
      return <FontAwesomeIcon icon={faExclamationCircle} />;
    }

    if (logLevel >= 400) {
      return <FontAwesomeIcon icon={faExclamationTriangle} />;
    }

    return undefined;
  }, [logLevel]);
  return (
    <Chip
      label={<FormattedMessage id={`logLevels.${logLevel}`} />}
      variant="filled"
      color={colorMapping[logLevel] || 'default'}
      icon={icon}
      className={className}
      {...props}
    />
  );
};

export default LogLevelChip;

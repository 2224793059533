import { ReactElement } from 'react';
import { FormDialog } from 'Organisms';
import { FormattedMessage } from 'react-intl';
import {
  CreateUserInputType,
  CreateUserInputTypeDefaultValues,
  CreateUserInputTypeValidationBuilder,
} from 'InputTypes';
import { Button, Icon } from 'Atoms';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import CreateUserForm from 'Forms/CreateUserForm/CreateUserForm';
import { useIsSuperAdmin } from 'Utils';

export type CreateUserFormDialogProps = {
  className?: string;
  open: boolean;
  onClose: () => void;
  onSubmit: (values: CreateUserInputType) => void | Promise<void>;
};

const CreateUserFormDialog = ({
  ...props
}: CreateUserFormDialogProps): ReactElement => {
  const isSuperAdmin = useIsSuperAdmin();
  return (
    <FormDialog<CreateUserInputType>
      name="create-user"
      title={<FormattedMessage id="label.createUser" />}
      defaultValues={{
        ...CreateUserInputTypeDefaultValues(),
      }}
      validationSchema={CreateUserInputTypeValidationBuilder()}
      closeButtonLast
      dialogActions={[
        <Button type="submit" key="create">
          <Icon icon={faCheck} />
          &nbsp;
          <FormattedMessage id="button.create" />
        </Button>,
      ]}
      fullWidth
      maxWidth="sm"
      useProvider
      hideBackendErrors
      {...props}
    >
      <CreateUserForm allowEditingRole={isSuperAdmin} />
    </FormDialog>
  );
};

export default CreateUserFormDialog;

import { ReactElement, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { AuthState, UserState } from 'State';
import { UserByUserIdRequest } from 'Requests';

export type SecureRouteProps = {
  children: ReactElement;
};

const SecureRoute = ({ children }: SecureRouteProps): ReactElement | null => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [authState, setAuthState] = useRecoilState(AuthState);
  const [userState, setUserState] = useRecoilState(UserState);

  useEffect(() => {
    (async () => {
      if (
        authState.token &&
        authState.userId &&
        userState?.userId !== authState.userId
      ) {
        const { data } = await UserByUserIdRequest({
          userId: authState.userId,
        });

        setUserState(data);
      }
    })();
  }, [authState.token, authState.userId, userState, setUserState]);

  useEffect(() => {
    if (!authState || !authState.token) {
      if (!authState.initialPath) {
        setAuthState({ initialPath: pathname });
      }

      if (pathname !== '/login') {
        navigate('/login');
      }
    }
  }, [authState.token, pathname, navigate, setAuthState, authState]);

  if (!authState.token || !userState?.userId) {
    return null;
  }

  return children;
};

export default SecureRoute;

// eslint-disable-next-line import/prefer-default-export
export enum TaskStatus {
  PENDING = 'pending',
  PROCESSING = 'processing',
  COMPLETE = 'complete',
  FAILED = 'failed',
  CANCELLED = 'cancelled',
  TIMEOUT = 'timeout',
  QUEUED = 'queued',
}

import { ReactElement, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Grid, styled, Typography } from '@mui/material';
import { Alert, Button, Form } from 'Atoms';
import {
  VerifyResetPasswordForm,
  VerifyResetPasswordInitialValues,
  VerifyResetPasswordInput,
  VerifyResetPasswordValidation,
} from 'Forms';
import { useVerifyResetPasswordMutation } from 'Mutations';
import { AuthenticationTemplate } from 'Templates';
import { reactHookFormBackendErrorHandler } from 'Utils';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

export type VerifyResetPasswordPageProps = {
  className?: string;
};

const VerifyResetPasswordPage = ({
  className,
}: VerifyResetPasswordPageProps): ReactElement => {
  const { token } = useParams();
  const navigate = useNavigate();

  const [verifyResetPasswordSucceed, setVerifyResetPasswordSucceed] =
    useState<boolean>(false);

  const form = useForm<VerifyResetPasswordInput>({
    resolver: yupResolver(VerifyResetPasswordValidation()),
    mode: 'all',
    defaultValues: VerifyResetPasswordInitialValues && { token },
    shouldFocusError: true,
  });

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
  } = form;

  const handleVerifyResetPasswordSucceeded = async () => {
    setVerifyResetPasswordSucceed(true);

    await new Promise((resolve) => {
      setTimeout(resolve, 15000);
    });

    navigate('/login');
  };

  const verifyResetPassword = useVerifyResetPasswordMutation({
    onSuccess: async () => {
      await handleVerifyResetPasswordSucceeded();
    },
  });

  const handleVerifyResetPassword = useCallback(
    async (values: VerifyResetPasswordInput) => {
      setVerifyResetPasswordSucceed(false);

      const newValues = values;
      newValues.token = token || '';

      try {
        await verifyResetPassword.mutateAsync(newValues);
      } catch (exception) {
        if (axios.isAxiosError(exception)) {
          reactHookFormBackendErrorHandler<VerifyResetPasswordInput>(
            exception,
            form
          );

          return;
        }

        throw exception;
      }
    },
    [form, token, verifyResetPassword]
  );

  return (
    <AuthenticationTemplate>
      <Box className={className}>
        <Box className="Db-VerifyResetPasswordPage-Title">
          <Typography variant="h3">
            <FormattedMessage id="verifyResetPassword.title" />
          </Typography>
          <Typography variant="h6">
            <FormattedMessage id="verifyResetPassword.subtitle" />
          </Typography>
        </Box>
        <Box className="Db-VerifyResetPasswordPage-Form">
          <Form
            name="VerifyResetPassword"
            noValidate
            onSubmit={handleSubmit(handleVerifyResetPassword)}
          >
            <FormProvider {...form}>
              <Grid container rowSpacing={2} alignItems="center">
                <Grid item xs={12}>
                  {errors.status && (
                    <Alert severity="warning">
                      <FormattedMessage id={errors.status?.message} />
                    </Alert>
                  )}
                </Grid>

                {verifyResetPasswordSucceed && (
                  <Grid item xs={12}>
                    <Alert severity="success">
                      <FormattedMessage id="verifyResetPassword.succeedMessage" />
                    </Alert>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <VerifyResetPasswordForm />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    loading={isSubmitting}
                    fullWidth
                    color={verifyResetPasswordSucceed ? 'success' : 'primary'}
                    disabled={verifyResetPasswordSucceed}
                  >
                    <FormattedMessage id="button.resetPassword" />
                  </Button>
                </Grid>
              </Grid>
            </FormProvider>
          </Form>
        </Box>
      </Box>
    </AuthenticationTemplate>
  );
};

export default styled(VerifyResetPasswordPage)(
  ({ theme }) => `
    .Db-VerifyResetPasswordPage-Title {
      margin-bottom: 30px;
      max-width: 500px;

      .MuiTypography-h6 {
        color: #667085;
        margin-top: 10px;
      }
  }
  
  .Db-VerifyResetPasswordPage-Form {
    width: 500px;

    .MuiTypography-h6 {
      color: #667085;
      margin-top: 10px;
    }
    
    .MuiButton-root {
      border-radius: 8px;
      height: 52px;
    }

    .MuiButton-text {
      color: ${theme.palette.primary.main};
    }
  }
  `
);

import { ErrorResponse, Uuid } from 'ValueObjects';
import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
} from '@tanstack/react-query';

export type UpdateProfileEmailMutationProps = {
  userId: Uuid;
  emailAddress: string;
};

export const UpdateProfileEmailMutation = async ({
  ...values
}: UpdateProfileEmailMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/profile/user/${values.userId}/email`, {
    ...values,
  });

export const useUpdateProfileEmailMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateProfileEmailMutationProps
  > = {}
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  UpdateProfileEmailMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateProfileEmailMutationProps
  >(UpdateProfileEmailMutation, {
    useErrorBoundary: false,
    ...options,
  });

import { ReactElement } from 'react';
import { RequestAndResponse } from 'Models';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from 'Molecules';
import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Button, Icon } from 'Atoms';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import colors from 'App/colors';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';

export type RequestAndResponseDetailsModalProps = Omit<
  DialogProps,
  'title' | 'children'
> & {
  requestAndResponse: RequestAndResponse;
};

const RequestAndResponseDetailsModal = ({
  requestAndResponse,
  ...props
}: RequestAndResponseDetailsModalProps): ReactElement => (
  <Dialog maxWidth="md" fullWidth {...props}>
    <DialogTitle>{requestAndResponse.url}</DialogTitle>
    <DialogContent>
      <Box sx={{ width: '100%', overflow: 'hidden' }}>
        <Table sx={{ tableLayout: 'fixed' }}>
          <TableBody>
            <TableRow>
              <TableCell variant="head">
                <FormattedMessage id="label.url" />:
              </TableCell>
              <TableCell>{requestAndResponse.url}</TableCell>
            </TableRow>
            {requestAndResponse.moduleName && (
              <TableRow>
                <TableCell variant="head">
                  <FormattedMessage id="label.module" />:
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id={`moduleTypes.${requestAndResponse.packageType}.${requestAndResponse.moduleName}`}
                    defaultMessage={requestAndResponse.moduleName}
                  />
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell variant="head">
                <FormattedMessage id="label.method" />:
              </TableCell>
              <TableCell>{requestAndResponse.method}</TableCell>
            </TableRow>
            {requestAndResponse.exception && (
              <TableRow>
                <TableCell>
                  <FormattedMessage id="label.exception" />
                </TableCell>
                <TableCell>
                  <SyntaxHighlighter language="json" style={atomOneDark}>
                    {JSON.stringify(requestAndResponse.exception, null, 2)}
                  </SyntaxHighlighter>
                </TableCell>
              </TableRow>
            )}
            {requestAndResponse.options && (
              <TableRow>
                <TableCell>
                  <FormattedMessage id="label.options" />
                </TableCell>
                <TableCell>
                  <SyntaxHighlighter language="json" style={atomOneDark}>
                    {JSON.stringify(requestAndResponse.options, null, 2)}
                  </SyntaxHighlighter>
                </TableCell>
              </TableRow>
            )}
            {requestAndResponse.response && (
              <TableRow>
                <TableCell>
                  <FormattedMessage id="label.response" />
                </TableCell>
                <TableCell>
                  <SyntaxHighlighter language="json" style={atomOneDark}>
                    {JSON.stringify(requestAndResponse.response, null, 2)}
                  </SyntaxHighlighter>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
    </DialogContent>
    <DialogActions>
      <Button
        color="quaternary"
        size="medium"
        onClick={() => props.onClose?.({}, 'escapeKeyDown')}
      >
        <Icon icon={faTimes} />
        &nbsp; <FormattedMessage id="button.close" />
      </Button>
    </DialogActions>
  </Dialog>
);

export default styled(RequestAndResponseDetailsModal)(`
.MuiTableCell-head {
    height: 54px;
    width: 113px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.17px;
    color: ${colors.coolGray[900]};
}
`);

import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { styled, ButtonProps as MuiButtonProps } from '@mui/material';
import { ReactElement, useCallback, useState } from 'react';
import { Icon } from 'Atoms/Icon';
import { faRefresh } from '@fortawesome/pro-light-svg-icons';
import { FormattedMessage } from 'react-intl';
import colors from 'App/colors';

export type ButtonProps = LoadingButtonProps & MuiButtonProps;

const Button = ({
  loading,
  children,
  onClick,
  ...props
}: ButtonProps): ReactElement => {
  const [forceLoading, setForceLoading] = useState<boolean>(false);
  const handleClick = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      setForceLoading(true);
      await onClick?.(event);
      setForceLoading(false);
    },
    [onClick]
  );

  return (
    <LoadingButton
      loading={loading || forceLoading}
      onClick={handleClick}
      {...props}
    >
      {loading ? <FormattedMessage id="label.loading" /> : children}
    </LoadingButton>
  );
};

Button.defaultProps = {
  variant: 'contained',
  color: 'secondary',
  loadingIndicator: <Icon icon={faRefresh} spin />,
  loadingPosition: 'start',
};

export default styled(Button)(
  () => `
  text-transform: none;
  border-radius: 8px;
  box-shadow: none;
  
  &.MuiButton-outlined {
    border-radius: 4px;
  }
  
  
  &:hover {
    box-shadow: none;
  }
  
  &.MuiButton-containedTertiary {
    color: ${colors.coolGray[700]};
    background: ${colors.coolGray[50]};
    border: 1px solid ${colors.coolGray[200]};
    
    &:hover {
      box-shadow: none;
      background: ${colors.coolGray[200]} !important;
    }
  }
  
  
  &.MuiButton-containedQuaternary {
    color: ${colors.coolGray[900]};
    background: ${colors.coolGray[200]};
    border: 1px solid ${colors.coolGray[200]};
    
    &:hover {
      box-shadow: none;
      background: ${colors.coolGray[400]} !important;
    }
  }
  
  &.MuiButton-outlined, &.MuiButton-container, &.MuiButton-text {
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 22px;
    font-sze: 14px;
  }
  
  &.MuiButton-root.MuiLoadingButton-root.Mui-disabled.MuiLoadingButton-loading {
    opacity: 1;
  }
  
  .MuiLoadingButton-loadingIndicator {
    position: initial;
    margin-right: 12px;
    left: 0;
  }
  
  &.MuiButton-sizeLarge {
    height: 40px;
    padding: 10px 16px;
  }
  
  &.MuiButton-sizeMedium {
    height: 34px;
    padding: 8px 12px;
  }
  &.MuiButton-sizeSmall {
    padding: 4px 8px;
    height: 28px;
  }
`
);

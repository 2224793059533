import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import * as Yup from 'yup';
import { Grid, MenuItem, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import {
  Controller,
  FieldValues,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import {
  Button,
  Icon,
  ReactHookFormTextField,
  ReactHookWizardStepProps,
  Step,
  StepLabel,
} from 'Atoms';
import { Uuid } from 'ValueObjects';
import { v4 } from 'uuid';
import {
  useCreateCredentialMutation,
  ExactOnlineCredentialDto,
  ExactOnlineCredentialRequestDto,
} from 'Mutations';
import { PackageType } from 'Models';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { useCredentialByCredentialIdRequestQuery } from 'Requests';
import { WizardStepper } from 'Molecules';

export type ConnectWithExactOnlineStepStepInput = {
  credentials: {
    'exact-online': {
      name: string;
      connected: boolean;
      country: string;
      credentialId: Uuid;
    };
  };
};

const supportedCountries = ['NL', 'BE', 'DE', 'UK'];

export const ExactOnlineCredentialStepBeforeConnectFormValidation =
  (): Yup.AnyObjectSchema =>
    Yup.object({
      name: Yup.string().required().label('label.name'),
      country: Yup.string()
        .required()
        .label('label.country')
        .oneOf(supportedCountries),
    });

export const ConnectWithExactOnlineStepValidation =
  (): Yup.SchemaOf<ConnectWithExactOnlineStepStepInput> =>
    Yup.object({
      credentials: Yup.object({
        'exact-online': Yup.object({
          credentialId: Yup.string().required().label('label.credentialId'),
          connected: Yup.boolean().required().label('label.connected').isTrue(),
        }).concat(ExactOnlineCredentialStepBeforeConnectFormValidation()),
      }),
    });

const ConnectWithExactOnlineStep = <
  FormInput extends FieldValues = FieldValues
>({
  currentStep,
  wizardSteps,
}: ReactHookWizardStepProps<FormInput>): ReactElement => {
  const organizationId = useWatch({ name: 'organizationId' });
  const { setValue } = useFormContext();
  const [credentialId, name, country, connected] = useWatch<
    ConnectWithExactOnlineStepStepInput,
    [
      'credentials.exact-online.credentialId',
      'credentials.exact-online.name',
      'credentials.exact-online.country',
      'credentials.exact-online.connected'
    ]
  >({
    name: [
      'credentials.exact-online.credentialId',
      'credentials.exact-online.name',
      'credentials.exact-online.country',
      'credentials.exact-online.connected',
    ],
  });

  const createNewCredential = useCreateCredentialMutation();
  const { data: credentialData } = useCredentialByCredentialIdRequestQuery(
    {
      credentialId: credentialId || '',
    },
    {
      enabled: !!credentialId,
    }
  );

  useEffect(() => {
    if (credentialData?.data?.connected) {
      setValue('credentials.exact-online.connected', true, {
        shouldValidate: true,
      });
    }
  }, [credentialData?.data?.connected, setValue]);

  const handleOpenConnect = useCallback((connectingId: string) => {
    window.open(
      `${process.env.REACT_APP_AUTH_URL}/connect/exact-online/${connectingId}`,
      '_blank'
    );
  }, []);

  const handleConnect = useCallback(async () => {
    if (credentialId && !connected) {
      handleOpenConnect(credentialId);
      return;
    }

    const newCredentialId = credentialId || v4();
    const credentialRequest: ExactOnlineCredentialRequestDto = {
      successUrl: `${window.location.origin}/credential/oauth/success/${newCredentialId}`,
      failureUrl: `${window.location.origin}/credential/oauth/error/${newCredentialId}`,
      country,
    };

    const newCredential: ExactOnlineCredentialDto = {
      name,
      country,
    };

    const result = await createNewCredential.mutateAsync({
      credentialId: newCredentialId,
      organizationId,
      package: {
        packageType: PackageType.EXACT_ONLINE,
        credentialRequest,
        credential: newCredential,
        settings: [],
      },
    });

    setValue(
      'credentials.exact-online.credentialId',
      result.data.credentialId,
      {
        shouldValidate: true,
      }
    );
    handleOpenConnect(result.data.credentialId);
  }, [
    connected,
    country,
    createNewCredential,
    credentialId,
    handleOpenConnect,
    name,
    organizationId,
    setValue,
  ]);

  const isValidToConnect: boolean = useMemo(() => {
    const validation = ExactOnlineCredentialStepBeforeConnectFormValidation();
    try {
      validation.validateSync({ name, country });
      return true;
      // eslint-disable-next-line no-empty
    } catch (e) {}

    return false;
  }, [name, country]);

  const subSteps = useMemo(
    () =>
      wizardSteps.filter(({ category }) => category === currentStep.category),
    [currentStep.category, wizardSteps]
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h4" color="primary">
          <FormattedMessage
            id="onboarding.steps.connect"
            values={{
              packageType: <FormattedMessage id="packageTypes.exact-online" />,
            }}
          />
        </Typography>
      </Grid>
      <Grid item pt={2}>
        <WizardStepper
          activeStep={subSteps.indexOf(currentStep)}
          orientation="horizontal"
        >
          {subSteps.map(({ title }, index) => (
            <Step key={index}>
              <StepLabel>{title}</StepLabel>
            </Step>
          ))}
        </WizardStepper>
      </Grid>
      <Grid item xs={12} container spacing={3} pt={4}>
        <Grid item xs={12}>
          <Controller
            name="credentials.exact-online.name"
            render={(registered) => (
              <ReactHookFormTextField
                {...registered}
                fullWidth
                disabled={!!credentialId}
                label={<FormattedMessage id="label.name" />}
                autoFocus
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="credentials.exact-online.country"
            render={(registered) => (
              <ReactHookFormTextField
                {...registered}
                fullWidth
                disabled={!!credentialId}
                label={<FormattedMessage id="label.country" />}
                select
              >
                {supportedCountries.map((supportedCountry) => (
                  <MenuItem key={supportedCountry} value={supportedCountry}>
                    {supportedCountry}
                  </MenuItem>
                ))}
              </ReactHookFormTextField>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          {!connected && (
            <Button disabled={!isValidToConnect} onClick={handleConnect}>
              <FormattedMessage id="button.connect" />
            </Button>
          )}
          {connected && (
            <Button color="success">
              <Icon icon={faCheck} />
              &nbsp;
              <FormattedMessage id="button.connected" />
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ConnectWithExactOnlineStep;

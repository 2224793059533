import {
  LayoutWithTopBar,
  WatermarkedTemplate,
  WizardTemplate,
} from 'Templates';
import { ReactElement, useMemo, useRef } from 'react';
import {
  Icon,
  MenuItem,
  MenuList,
  ReactHookWizard,
  ReactHookWizardStep,
} from 'Atoms';
import { EmailAddress, Uuid } from 'ValueObjects';
import { useParams, useSearchParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { isPackageType, PackageType } from 'Models';
import {
  InvoiceDetailsStepForm,
  InvoiceDetailsStepFormValidation,
  RegistrationStepForm,
  RegistrationStepFormValidation,
} from 'Forms';
import { DevTool } from '@hookform/devtools';
import { useDynamicStepsForConnection } from 'Utils';
import { HelpTooltip } from 'Molecules';
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import { ListItemIcon, ListItemText } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type OnboardingWizardInput<Credentials = any> = Credentials & {
  onboardingId: Uuid;
  connectionId?: Uuid;
  organizationId?: Uuid;
  organizationName?: string;
  contactName?: string;
  emailAddress?: EmailAddress;
  password?: string;
  confirmPassword?: string;
  acceptTermsAndConditions?: boolean;
  packages: PackageType[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  credentials: any;
};

const defaultValues: OnboardingWizardInput = {
  onboardingId: '',
  organizationId: '',
  organizationName: '',
  contactName: '',
  emailAddress: '',
  password: '',
  confirmPassword: '',
  acceptTermsAndConditions: false,
  packages: [],
  credentials: {},
};

type OnboardingWizardStep = ReactHookWizardStep<OnboardingWizardInput>;

const OnboardingWizardPage = (): ReactElement | null => {
  const { onboardingId } = useParams();
  const [searchParams] = useSearchParams();
  const packages: PackageType[] = useMemo(
    () =>
      (searchParams.getAll('package[]') || []).filter((p): p is PackageType =>
        isPackageType(p)
      ),
    [searchParams]
  );
  const containerRef = useRef<HTMLDivElement>(null);

  const dynamicSteps = useDynamicStepsForConnection<OnboardingWizardInput>({
    packages,
  });

  const steps: OnboardingWizardStep[] = useMemo(() => {
    const initialSteps: OnboardingWizardStep[] = [
      // {
      //   title: <FormattedMessage id="onboarding.steps.connectionSettings" />,
      //   render: () => <PayPalExactOnlineConnectionSettingsStepForm />,
      //   validationSchema:
      //     PaypalConnectionSettingsStepCurrencyMappingFormValidation2(),
      // },
      {
        title: <FormattedMessage id="onboarding.steps.registration" />,
        category: 'registration',
        categoryTitle: <FormattedMessage id="onboarding.steps.registration" />,
        render: ({ actions }) => (
          <RegistrationStepForm actions={actions} packageTypes={packages} />
        ),
        validationSchema: RegistrationStepFormValidation(),
      },
      {
        title: <FormattedMessage id="onboarding.steps.invoiceDetails" />,
        category: 'invoiceDetails',
        categoryTitle: (
          <FormattedMessage id="onboarding.steps.invoiceDetails" />
        ),
        render: () => <InvoiceDetailsStepForm />,
        validationSchema: InvoiceDetailsStepFormValidation(),
      },
    ];

    return [
      ...initialSteps,
      ...dynamicSteps.credentialSteps,
      ...dynamicSteps.settingsSteps,
      ...dynamicSteps.finishingSteps,
    ];
  }, [
    dynamicSteps.credentialSteps,
    dynamicSteps.finishingSteps,
    dynamicSteps.settingsSteps,
    packages,
  ]);

  if (!onboardingId || packages.length === 0) {
    return null;
  }

  const initialValues = {
    ...defaultValues,
    onboardingId,
    packages,
  };

  return (
    <LayoutWithTopBar>
      <WatermarkedTemplate>
        <ReactHookWizard
          // eslint-disable-next-line no-console
          onSubmit={(values) => console.log(values)}
          initialValues={initialValues}
          initialStepIndex={0}
          wizardSteps={steps}
          useProvider
          name="onboarding"
          persist={`onboarding-${onboardingId}`}
          container={containerRef.current}
        >
          {({
            children,
            currentStepIndex,
            handlePrev,
            isFirstStep,
            form: {
              control,
              formState: { isSubmitting, isValid },
            },
          }) => (
            <WizardTemplate
              steps={steps}
              currentStepIndex={currentStepIndex}
              handlePrev={handlePrev}
              isPrevDisabled={isFirstStep}
              isNextDisabled={!isValid || isSubmitting}
              ref={containerRef}
            >
              <DevTool control={control} />

              {children}
            </WizardTemplate>
          )}
        </ReactHookWizard>
        <HelpTooltip>
          <MenuList>
            <MenuItem>
              <ListItemIcon>
                <Icon icon={faCircleInfo} />
              </ListItemIcon>
              <ListItemText>
                <FormattedMessage id="button.requestHelp" />
              </ListItemText>
            </MenuItem>
            <MenuItem>
              <ListItemIcon>
                <Icon icon={faCircleInfo} />
              </ListItemIcon>
              <ListItemText>
                <FormattedMessage id="button.iDontKnow" />
              </ListItemText>
            </MenuItem>
          </MenuList>
        </HelpTooltip>
      </WatermarkedTemplate>
    </LayoutWithTopBar>
  );
};

export default OnboardingWizardPage;

import {
  ClickAwayListener,
  Divider,
  Grow,
  MenuItem,
  Paper,
  Popper,
  styled,
} from '@mui/material';
import { Checkbox, MenuList, MenuListProps } from 'Atoms';
import { ReactElement, useCallback, useMemo } from 'react';

export type MultiSelectMenuValueItem = {
  key: string;
  label: string | ReactElement;
  checked?: boolean;
  favorite?: boolean;
};

export type MultiSelectMenuProps = MenuListProps & {
  items: MultiSelectMenuValueItem[];
  onChangeItem: (
    option: MultiSelectMenuValueItem,
    checked: boolean
  ) => void | Promise<void>;
  open: boolean;
  onClose: () => void;
  anchorEl: HTMLElement | null;
};

const MultiSelectMenu = ({
  items,
  onChangeItem,
  onClose,
  open,
  anchorEl,
  ...args
}: MultiSelectMenuProps): ReactElement => {
  const favoriteItems = useMemo(
    () => items.filter(({ favorite }) => favorite),
    [items]
  );

  const nonFavoriteItems = useMemo(
    () => items.filter(({ favorite }) => !favorite),
    [items]
  );

  const renderMenuItem = useCallback(
    (item: MultiSelectMenuValueItem) => (
      <MenuItem
        key={item.key}
        onClick={(e) => {
          if (e.target instanceof HTMLLIElement) {
            onChangeItem(item, !item.checked);
          }
        }}
      >
        <Checkbox
          disableRipple
          label={item.label}
          name={item.key}
          checked={item.checked}
          onChange={(e, checked) => onChangeItem(item, checked)}
        />
      </MenuItem>
    ),
    [onChangeItem]
  );

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement="bottom"
      transition
      style={{ zIndex: 9 }}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper elevation={8} sx={{ overflow: 'auto', maxHeight: '275px' }}>
            <ClickAwayListener onClickAway={onClose}>
              <MenuList {...args}>
                {favoriteItems.map(renderMenuItem)}
                {favoriteItems.length > 0 && (
                  <Divider style={{ marginTop: 0, marginBottom: 0 }} />
                )}
                {nonFavoriteItems.map(renderMenuItem)}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default styled(MultiSelectMenu)(
  () => ` 
  .MuiFormControlLabel-root {
    margin-right: 0px;
  }  
  
  .MuiMenuItem-root {
    height: 36px;
  }
`
);

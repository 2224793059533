import {
  MenuItem as MuiMenuItem,
  MenuItemProps as MuiMenuItemProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactElement } from 'react';

export type MenuItemProps = MuiMenuItemProps;

const MenuItemItem = ({ ...props }: MenuItemProps): ReactElement => (
  <MuiMenuItem {...props} />
);

MenuItemItem.defaultProps = {};

export default styled(MenuItemItem)(
  ({ theme }) => `
  padding: ${theme.spacing(1, 0)};
  
  &:hover {
    background-color: inherit;
    &, .MuiListItemIcon-root {
      color: ${theme.palette.secondary.main};
    }
  }
  .MuiListItemIcon-root {
    font-size: 20px;
    color: #C4C4C4;
  }
  .MuiListItemText-root .MuiListItemText-primary {
    color: rgba(0, 0, 0, 0.87);
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.15px;
  }
`
);

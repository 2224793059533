import { ReactElement, ReactNode } from 'react';
import { Box, styled } from '@mui/material';
import clsx from 'clsx';

export type TwoSidesTemplateProps = {
  className?: string;
  leftSide: ReactNode | ReactNode[];
  rightSide?: ReactNode | ReactNode[];
};

const TwoSidesTemplate = ({
  leftSide,
  rightSide,
  className,
}: TwoSidesTemplateProps): ReactElement => (
  <Box className={clsx('Db-TwoSidesTemplate', className)}>
    <Box className="Db-TwoSidesTemplate-LeftSide">{leftSide}</Box>
    <Box className="Db-TwoSidesTemplate-RightSide">
      <Box className="Db-TwoSidesTemplate-RightSideContent">{rightSide}</Box>
    </Box>
  </Box>
);

export default styled(TwoSidesTemplate)(
  ({ theme }) => `
  height: 100%;
  background: ${theme.palette.background.default};
  display: flex;
  
  .Db-TwoSidesTemplate-LeftSide, .Db-TwoSidesTemplate-RightSide {
    width: 50%;
  }
  
  .Db-TwoSidesTemplate-RightSide {
    position: relative;
    .Db-TwoSidesTemplate-RightSideContent {
      z-index: 100;
    }
    
    .Db-TwoSidesTemplate-RightSideContent {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
`
);

import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import * as Yup from 'yup';
import { TabGroup } from 'Organisms';
import { PaypalCurrencyMap, PaypalTransactionField } from 'Models';
import { ReactHookFormAutocomplete } from 'Atoms';
import hash from 'object-hash';
import { PayPalCurrencyMappingForm } from '../PayPalCurrencyMappingForm';

export type PaypalCurrencyMapping = PaypalCurrencyMap & {
  enabled: boolean;
  validatedByBackoffice: boolean;
  validatingWithBackoffice: boolean;
  validatedHash?: string;
};

export const PaypalCurrencyMappingInitialValues: PaypalCurrencyMapping = {
  currency: '',
  bankJournalCode: '',
  feesGeneralLedgerCode: '',
  unallocatedGeneralLedgerCode: '',
  enabled: true,
  validatedByBackoffice: false,
  validatingWithBackoffice: false,
};

const options = [
  { key: 'EUR', label: 'EUR', favorite: true },
  { key: 'USD', label: 'USD', favorite: true },
  { key: 'GBP', label: 'GBP', favorite: true },
  { key: 'AUD', label: 'AUD', favorite: true },
  { key: 'CAD', label: 'CAD', favorite: false },
  { key: 'CZK', label: 'CZK', favorite: false },
  { key: 'DKK', label: 'DKK', favorite: false },
  { key: 'HKD', label: 'HKD', favorite: false },
  { key: 'ILS', label: 'ILS', favorite: false },
  { key: 'MXN', label: 'MXN', favorite: false },
  { key: 'NZD', label: 'NZD', favorite: false },
  { key: 'NOK', label: 'NOK', favorite: false },
  { key: 'PHP', label: 'PHP', favorite: false },
  { key: 'PLN', label: 'PLN', favorite: false },
  { key: 'RUB', label: 'RUB', favorite: false },
  { key: 'SGD', label: 'SGD', favorite: false },
  { key: 'SEK', label: 'SEK', favorite: false },
  { key: 'CHF', label: 'CHF', favorite: false },
  { key: 'THB', label: 'THB', favorite: false },
  { key: 'JPY', label: 'JPY', favorite: false },
  { key: 'HUF', label: 'HUF', favorite: false },
  { key: 'TWD', label: 'TWD', favorite: false },
  { key: 'BRL', label: 'BRL', favorite: false },
  { key: 'CNY', label: 'CNY', favorite: false },
  { key: 'AED', label: 'AED', favorite: false },
];

export type PaypalSettingsFormInput = {
  currencyMapping: PaypalCurrencyMapping[];
  descriptionFieldsPriority: PaypalTransactionField[];
};

const PaypalTransactionFieldValidation =
  (): Yup.SchemaOf<PaypalTransactionField> =>
    // @ts-ignore
    Yup.mixed<PaypalTransactionField>()
      .oneOf(Object.values(PaypalTransactionField))
      .required()
      .label('label.descriptionFieldsPriority');

const PaypalCurrencyMappingValidation =
  (): Yup.SchemaOf<PaypalCurrencyMapping> =>
    Yup.object({
      currency: Yup.string().required().label('label.currency'),
      bankJournalCode: Yup.string().required().label('label.bankJournalCode'),
      feesGeneralLedgerCode: Yup.string()
        .required()
        .label('label.feesGeneralLedgerCode'),
      unallocatedGeneralLedgerCode: Yup.string()
        .required()
        .label('label.unallocatedGeneralLedgerCode'),
      validatedHash: Yup.string().label('label.validatedHash'),
      enabled: Yup.boolean().required().label('label.enabled'),
      validatedByBackoffice: Yup.boolean()
        .required()
        .isTrue()
        .label('label.validatedByBackoffice'),
      validatingWithBackoffice: Yup.boolean()
        .required()
        .isFalse()
        .label('label.validatingWithBackoffice'),
    }).when((value, schema) => {
      if (value.enabled) {
        return schema;
      }

      return Yup.object().nullable();
    });

export const PaypalSettingsFormValidation =
  (): Yup.SchemaOf<PaypalSettingsFormInput> =>
    Yup.object({
      descriptionFieldsPriority: Yup.array()
        .of(PaypalTransactionFieldValidation())
        .label('label.descriptionFieldsPriority'),
      currencyMapping: Yup.array().of(PaypalCurrencyMappingValidation()).min(1),
    });

type StepInputType = { settings: { paypal: PaypalSettingsFormInput } };

export type PaypalSettingsFormProps = {
  generalLedgers: { externalId: string; label: string }[];
  bankJournals: { externalId: string; label: string }[];
  hasFailedLoading?: boolean;
  isLoading?: boolean;
};

const PaypalSettingsForm = ({
  generalLedgers,
  bankJournals,
  isLoading,
  hasFailedLoading,
}: PaypalSettingsFormProps): ReactElement => {
  const { formatMessage } = useIntl();
  const selectedFields = useWatch({
    name: 'settings.paypal.descriptionFieldsPriority',
  });
  const {
    setValue,
    formState: { errors },
  } = useFormContext<StepInputType>();

  useEffect(() => {
    if (typeof selectedFields !== 'object') {
      setValue('settings.paypal.descriptionFieldsPriority', []);
    }
  }, [selectedFields, setValue]);

  const watchedCurrencyMappings = useWatch<
    StepInputType,
    'settings.paypal.currencyMapping'
  >({ name: 'settings.paypal.currencyMapping' });

  const { fields, append } = useFieldArray<
    StepInputType,
    'settings.paypal.currencyMapping'
  >({
    name: 'settings.paypal.currencyMapping',
  });

  const currencyMappings = useMemo(
    () =>
      fields.map((field, index) => ({
        ...field,
        ...watchedCurrencyMappings[index],
      })),
    [fields, watchedCurrencyMappings]
  );

  const handleAddTab = useCallback(
    (currency: string) => {
      const index = currencyMappings.findIndex(
        (map) => map.currency === currency
      );
      if (index >= 0) {
        setValue(`settings.paypal.currencyMapping.${index}.enabled`, true, {
          shouldValidate: true,
          shouldDirty: true,
        });
      } else {
        append({
          ...PaypalCurrencyMappingInitialValues,
          currency,
        });
      }
    },
    [append, currencyMappings, setValue]
  );

  const handleRemoveTab = useCallback(
    (id: string) => {
      const mappingIndex = currencyMappings.findIndex(
        (map) => map.id === id || map.currency === id
      );

      if (mappingIndex >= 0) {
        setValue(
          `settings.paypal.currencyMapping.${mappingIndex}.enabled`,
          false,
          {
            shouldValidate: true,
            shouldDirty: true,
          }
        );
      }
    },
    [currencyMappings, setValue]
  );

  const handleValidateCurrencyMapping = useCallback(
    async (index: number) => {
      const mapping = currencyMappings[index];

      setValue(
        `settings.paypal.currencyMapping.${index}.validatingWithBackoffice`,
        true
      );

      await new Promise((r) => {
        setTimeout(r, 1050);
      });

      setValue(
        `settings.paypal.currencyMapping.${index}.validatedByBackoffice`,
        true
      );

      setValue(
        `settings.paypal.currencyMapping.${index}.validatingWithBackoffice`,
        false
      );

      setValue(
        `settings.paypal.currencyMapping.${index}.validatedHash`,
        hash(mapping, {
          excludeKeys: (key) =>
            [
              'id',
              'enabled',
              'validatedByBackoffice',
              'validatingWithBackoffice',
              'validatedHash',
            ].includes(key),
        })
      );
    },
    [currencyMappings, setValue]
  );

  const getOptionLabel = useCallback(
    (option: string): string =>
      formatMessage({ id: `paypalTransactionFields.${option}` }),
    [formatMessage]
  );

  const descriptionFieldOptions = useMemo(
    () =>
      Object.values(PaypalTransactionField).filter(
        (option) => !(selectedFields || []).includes(option)
      ),
    [selectedFields]
  );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h6" color="primary" pb="10px">
          <FormattedMessage id="packageTypes.paypal" />
        </Typography>
        <Controller
          name="settings.paypal.descriptionFieldsPriority"
          render={(registered) => (
            <ReactHookFormAutocomplete
              {...registered}
              textFieldProps={{
                fullWidth: true,
                label: (
                  <FormattedMessage id="label.descriptionFieldsPriority" />
                ),
              }}
              multiple
              options={descriptionFieldOptions}
              getOptionLabel={getOptionLabel}
              disableCloseOnSelect
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" color="primary" pb="10px">
          <FormattedMessage id="label.paypalCurrencyMapping" />
        </Typography>
        <TabGroup
          keyName="Currency"
          defaultContent={
            <Typography textAlign="center">
              <FormattedMessage id="label.startAddingCurrencies" />
            </Typography>
          }
          tabs={currencyMappings
            .map(({ id, currency, enabled, validatedByBackoffice }, index) => ({
              id,
              key: currency,
              label: currency,
              content: (
                <PayPalCurrencyMappingForm
                  onRequestValidation={() =>
                    handleValidateCurrencyMapping(index)
                  }
                  hasErrors={
                    !!errors.settings?.paypal?.currencyMapping?.[index]
                  }
                  index={index}
                  generalLedgers={generalLedgers}
                  bankJournals={bankJournals}
                  isLoading={isLoading}
                  hasFailedLoading={hasFailedLoading}
                />
              ),
              approved: validatedByBackoffice,
              enabled,
            }))
            .filter(({ enabled }) => enabled)}
          options={options}
          onAddTab={handleAddTab}
          onRemoveTab={handleRemoveTab}
        />
      </Grid>
    </Grid>
  );
};

export default PaypalSettingsForm;

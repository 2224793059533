import { ErrorResponse, Uuid } from 'ValueObjects';
import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
} from '@tanstack/react-query';

export type UpdateProfilePasswordMutationProps = {
  userId: Uuid;
  newPassword: string;
  oldPassword: string;
};

export const UpdateProfilePasswordMutation = async ({
  ...values
}: UpdateProfilePasswordMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/profile/user/${values.userId}/password`, {
    ...values,
  });

export const useUpdateProfilePasswordMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateProfilePasswordMutationProps
  > = {}
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  UpdateProfilePasswordMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateProfilePasswordMutationProps
  >(UpdateProfilePasswordMutation, {
    useErrorBoundary: false,
    ...options,
  });

import { ChangeEvent, useCallback, useState } from 'react';
import { useUploadFileToCreateAMediaObjectMutation } from 'Mutations';
import { UploadingFile } from 'ValueObjects';
import { FormattedMessage } from 'react-intl';
import { UseFormSetValue } from 'react-hook-form';

// eslint-disable-next-line import/prefer-default-export
export const useUploadFile = (
  fieldName: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<any>
) => {
  const { mutateAsync: uploadFile } =
    useUploadFileToCreateAMediaObjectMutation();
  const [uploadingFile, setUploadingFile] = useState<UploadingFile>();

  const handleUpload = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target;
      if (!files || !files[0]) {
        return;
      }

      const file = files[0];

      const fileSize = file.size / 1024 / 1024;
      if (fileSize > 45) {
        setUploadingFile({
          file,
          progress: 0,
          reason: <FormattedMessage id="label.fileTooBig" />,
          failed: true,
        });

        return;
      }

      setUploadingFile({ file, progress: 0 });

      uploadFile({
        file,
        onUploadProgress: (progressEvent) => {
          setUploadingFile((cur) => {
            if (cur) {
              return {
                ...cur,
                progress: Math.round(
                  (progressEvent.loaded * 100) / (progressEvent.total || 0)
                ),
              };
            }

            return undefined;
          });
        },
      })
        .then((result) => {
          setUploadingFile({
            file,
            progress: 100,
            failed: false,
            success: true,
          });

          setValue(fieldName, result.data.mediaObjectId);
        })
        .catch(() => {
          setUploadingFile({
            file,
            progress: 0,
            reason: <FormattedMessage id="label.failedToUpload" />,
            failed: true,
          });
        });
    },
    [fieldName, setValue, uploadFile]
  );

  return {
    handleUpload,
    uploadingFile,
  };
};

import {
  StepLabel as MuiStepLabel,
  StepLabelProps as MuiStepLabelProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { FunctionComponent } from 'react';

export type StepLabelProps = MuiStepLabelProps;

const StepLabel: FunctionComponent<StepLabelProps> = ({
  ...props
}: StepLabelProps) => <MuiStepLabel {...props} />;

StepLabel.defaultProps = {};

export default styled(StepLabel)(() => ``);

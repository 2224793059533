import { ReactElement } from 'react';
import { styled, Typography, Grid, TablePagination } from '@mui/material';
import {
  gridPageCountSelector,
  gridPageSelector,
  gridPageSizeSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import usePagination from '@mui/material/usePagination';
import colors from 'App/colors';
import clsx from 'clsx';

export type GridPaginationProps = {
  className?: string;
};

const GridPagination = ({
  className,
  ...props
}: GridPaginationProps): ReactElement => {
  const apiRef = useGridApiContext();
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  const currentPage = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const { items } = usePagination({
    page: currentPage + 1,
    count: pageCount,
    onChange: (event, page) => {
      apiRef.current.setPage(page - 1);
    },
  });

  return (
    <Grid
      container
      className={className}
      spacing="10px"
      justifyContent="flex-end"
    >
      <Grid item>
        <TablePagination
          page={currentPage}
          count={pageCount}
          onPageChange={(event, value) => apiRef.current.setPage(value)}
          onRowsPerPageChange={(event) => {
            apiRef.current.setPageSize(parseInt(event.target.value, 10));
            apiRef.current.setPage(0);
          }}
          rowsPerPage={pageSize}
        />
      </Grid>
      <Grid item>
        <nav>
          <Grid container className="Db-GridPagination-PagesContainer">
            {items.map(({ page, type, selected, ...item }, index) => {
              let children = null;

              if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                children = (
                  <div
                    className={clsx('Db-GridPagination-PageButton', {
                      'Mui-selected': selected,
                    })}
                    {...item}
                  >
                    …
                  </div>
                );
              } else if (type === 'page') {
                children = (
                  <button
                    type="button"
                    className={clsx('Db-GridPagination-PageButton', {
                      'Mui-selected': selected,
                    })}
                    {...item}
                  >
                    {page}
                  </button>
                );
              } else {
                children = (
                  <button
                    className="Db-GridPagination-NextOrPrevButton"
                    type="button"
                    {...item}
                  >
                    {type}
                  </button>
                );
              }

              return (
                <Grid item key={index}>
                  {children}
                </Grid>
              );
            })}
          </Grid>
        </nav>
      </Grid>
    </Grid>
  );
};

export default styled(GridPagination)(
  () => `
  .MuiTablePagination-displayedRows, .MuiTablePagination-actions {
    display: none;
  }
  
  .MuiTablePagination-root {
    height: 33px;
    box-sizing: border-box;
    border: 1px solid ${colors.gray[400]};
    border-radius: 4px;
    .MuiToolbar-root {
      min-height: unset;
      padding: 4.5px 12px;
    }
    .MuiTablePagination-selectLabel {
      color: ${colors.gray[600]};
      margin: 0;
      padding: 0;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      letter-spacing: 0.15px;
    }
    .MuiInputBase-root {
      margin: 0;
      padding: 0;
      color: ${colors.gray[400]};
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      letter-spacing: 0.15px;
    }
    .MuiSelect-select {
      margin: 0;
      padding: 0;
      padding-right: 24px;
      padding-left: 15px;
    }
  }
  
  .Db-GridPagination-NextOrPrevButton, .Db-GridPagination-PageButton {
    cursor: pointer;
    background: ${colors.white};
    box-sizing: border-box;
    border: 1px solid ${colors.gray[400]};
    border-left: none;
    padding: 6px 12px;  
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.15px;
    color: ${colors.grey[500]};
  }
  
  
  .Db-GridPagination-PagesContainer {
    div:first-of-type > button {
      text-transform: capitalize;
      border-radius: 4px 0px 0px 4px;
      border-left: 1px solid ${colors.gray[400]};
    }
    div:last-of-type > button {
      text-transform: capitalize;
      border-radius: 0px 4px 4px 0px;
    }
  }
  
  .Db-GridPagination-PageButton {
    height: 33px;
    min-width: 33px;
    
    &.Mui-selected {
      color: ${colors.primary.main};
      background: #EAF2F9;
    }
  }
`
);

import { ReactElement } from 'react';
import * as Yup from 'yup';
import { PackageType } from 'Models';
import {
  PayPalExactOnlineConnectionSettingsStepForm,
  PayPalExactOnlineConnectionSettingsStepFormValidation,
} from 'Forms';

type PackagesSettingsMapping = {
  handles: (packages: PackageType[]) => boolean;
  form: ReactElement;
  validation: () => Yup.AnyObjectSchema;
};

const getSettingsMapping = (): PackagesSettingsMapping[] => [
  {
    handles: (packages) =>
      packages.includes(PackageType.PAYPAL) &&
      packages.includes(PackageType.EXACT_ONLINE),
    form: <PayPalExactOnlineConnectionSettingsStepForm />,
    validation: PayPalExactOnlineConnectionSettingsStepFormValidation,
  },
];

// eslint-disable-next-line import/prefer-default-export
export const getSettingsForm = (
  packages: PackageType[]
): PackagesSettingsMapping[] => {
  const mapping = getSettingsMapping();

  return mapping.filter(({ handles }) => handles(packages));
};

import { ReactElement } from 'react';
import { Box, BoxProps, styled, Typography } from '@mui/material';
import { PackageType } from 'Models';
import { FormattedMessage } from 'react-intl';
import emasphere from 'Images/PackageLogos/emasphere.png';
import exactOnline from 'Images/PackageLogos/exactOnline.svg';
import mollie from 'Images/PackageLogos/mollie.svg';
import paypal from 'Images/PackageLogos/paypal.svg';
import twinfield from 'Images/PackageLogos/twinfield.png';
import xero from 'Images/PackageLogos/xero.png';
import recras from 'Images/PackageLogos/recras.png';

export type PackageLogoProps = BoxProps & {
  packageType: PackageType;
  withoutStyling?: boolean;
};

const packageLogos: { [key: string]: string } = {
  [PackageType.EMASPHERE]: emasphere,
  [PackageType.EXACT_ONLINE]: exactOnline,
  [PackageType.MOLLIE]: mollie,
  [PackageType.PAYPAL]: paypal,
  [PackageType.TWINFIELD]: twinfield,
  [PackageType.XERO]: xero,
  [PackageType.RECRAS]: recras,
};

const PackageLogo = ({
  packageType,
  className,
  withoutStyling,
  ...args
}: PackageLogoProps): ReactElement => (
  <Box {...args} className={withoutStyling ? undefined : className}>
    {packageLogos[packageType] ? (
      <img src={packageLogos[packageType]} alt={packageType} />
    ) : (
      <Typography variant="h5" className="Db-PackageLogo-PackageName">
        <FormattedMessage id={`packageTypes.${packageType}`} />
      </Typography>
    )}
  </Box>
);

export default styled(PackageLogo)(
  ({ theme }) => `
  height: 84px;
  max-width: 300px;
  border-radius: 8px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #2196F3;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing(0, 4)};
  img {
    max-height: 64px;
    max-width: 160px;
  }
  
  .Db-PackageLogo-PackageName {
    font-weight: bold;
  }
`
);

import * as Yup from 'yup';

export type UploadProfilePictureInputType = {
  fileId?: string | null;
  uploading?: boolean;
};

export const UploadProfilePictureInputTypeDefaultValues =
  (): UploadProfilePictureInputType => ({
    fileId: '',
    uploading: false,
  });

export const UploadProfilePictureInputTypeValidationBuilder =
  (): Yup.SchemaOf<UploadProfilePictureInputType> =>
    Yup.object({
      fileId: Yup.string().nullable().label('label.fileId'),
      uploading: Yup.boolean().isFalse().label('label.uploading'),
    });

import { Stepper, StepperProps, styled } from '@mui/material';

export type WizardStepperProps = StepperProps;

const WizardStepper = ({ ...args }: WizardStepperProps) => (
  <Stepper {...args} />
);

WizardStepper.defaultProps = {
  orientation: 'vertical',
};

export default styled(WizardStepper)(
  ({ theme }) => `
  &.MuiStepper-horizontal {
    .MuiStepConnector-root {
      min-width: 48px;
    }
  }
  
  &.MuiStepper-vertical {
    .MuiStepLabel-root {
      padding-top: 0;
      padding-bottom: 0;
    }
    
    .MuiStepConnector-root {
      margin-left: 19px;  
    }
    
    .MuiStepConnector-line {
      min-height: 30px;
      border-left: 1px dashed #C4C4C4;
    }
    
    .MuiStepConnector-root {
      &.Mui-completed {
        .MuiStepConnector-line {
          border-left: 1px solid #4CAF50;
        }
      }
      
      &.Mui-active {
        .MuiStepConnector-line {
          border: none;
          position: relative;
          
          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 1px;        
            background: linear-gradient(180deg, #58BC5C 0%, #133156 100%);
          }
        }
      }
    }
    
    .MuiStepLabel-iconContainer {
      padding-right: 10px;
    }
    
    .Db-StepIcon-Finished {
      color: #C4C4C4;
      border: 1px solid #C4C4C4;
      box-sizing: border-box;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
      
    .MuiStepIcon-text, .MuiStepLabel-label {
      font-family: Source Sans Pro;
      font-weight: normal;
      &.MuiStepIcon-text {
        font-size: 12px;
      }
      
      &.MuiStepLabel-label, &.MuiStepLabel-label.Mui-disabled {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.87);
      }
      
      &.Mui-active {
        color: ${theme.palette.primary.main};
      }
    }
    
    .Mui-disabled {
      .MuiStepIcon-root {
        fill: rgba(0, 0, 0, 0.04);
      }
    }
    .MuiStepIcon-root, .Db-StepIcon-Finished {
      height: 40px;
      width: 40px;
      
      &.Mui-completed {
        color: #4CAF50;
      }
    }
  }
`
);

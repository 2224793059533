import {
  AbstractCredentialDto,
  isAbstractCredentialDto,
} from './AbstractCredentialDto';

export interface ExactOnlineCredentialDto extends AbstractCredentialDto {
  country: string;
}

export function isExactOnlineCredentialDto(
  object: unknown
): object is ExactOnlineCredentialDto {
  return (
    isAbstractCredentialDto(object) &&
    'country' in object &&
    typeof object.country === 'string'
  );
}

import { ReactElement } from 'react';
import { Grid } from '@mui/material';
import { Controller } from 'react-hook-form';
import {
  MenuItem,
  ReactHookFormOrganizationAutocomplete,
  ReactHookFormTextField,
} from 'Atoms';
import { useIntl } from 'react-intl';
import { CreateOrganizationInputType } from 'InputTypes';
import { useGetCountryOptions } from 'Utils';

const CreateOrganizationForm = (): ReactElement => {
  const intl = useIntl();
  const countryOptions = useGetCountryOptions();

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Controller<CreateOrganizationInputType>
          name="organizationName"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={intl.formatMessage({ id: 'label.name' })}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller<CreateOrganizationInputType>
          name="organizationCode"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={intl.formatMessage({ id: 'label.organizationCode' })}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller<CreateOrganizationInputType>
          name="invoiceEmailAddress"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={intl.formatMessage({ id: 'label.invoiceEmailAddress' })}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller<CreateOrganizationInputType>
          name="address.street"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={intl.formatMessage({ id: 'label.street' })}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller<CreateOrganizationInputType>
          name="address.houseNumberWithAddition"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={intl.formatMessage({
                id: 'label.houseNumberWithAddition',
              })}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller<CreateOrganizationInputType>
          name="address.postalCode"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={intl.formatMessage({ id: 'label.postalCode' })}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller<CreateOrganizationInputType>
          name="address.city"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={intl.formatMessage({ id: 'label.city' })}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller<CreateOrganizationInputType>
          name="address.country"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={intl.formatMessage({ id: 'label.country' })}
              select
            >
              {countryOptions.map(({ label, code }) => (
                <MenuItem key={code} value={code}>
                  {label}
                </MenuItem>
              ))}
            </ReactHookFormTextField>
          )}
        />
      </Grid>
      <Grid item xs={6} />
      <Grid item xs={6}>
        <Controller<CreateOrganizationInputType>
          name="vatNumber"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={intl.formatMessage({ id: 'label.vatNumber' })}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller<CreateOrganizationInputType>
          name="reseller"
          render={(registered) => (
            <ReactHookFormOrganizationAutocomplete
              {...registered}
              fullWidth
              textField={{
                label: intl.formatMessage({ id: 'label.reseller' }),
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
export default CreateOrganizationForm;

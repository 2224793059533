import { ReactElement, ReactNode } from 'react';
import { Grid, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

export type FinishConnectionStepTemplateProps = {
  children: ReactNode;
  isRunning?: boolean;
  isSuccessful?: boolean;
  isFailed?: boolean;
};

const FinishConnectionStepTemplate = ({
  children,
  isRunning,
  isSuccessful,
  isFailed,
}: FinishConnectionStepTemplateProps): ReactElement => (
  <Grid container spacing={4}>
    <Grid item xs={12}>
      <Typography variant="h4" color="primary">
        <FormattedMessage id="onboarding.steps.runConnection" />
      </Typography>
    </Grid>
    <Grid item xs={12} hidden={isRunning || isSuccessful || isFailed}>
      {children}
    </Grid>
  </Grid>
);

export default FinishConnectionStepTemplate;

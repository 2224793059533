import {
  isAbstractCredential,
  AbstractCredential,
  CredentialRequest,
} from 'Models';

export interface OAuth2Credential extends AbstractCredential {
  accessTokenExpires?: Date;
  refreshTokenExpires?: Date;
  credentialRequest?: CredentialRequest;
}

export function isOAuth2Credential(
  object: unknown
): object is OAuth2Credential {
  return (
    isAbstractCredential(object) &&
    (object['@type'] === 'OAuth2Credential' ||
      object['@type'] === 'ExactOnlineCredential')
  );
}

export function isOAuth2CredentialOrExtendsOauth2Credential(
  object: unknown
): object is OAuth2Credential {
  return (
    isAbstractCredential(object) &&
    (object['@type'] === 'OAuth2Credential' ||
      object['@type'] === 'ExactOnlineCredential')
  );
}

export function generateOAuth2CredentialConnectUrl(
  credential: OAuth2Credential
): string {
  return `${process.env.REACT_APP_API_URL}/connect/${credential.packageType}/${credential.credentialId}`;
}

import { Language, EmailAddress, Uuid } from 'ValueObjects';
import { isObjectResponse } from 'Requests';
import { UserOrganizationAccess } from '../UserOrganizationAccess';
import { PersonName } from './PersonName';
import { Role } from './Role';

export interface User {
  userId: Uuid;
  personName: PersonName;
  emailAddress: EmailAddress;
  roles: Role[];
  password: string;
  passwordChangedAt: Date;
  accessToOrganizations: UserOrganizationAccess[];
  verified: boolean;
  active: boolean;
  language: Language;
  createdAt: Date;
  totpAuthenticationEnabled: boolean;
  profilePictureUrl?: string;
}

export function isUser(object: unknown): object is User {
  return !!object && isObjectResponse(object) && object['@type'] === 'User';
}

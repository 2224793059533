import { ErrorResponse, Uuid } from 'ValueObjects';
import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
} from '@tanstack/react-query';
import { Role } from 'Models';

export type UpdateUserRoleMutationProps = {
  userId: Uuid;
  role: Role;
};

export const UpdateUserRoleMutation = async ({
  ...values
}: UpdateUserRoleMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/users/${values.userId}/update-role`, {
    ...values,
  });

export const useUpdateUserRoleMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateUserRoleMutationProps
  > = {}
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  UpdateUserRoleMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateUserRoleMutationProps
  >(UpdateUserRoleMutation, {
    useErrorBoundary: false,
    ...options,
  });

import { Box, BoxProps, Grid, styled } from '@mui/material';
import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { ReactHookFormTextField } from 'Atoms';
import { Controller } from 'react-hook-form';

export type RequestResetPasswordFormProps = BoxProps;

export type RequestResetPasswordInput = {
  emailAddress: string;
  status?: string;
};

export const RequestResetPasswordValidation =
  (): Yup.SchemaOf<RequestResetPasswordInput> =>
    Yup.object({
      emailAddress: Yup.string().email().required().label('label.emailAddress'),
      status: Yup.string().optional(),
    });

export const RequestResetPasswordInitialValues: RequestResetPasswordInput = {
  emailAddress: '',
};

const RequestResetPasswordForm: FunctionComponent<
  RequestResetPasswordFormProps
> = ({ ...args }: RequestResetPasswordFormProps) => {
  const intl = useIntl();

  return (
    <Box {...args}>
      <Grid container rowSpacing={3}>
        <Grid container item xs={12}>
          <Controller
            name="emailAddress"
            render={(registered) => (
              <ReactHookFormTextField
                {...registered}
                fullWidth
                type="email"
                placeholder={intl.formatMessage({
                  id: 'placeholder.emailAddress',
                })}
                autoFocus
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

RequestResetPasswordForm.defaultProps = {};

export default styled(RequestResetPasswordForm)(
  () => `
`
);

import { PayPalCredentialDto } from 'Mutations';
import * as Yup from 'yup';
import { PaypalCredential } from '../../../../../Models';

export type PaypalCredentialInputType = PayPalCredentialDto;

export const PaypalCredentialInputTypeValidationBuilder =
  (): Yup.SchemaOf<PaypalCredentialInputType> =>
    Yup.object({
      name: Yup.string().required().label('label.name'),
      clientId: Yup.string().required().label('label.clientId'),
      clientSecret: Yup.string().required().label('label.clientSecret'),
    });

export const PaypalCredentialInputTypeDefaultValues = (
  credential?: PaypalCredential
): PaypalCredentialInputType => {
  if (credential) {
    return {
      name: credential.name,
      clientId: credential.extraData.clientId,
      clientSecret: credential.extraData.clientSecret,
    };
  }

  return {
    name: '',
    clientId: '',
    clientSecret: '',
  };
};

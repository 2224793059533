import { ErrorResponse, Uuid } from 'ValueObjects';
import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
} from '@tanstack/react-query';

export type StartOnboardingWizardMutationProps = {
  onboardingId: Uuid;
  organizationName: string;
  contactName: string;
  emailAddress: string;
  password: string;
};

export type StartOnboardingWizardMutationResponse = {
  onboardingId: Uuid;
  onboardingPassword: string;
  resellerId: Uuid;
  organizationId: Uuid;
};

export const StartOnboardingWizardMutation = async ({
  ...values
}: StartOnboardingWizardMutationProps): Promise<
  AxiosResponse<StartOnboardingWizardMutationResponse>
> =>
  axios.post(`api/wizard/new`, {
    ...values,
  });

export const useStartOnboardingWizardMutation = (
  options: UseMutationOptions<
    AxiosResponse<StartOnboardingWizardMutationResponse>,
    AxiosError<ErrorResponse>,
    StartOnboardingWizardMutationProps
  > = {}
): UseMutationResult<
  AxiosResponse<StartOnboardingWizardMutationResponse>,
  AxiosError<ErrorResponse>,
  StartOnboardingWizardMutationProps
> =>
  useMutation<
    AxiosResponse<StartOnboardingWizardMutationResponse>,
    AxiosError<ErrorResponse>,
    StartOnboardingWizardMutationProps
  >(StartOnboardingWizardMutation, {
    useErrorBoundary: false,
    ...options,
  });

/* eslint-disable @typescript-eslint/no-explicit-any,react/prop-types */
import { Grid, StepIcon } from '@mui/material';
import { forwardRef, ReactElement, ReactNode, useMemo } from 'react';
import { Button, Icon, ReactHookWizardStep, Step, StepLabel } from 'Atoms';
import { WizardStepper, WizardContent } from 'Molecules';
import { faArrowLeft, faCheck } from '@fortawesome/pro-light-svg-icons';
import { FormattedMessage } from 'react-intl';
import { FieldValues } from 'react-hook-form';

export type WizardTemplateProps<T extends FieldValues = FieldValues> = {
  steps: ReactHookWizardStep<T>[];
  currentStepIndex: number;
  children: ReactNode | ReactNode[];
  handlePrev: () => void;
  isPrevDisabled: boolean;
  isNextDisabled: boolean;
};

const FinishedIcon = (): ReactElement => (
  <StepIcon
    icon={
      <span className="Db-StepIcon-Finished">
        <Icon icon={faCheck} />
      </span>
    }
  />
);

const WizardTemplate = forwardRef<HTMLDivElement, WizardTemplateProps<any>>(
  (
    {
      steps,
      currentStepIndex,
      handlePrev,
      isPrevDisabled,
      isNextDisabled,
      children,
    }: WizardTemplateProps<any>,
    ref
  ): ReactElement => {
    const categories = useMemo(
      () =>
        steps
          .map(({ category, categoryTitle }) => ({ category, categoryTitle }))
          .filter(
            (cat, index, arr) =>
              arr.findIndex(({ category }) => cat.category === category) ===
              index
          ),
      [steps]
    );

    const currentCategoryIndex: number = useMemo(
      () =>
        categories.findIndex(
          ({ category }) => category === steps[currentStepIndex].category
        ),
      [categories, currentStepIndex, steps]
    );

    return (
      <Grid container justifyContent="center">
        <Grid
          item
          sx={{
            width: '285px',
            boxSizing: 'border-box',
            paddingRight: 2,
            height: '100px',
          }}
        />

        <Grid
          item
          sx={{ width: '825px', height: '100px' }}
          container
          alignItems="center"
        >
          <Button variant="text" onClick={handlePrev} disabled={isPrevDisabled}>
            <Icon icon={faArrowLeft} sx={{ marginRight: '11px' }} />
            <FormattedMessage id="button.back" />
          </Button>
        </Grid>
        <Grid item xs={12} />
        <Grid
          item
          sx={{ width: '285px', boxSizing: 'border-box', paddingRight: 2 }}
        >
          <WizardStepper activeStep={currentCategoryIndex}>
            {categories.map(({ categoryTitle }, index) => (
              <Step key={index}>
                <StepLabel>{categoryTitle}</StepLabel>
              </Step>
            ))}
            <Step>
              <StepLabel StepIconComponent={FinishedIcon}>
                <FormattedMessage id="label.youreDone" />
              </StepLabel>
            </Step>
          </WizardStepper>
        </Grid>
        <Grid item sx={{ width: '825px' }}>
          <WizardContent
            ref={ref}
            actions={
              <Button color="secondary" type="submit" disabled={isNextDisabled}>
                {currentStepIndex === 0 && (
                  <FormattedMessage id="button.register" />
                )}
                {currentStepIndex > 0 && <FormattedMessage id="button.next" />}
              </Button>
            }
          >
            {children}
          </WizardContent>
        </Grid>
      </Grid>
    );
  }
);

export default WizardTemplate;

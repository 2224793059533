import {
  StepContent as MuiStepContent,
  StepContentProps as MuiStepContentProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { FunctionComponent } from 'react';

export type StepContentProps = MuiStepContentProps;

const StepContent: FunctionComponent<StepContentProps> = ({
  ...props
}: StepContentProps) => <MuiStepContent {...props} />;

StepContent.defaultProps = {};

export default styled(StepContent)(() => ``);

import axios, { AxiosError, AxiosResponse } from 'axios';
import { RequestAndResponse, isRequestAndResponse } from 'Models';
import { Pagination } from 'Requests/Pagination';
import { Collection } from 'Requests/Collection';
import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { ErrorResponse } from 'ValueObjects';
import Qs from 'qs';
import { Filterable } from 'Requests/Filterable';
import { QueryInvalidator } from 'Requests/QueryInvalidator';

export type RequestAndResponseListRequestProps = Pagination & Filterable;
type Response = AxiosResponse<Collection<RequestAndResponse>>;

export const RequestAndResponseListRequest = async ({
  ...params
}: RequestAndResponseListRequestProps): Promise<Response> =>
  axios.get(`api/request-and-responses`, {
    params: { ...params },
    paramsSerializer: {
      encode: (param) => Qs.parse(param),
      serialize: (param) => Qs.stringify(param, { arrayFormat: 'brackets' }),
    },
  });

export const useRequestAndResponseListRequest = (
  request: RequestAndResponseListRequestProps,
  options: UseQueryOptions<Response, AxiosError<ErrorResponse>, Response> = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => RequestAndResponseListRequest(request),
    queryKey: ['request-and-responses', 'list', ...Object.values(request)],
  });

export const RequestAndResponseListRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isRequestAndResponse(data)) {
    queryClient.invalidateQueries(['request-and-responses', 'list']);
  }
};

import { ErrorResponse, Uuid } from 'ValueObjects';
import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
} from '@tanstack/react-query';
import { CredentialPackage } from 'Mutations';

export type UpdateCredentialMutationProps = {
  credentialId: Uuid;
  organizationId?: Uuid;
  package: CredentialPackage;
};

type Response = AxiosResponse<{ credentialId: Uuid }>;

export const UpdateCredentialMutation = async ({
  credentialId,
  ...values
}: UpdateCredentialMutationProps): Promise<Response> =>
  axios.put(`api/credentials/${credentialId}`, {
    credentialId,
    ...values,
  });

export const useUpdateCredentialMutation = (
  options: UseMutationOptions<
    Response,
    AxiosError<ErrorResponse>,
    UpdateCredentialMutationProps
  > = {}
): UseMutationResult<
  Response,
  AxiosError<ErrorResponse>,
  UpdateCredentialMutationProps
> =>
  useMutation<
    Response,
    AxiosError<ErrorResponse>,
    UpdateCredentialMutationProps
  >(UpdateCredentialMutation, {
    useErrorBoundary: false,
    ...options,
  });

import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { AuthState, AuthStateDefaultValue } from 'State';
import {
  LoadingPage,
  LoginPage,
  LogoutPage,
  OnboardingWizardPage,
  RequestResetPasswordPage,
  VerifyResetPasswordPage,
  SuccessfullyConnectedToOAuthPage,
  DidNotConnectedToOAuthPage,
  LockScreenWhenTokenIsExpiredModalPage,
  BackofficePages,
} from 'Pages';
import axios from 'axios';
import * as localForage from 'localforage';
import { SecureRoute } from 'Atoms';
import { setLocale } from 'yup';
import en from 'yup/lib/locale';
import dayjs from 'dayjs';
import { SnackbarProvider } from 'notistack';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import theme from './theme';
import YupTranslatePath from './translations/YupTranslatePath';

localForage.config({
  name: 'Databrydge',
});

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
// @ts-ignore
axios.defaults.headers['Content-Type'] = 'application/ld+json';

LicenseInfo.setLicenseKey(
  '07096dc9fbe52944ed55d1d717d44d10Tz01NDgzNyxFPTE3MDA3NDQ5MTc4NzQsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

const App: FunctionComponent = () => {
  const [authState, setAuthState] = useRecoilState(AuthState);
  const [didSetYupLocale, setYupLocale] = useState<boolean>(false);
  const [showLockScreen, setShowLockScreen] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  useEffect(() => {
    let interval: null | NodeJS.Timer = null;
    if (!showLockScreen) {
      interval = setInterval(() => {
        if (dayjs(authState.expiresAt).isBefore(dayjs().subtract(6, 'hour'))) {
          setAuthState(AuthStateDefaultValue);
          navigate('/login');

          return;
        }

        setShowLockScreen(dayjs(authState.expiresAt).isBefore(dayjs()));
      }, 1000);
    } else if (showLockScreen && interval) {
      clearInterval(interval);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [authState.expiresAt, navigate, setAuthState, showLockScreen]);

  useEffect(() => {
    axios.interceptors.request.use(
      (config) => {
        if (
          authState.token &&
          config.headers &&
          !config.url?.includes('/login') &&
          !config.headers.Authorization &&
          !config.headers.skipAuthorization
        ) {
          config.headers.Authorization = `Bearer ${authState.token}`;
        }

        return config;
      },
      (err) => Promise.reject(err)
    );

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const { response } = error;
        if (
          response &&
          response.status === 401 &&
          response.data.message !== 'Invalid credentials.'
        ) {
          setAuthState({
            token: undefined,
            userId: undefined,
          });

          return Promise.resolve();
        }

        return Promise.reject(error);
      }
    );
  }, [authState, setAuthState]);

  useEffect(() => {
    if (!didSetYupLocale) {
      const yupLocale = YupTranslatePath(en, formatMessage);

      setLocale(yupLocale);
      setYupLocale(true);
    }
  }, [didSetYupLocale, formatMessage]);

  if (!didSetYupLocale) {
    return <LoadingPage />;
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={4}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
          {/* {showLockScreen && <LockScreenWhenTokenIsExpiredModalPage />} */}
          <Routes>
            <Route
              path="/onboarding/:onboardingId"
              element={<OnboardingWizardPage />}
            />
            <Route path="/login" element={<LoginPage />} />
            <Route
              path="/reset-password/verify/:token"
              element={<VerifyResetPasswordPage />}
            />
            <Route
              path="/reset-password"
              element={<RequestResetPasswordPage />}
            />
            <Route
              path="/credential/oauth/success/:credentialId"
              element={<SuccessfullyConnectedToOAuthPage />}
            />
            <Route
              path="/credential/oauth/error/:credentialId"
              element={<DidNotConnectedToOAuthPage />}
            />
            <Route path="/logout" element={<LogoutPage />} />
            <Route
              path="/*"
              element={
                <SecureRoute>
                  <BackofficePages />
                </SecureRoute>
              }
            />
          </Routes>
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;

export interface AbstractCredentialDto {
  name: string;
}

export function isAbstractCredentialDto(
  object: unknown
): object is AbstractCredentialDto {
  return (
    typeof object === 'object' &&
    object !== null &&
    'name' in object &&
    typeof object.name === 'string'
  );
}

import { ErrorResponse, Uuid } from 'ValueObjects';
import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
} from '@tanstack/react-query';

export type ClearTasksForConnectionMutationProps = {
  connectionId: Uuid;
  force?: boolean;
};

export const ClearTasksForConnectionMutation = async ({
  ...values
}: ClearTasksForConnectionMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/connections/${values.connectionId}/clear-tasks`, {
    ...values,
  });

export const useClearTasksForConnectionMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ClearTasksForConnectionMutationProps
  > = {}
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ClearTasksForConnectionMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ClearTasksForConnectionMutationProps
  >(ClearTasksForConnectionMutation, {
    useErrorBoundary: false,
    ...options,
  });

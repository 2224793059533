import { ErrorResponse, Uuid } from 'ValueObjects';
import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
} from '@tanstack/react-query';

export type UpdateProfileMutationProps = {
  userId: Uuid;
  firstName?: string;
  middleName?: string;
  lastName: string;
};

export const UpdateProfileMutation = async ({
  ...values
}: UpdateProfileMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/profile/user/${values.userId}`, {
    ...values,
  });

export const useUpdateProfileMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateProfileMutationProps
  > = {}
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  UpdateProfileMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateProfileMutationProps
  >(UpdateProfileMutation, {
    useErrorBoundary: false,
    ...options,
  });

import axios, { AxiosError, AxiosResponse } from 'axios';
import { Connection, isConnection } from 'Models';
import { Pagination } from 'Requests/Pagination';
import { Collection } from 'Requests/Collection';
import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { ErrorResponse } from 'ValueObjects';
import Qs from 'qs';
import { Filterable } from 'Requests/Filterable';
import { QueryInvalidator } from 'Requests/QueryInvalidator';

export type ConnectionsListRequestProps = Pagination & Filterable;
type Response = AxiosResponse<Collection<Connection>>;

export const ConnectionsListRequest = async ({
  ...params
}: ConnectionsListRequestProps): Promise<Response> =>
  axios.get(`api/connections`, {
    params: { ...params },
    paramsSerializer: {
      encode: (param) => Qs.parse(param),
      serialize: (param) => Qs.stringify(param, { arrayFormat: 'brackets' }),
    },
  });

export const useConnectionsListRequest = (
  request: ConnectionsListRequestProps,
  options: UseQueryOptions<Response, AxiosError<ErrorResponse>, Response> = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => ConnectionsListRequest(request),
    queryKey: ['connections', 'list', ...Object.values(request)],
  });

export const ConnectionsListRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isConnection(data)) {
    queryClient.invalidateQueries(['connections', 'list']);
  }
};

import { ErrorResponse, Uuid } from 'ValueObjects';
import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
} from '@tanstack/react-query';

export type ClearLogsForConnectionMutationProps = {
  connectionId: Uuid;
};

export const ClearLogsForConnectionMutation = async ({
  ...values
}: ClearLogsForConnectionMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/connections/${values.connectionId}/clear-logs`, {
    ...values,
  });

export const useClearLogsForConnectionMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ClearLogsForConnectionMutationProps
  > = {}
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ClearLogsForConnectionMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ClearLogsForConnectionMutationProps
  >(ClearLogsForConnectionMutation, {
    useErrorBoundary: false,
    ...options,
  });

import { ErrorResponse, Uuid } from 'ValueObjects';
import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
} from '@tanstack/react-query';

export type RemoveAccessToOrganizationsMutationProps = {
  userId: Uuid;
  organizationsIds: Uuid[];
};

export const RemoveAccessToOrganizationsMutation = async ({
  ...values
}: RemoveAccessToOrganizationsMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/users/remove-access`, {
    ...values,
  });

export const useRemoveAccessToOrganizationsMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveAccessToOrganizationsMutationProps
  > = {}
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  RemoveAccessToOrganizationsMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveAccessToOrganizationsMutationProps
  >(RemoveAccessToOrganizationsMutation, {
    useErrorBoundary: false,
    ...options,
  });

import { ReactElement, useCallback, useEffect } from 'react';
import { Grid } from '@mui/material';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Button, Icon, ReactHookFormAutocomplete } from 'Atoms';
import { FormattedMessage, useIntl } from 'react-intl';
import { faArrowRight, faCheck } from '@fortawesome/pro-light-svg-icons';
import hash from 'object-hash';

export type PayPalCurrencyMappingFormProps = {
  index: number;
  onRequestValidation: () => Promise<void> | void;
  hasErrors: boolean;
  generalLedgers: { externalId: string; label: string }[];
  bankJournals: { externalId: string; label: string }[];
  isLoading?: boolean;
  hasFailedLoading?: boolean;
};

const PayPalCurrencyMappingForm = ({
  index,
  onRequestValidation,
  hasErrors,
  generalLedgers,
  bankJournals,
  isLoading,
  hasFailedLoading,
}: PayPalCurrencyMappingFormProps): ReactElement => {
  const { formatMessage } = useIntl();
  const { setValue } = useFormContext();
  const mapping = useWatch({
    name: `settings.paypal.currencyMapping.${index}`,
  });

  useEffect(() => {
    const newHash = hash(mapping, {
      excludeKeys: (key) =>
        [
          'id',
          'enabled',
          'validatedByBackoffice',
          'validatingWithBackoffice',
          'validatedHash',
        ].includes(key),
    });
    const isValidated = mapping.validatedHash === newHash;

    if (mapping.validatedByBackoffice !== isValidated) {
      setValue(
        `settings.paypal.currencyMapping.${index}.validatedByBackoffice`,
        isValidated
      );
    }
  }, [index, mapping, setValue]);

  const renderBankJournalLabel = useCallback(
    (option: { label: string } | string) => {
      if (hasFailedLoading) {
        return formatMessage({ id: 'label.failedToLoadOptions' });
      }

      if (isLoading) {
        return `${formatMessage({ id: 'label.loading' })}...`;
      }

      return typeof option === 'string'
        ? bankJournals.find(({ externalId }) => externalId === option)?.label ||
            ''
        : option.label;
    },
    [bankJournals, formatMessage, hasFailedLoading, isLoading]
  );

  const renderGeneralLedgerLabel = useCallback(
    (option: { label: string } | string) => {
      if (hasFailedLoading) {
        return formatMessage({ id: 'label.failedToLoadOptions' });
      }

      if (isLoading) {
        return `${formatMessage({ id: 'label.loading' })}...`;
      }

      return typeof option === 'string'
        ? generalLedgers.find(({ externalId }) => externalId === option)
            ?.label || ''
        : option.label;
    },
    [formatMessage, generalLedgers, hasFailedLoading, isLoading]
  );

  return (
    <Grid item xs={12} container spacing={1}>
      <Grid item xs={12}>
        <Controller
          name={`settings.paypal.currencyMapping.${index}.bankJournalCode`}
          render={(registered) => (
            <ReactHookFormAutocomplete
              {...registered}
              fullWidth
              textField={{
                label: <FormattedMessage id="label.bankJournalCode" />,
              }}
              identifier="externalId"
              options={bankJournals}
              loading={isLoading}
              getOptionLabel={renderBankJournalLabel}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name={`settings.paypal.currencyMapping.${index}.feesGeneralLedgerCode`}
          render={(registered) => (
            <ReactHookFormAutocomplete
              {...registered}
              fullWidth
              textField={{
                label: <FormattedMessage id="label.feesGeneralLedgerCode" />,
              }}
              identifier="externalId"
              options={generalLedgers}
              loading={isLoading}
              getOptionLabel={renderGeneralLedgerLabel}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name={`settings.paypal.currencyMapping.${index}.unallocatedGeneralLedgerCode`}
          render={(registered) => (
            <ReactHookFormAutocomplete
              {...registered}
              fullWidth
              textField={{
                label: (
                  <FormattedMessage id="label.unallocatedGeneralLedgerCode" />
                ),
              }}
              identifier="externalId"
              options={generalLedgers}
              loading={isLoading}
              getOptionLabel={renderGeneralLedgerLabel}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} mt={1}>
        {mapping.validatedByBackoffice && !mapping.validatingWithBackoffice ? (
          <Button color="success">
            <Icon icon={faCheck} />
            &nbsp;
            <FormattedMessage id="button.validated" />
          </Button>
        ) : (
          <Button
            disabled={hasErrors}
            onClick={onRequestValidation}
            color="secondary"
            loading={mapping.validatingWithBackoffice}
          >
            <Icon icon={faArrowRight} />
            &nbsp;
            <FormattedMessage id="button.validate" />
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default PayPalCurrencyMappingForm;

import { List as MuiList, ListProps as MuiListProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactElement } from 'react';

export type ListProps = MuiListProps & {
  component: string;
};

const List = ({ ...props }: ListProps): ReactElement => <MuiList {...props} />;

List.defaultProps = {
  component: undefined,
};

export default styled(List)(
  () => `
`
);

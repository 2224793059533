import { ReactElement } from 'react';
import { Box, BoxProps, styled } from '@mui/material';
import { Sidebar } from 'Organisms';
import { useRecoilState } from 'recoil';
import { UserState } from 'State';
import { Route, Navigate, Routes } from 'react-router-dom';
import {
  ConnectionDetailsPage,
  ConnectionInsightPage,
  ConnectionOverviewPage,
  ConnectionRequestsPage,
  ConnectionSettingsPage,
} from 'Pages/Connection';
import {
  CredentialDetailsPage,
  CredentialOverviewPage,
  CredentialConnectionsPage,
} from 'Pages/Credential';
import {
  OrganizationOverviewPage,
  OrganizationDetailsPage,
  OrganizationUsersPage,
  OrganizationConnectionsPage,
} from 'Pages/Organization';
import { UserDetailsPage, UserOverviewPage } from 'Pages/User';
import { ProfilePage } from 'Pages/App';

const BackofficePages = ({ className }: BoxProps): ReactElement => {
  const [userState] = useRecoilState(UserState);
  if (!userState) {
    return <Box />;
  }

  return (
    <Box className={className}>
      <Box className="Db-Backoffice-Sidebar">
        <Sidebar currentUser={userState} />
      </Box>
      <Box className="Db-Backoffice-Content">
        <Routes>
          <Route path="/connections" element={<ConnectionOverviewPage />} />
          <Route
            path="/connections/:connectionId"
            element={<ConnectionDetailsPage />}
          />
          <Route
            path="/connections/:connectionId/insights"
            element={<ConnectionInsightPage />}
          />
          <Route
            path="/connections/:connectionId/settings"
            element={<ConnectionSettingsPage />}
          />
          <Route
            path="/connections/:connectionId/requests"
            element={<ConnectionRequestsPage />}
          />
          <Route path="/credentials" element={<CredentialOverviewPage />} />
          <Route
            path="/credentials/:credentialId"
            element={<CredentialDetailsPage />}
          />
          <Route
            path="/credentials/:credentialId/connections"
            element={<CredentialConnectionsPage />}
          />
          <Route path="/organizations" element={<OrganizationOverviewPage />} />
          <Route
            path="/organizations/:organizationId"
            element={<OrganizationDetailsPage />}
          />
          <Route
            path="/organizations/:organizationId/users"
            element={<OrganizationUsersPage />}
          />
          <Route
            path="/organizations/:organizationId/connections"
            element={<OrganizationConnectionsPage />}
          />
          <Route path="/users" element={<UserOverviewPage />} />
          <Route path="/users/:userId" element={<UserDetailsPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/" element={<Navigate to="/connections" />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default styled(BackofficePages)(
  ({ theme }) => `
    display: flex;
    flex-direction: row;
    background-color: #F9FAFB;
    overflow: hidden;
    height: 100vh;
    width: 100vw;
    
    .Db-Backoffice-Content {
        padding-top: ${theme.spacing(4)};
        padding-left: ${theme.spacing(3)};
        padding-right: ${theme.spacing(3)};
        flex: 1;
        display: flex;
        overflow: auto;
    }
`
);

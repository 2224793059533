import { isAbstractCredential, AbstractCredential } from 'Models';

export interface PaypalCredential extends AbstractCredential {
  extraData: {
    clientId: string;
    clientSecret: string;
  };
}

export function isPaypalCredential(
  object: unknown
): object is PaypalCredential {
  return isAbstractCredential(object) && object['@type'] === 'PaypalCredential';
}

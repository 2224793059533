import axios, { AxiosError, AxiosResponse } from 'axios';
import { Organization, isOrganization } from 'Models';
import { Pagination } from 'Requests/Pagination';
import { Collection } from 'Requests/Collection';
import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { ErrorResponse } from 'ValueObjects';
import Qs from 'qs';
import { Filterable } from 'Requests/Filterable';
import { QueryInvalidator } from 'Requests/QueryInvalidator';

export type OrganizationsListRequestProps = Pagination & Filterable;
type Response = AxiosResponse<Collection<Organization>>;

export const OrganizationsListRequest = async ({
  ...params
}: OrganizationsListRequestProps): Promise<Response> =>
  axios.get(`api/organizations`, {
    params: { ...params },
    paramsSerializer: {
      encode: (param) => Qs.parse(param),
      serialize: (param) => Qs.stringify(param, { arrayFormat: 'brackets' }),
    },
  });

export const useOrganizationsListRequest = (
  request: OrganizationsListRequestProps,
  options: UseQueryOptions<Response, AxiosError<ErrorResponse>, Response> = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => OrganizationsListRequest(request),
    queryKey: ['organizations', 'list', ...Object.values(request)],
  });

export const OrganizationsListRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isOrganization(data)) {
    queryClient.invalidateQueries(['organizations', 'list']);
  }
};

import { ReactElement } from 'react';
import { FormDialog } from 'Organisms/FormDialog';
import { UploadCompanyLogoForm } from 'Forms';
import {
  UploadCompanyLogoInputType,
  UploadCompanyLogoInputTypeDefaultValues,
  UploadCompanyLogoInputTypeValidationBuilder,
} from 'InputTypes';
import { DialogProps } from 'Molecules/Dialog';
import { SubmitHandler } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Button } from 'Atoms';

export type UploadCompanyLogoFormDialogProps = Omit<DialogProps, 'onSubmit'> & {
  onSubmit: SubmitHandler<UploadCompanyLogoInputType>;
};

const UploadCompanyLogoFormDialog = ({
  onSubmit,
  onClose,
  ...args
}: UploadCompanyLogoFormDialogProps): ReactElement => (
  <FormDialog
    {...args}
    maxWidth="sm"
    fullWidth
    onClose={onClose}
    defaultValues={UploadCompanyLogoInputTypeDefaultValues()}
    validationSchema={UploadCompanyLogoInputTypeValidationBuilder()}
    name="upload-employee-image"
    useProvider
    title={<FormattedMessage id="label.uploadCompanyLogo" />}
    onSubmit={onSubmit}
    hideCloseButton
    dialogActions={[
      <Button type="submit" key="save">
        <FormattedMessage id="button.save" />
      </Button>,
    ]}
  >
    <UploadCompanyLogoForm />
  </FormDialog>
);

export default UploadCompanyLogoFormDialog;

import axios, { AxiosError, AxiosResponse } from 'axios';
import { EmailAddress, ErrorResponse } from 'ValueObjects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';

export type RequestResetPasswordMutationProps = {
  emailAddress: EmailAddress;
};

export type RequestResetPasswordMutationResponse = Record<string, never>;

export const RequestResetPasswordMutation = async ({
  emailAddress,
}: RequestResetPasswordMutationProps): Promise<
  AxiosResponse<RequestResetPasswordMutationResponse>
> =>
  axios.post(`api/users/reset-password/${emailAddress}`, {
    emailAddress,
  });

export const useRequestResetPasswordMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RequestResetPasswordMutationProps
  >
): UseMutationResult<
  AxiosResponse<RequestResetPasswordMutationResponse>,
  AxiosError<ErrorResponse>,
  RequestResetPasswordMutationProps
> =>
  useMutation<
    AxiosResponse<RequestResetPasswordMutationResponse>,
    AxiosError<ErrorResponse>,
    RequestResetPasswordMutationProps
  >(RequestResetPasswordMutation, {
    ...options,
    useErrorBoundary: (error) => (error?.response?.status || 500) >= 500,
  });

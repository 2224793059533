import { ReactElement, useCallback, useState } from 'react';
import { Grid, List, ListItemText, Typography } from '@mui/material';
import { FinishConnectionStepTemplate } from 'Templates';
import { BulletListItem, Button, Icon } from 'Atoms';
import { useFormContext, useWatch } from 'react-hook-form';
import { useExactOnlineDivisionsByCredentialIdRequestQuery } from 'Requests/Synchronizer/Package/ExactOnline';
import {
  ConnectWithExactOnlineStepStepInput,
  ConnectWithPaypalStepInput,
  ExactOnlineCredentialSettingsFormInput,
} from 'Forms/OnboardingWizard/CredentialSteps';
import { FormattedDate, FormattedDisplayName, FormattedList } from 'react-intl';
import {
  PaypalCurrencyMapping,
  PaypalSettingsFormInput,
} from 'Forms/OnboardingWizard/Parts';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import {
  useCreateConnectionMutation,
  useUpdateConnectionMutation,
} from 'Mutations';
import { OnboardingWizardInput } from 'Pages';
import { v4 } from 'uuid';
import { Package, PackageType, PaypalPackageSettings } from 'Models';
import { ExactOnlinePackageSettings } from 'Models/Synchronizer/Connection/ExactOnline';

type ConnectionInput = OnboardingWizardInput & {
  settings: {
    paypal: PaypalSettingsFormInput;
    'exact-online': ExactOnlineCredentialSettingsFormInput;
  };
} & ConnectWithExactOnlineStepStepInput &
  ConnectWithPaypalStepInput;

const FinishExactOnlinePaypalConnectionStep = (): ReactElement => {
  const createConnectionMutation = useCreateConnectionMutation();
  const updateConnectionMutation = useUpdateConnectionMutation();

  const { getValues, setValue } = useFormContext<ConnectionInput>();

  const [loading, setLoading] = useState(false);

  const credentialId = useWatch<
    ConnectionInput,
    'credentials.exact-online.credentialId'
  >({
    name: 'credentials.exact-online.credentialId',
  });

  const division = useWatch<ConnectionInput, 'settings.exact-online.division'>({
    name: 'settings.exact-online.division',
  });

  const { data: divisionsData } =
    useExactOnlineDivisionsByCredentialIdRequestQuery({
      credentialId,
      perPage: 9999,
    });

  const currencyMappings: PaypalCurrencyMapping[] = useWatch<
    ConnectionInput,
    'settings.paypal.currencyMapping'
  >({
    name: 'settings.paypal.currencyMapping',
  });

  const synchronizationStartDate = useWatch<ConnectionInput>({
    name: 'synchronizationStartDate',
  });

  const handleRunSynchronization = useCallback(async () => {
    setLoading(true);
    const values = getValues();

    const paypalPackage: Package & {
      settings: PaypalPackageSettings;
    } = {
      packageType: PackageType.PAYPAL,
      credentialId: values.credentials.paypal.credentialId,
      settings: values.settings.paypal,
    };

    const exactOnlinePackage: Package & {
      settings: ExactOnlinePackageSettings;
    } = {
      packageType: PackageType.EXACT_ONLINE,
      credentialId: values.credentials['exact-online'].credentialId,
      settings: values.settings['exact-online'],
    };

    const request = {
      organizationId: values.organizationId,
      connectionName: `${values.organizationName}`,
      packages: [paypalPackage, exactOnlinePackage],
      synchronizationStartDate: values.synchronizationStartDate,
    };

    if (values.connectionId) {
      await updateConnectionMutation.mutateAsync({
        connectionId: values.connectionId,
        active: true,
        ...request,
      });
    } else {
      const newConnectionId = v4();
      await createConnectionMutation.mutateAsync({
        connectionId: newConnectionId,
        ...request,
      });
      setValue('connectionId', newConnectionId);
    }
    setLoading(false);
  }, [createConnectionMutation, getValues, setValue, updateConnectionMutation]);

  return (
    <FinishConnectionStepTemplate>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>
            For this division we will process the following data in your
            accounting account:
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <List disablePadding>
            <BulletListItem>
              <ListItemText
                primary={
                  <span>
                    Division:&nbsp;
                    {
                      divisionsData?.data?.['hydra:member'].find(
                        ({ Code }) => Code === division
                      )?.Description
                    }
                  </span>
                }
              />
            </BulletListItem>
            <BulletListItem>
              <ListItemText
                primary={
                  <span>
                    Currencies:&nbsp;
                    <FormattedList
                      value={currencyMappings.map(({ currency }) => (
                        <FormattedDisplayName
                          type="currency"
                          value={currency}
                        />
                      ))}
                    />
                  </span>
                }
              />
            </BulletListItem>
            <BulletListItem>
              <ListItemText
                primary={
                  <span>
                    Start date:&nbsp;
                    <FormattedDate
                      dateStyle="long"
                      value={synchronizationStartDate}
                    />
                  </span>
                }
              />
            </BulletListItem>
          </List>
        </Grid>
        <Grid item xs={12}>
          <Button onClick={handleRunSynchronization} loading={loading}>
            <Icon icon={faArrowRight} />
            &nbsp; run synchronisation
          </Button>
        </Grid>
      </Grid>
    </FinishConnectionStepTemplate>
  );
};

export default FinishExactOnlinePaypalConnectionStep;

import * as React from 'react';
import {
  ReactElement,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  ReactHookFormAutocomplete,
  ReactHookFormAutocompleteProps,
} from 'Atoms/ReactHookFormAutocomplete';
import { FieldPath, FieldValues } from 'react-hook-form';
import { Organization } from 'Models';
import { throttle } from 'lodash';
import { useOrganizationsListRequest } from 'Requests';
import { Uuid } from 'ValueObjects';

export type ReactHookFormOrganizationAutocompleteProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = ReactHookFormAutocompleteProps<TFieldValues, TName> & { relationId?: Uuid };

const ReactHookFormOrganizationAutocomplete = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  field,
  relationId,
  ...args
}: ReactHookFormOrganizationAutocompleteProps<
  TFieldValues,
  TName
>): ReactElement => {
  const { value, onChange } = field;
  const [globalFilter, setGlobalFilter] = useState<string>();
  const [inputValue, setInputValue] = useState<string>();
  const request = useMemo(
    () => ({
      perPage: 25,
      globalFilter,
    }),
    [globalFilter, relationId]
  );

  const {
    data: organizationsData,
    isLoading,
    isFetching,
  } = useOrganizationsListRequest(request, { keepPreviousData: true });

  const renderOptionLabel = useCallback(
    (organization: Organization) =>
      organization
        ? `${organization.organizationName} (${organization.organizationCode})`
        : '',
    []
  );

  const searchCustomers = useMemo(
    () => throttle((newValue) => setGlobalFilter(newValue), 100),
    []
  );

  const handleSearch = useCallback(
    async (event: SyntheticEvent, newValue: string) => {
      setInputValue(newValue);
    },
    []
  );

  useEffect(() => {
    searchCustomers(inputValue);
  }, [inputValue, searchCustomers]);

  const options = useMemo(() => {
    const result = organizationsData?.data['hydra:member'] || [];

    if (
      value &&
      result.findIndex(
        ({ organizationId }) => organizationId === value.organizationId
      ) === -1
    ) {
      return [value, ...result];
    }

    return result;
  }, [organizationsData?.data, value]);

  return (
    <ReactHookFormAutocomplete<TFieldValues, TName>
      field={field}
      overrideValue={value}
      filterOptions={(x: Organization[]) => x}
      options={options}
      onChange={(event: SyntheticEvent, newValue?: Organization) => {
        onChange(newValue);
      }}
      loading={isLoading || isFetching}
      onInputChange={handleSearch}
      isOptionEqualToValue={(
        option: Organization,
        currentValue?: Organization
      ) => option.organizationId === currentValue?.organizationId}
      getOptionLabel={renderOptionLabel}
      {...args}
    />
  );
};

export default ReactHookFormOrganizationAutocomplete;

// eslint-disable-next-line import/prefer-default-export
export enum PackageType {
  EXACT_ONLINE = 'exact-online',
  TWINFIELD = 'twinfield',
  EMASPHERE = 'emasphere',
  XERO = 'xero',
  XEINADIN_GROUP = 'xeinadin-group',
  BRAND_NEW_DAY = 'brand-new-day',
  BRAND_NEW_DAY_SOAP = 'brand-new-day-soap',
  AMERSFOORTSE = 'amersfoortse',
  AFAS = 'afas',
  NMBRS = 'nmbrs',
  UNIT4_STAFF_AND_SALARY = 'unit4-staff-and-salary',
  INSURANCE_BRYDGE = 'insurance-brydge',
  EFRONT = 'efront',
  LOKET = 'loket',
  EASY_FLEX = 'easy-flex',
  PAYPAL = 'paypal',
  MOLLIE = 'mollie',
  RECRAS = 'recras',
}

export function isPackageType(object: unknown): object is PackageType {
  return (
    // @ts-ignore
    typeof object === 'string' && Object.values(PackageType).includes(object)
  );
}

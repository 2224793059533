import {
  styled,
  BreadcrumbsProps as MuiBreadcrumbsProps,
  Breadcrumbs as MuiBreadcrumbs,
} from '@mui/material';
import { ReactElement } from 'react';
import colors from 'App/colors';

export type BreadcrumbsProps = MuiBreadcrumbsProps;

const Breadcrumbs = ({
  children,
  ...props
}: BreadcrumbsProps): ReactElement => (
  <MuiBreadcrumbs {...props}>{children}</MuiBreadcrumbs>
);

Breadcrumbs.defaultProps = {};

export default styled(Breadcrumbs)(
  () => `
  background: ${colors.coolGray[50]};
  border: 1px solid ${colors.coolGray[200]};
  border-radius: 8px;
  padding: 5px 20px;
  
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.17px;
  
  .MuiBreadcrumbs-separator, .MuiBreadcrumbs-li {
      padding-left: 16px;
  }
  
  .MuiBreadcrumbs-ol :nth-of-type(2) {
    display: none;
  }
  
  .MuiBreadcrumbs-separator {
   
    &, a {
      color: ${colors.coolGray[400]};
    }
  }
  
  .MuiBreadcrumbs-li {
    p {
      color: rgba(68, 73, 80, 0.6);
      text-transform: lowercase;
    }
    a {
      color: rgba(0, 0, 0, 0.87);
      text-transform: lowercase;
      text-decoration: none;
    }
  }
  
  li:first-of-type {
    padding-left: 0;
    a {
      color: #3F51B5;
    }
  }
`
);

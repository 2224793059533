import * as Yup from 'yup';
import { Role } from 'Models';
import { Uuid } from 'ValueObjects';

export interface CreateUserInputType {
  firstName: string;
  middleName?: string;
  lastName: string;
  emailAddress: string;
  password?: string;
  role?: Role;
  accessToOrganizations: { organizationId?: Uuid }[];
}

export const CreateUserInputTypeValidationBuilder =
  (): Yup.SchemaOf<CreateUserInputType> =>
    Yup.object({
      firstName: Yup.string().required().label('label.firstName'),
      middleName: Yup.string().label('label.middleName'),
      lastName: Yup.string().required().label('label.lastName'),
      emailAddress: Yup.string().required().label('label.emailAddress'),
      password: Yup.string().label('label.password'),
      accessToOrganizations: Yup.array()
        .of(Yup.object({ organizationId: Yup.string().required() }).required())
        .label('label.accessToOrganizations'),
      role: Yup.mixed<Role>().oneOf(Object.values(Role)).label('label.role'),
    });

export const CreateUserInputTypeDefaultValues = (): CreateUserInputType => ({
  firstName: '',
  middleName: '',
  lastName: '',
  emailAddress: '',
  password: '',
  accessToOrganizations: [],
});

import {
  ListItemButton as MuiListItemButton,
  ListItemButtonProps as MuiListItemButtonProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactElement } from 'react';

export type ListItemButtonProps = MuiListItemButtonProps;

const ListItemButton = ({ ...props }: ListItemButtonProps): ReactElement => (
  <MuiListItemButton {...props} />
);

ListItemButton.defaultProps = {};

export default styled(ListItemButton)(
  ({ theme }) => `
  position:relative;

  &.Mui-selected {
    background-color: #EAF2F9;
    
    &::before {
      content: "";
      display: block;
      height: 50%;
      width: 4px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      background-color: ${theme.palette.primary.main};
      position: absolute;
      left: 0;
      top: 25%;
    }
  }
  
   &.MuiListItemButton-root:hover {
    background-color: ${theme.palette.background.default};
  }
`
);

import { DetailedHTMLProps, FormHTMLAttributes, ReactElement } from 'react';

export type FormProps = DetailedHTMLProps<
  FormHTMLAttributes<HTMLFormElement>,
  HTMLFormElement
> & {
  name: string;
};

const Form = ({
  name,
  onSubmit,
  children,
  ...props
}: FormProps): ReactElement => (
  <form
    onSubmit={(e) => {
      if (!onSubmit) {
        return;
      }

      e.preventDefault();
      let submittedName = '';
      if (e.target instanceof HTMLFormElement) {
        submittedName = e.target.name;
      }

      if (submittedName !== name) {
        return;
      }

      onSubmit(e);
    }}
    name={name}
    noValidate
    autoComplete="off"
    {...props}
  >
    {children}
  </form>
);

export default Form;

import { ReactElement, useMemo } from 'react';
import { SyncStatus } from 'Models';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faQuestion,
  faTrashCan,
} from '@fortawesome/pro-light-svg-icons';
import { Chip } from 'Atoms';
import { styled } from '@mui/material';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';

export type SyncStatusChipProps = {
  status: SyncStatus;
  className?: string;
};

const colorMapping: {
  [key: string]:
    | 'default'
    | 'success'
    | 'secondary'
    | 'error'
    | 'warning'
    | 'primary'
    | 'info'
    | undefined;
} = {
  [SyncStatus.COMPLETE]: 'success',
  [SyncStatus.REMOVE]: 'default',
  [SyncStatus.FAILED]: 'error',
  [SyncStatus.PENDING]: 'warning',
  [SyncStatus.PROCESSING]: 'primary',
  [SyncStatus.IGNORED]: 'default',
  [SyncStatus.UNKNOWN]: 'default',
};
const SyncStatusChip = ({
  status,
  className,
}: SyncStatusChipProps): ReactElement => {
  const icon = useMemo(() => {
    if (status === SyncStatus.COMPLETE) {
      return <FontAwesomeIcon icon={faCheckCircle} />;
    }
    if (status === SyncStatus.FAILED) {
      return <FontAwesomeIcon icon={faExclamationCircle} />;
    }
    if (status === SyncStatus.PENDING) {
      return <FontAwesomeIcon icon={faExclamationTriangle} />;
    }
    if (status === SyncStatus.PROCESSING) {
      return <FontAwesomeIcon icon={faSpinnerThird} spin />;
    }
    if (status === SyncStatus.REMOVE) {
      return <FontAwesomeIcon icon={faTrashCan} />;
    }
    if (status === SyncStatus.IGNORED) {
      return <FontAwesomeIcon icon={faTrashCan} />;
    }
    if (status === SyncStatus.UNKNOWN) {
      return <FontAwesomeIcon icon={faQuestion} />;
    }

    return undefined;
  }, [status]);
  return (
    <Chip
      label={<FormattedMessage id={`syncStatus.${status}`} />}
      variant="filled"
      color={colorMapping[status] || 'default'}
      icon={icon}
      className={className}
    />
  );
};

export default styled(SyncStatusChip)(`
`);

import {
  DialogContent as MuiDialogContent,
  DialogContentProps as MuiDialogContentProps,
  styled,
} from '@mui/material';
import { ReactElement } from 'react';

export type DialogContentProps = MuiDialogContentProps;
const DialogContent = ({ ...props }: DialogContentProps): ReactElement => (
  <MuiDialogContent {...props} />
);

const StyledDialog = styled(DialogContent)(`
  min-height: 300px;
  padding: 8px 32px;
  box-sizing: border-box;
`);

const FinalDialog = ({ ...props }: DialogContentProps) => (
  <StyledDialog {...props} />
);

export default FinalDialog;

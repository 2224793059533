import { ReactElement } from 'react';
import { styled } from '@mui/material';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { Icon, Tab, TabProps } from 'Atoms';

export type RemovableTabProps = TabProps & {
  onRemoveTab: () => void | Promise<void>;
};

const RemovableTab = ({
  onRemoveTab,
  ...args
}: RemovableTabProps): ReactElement => (
  <Tab
    {...args}
    icon={
      args.disabled ? undefined : (
        <Icon
          icon={faTimes}
          className="Db-RemoveableTab-Remove"
          onClick={onRemoveTab}
        />
      )
    }
  />
);

export default styled(RemovableTab)(
  ({ theme }) => `
  .Db-RemoveableTab-Remove {
    color: rgba(196, 196, 196, 1);
    z-index: 4;
  }  
  
  &.Mui-selected {
    .Db-RemoveableTab-Remove:hover {
      color: ${theme.palette.primary.main};
    }
  }
  
  .Db-RemoveableTab-Remove-Wrapper {
    position: absolute;
  }
`
);

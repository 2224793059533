import { TextField, TextFieldProps } from 'Atoms';
import { ReactElement, useMemo } from 'react';
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  UseFormStateReturn,
} from 'react-hook-form';

export type ReactHookFormTextFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = Omit<TextFieldProps, 'name' | 'value' | 'error'> & {
  field: ControllerRenderProps<TFieldValues, TName>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<FieldValues>;
  disableError?: boolean;
};

const ReactHookFormTextField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  children,
  disabled,
  helperText,
  disableError,
  field: { value, onBlur, onChange },
  fieldState: { error, invalid, isTouched, isDirty },
  formState: { isSubmitting },
  ...props
}: ReactHookFormTextFieldProps<TFieldValues, TName>): ReactElement => {
  const fieldError = error?.message;
  const showError = !disableError && invalid;

  const textFieldArgs = useMemo(
    () => ({
      variant: props.variant,
      error: showError,
      helperText: showError ? fieldError : helperText,
      disabled: disabled ?? isSubmitting,
    }),
    [disabled, fieldError, helperText, isSubmitting, props, showError]
  );

  let color: 'success' | undefined;

  if (isTouched && isDirty && !invalid) {
    color = 'success';
  }

  return (
    <TextField
      {...textFieldArgs}
      {...props}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      color={color}
    >
      {children}
    </TextField>
  );
};

export default ReactHookFormTextField;

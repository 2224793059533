import { ErrorResponse, Uuid } from 'ValueObjects';
import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
} from '@tanstack/react-query';
import { Role, User } from 'Models';

export type CreateUserMutationProps = {
  userId: Uuid;
  firstName: string;
  middleName?: string;
  lastName: string;
  emailAddress: string;
  password?: string;
  role?: Role;
  accessToOrganizations: Uuid[];
};

export const CreateUserMutation = async ({
  ...values
}: CreateUserMutationProps): Promise<AxiosResponse<User>> =>
  axios.post(`api/users`, {
    ...values,
  });

export const useCreateUserMutation = (
  options: UseMutationOptions<
    AxiosResponse<User>,
    AxiosError<ErrorResponse>,
    CreateUserMutationProps
  > = {}
): UseMutationResult<
  AxiosResponse<User>,
  AxiosError<ErrorResponse>,
  CreateUserMutationProps
> =>
  useMutation<
    AxiosResponse<User>,
    AxiosError<ErrorResponse>,
    CreateUserMutationProps
  >(CreateUserMutation, {
    useErrorBoundary: false,
    ...options,
  });

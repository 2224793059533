import * as Yup from 'yup';
import { Role } from 'Models';

export interface UserProfileInputType {
  firstName: string;
  middleName?: string;
  lastName: string;
  emailAddress: string;
  newPassword?: string;
  oldPassword?: string;
  role?: Role;
}

export const UserProfileInputTypeValidationBuilder =
  (): Yup.SchemaOf<UserProfileInputType> =>
    Yup.object({
      firstName: Yup.string().required().label('label.firstName'),
      middleName: Yup.string().label('label.middleName'),
      lastName: Yup.string().required().label('label.lastName'),
      emailAddress: Yup.string().email().required().label('label.emailAddress'),
      oldPassword: Yup.string()
        .when('newPassword', {
          is: (val?: string) => !!val,
          then: (schema) => schema.required(),
          otherwise: (schema) => schema,
        })
        .label('label.oldPassword'),
      newPassword: Yup.string().label('label.password'),
      role: Yup.mixed<Role>().oneOf(Object.values(Role)).label('label.role'),
    });

export const UserProfileInputTypeDefaultValues = (): UserProfileInputType => ({
  firstName: '',
  middleName: '',
  lastName: '',
  emailAddress: '',
  oldPassword: '',
  newPassword: '',
});

import { Box, Grid, Typography } from '@mui/material';
import { ReactElement, useEffect, useState } from 'react';
import {
  Button,
  ReactHookFormCheckbox,
  ReactHookFormTextField,
  ReactHookWizardStepActions,
} from 'Atoms';
import { EmailAddress, Uuid } from 'ValueObjects';
import { Controller, UseFormReturn } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import YupPassword from 'yup-password';
import { useLoginMutation, useStartOnboardingWizardMutation } from 'Mutations';
import axios from 'axios';
import { useRecoilState } from 'recoil';
import { reactHookFormBackendErrorHandler } from 'Utils';
import { AuthState } from 'State';
import { OnboardingWizardInput } from 'Pages';
import { ConnectingPackageLogos, HelpDialog } from 'Molecules';
import { PackageType } from 'Models';
import { styled } from '@mui/material/styles';

YupPassword(Yup);

export type RegistrationStepFormInput = {
  onboardingId: Uuid;
  organizationId?: Uuid;
  organizationName?: string;
  contactName?: string;
  emailAddress?: EmailAddress;
  password?: string;
  confirmPassword?: string;
  acceptTermsAndConditions?: boolean;
};

export type RegistrationStepFormProps = {
  actions: ReactHookWizardStepActions<OnboardingWizardInput>;
  packageTypes: PackageType[];
};

export const RegistrationStepFormValidation =
  (): Yup.SchemaOf<RegistrationStepFormInput> =>
    Yup.object({
      onboardingId: Yup.string()
        .required()
        .ensure()
        .uuid()
        .label('label.onboardingId'),
      organizationId: Yup.string().label('label.organizationId'),
      organizationName: Yup.string()
        .required()
        .ensure()
        .label('label.organizationName'),
      contactName: Yup.string().required().ensure().label('label.contactName'),
      emailAddress: Yup.string()
        .required()
        .ensure()
        .email()
        .label('label.emailAddress'),
      password: Yup.string()
        .password()
        .required()
        .ensure()
        .label('label.password'),
      confirmPassword: Yup.string()
        .required()
        .ensure()
        .oneOf([Yup.ref('password'), null])
        .label('label.confirmPassword'),
      acceptTermsAndConditions: Yup.boolean()
        .required()
        .label('label.acceptTermsAndConditions.fieldName')
        .isTrue(),
    });

const RegistrationStepForm = ({
  actions: { overrideSubmit, handleNext },
  packageTypes,
  ...args
}: RegistrationStepFormProps): ReactElement => {
  const [authState, setAuthState] = useRecoilState(AuthState);
  const loginUser = useLoginMutation({
    onSuccess: async ({ data }) => {
      setAuthState(data);
    },
  });

  const startOnboardingWizard = useStartOnboardingWizardMutation();

  useEffect(() => {
    overrideSubmit(
      () =>
        (form: UseFormReturn<OnboardingWizardInput>) =>
        async (values: OnboardingWizardInput) => {
          if (
            !values.organizationName ||
            !values.contactName ||
            !values.emailAddress ||
            !values.password
          ) {
            return;
          }

          try {
            if (!values.organizationId) {
              const { data } = await startOnboardingWizard.mutateAsync({
                onboardingId: values.onboardingId,
                organizationName: values.organizationName,
                contactName: values.contactName,
                emailAddress: values.emailAddress,
                password: values.password,
              });
              form.setValue('organizationId', data.organizationId);
            }

            if (!authState.token) {
              await loginUser.mutateAsync({
                emailAddress: values.emailAddress,
                password: values.password,
              });
            }

            overrideSubmit(undefined);
            handleNext(true, true);
          } catch (exception) {
            if (axios.isAxiosError(exception)) {
              reactHookFormBackendErrorHandler<OnboardingWizardInput>(
                exception,
                form
              );

              return;
            }

            throw exception;
          }
        }
    );

    return () => {
      overrideSubmit(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showHelpDialog, setShowHelpDialog] = useState(false);

  const handleClickTestButton = () => {
    setShowHelpDialog((prevState) => !prevState);
  };

  return (
    <Grid {...args} container spacing={4}>
      {showHelpDialog && (
        <Box sx={{ width: 635 }}>
          <HelpDialog
            title="test title"
            helpContent=""
            {...args}
            open
            backdrop={false}
            onClose={() => setShowHelpDialog(false)}
          />
        </Box>
      )}
      <Grid item xs={12}>
        <Typography variant="h4" color="primary">
          <FormattedMessage id="onboarding.steps.registration" />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          This connection imports the PayPal transactions each night into Exact
          Online. Inside Exact Online, you&quot;ll find a journal entry inside a
          bank journal.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ConnectingPackageLogos packageTypes={packageTypes} />
      </Grid>
      <Grid item xs={12} container spacing={3}>
        <Grid item xs={12}>
          <Controller
            name="organizationName"
            render={(registered) => (
              <ReactHookFormTextField
                {...registered}
                fullWidth
                label={<FormattedMessage id="label.organizationName" />}
                autoFocus
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="contactName"
            render={(registered) => (
              <ReactHookFormTextField
                {...registered}
                fullWidth
                label={<FormattedMessage id="label.contactName" />}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="emailAddress"
            render={(registered) => (
              <ReactHookFormTextField
                {...registered}
                fullWidth
                type="email"
                label={<FormattedMessage id="label.emailAddress" />}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="password"
            render={(registered) => (
              <ReactHookFormTextField
                {...registered}
                fullWidth
                type="password"
                label={<FormattedMessage id="label.password" />}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="confirmPassword"
            render={(registered) => (
              <ReactHookFormTextField
                {...registered}
                fullWidth
                type="password"
                label={<FormattedMessage id="label.confirmPassword" />}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Button onClick={handleClickTestButton}>Test</Button>
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="acceptTermsAndConditions"
            render={(registered) => (
              <ReactHookFormCheckbox
                {...registered}
                label={
                  <FormattedMessage
                    id="label.acceptTermsAndConditions"
                    values={{
                      termAndConditions: (
                        <a
                          href={process.env.REACT_APP_TERMS_AND_CONDITIONS_NL}
                          target="_blank"
                          rel="noreferrer"
                          className="Db-RegistrationStepForm-Anchor"
                        >
                          <FormattedMessage id="label.acceptTermsAndConditions.termAndConditions" />
                        </a>
                      ),
                      privacyPolicy: (
                        <a
                          href={process.env.REACT_APP_PRIVACY_POLICY_NL}
                          target="_blank"
                          rel="noreferrer"
                          className="Db-RegistrationStepForm-Anchor"
                        >
                          <FormattedMessage id="label.acceptTermsAndConditions.privacyPolicy" />
                        </a>
                      ),
                    }}
                  />
                }
              />
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default styled(RegistrationStepForm)(
  ({ theme }) => `
  .Db-RegistrationStepForm-Anchor {
    text-decoration: none;
    color: ${theme.palette.secondary.main};
  }
`
);

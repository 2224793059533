import { Organization } from 'Models';
import { Box, Grid, styled, Typography } from '@mui/material';
import colors from 'App/colors';
import { DetailsWithActionsPageTemplate } from 'Templates/DetailsWithActionsPageTemplate';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Avatar } from 'Atoms';
import { AvatarSizeType } from 'Atoms/Avatar/Avatar';
import { useNavigate } from 'react-router-dom';
import { ReactNode } from 'react';
import { useGetCompanyLogo } from 'Utils';

export type OrganizationDetailsPageTemplateProps = {
  organization: Organization;
  currentPage: string;
  className?: string;
  actions?: ReactNode | ReactNode[];
  children: ReactNode | ReactNode[];
  onUpdateCompanyLogo?: () => void;
};

const OrganizationDetailsPageTemplate = ({
  currentPage,
  organization,
  className,
  actions,
  children,
  onUpdateCompanyLogo,
}: OrganizationDetailsPageTemplateProps) => {
  const navigate = useNavigate();
  const profileImage = useGetCompanyLogo(organization);

  return (
    <DetailsWithActionsPageTemplate
      currentPage={currentPage}
      actions={[
        {
          value: 'overview',
          children: <FormattedMessage id="button.overview" />,
          onClick: () =>
            navigate(`/organizations/${organization.organizationId}`),
        },
        {
          value: 'users',
          children: <FormattedMessage id="button.users" />,
          onClick: () =>
            navigate(`/organizations/${organization.organizationId}/users`),
        },
        {
          value: 'connections',
          children: <FormattedMessage id="button.connections" />,
          onClick: () =>
            navigate(
              `/organizations/${organization.organizationId}/connections`
            ),
        },
      ]}
      className={className}
    >
      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          <Box className="Db-OrganizationDetailsPage-Block">
            <Grid
              container
              columnSpacing={4}
              rowSpacing={3}
              alignItems="center"
            >
              <Grid item xs={6}>
                <Typography variant="h5">
                  <FormattedMessage id="label.organization" />
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                justifyContent="flex-end"
                display="flex"
                container
                columnSpacing={1}
              >
                {actions}
              </Grid>
              <Grid item>
                <Avatar
                  src={profileImage}
                  size={AvatarSizeType.BIG}
                  onClick={onUpdateCompanyLogo}
                />
              </Grid>
              <Grid item>
                <Typography variant="h5">
                  {organization.organizationName}
                </Typography>
                <Typography variant="caption">
                  <FormattedMessage
                    id="label.activeSince"
                    values={{
                      date: (
                        <FormattedDate
                          value={organization.createdAt}
                          dateStyle="short"
                        />
                      ),
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </DetailsWithActionsPageTemplate>
  );
};

export default styled(OrganizationDetailsPageTemplate)`
  width: 100%;

  .Db-OrganizationDetailsPage-Block {
    padding: 24px;
    background: ${colors.white};
  }

  .Db-OrganizationDetailsPage-SubBlock {
    &:before {
      display: block;
      content: '';
      height: 2px;
      width: 100%;
      background: #eaf2f9;
      margin-top: 4px;
      margin-bottom: 36px;
    }
  }

  .Db-OrganizationDetailsPage-SubBlock-Text {
    padding-top: 27px;
  }

  .Db-OrganizationDetailsPage-Organizations {
    padding-top: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    .Db-OrganizationDetailsPage-Organizations-Organization {
      background: #e4e4e7;
      border-radius: 90px;
      padding: 5px 15px;

      .MuiChip-label {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #18181b;
      }
    }
  }
`;

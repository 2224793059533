import { AbstractCredential } from 'Models';
import { Box, Grid, styled, Typography } from '@mui/material';
import colors from 'App/colors';
import { DetailsWithActionsPageTemplate } from 'Templates/DetailsWithActionsPageTemplate';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { ReactNode } from 'react';

export type CredentialDetailsPageTemplateProps<
  CredentialType extends AbstractCredential
> = {
  credential: CredentialType;
  currentPage: string;
  className?: string;
  actions?: ReactNode | ReactNode[];
  children: ReactNode | ReactNode[];
};

const CredentialDetailsPageTemplate = ({
  currentPage,
  credential,
  className,
  actions,
  children,
}: CredentialDetailsPageTemplateProps<AbstractCredential>) => {
  const navigate = useNavigate();

  return (
    <DetailsWithActionsPageTemplate
      currentPage={currentPage}
      actions={[
        {
          value: 'overview',
          children: <FormattedMessage id="button.overview" />,
          onClick: () => navigate(`/credentials/${credential.credentialId}`),
        },
        {
          value: 'connections',
          children: <FormattedMessage id="button.connections" />,
          onClick: () =>
            navigate(`/credentials/${credential.credentialId}/connections`),
        },
      ]}
      className={className}
    >
      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          <Box className="Db-CredentialDetailsPage-Block">
            <Grid
              container
              columnSpacing={4}
              rowSpacing={3}
              alignItems="center"
            >
              <Grid item xs={6}>
                <Typography variant="h5">
                  <FormattedMessage id="label.credential" />
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                justifyContent="flex-end"
                display="flex"
                container
                columnSpacing={1}
              >
                {actions}
              </Grid>
              <Grid item>
                <Grid container direction="column">
                  <Grid item marginBottom={1}>
                    <Typography variant="h5">{credential.name}</Typography>
                  </Grid>

                  <Grid item>
                    <Typography variant="caption">
                      <FormattedMessage
                        id="label.packageWithValue"
                        values={{
                          packageType: (
                            <FormattedMessage
                              id={`packageTypes.${credential.packageType}`}
                            />
                          ),
                        }}
                      />
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography variant="caption">
                      <FormattedMessage
                        id="label.organizationWithValue"
                        values={{
                          organizationName:
                            credential.organization.organizationName,
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="caption">
                      <FormattedMessage
                        id="label.createdAt"
                        values={{
                          date: (
                            <FormattedDate
                              value={credential.createdAt}
                              dateStyle="short"
                            />
                          ),
                        }}
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </DetailsWithActionsPageTemplate>
  );
};

export default styled(CredentialDetailsPageTemplate)`
  width: 100%;

  .Db-CredentialDetailsPage-Block {
    padding: 24px;
    background: ${colors.white};
  }

  .Db-CredentialDetailsPage-SubBlock {
    &:before {
      display: block;
      content: '';
      height: 2px;
      width: 100%;
      background: #eaf2f9;
      margin-top: 4px;
      margin-bottom: 36px;
    }
  }

  .Db-CredentialDetailsPage-SubBlock-Text {
    padding-top: 27px;
  }

  .Db-CredentialDetailsPage-Organizations {
    padding-top: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    .Db-CredentialDetailsPage-Organizations-Organization {
      background: #e4e4e7;
      border-radius: 90px;
      padding: 5px 15px;

      .MuiChip-label {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #18181b;
      }
    }
  }
`;

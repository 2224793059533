import {
  AbstractCredential,
  isExactOnlineCredential,
  isPaypalCredential,
  PackageType,
} from 'Models';
import { AnyObjectSchema } from 'yup';
import { ReactElement } from 'react';
import * as Yup from 'yup';

import {
  RecrasCredentialInputTypeDefaultValues,
  RecrasCredentialInputTypeValidationBuilder,
  PaypalCredentialInputTypeDefaultValues,
  PaypalCredentialInputTypeValidationBuilder,
  ExactOnlineCredentialInputTypeDefaultValues,
  ExactOnlineCredentialInputTypeValidationBuilder,
} from '../InputTypes';
import { AbstractCredentialDto } from '../Mutations';
import PaypalCredentialForm from '../Forms/Package/Paypal/PaypalCredentialForm/PaypalCredentialForm';
import RecrasCredentialForm from '../Forms/Package/Recras/RecrasCredentialForm/RecrasCredentialForm';
import ExactOnlineCredentialForm from '../Forms/Package/ExactOnline/ExactOnlineCredentialForm/ExactOnlineCredentialForm';
import { isRecrasCredential } from '../Models/Synchronizer/Credential/RecrasCredential';

const credentialInputTypeValidationBuilderMapping = (): {
  [key: string]: AnyObjectSchema;
} => ({
  [PackageType.PAYPAL]: PaypalCredentialInputTypeValidationBuilder(),
  [PackageType.RECRAS]: RecrasCredentialInputTypeValidationBuilder(),
  [PackageType.EXACT_ONLINE]: ExactOnlineCredentialInputTypeValidationBuilder(),
});

const credentialInputTypeDefaultValuesMapping = (): {
  [key: string]: AbstractCredentialDto;
} => ({
  [PackageType.PAYPAL]: PaypalCredentialInputTypeDefaultValues(),
  [PackageType.RECRAS]: RecrasCredentialInputTypeDefaultValues(),
  [PackageType.EXACT_ONLINE]: ExactOnlineCredentialInputTypeDefaultValues(),
});

const credentialFormMapping = (
  prefix: string | undefined
): {
  [key: string]: ReactElement;
} => ({
  [PackageType.PAYPAL]: <PaypalCredentialForm prefix={prefix} />,
  [PackageType.RECRAS]: <RecrasCredentialForm prefix={prefix} />,
  [PackageType.EXACT_ONLINE]: <ExactOnlineCredentialForm prefix={prefix} />,
});

export const getCredentialInputTypeValidationBuilderByPackageType = (
  packageType: PackageType | undefined
): AnyObjectSchema => {
  if (!packageType) {
    return Yup.object({});
  }

  const mapping = credentialInputTypeValidationBuilderMapping();

  return mapping[packageType] || null;
};

export const getCredentialInputTypeEmptyDefaultValuesByPackageType = (
  packageType: PackageType | undefined
): AbstractCredentialDto | null => {
  if (!packageType) {
    return null;
  }

  const mapping = credentialInputTypeDefaultValuesMapping();

  return mapping[packageType] || null;
};

export const getCredentialInputTypeDefaultValuesByPackageType = (
  packageType: PackageType | undefined,
  credential?: AbstractCredential | undefined
): AbstractCredentialDto | null => {
  if (!packageType) {
    return null;
  }

  if (!credential) {
    return getCredentialInputTypeEmptyDefaultValuesByPackageType(packageType);
  }

  if (isPaypalCredential(credential)) {
    return PaypalCredentialInputTypeDefaultValues(credential);
  }

  if (isExactOnlineCredential(credential)) {
    return ExactOnlineCredentialInputTypeDefaultValues(credential);
  }

  if (isRecrasCredential(credential)) {
    return RecrasCredentialInputTypeDefaultValues(credential);
  }

  return null;
};

export const getCredentialFormByPackageType = (
  packageType: PackageType | undefined,
  prefix: string | undefined
): ReactElement | null => {
  if (!packageType) {
    return null;
  }

  const mapping = credentialFormMapping(prefix);

  return mapping[packageType] || null;
};

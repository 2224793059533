import { ReactElement, useEffect } from 'react';
import { TopBar } from 'Organisms';
import { Box, BoxProps, styled } from '@mui/material';
import { useRecoilState } from 'recoil';
import { AuthState, UserState } from 'State';
import { UserByUserIdRequest } from 'Requests';

export type LayoutWithTopBarProps = BoxProps;

const LayoutWithTopBar = ({
  children,
  ...args
}: LayoutWithTopBarProps): ReactElement => {
  const [authState] = useRecoilState(AuthState);
  const [userState, setUserState] = useRecoilState(UserState);

  useEffect(() => {
    (async () => {
      if (
        authState.token &&
        authState.userId &&
        userState?.userId !== authState.userId
      ) {
        await new Promise((r) => {
          setTimeout(r, 150);
        });
        const { data } = await UserByUserIdRequest({
          userId: authState.userId,
        });

        setUserState(data);
      }
    })();
  }, [authState.token, authState.userId, userState, setUserState]);

  return (
    <Box {...args}>
      <TopBar currentUser={userState} />
      <Box className="Db-LayoutWithTopBar-Content">{children}</Box>
    </Box>
  );
};

export default styled(LayoutWithTopBar)(
  () => `
  height: 100%;
  padding-top: 80px;
  box-sizing: border-box;
  .Db-LayoutWithTopBar-Content {
    height: 100%;
  }
`
);

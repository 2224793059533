import { Box, Paper, styled } from '@mui/material';
import { forwardRef, ReactNode } from 'react';

export type WizardContentProps = {
  children: ReactNode | ReactNode[];
  actions: ReactNode | ReactNode[];
  className?: string;
};

const WizardContent = forwardRef<HTMLDivElement, WizardContentProps>(
  // eslint-disable-next-line react/prop-types
  ({ children, actions, className }: WizardContentProps, ref) => (
    <Paper elevation={5} className={className} ref={ref}>
      <Box className="Db-WizardContent-Content">{children}</Box>
      <Box className="Db-WizardContent-WizardActions">{actions}</Box>
    </Paper>
  )
);

export default styled(WizardContent)(
  () => `
  min-height: 640px;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0px 20px 50px rgba(29, 53, 71, 0.1);
 
  .Db-WizardContent-Content {
    padding: 50px 95px;
  }
      
  .Db-WizardContent-WizardActions {
    background: rgb(242, 242, 242, 0.5);
    width: 100%;
    height: 70px;
    margin-top: auto;
    display: flex;
    align-items: center;
    padding: 0 95px;
    box-sizing: border-box;
  }
`
);

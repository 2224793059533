import { ReactElement, ReactNode, useCallback, useMemo } from 'react';
import { Dialog, DialogProps } from 'Molecules';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Portal,
  Typography,
} from '@mui/material';
import { Button, ReactHookForm, ReactHookFormProps } from 'Atoms';
import { FieldValues, useFormContext, useFormState } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

export type FormDialogProps<TFieldValues extends FieldValues = FieldValues> =
  Omit<DialogProps, 'title' | 'onSubmit'> &
    ReactHookFormProps<TFieldValues> & {
      title: ReactNode;
      dialogActions: ReactNode[] | ReactNode;
      hideCloseButton?: boolean;
      closeButtonLast?: boolean;
    };

type RenderDialogProps = Omit<DialogProps, 'title' | 'onSubmit'> & {
  title: ReactNode;
  dialogActions: ReactNode[] | ReactNode;
  hideCloseButton?: boolean;
  closeButtonLast?: boolean;
};

const RenderDialog = ({
  title,
  children,
  dialogActions,
  closeButtonLast,
  hideCloseButton,
  onClose,
  ...args
}: RenderDialogProps): ReactElement => {
  const formState = useFormState();
  const { getValues } = useFormContext();
  const { formatMessage } = useIntl();
  const backendErrors = useMemo(
    () => formState.errors?.backendErrors,
    [formState.errors?.backendErrors]
  );

  const handleClose = useCallback(
    (
      event: Record<string, unknown>,
      reason: 'backdropClick' | 'escapeKeyDown'
    ) => {
      if (!onClose) {
        return;
      }

      if (formState.isDirty) {
        const dirtyFields = Object.keys(formState.dirtyFields).filter(
          (key) => !!getValues(key)
        );

        if (
          dirtyFields.length > 0 &&
          // eslint-disable-next-line no-alert
          !window.confirm(formatMessage({ id: 'label.closeWithDirtyFields' }))
        ) {
          return;
        }
      }

      onClose(event, reason);
    },
    [
      formState.dirtyFields,
      formState.isDirty,
      formatMessage,
      getValues,
      onClose,
    ]
  );

  return (
    <Dialog {...args} onClose={handleClose} disablePortal>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {backendErrors && (
          <Typography pb={1} color="error">
            {/* @ts-ignore */}
            {backendErrors.message}
          </Typography>
        )}
        {children}
      </DialogContent>
      <DialogActions>
        {!hideCloseButton && !closeButtonLast && onClose && (
          <Button
            key="cancel"
            variant="contained"
            color="quaternary"
            onClick={() => handleClose({}, 'escapeKeyDown')}
          >
            <FormattedMessage id="button.cancel" />
          </Button>
        )}

        {dialogActions}
        {!hideCloseButton && closeButtonLast && onClose && (
          <Button
            key="cancel"
            variant="contained"
            color="quaternary"
            onClick={() => handleClose({}, 'escapeKeyDown')}
          >
            <FormattedMessage id="button.cancel" />
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

RenderDialog.defaultProps = {
  hideCloseButton: false,
};

const FormDialog = <TFieldValues extends FieldValues = FieldValues>({
  defaultValues,
  validationSchema,
  useProvider,
  name,
  onSubmit,
  ...args
}: FormDialogProps<TFieldValues>): ReactElement => (
  <Portal>
    <ReactHookForm
      defaultValues={defaultValues}
      validationSchema={validationSchema}
      useProvider={useProvider}
      name={name}
      onSubmit={onSubmit}
      hideBackendErrors
    >
      <RenderDialog {...args} />
    </ReactHookForm>
  </Portal>
);

export default FormDialog;

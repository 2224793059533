// eslint-disable-next-line import/prefer-default-export
export enum PaypalTransactionField {
  TRANSACTION_ID = 'transactionId',
  TRANSACTION_INVOICE_ID = 'invoiceId',
  TRANSACTION_CUSTOM_FIELD = 'customField',
  TRANSACTION_SUBJECT = 'transactionSubject',
  TRANSACTION_PAYER_EMAIL = 'payerEmail',
  TRANSACTION_NOTE = 'transactionNote',
  ITEM_CODE = 'itemCode',
  ITEM_NAME = 'itemName',
  ITEM_DESCRIPTION = 'itemDescription',
}

import { ReactElement } from 'react';
import { Grid } from '@mui/material';
import { Controller } from 'react-hook-form';
import { ReactHookFormTextField } from 'Atoms';
import { FormattedMessage, useIntl } from 'react-intl';

export type PaypalCredentialFormProps = {
  prefix?: string | null;
};

const PaypalCredentialForm = ({
  prefix,
}: PaypalCredentialFormProps): ReactElement => {
  const intl = useIntl();

  return (
    // <Grid container spacing={2}>
    <>
      <Grid item xs={12}>
        <Controller
          name={prefix ? `${prefix}.name` : 'name'}
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={intl.formatMessage({ id: 'label.name' })}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name={prefix ? `${prefix}.clientId` : 'clientId'}
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={<FormattedMessage id="label.clientId" />}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name={prefix ? `${prefix}.clientSecret` : 'clientSecret'}
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              type="password"
              label={<FormattedMessage id="label.clientSecret" />}
            />
          )}
        />
      </Grid>
    </>
    // </Grid>
  );
};

export default PaypalCredentialForm;

import {
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  styled,
  useTheme,
  useMediaQuery,
  IconButton,
} from '@mui/material';
import { FunctionComponent } from 'react';
import { Icon } from 'Atoms';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import colors from 'App/colors';

export type DialogProps = MuiDialogProps;

const Dialog: FunctionComponent<DialogProps> = ({
  children,
  fullScreen,
  onClose,
  ...args
}: DialogProps) => {
  const theme = useTheme();
  const forceFullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <MuiDialog
      fullScreen={forceFullScreen || fullScreen}
      onClose={onClose}
      {...args}
    >
      <IconButton
        disableRipple
        className="Databrydge-Dialog-Close"
        onClick={() => !!onClose && onClose({}, 'escapeKeyDown')}
      >
        <Icon icon={faTimes} />
      </IconButton>
      {children}
    </MuiDialog>
  );
};

Dialog.defaultProps = {
  maxWidth: 'lg',
};

const StyledDialog = styled(Dialog)(
  ({ theme }) => `
 .Databrydge-Dialog-Close {
    color: ${colors.grey[900]};
    position: absolute;
    font-size: 12px;
    top: 30px;
    right: 20px;
    padding: 0;
    z-index: 9;
 }
`
);

const FinalDialog: FunctionComponent<DialogProps> = ({
  ...props
}: DialogProps) => <StyledDialog {...props} />;

export default FinalDialog;

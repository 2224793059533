import { ErrorResponse, Uuid } from 'ValueObjects';
import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
} from '@tanstack/react-query';

export type DeleteCredentialMutationProps = {
  credentialId?: Uuid;
};

export const DeleteCredentialMutation = async ({
  ...values
}: DeleteCredentialMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/credentials/${values.credentialId}/delete`, {
    ...values,
  });

export const useDeleteCredentialMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    DeleteCredentialMutationProps
  > = {}
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  DeleteCredentialMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    DeleteCredentialMutationProps
  >(DeleteCredentialMutation, {
    useErrorBoundary: false,
    ...options,
  });

import { AtomEffect, DefaultValue } from 'recoil';
import * as localForage from 'localforage';

const LocalForageEffect: <T>(key: string) => AtomEffect<T> =
  (key: string) =>
  ({ setSelf, onSet }) => {
    setSelf(
      // @ts-ignore
      localForage
        .getItem(key)
        .then((savedValue) => savedValue || new DefaultValue())
    );

    onSet((newValue) => {
      localForage.setItem(key, newValue);
    });
  };

export default LocalForageEffect;

import { ReactElement } from 'react';
import { Box, BoxProps, styled } from '@mui/material';

export type CenteredLayoutTemplateProps = BoxProps;

const CenteredLayoutTemplate = ({
  children,
  ...args
}: CenteredLayoutTemplateProps): ReactElement => (
  <Box {...args}>
    <Box className="Content">{children}</Box>
  </Box>
);

export default styled(CenteredLayoutTemplate)(
  ({ theme }) => `
  height: 100vh;
  width: 100vw;
  background: ${theme.palette.primary.main};
  display: flex;
  align-items: center;
  justify-content: center;
  
  .Content {
    max-width: 500px;
    padding: ${theme.spacing(5)};
    background: ${theme.palette.common.white};
    border-radius: 5px;
  }
`
);

import { UseFormWatch, FieldValues } from 'react-hook-form';
import { useDebounce } from 'react-use';

export type UsePersistFormProps<
  TFieldValues extends FieldValues = FieldValues
> = {
  watch: UseFormWatch<TFieldValues>;
  persist?: string;
  extraData?: Record<string, unknown>;
};

export const usePersistForm = <TFieldValues extends FieldValues = FieldValues>({
  watch,
  persist,
  extraData = undefined,
}: UsePersistFormProps<TFieldValues>): void => {
  const values = watch();

  useDebounce(
    () => {
      if (persist) {
        window.localStorage.setItem(
          persist,
          JSON.stringify({ ...values, ...extraData })
        );
      }
    },
    300,
    [persist, values, extraData]
  );
};

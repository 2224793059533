import { ReactElement, ReactNode, useEffect } from 'react';
import { FormHelperText, Grid, styled, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import { Button } from 'Atoms';
import { useUploadFile } from 'Utils';

export type UploadProfilePictureFormProps = {
  children?: ReactNode;
  className?: string;
};

const UploadProfilePictureForm = ({
  className,
  children,
}: UploadProfilePictureFormProps): ReactElement => {
  const { setValue, watch } = useFormContext();

  const { handleUpload, uploadingFile } = useUploadFile('fileId', setValue);
  useEffect(() => {
    if (uploadingFile?.success || uploadingFile?.failed) {
      setValue('uploading', false, { shouldValidate: true });
      return;
    }

    setValue('uploading', true, { shouldValidate: true });
  }, [uploadingFile, setValue]);

  return (
    <Grid container rowSpacing={3} className={className}>
      {uploadingFile && (
        <Grid item xs={12}>
          <img
            src={URL.createObjectURL(uploadingFile.file)}
            alt={uploadingFile.file.name}
            style={{ width: '100%' }}
          />
          <Typography>{uploadingFile.file.name}</Typography>
          {!uploadingFile.success && uploadingFile.failed && (
            <FormHelperText error={uploadingFile.failed}>
              {uploadingFile.reason}
            </FormHelperText>
          )}
        </Grid>
      )}
      <Grid item xs={12}>
        <label htmlFor="upload-file">
          <input
            accept="image/*"
            id="upload-file"
            className="Bas-UploadProfilePictureForm-Input"
            type="file"
            onChange={handleUpload}
            disabled={
              uploadingFile &&
              uploadingFile.progress !== 100 &&
              !uploadingFile.failed
            }
          />
          <Button
            /** @ts-ignore */
            component="span"
            fullWidth
            disabled={
              uploadingFile &&
              uploadingFile.progress !== 100 &&
              !uploadingFile.failed
            }
            loading={
              uploadingFile &&
              uploadingFile.progress !== 100 &&
              !uploadingFile.failed
            }
          >
            <FormattedMessage id="button.uploadImage" />
          </Button>
        </label>
      </Grid>
      {children}
    </Grid>
  );
};

export default styled(UploadProfilePictureForm)(
  () => `
   .Bas-UploadProfilePictureForm-Input {
     display: none;
   }
`
);

import { DatePicker, DatePickerProps } from 'Atoms/DatePicker';
import { ReactElement, ReactNode } from 'react';
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  UseFormStateReturn,
} from 'react-hook-form';

export interface ReactHookFormDatePickerProps<
  TInputDate,
  TDate,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Omit<
    DatePickerProps<TInputDate, TDate>,
    'name' | 'value' | 'error' | 'onChange'
  > {
  onBlur?: () => void;
  helperText?: ReactNode;
  field: ControllerRenderProps<TFieldValues, TName>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<FieldValues>;
}

export const fieldToDatePicker = <
  TInputDate,
  TDate,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  disabled,
  field: { onChange, onBlur, value },
  fieldState: { error, invalid },
  formState: { isSubmitting },
  helperText,
  textFieldProps,
  ...props
}: ReactHookFormDatePickerProps<
  TInputDate,
  TDate,
  TFieldValues,
  TName
>): DatePickerProps<TInputDate, TDate> => {
  const fieldError = error?.message;
  const showError = invalid;

  return {
    textFieldProps: {
      ...textFieldProps,
      error: showError,
      helperText: showError ? fieldError : helperText,
      onBlur,
    },
    disabled: disabled ?? isSubmitting,
    onChange,
    ...props,
    value,
  };
};

const ReactHookFormDatePicker = <
  TInputDate,
  TDate,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  ...args
}: ReactHookFormDatePickerProps<
  TInputDate,
  TDate,
  TFieldValues,
  TName
>): ReactElement => (
  // @ts-ignore
  <DatePicker
    {...fieldToDatePicker<TInputDate, TDate, TFieldValues, TName>(args)}
  />
);

export default ReactHookFormDatePicker;

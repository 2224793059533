import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import SentryRRWeb from '@sentry/rrweb';
import {
  Dedupe as DedupeIntegration,
  ExtraErrorData,
} from '@sentry/integrations';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import './index.css';
import { StrictMode, Suspense } from 'react';
import { RecoilRoot, useRecoilState } from 'recoil';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { LoadingPage, ErrorPage } from 'Pages';
import { AdapterDayjs as DateAdapter } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import 'dayjs/locale/nl';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import enTranslations from './translations/en.json';
import { UserState } from './State';

import '@fontsource/source-sans-pro';
import '@fontsource/source-sans-pro/400-italic.css';
import '@fontsource/source-sans-pro/700.css';
import '@fontsource/source-sans-pro/700-italic.css';
import '@fontsource/source-sans-pro/latin.css';
import '@fontsource/source-sans-pro/latin-400.css';
import '@fontsource/source-sans-pro/latin-400-italic.css';
import '@fontsource/source-sans-pro/latin-700.css';
import '@fontsource/source-sans-pro/latin-700-italic.css';
import '@fontsource/inter';
import '@fontsource/inter/500.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/latin.css';
import '@fontsource/inter/latin-400.css';
import '@fontsource/inter/latin-500.css';
import '@fontsource/inter/latin-700.css';

import '@fontsource/roboto';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/latin.css';
import '@fontsource/roboto/latin-400.css';
import '@fontsource/roboto/latin-500.css';
import '@fontsource/roboto/latin-700.css';

import MercureSubscriber from './App/MercureSubscriber';

const translations = {
  en: enTranslations,
  nl: enTranslations,
};

Sentry.init({
  dsn: 'https://978de0f5abf94936a1342eadec67ba65@o272840.ingest.sentry.io/5986059',
  environment:
    process.env.NODE_ENV === 'development'
      ? process.env.NODE_ENV
      : window.location.hostname.split('.')[0],
  release: process.env.REACT_APP_RELEASE,
  integrations: [
    new ExtraErrorData({ depth: 5 }),
    new TracingIntegrations.BrowserTracing(),
    new SentryRRWeb(),
    // Sentry.reactRouterV5Instrumentation(appHistory),
    new DedupeIntegration(),
  ],
  tracesSampleRate: 1.0, // Be sure to lower this in production
});

if (process.env.NODE_ENV !== 'production') {
  document.title = `Local ${document.title}`;
}

const queryClient = new QueryClient();

const LocalizedIndex = () => {
  const [user] = useRecoilState(UserState);
  const locale = user?.language || 'nl';

  return (
    <IntlProvider locale={locale} messages={translations[locale]}>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <Sentry.ErrorBoundary fallback={<ErrorPage />}>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <MercureSubscriber />
              <App />
            </BrowserRouter>
          </QueryClientProvider>
        </Sentry.ErrorBoundary>
      </LocalizationProvider>
    </IntlProvider>
  );
};

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <StrictMode>
    <RecoilRoot>
      <Suspense fallback={<LoadingPage />}>
        <LocalizedIndex />
      </Suspense>
    </RecoilRoot>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line no-console
// reportWebVitals(console.log);

import { FunctionComponent } from 'react';
import { BoxProps, styled } from '@mui/material';
import { CenteredLayoutTemplate } from 'Templates';
import { Icon } from 'Atoms';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';

const LoadingPage: FunctionComponent = ({ ...args }: BoxProps) => (
  <CenteredLayoutTemplate {...args}>
    <Icon icon={faSpinner} size="4x" spin />
  </CenteredLayoutTemplate>
);

export default styled(LoadingPage)(
  () => `

    .Content {
       background: transparent;
    }
`
);

import {
  AbstractCredentialDto,
  isAbstractCredentialDto,
} from './AbstractCredentialDto';

export interface PayPalCredentialDto extends AbstractCredentialDto {
  clientId: string;
  clientSecret: string;
}

export function isPayPalCredentialDto(
  object: unknown
): object is PayPalCredentialDto {
  return (
    isAbstractCredentialDto(object) &&
    'clientId' in object &&
    typeof object.clientId === 'string' &&
    'clientSecret' in object &&
    typeof object.clientSecret === 'string'
  );
}

// eslint-disable-next-line import/prefer-default-export
export enum SettingFieldType {
  TYPE_STRING = 'string',
  // @TODO BE GONE DEMON
  TYPE_DECIMAL = 'number',
  // @TODO BE GONE DEMON
  TYPE_BOOLEAN = 'boolean',
  TYPE_TAGS = 'tags',
  TYPE_CHECKBOX = 'checkbox',
  TYPE_FLOAT = 'float',
  TYPE_TABLE = 'table',
  TYPE_SELECT = 'select',
}

import { ReactElement } from 'react';
import { FormDialog } from 'Organisms';
import { FormattedMessage } from 'react-intl';
import {
  DisableTwoFactorAuthenticationInputType,
  DisableTwoFactorAuthenticationInputTypeDefaultValues,
  DisableTwoFactorAuthenticationInputTypeValidationBuilder,
} from 'InputTypes/App/DisableTwoFactorAuthenticationInputType';
import { Button, Icon, ReactHookFormTextField } from 'Atoms';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { Grid } from '@mui/material';
import { Controller } from 'react-hook-form';

export type DisableTwoFactorAuthenticationFormDialogProps = {
  className?: string;
  open: boolean;
  onClose: () => void;
  asSuperAdmin?: boolean;
  onSubmit: (
    values: DisableTwoFactorAuthenticationInputType
  ) => void | Promise<void>;
};

const DisableTwoFactorAuthenticationFormDialog = ({
  asSuperAdmin,
  ...props
}: DisableTwoFactorAuthenticationFormDialogProps): ReactElement => (
  <FormDialog<DisableTwoFactorAuthenticationInputType>
    name="disable-two-factor"
    title={<FormattedMessage id="label.disableTwoFactorAuthentication" />}
    defaultValues={{
      ...DisableTwoFactorAuthenticationInputTypeDefaultValues(),
    }}
    validationSchema={DisableTwoFactorAuthenticationInputTypeValidationBuilder(
      !!asSuperAdmin
    )}
    closeButtonLast
    dialogActions={[
      <Button type="submit" key="save">
        <Icon icon={faCheck} />
        &nbsp;
        <FormattedMessage id="button.save" />
      </Button>,
    ]}
    fullWidth
    maxWidth="sm"
    useProvider
    hideBackendErrors
    {...props}
  >
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormattedMessage
          id={`label.twoFactorAuthenticationDisableDescription${
            asSuperAdmin ? 'AsSuperAdmin' : ''
          }`}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="password"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              type="password"
              label={<FormattedMessage id="label.password" />}
            />
          )}
        />
      </Grid>
      {!asSuperAdmin && (
        <Grid item xs={12}>
          <Controller
            name="code"
            render={(registered) => (
              <ReactHookFormTextField
                {...registered}
                fullWidth
                label={<FormattedMessage id="label.twoFactorCode" />}
              />
            )}
          />
        </Grid>
      )}
    </Grid>
  </FormDialog>
);

export default DisableTwoFactorAuthenticationFormDialog;

import { styled } from '@mui/material';
import { GridFilterPanel, GridFilterPanelProps } from '@mui/x-data-grid-pro';
import { ReactElement } from 'react';
import colors from 'App/colors';

const FilterPanel = ({ ...props }: GridFilterPanelProps): ReactElement => (
  <GridFilterPanel {...props} />
);

export default styled(FilterPanel)`
  .MuiDataGrid-panelContent {
    > div:first-child {
      .MuiInputLabel-root {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.14px;
        color: ${colors.coolGray[900]};
        transform: none;
        position: relative;
        padding: 10px 16px;
        height: 40px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        background: ${colors.coolGray[50]};
      }

      .MuiDataGrid-filterFormDeleteIcon,
      .MuiDataGrid-filterFormLinkOperatorInput {
        margin-right: 0;
        visibility: initial;
        .Mui-disabled {
          visibility: hidden;
        }

        &:before {
          content: '';

          padding: 10px 16px;
          height: 40px;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          background: ${colors.coolGray[50]};
          top: 0;
          right: 0;
          left: 0;
          position: absolute;
        }
      }
    }

    > div:not(:first-child) {
      .MuiInputLabel-root {
        display: none;
      }

      .MuiDataGrid-filterFormDeleteIcon,
      .MuiDataGrid-filterFormLinkOperatorInput {
        margin-right: 0;
      }
    }

    .MuiInputBase-root {
      &:before {
        border-bottom-color: ${colors.coolGray[200]};
      }
      .MuiInputBase-input {
        padding: 8px 16px;
        gap: 24px;
      }
    }
  }

  .MuiDataGrid-panelFooter {
    padding-left: 32px;
    padding-bottom: 16px;
    padding-top: 16px;

    .MuiButtonBase-root {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 12px 8px 11px;
      gap: 8px;
      background: #f3f5f5;
      border-radius: 8px;
      text-transform: initial;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.17px;

      color: #71717a;
    }
  }

  .MuiDataGrid-filterForm {
    padding: 0;
  }
`;

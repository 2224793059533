import { ReactElement, SyntheticEvent, useState } from 'react';
import { FormDialog } from 'Organisms';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Button,
  Icon,
  ReactHookFormAutocomplete,
  ReactHookFormOrganizationAutocomplete,
} from 'Atoms';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { Grid } from '@mui/material';
import { Controller } from 'react-hook-form';
import {
  CreateCredentialInputType,
  CreateCredentialInputTypeValidationBuilder,
} from 'InputTypes/App/Credential/CreateCredentialInputType';
import {
  AbstractCredentialDto,
  CredentialsThatCanBeCreated,
} from '../../Mutations';
import { PackageType } from '../../Models';
import { getCredentialFormByPackageType } from '../../Utils/CredentialInputMapper';

export type CreateCredentialFormDialogProps = {
  className?: string;
  open: boolean;
  onClose: () => void;
  onSubmit: (
    values: CreateCredentialInputType<AbstractCredentialDto>
  ) => void | Promise<void>;
};

const CreateCredentialFormDialog = ({
  ...props
}: CreateCredentialFormDialogProps): ReactElement => {
  const intl = useIntl();

  const [packageType, setPackageType] = useState<PackageType>();

  return (
    <FormDialog<CreateCredentialInputType<AbstractCredentialDto>>
      name="create-credential"
      title={<FormattedMessage id="label.createCredential" />}
      validationSchema={CreateCredentialInputTypeValidationBuilder(packageType)}
      closeButtonLast
      dialogActions={[
        <Button type="submit" key="create">
          <Icon icon={faCheck} />
          &nbsp;
          <FormattedMessage id="button.create" />
        </Button>,
      ]}
      fullWidth
      maxWidth="sm"
      useProvider
      hideBackendErrors
      {...props}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            name="organization"
            render={(registered) => (
              <ReactHookFormOrganizationAutocomplete
                {...registered}
                fullWidth
                textField={{
                  label: intl.formatMessage({ id: 'label.organization' }),
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="package.packageType"
            render={(registered) => (
              <ReactHookFormAutocomplete
                {...registered}
                fullWidth
                onChange={(
                  event: SyntheticEvent,
                  newValue?: PackageType | undefined
                ) => {
                  registered.field.onChange(newValue);
                  setPackageType(newValue);
                }}
                textField={{
                  label: intl.formatMessage({ id: 'label.packageType' }),
                }}
                options={CredentialsThatCanBeCreated}
              />
            )}
          />
        </Grid>

        {packageType &&
          getCredentialFormByPackageType(packageType, 'package.credential')}
      </Grid>
    </FormDialog>
  );
};
export default CreateCredentialFormDialog;

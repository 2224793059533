import { useMemo } from 'react';
import { AlertProps } from 'Atoms';
import { Connection } from 'Models';

export type UseConnectionAlertsProps = {
  connection?: Connection;
};
// eslint-disable-next-line import/prefer-default-export
export const useConnectionAlerts = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  connection,
}: UseConnectionAlertsProps) => {
  const alerts = useMemo(() => {
    const result: AlertProps[] = [];
    // result.push({
    //   children: (
    //     <span>
    //       <span style={{ paddingRight: 9 }}>
    //         <Icon icon={faLink} />
    //       </span>
    //       Connection error: PayPal is offline
    //     </span>
    //   ),
    //   severity: 'error',
    //   action: (
    //     <ButtonGroup>
    //       <Button size="large" variant="contained" color="tertiary">
    //         <Icon icon={faHeartPulse} />
    //         &nbsp;Status
    //       </Button>
    //     </ButtonGroup>
    //   ),
    // });
    //
    // result.push({
    //   children: (
    //     <span>
    //       <span style={{ paddingRight: 9 }}>
    //         <Icon icon={faFingerprint} />
    //       </span>
    //       Exact Credential error
    //     </span>
    //   ),
    //   severity: 'warning',
    //   action: (
    //     <ButtonGroup>
    //       <Button size="large" variant="contained" color="tertiary">
    //         <Icon icon={faLoader} />
    //         &nbsp;Renew
    //       </Button>
    //       <Button size="large" variant="contained" color="tertiary">
    //         <Icon icon={faFilePlus} />
    //         &nbsp;Details
    //       </Button>
    //     </ButtonGroup>
    //   ),
    // });

    // result.push({
    //   children: (
    //     <span>
    //       <span style={{ paddingRight: 9 }}>
    //         <Icon icon={faSpinnerThird} spin />
    //       </span>
    //       Connection is running
    //     </span>
    //   ),
    //   severity: 'info',
    // });
    //
    // result.push({
    //   children: (
    //     <span>
    //       <span style={{ paddingRight: 9 }}>
    //         <Icon icon={faCheck} />
    //       </span>
    //       First run successful
    //     </span>
    //   ),
    //   severity: 'success',
    // });

    return result;
  }, []);

  return alerts;
};

import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { LoginMutationResponse } from '../LoginMutation';
import { ErrorResponse } from '../../../ValueObjects';

export type TwoFactorLoginMutationProps = {
  token: string;
  code: number;
};

export type TwoFactorLoginMutationResponse = LoginMutationResponse;

export const TwoFactorLoginMutation = async ({
  token,
  code,
}: TwoFactorLoginMutationProps): Promise<
  AxiosResponse<TwoFactorLoginMutationResponse>
> =>
  axios.post(
    `api/auth/two-factor-check`,
    {
      code,
    },
    {
      params: {
        code,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const useTwoFactorLoginMutation = (
  options: UseMutationOptions<
    AxiosResponse<TwoFactorLoginMutationResponse>,
    AxiosError<ErrorResponse>,
    TwoFactorLoginMutationProps
  >
): UseMutationResult<
  AxiosResponse<TwoFactorLoginMutationResponse>,
  AxiosError<ErrorResponse>,
  TwoFactorLoginMutationProps
> =>
  useMutation<
    AxiosResponse<TwoFactorLoginMutationResponse>,
    AxiosError<ErrorResponse>,
    TwoFactorLoginMutationProps
  >(TwoFactorLoginMutation, {
    ...options,
    useErrorBoundary: (error) => (error?.response?.status || 500) >= 500,
  });

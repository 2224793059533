import { ErrorResponse, Uuid } from 'ValueObjects';
import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';

export type DeleteOrganizationMutationProps = {
  organizationId: Uuid;
};

export const DeleteOrganizationMutation = async ({
  ...values
}: DeleteOrganizationMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/organizations/${values.organizationId}/delete`, values);

export const useDeleteOrganizationMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    DeleteOrganizationMutationProps
  > = {}
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  DeleteOrganizationMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    DeleteOrganizationMutationProps
  >(DeleteOrganizationMutation, {
    useErrorBoundary: false,
    ...options,
  });

import { isObjectResponse } from 'Requests';
import { EmailAddress, Uuid } from 'ValueObjects';
import { OrganizationInvoiceAddress } from './OrganizationInvoiceAddress';
import { OrganizationInvoiceVatNumber } from './OrganizationInvoiceVatNumber';

export interface Organization {
  organizationId: Uuid;
  organizationName: string;
  organizationCode: string;
  reseller?: Organization;
  address?: OrganizationInvoiceAddress;
  vatNumber?: OrganizationInvoiceVatNumber;
  invoiceContactName?: string;
  invoiceEmailAddress?: EmailAddress;
  childrenOrganizations?: Organization[];
  reference?: string;
  logoUrl?: string;

  createdAt: Date;
}

export function isOrganization(object: unknown): object is Organization {
  return (
    !!object && isObjectResponse(object) && object['@type'] === 'Organization'
  );
}

import { Uuid } from 'ValueObjects';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { ConnectionStatusReport } from 'Models';
import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

export type ConnectionStatusReportByConnectionIdRequestProps = {
  connectionId: Uuid;
};

export const ConnectionStatusReportByConnectionIdRequest = async ({
  connectionId,
}: ConnectionStatusReportByConnectionIdRequestProps): Promise<
  AxiosResponse<ConnectionStatusReport>
> => axios.get(`api/connections/${connectionId}/status-report`);

export const useConnectionStatusReportByConnectionIdRequestQuery = (
  request: ConnectionStatusReportByConnectionIdRequestProps,
  options: UseQueryOptions<
    AxiosResponse<ConnectionStatusReport>,
    AxiosError,
    AxiosResponse<ConnectionStatusReport>
  > = {}
): UseQueryResult<AxiosResponse<ConnectionStatusReport>, AxiosError> =>
  useQuery<
    AxiosResponse<ConnectionStatusReport>,
    AxiosError,
    AxiosResponse<ConnectionStatusReport>
  >({
    ...options,
    queryFn: async () => ConnectionStatusReportByConnectionIdRequest(request),
    queryKey: ['connections', 'status-report', request.connectionId],
  });

import { Grid, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { ReactHookFormTextField } from 'Atoms';
import { EmailAddress, Uuid } from 'ValueObjects';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import YupPassword from 'yup-password';

YupPassword(Yup);

export type InvoiceDetailsStepFormInput = {
  onboardingId: Uuid;
  invoiceContactName?: string;
  invoiceEmailAddress?: EmailAddress;
  vatNumber?: string;
  reference?: string;
  location?: {
    street: string;
    houseNumberWithAddition: string;
    city: string;
    postalCode: string;
    country: string;
  };
};

export const InvoiceDetailsStepFormValidation =
  (): Yup.SchemaOf<InvoiceDetailsStepFormInput> =>
    Yup.object({
      onboardingId: Yup.string()
        .required()
        .ensure()
        .uuid()
        .label('label.onboardingId'),
      location: Yup.object({
        street: Yup.string().required().ensure().label('label.street'),
        houseNumberWithAddition: Yup.string()
          .required()
          .ensure()
          .label('label.houseNumberWithAddition'),
        city: Yup.string().required().ensure().label('label.city'),
        postalCode: Yup.string().required().ensure().label('label.postalCode'),
        country: Yup.string().required().ensure().label('label.country'),
      }),
      invoiceContactName: Yup.string()
        .required()
        .ensure()
        .label('label.invoiceContactName'),
      invoiceEmailAddress: Yup.string()
        .required()
        .ensure()
        .email()
        .label('label.invoiceEmailAddress'),
      vatNumber: Yup.string().label('label.vatNumber'),
      reference: Yup.string().label('label.reference'),
    });

const InvoiceDetailsStepForm = (): ReactElement => (
  <Grid container spacing={4}>
    <Grid item xs={12}>
      <Typography variant="h4" color="primary">
        <FormattedMessage id="onboarding.steps.invoiceDetails" />
      </Typography>
    </Grid>
    <Grid item xs={12} container spacing={3}>
      <Grid item xs={8}>
        <Controller
          name="location.street"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={<FormattedMessage id="label.street" />}
            />
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          name="location.houseNumberWithAddition"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={<FormattedMessage id="label.houseNumberWithAddition" />}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="location.postalCode"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={<FormattedMessage id="label.postalCode" />}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="location.city"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={<FormattedMessage id="label.city" />}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="location.country"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={<FormattedMessage id="label.country" />}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="invoiceContactName"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={<FormattedMessage id="label.invoiceContactName" />}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="invoiceEmailAddress"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              type="email"
              label={<FormattedMessage id="label.invoiceEmailAddress" />}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="vatNumber"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={<FormattedMessage id="label.vatNumber" />}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="reference"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={<FormattedMessage id="label.reference" />}
            />
          )}
        />
      </Grid>
    </Grid>
  </Grid>
);

export default InvoiceDetailsStepForm;

import { Uuid } from 'ValueObjects';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { ConnectionSyncStatusReport } from 'Models';
import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

export type SyncStatusByConnectionIdRequestProps = {
  connectionId: Uuid;
};

export const SyncStatusByConnectionIdRequest = async ({
  connectionId,
}: SyncStatusByConnectionIdRequestProps): Promise<
  AxiosResponse<ConnectionSyncStatusReport>
> => axios.get(`api/connections/${connectionId}/report/sync-status`);

export const useSyncStatusByConnectionIdRequestQuery = (
  request: SyncStatusByConnectionIdRequestProps,
  options: UseQueryOptions<
    AxiosResponse<ConnectionSyncStatusReport>,
    AxiosError,
    AxiosResponse<ConnectionSyncStatusReport>
  > = {}
): UseQueryResult<AxiosResponse<ConnectionSyncStatusReport>, AxiosError> =>
  useQuery<
    AxiosResponse<ConnectionSyncStatusReport>,
    AxiosError,
    AxiosResponse<ConnectionSyncStatusReport>
  >({
    ...options,
    queryFn: async () => SyncStatusByConnectionIdRequest(request),
    queryKey: ['connections', 'sync-status', request.connectionId],
  });

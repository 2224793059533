import { ReactElement } from 'react';
import { Grid, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import { Controller } from 'react-hook-form';
import { ReactHookFormDatePicker } from 'Atoms';

export type StandardConnectionSettingsFormInput = {
  synchronizationStartDate: Date;
};

export const StandardConnectionSettingsFormValidation =
  (): Yup.SchemaOf<StandardConnectionSettingsFormInput> =>
    Yup.object({
      synchronizationStartDate: Yup.date().nullable().required(),
    });

const StandardConnectionSettingsForm = (): ReactElement => (
  <Grid container spacing="10px">
    <Grid item xs={12}>
      <Typography variant="h6" color="primary">
        <FormattedMessage id="label.connectionSettings" />
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Controller
        name="synchronizationStartDate"
        render={(registered) => (
          <ReactHookFormDatePicker
            {...registered}
            textFieldProps={{
              fullWidth: true,
            }}
            label={<FormattedMessage id="label.synchronizationStartDate" />}
          />
        )}
      />
    </Grid>
  </Grid>
);

export default StandardConnectionSettingsForm;

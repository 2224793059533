import axios, { AxiosError, AxiosProgressEvent, AxiosResponse } from 'axios';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { ErrorResponse } from 'ValueObjects/ErrorResponse';
import { Uuid } from 'ValueObjects/Uuid';

export type UploadFileToCreateAMediaObjectMutationProps = {
  file: File;
  onUploadProgress: (progressEvent: AxiosProgressEvent) => void;
};

export type UploadFileToCreateAMediaObjectMutationResponse = {
  mediaObjectId: Uuid;
  contentUrl: string;
};

export const UploadFileToCreateAMediaObjectMutation = async ({
  file,
  onUploadProgress,
}: UploadFileToCreateAMediaObjectMutationProps): Promise<
  AxiosResponse<UploadFileToCreateAMediaObjectMutationResponse>
> => {
  const formData = new FormData();
  formData.append('file', file);

  return axios.post(`api/media-objects`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress,
  });
};

export const useUploadFileToCreateAMediaObjectMutation = (
  options: UseMutationOptions<
    AxiosResponse<UploadFileToCreateAMediaObjectMutationResponse>,
    AxiosError<ErrorResponse>,
    UploadFileToCreateAMediaObjectMutationProps
  > = {}
): UseMutationResult<
  AxiosResponse<UploadFileToCreateAMediaObjectMutationResponse>,
  AxiosError<ErrorResponse>,
  UploadFileToCreateAMediaObjectMutationProps
> =>
  useMutation<
    AxiosResponse<UploadFileToCreateAMediaObjectMutationResponse>,
    AxiosError<ErrorResponse>,
    UploadFileToCreateAMediaObjectMutationProps
  >(UploadFileToCreateAMediaObjectMutation, {
    useErrorBoundary: false,
    ...options,
  });

import {
  DataGridProProps,
  GridValidRowModel,
  GridSortModel,
  GridFilterModel,
  GridToolbar,
} from '@mui/x-data-grid-pro';
import { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { Collection, Filterable, Pagination } from 'Requests';
import { usePagination } from 'Utils/UsePagination';
import { useCallback, useEffect, useState } from 'react';
import { ErrorResponse } from 'ValueObjects';
import { useIntl } from 'react-intl';
import { GridPagination } from 'Molecules';
import { Box } from '@mui/material';
import { FilterPanel } from 'Organisms';

export type UseDataGridDataWithReactQueryResult<
  Model extends GridValidRowModel
> = DataGridProProps<Model> & {
  handleSearch: (search: string | undefined) => void | Promise<void>;
  refetch: () => void;
};

export type useDataGridDataWithReactQueryProps<
  Model extends GridValidRowModel,
  RequestProps extends Pagination & Filterable = Pagination & Filterable
> = Omit<DataGridProProps<Model>, 'rows'> & {
  overviewPage?: boolean;
  getRowId: (row: Model) => string;
  request?: RequestProps;
  requestOptions?: UseQueryOptions<
    AxiosResponse<Collection<Model>>,
    AxiosError<ErrorResponse>,
    AxiosResponse<Collection<Model>>
  >;
  reactQuery: (
    request: RequestProps,
    options: UseQueryOptions<
      AxiosResponse<Collection<Model>>,
      AxiosError<ErrorResponse>,
      AxiosResponse<Collection<Model>>
    >
  ) => UseQueryResult<AxiosResponse<Collection<Model>>, AxiosError>;
};

const useDataGridDataWithReactQuery = <
  Model extends GridValidRowModel,
  RequestProps extends Pagination & Filterable = Pagination & Filterable
>({
  overviewPage,
  reactQuery,
  requestOptions,
  request,
  componentsProps,
  components,
  columns,
  ...params
}: useDataGridDataWithReactQueryProps<
  Model,
  RequestProps
>): UseDataGridDataWithReactQueryResult<Model> => {
  const [order, setOrder] = useState({});
  const [filter, setFilter] = useState({});
  const [globalFilter, setGlobalFilter] = useState('');
  const [rowCount, setRowCount] = useState<number>(0);
  const { currentPage, rowsPerPage, changeRowsPerPage, changePage } =
    usePagination({
      defaultRowsPerPage: params.pageSize,
    });

  const { formatMessage } = useIntl();

  const {
    data: response,
    isFetching,
    refetch,
  } = reactQuery(
    // @ts-ignore
    {
      perPage: rowsPerPage,
      page: currentPage,
      order,
      filter: JSON.stringify(filter),
      globalFilter: globalFilter || undefined,
      ...(request || {}),
    },
    {
      ...(requestOptions || {}),
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (!isFetching) {
      setRowCount(response?.data?.['hydra:totalItems'] || 0);
    }
  }, [isFetching, response?.data]);

  const handleSortModelChange = useCallback((model: GridSortModel) => {
    const resultingOrder: {
      [key: string]: 'asc' | 'desc' | null | undefined;
    } = {};
    model.forEach(({ field, sort }) => {
      resultingOrder[field] = sort;
    });

    setOrder(resultingOrder);
  }, []);

  const handleFilterModelChange = useCallback((model: GridFilterModel) => {
    const resultingFilter: Filterable = {};
    if (
      model &&
      model.quickFilterValues &&
      model.quickFilterValues.length > 0
    ) {
      setGlobalFilter(model?.quickFilterValues?.[0]);
    } else {
      console.log(model);
    }

    setFilter(model);
  }, []);

  const handleSearch = useCallback((search: string | undefined) => {
    setGlobalFilter(search || '');
  }, []);

  return {
    refetch,
    rows: response?.data?.['hydra:member'] || [],
    loading: isFetching,
    onPageChange: (page) => changePage(page + 1),
    onPageSizeChange: changeRowsPerPage,
    pagination: true,
    pageSize: rowsPerPage,
    autoHeight: true,
    rowCount,
    autoPageSize: false,
    componentsProps: {
      ...componentsProps,
      toolbar: {
        showQuickFilter: true,
        quickFilterProps: { debounceMs: 500 },
        ...(componentsProps?.toolbar || {}),
      },
    },
    components: {
      Toolbar: overviewPage ? null : GridToolbar,
      Pagination: GridPagination,
      FilterPanel,
      ...components,
    },
    columns: columns.map(({ headerName, ...rest }) => ({
      headerName: headerName ? formatMessage({ id: headerName }) : headerName,
      ...rest,
    })),
    disableColumnResize: true,
    disableColumnReorder: true,
    disableColumnPinning: true,
    disableSelectionOnClick: true,
    disableColumnMenu: true,
    paginationMode: 'server',
    sortingMode: 'server',
    filterMode: 'server',
    rowsLoadingMode: 'server',
    onSortModelChange: handleSortModelChange,
    onFilterModelChange: handleFilterModelChange,
    handleSearch,
    ...params,
  };
};

export default useDataGridDataWithReactQuery;

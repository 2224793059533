import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormGroup,
  FormHelperText,
  FormHelperTextProps,
  styled,
} from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { FormControlLabel } from 'Atoms';
import colors from 'App/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons';

export type CheckboxProps = MuiCheckboxProps & {
  label?: ReactElement | string;
  parentHover?: boolean;
  error?: boolean;
  FormHelperTextProps?: Partial<FormHelperTextProps>;
  helperText?: ReactNode;
};

export type CustomizedCheckboxComponentProps = MuiCheckboxProps;

const BpIcon = styled('span')(
  () => `
  background-color: ${colors.white};
  border: 1px solid #D0D5DD;
  box-sizing: border-box;
  border-radius: 4px;
  height: 16px;
  width: 16px;
`
);

const BpCheckedFontAwesomeIcon = ({
  className,
}: {
  className?: string;
}): ReactElement => (
  <BpIcon className={className}>
    <FontAwesomeIcon icon={faCheck} />
  </BpIcon>
);

const BpCheckedIcon = styled(BpCheckedFontAwesomeIcon)(`
  background-color: ${colors.blue[600]};
  color: ${colors.white};
  border: none;
  border-radius: 4px;
  height: 16px;
  width: 16px;
  font-size: 9px;
  align-items: center;
  justify-content: center;
  display: flex;
`);

const CustomizedCheckbox = ({
  ...props
}: CustomizedCheckboxComponentProps): ReactElement => (
  <MuiCheckbox
    color="default"
    checkedIcon={<BpCheckedIcon />}
    icon={<BpIcon />}
    disableRipple
    {...props}
  />
);

const Checkbox = ({
  label,
  error,
  FormHelperTextProps: helperProps,
  helperText,
  disabled,
  ...props
}: CheckboxProps): ReactElement => {
  const checkboxContent = <CustomizedCheckbox disabled={disabled} {...props} />;

  if (!label) {
    return checkboxContent;
  }

  return (
    <FormGroup>
      <FormControlLabel
        label={label}
        disabled={disabled}
        control={checkboxContent}
      />
      {helperText && (
        <FormHelperText {...helperProps} error={error}>
          {helperText}
        </FormHelperText>
      )}
    </FormGroup>
  );
};

export default Checkbox;

import { atom } from 'recoil';
import { Uuid } from 'ValueObjects';
import LocalForageEffect from '../LocalForageEffect';

export type AuthStateValue = {
  userId?: Uuid;
  token?: string;
  mercureToken?: string;
  initialPath?: string;
  expiresAt?: Date;
};

export const AuthStateDefaultValue = {
  token: undefined,
  mercureToken: undefined,
  userId: undefined,
  initialPath: undefined,
  expiresAt: undefined,
};

export default atom<AuthStateValue>({
  key: 'auth',
  default: AuthStateDefaultValue,
  effects_UNSTABLE: [LocalForageEffect<AuthStateValue>('auth')],
});

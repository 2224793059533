import { ReactElement } from 'react';
import { Grid, MenuItem } from '@mui/material';
import { Controller } from 'react-hook-form';
import { ReactHookFormTextField } from 'Atoms';
import { FormattedMessage, useIntl } from 'react-intl';
import { ExactOnlineSupportedCountries } from 'InputTypes/App/Credential/Package/ExactOnline';

export type ExactOnlineCredentialFormProps = {
  prefix?: string | null;
};

const ExactOnlineCredentialForm = ({
  prefix,
}: ExactOnlineCredentialFormProps): ReactElement => {
  const intl = useIntl();

  return (
    <>
      <Grid item xs={12}>
        <Controller
          name={prefix ? `${prefix}.name` : 'name'}
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={intl.formatMessage({ id: 'label.name' })}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name={prefix ? `${prefix}.country` : 'name'}
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={<FormattedMessage id="label.country" />}
              select
            >
              {ExactOnlineSupportedCountries.map(
                (exactOnlineSupportedCountry) => (
                  <MenuItem
                    key={exactOnlineSupportedCountry}
                    value={exactOnlineSupportedCountry}
                  >
                    {exactOnlineSupportedCountry}
                  </MenuItem>
                )
              )}
            </ReactHookFormTextField>
          )}
        />
      </Grid>
    </>
  );
};

export default ExactOnlineCredentialForm;

import { ReactElement, ReactNode } from 'react';
import {
  Breadcrumbs,
  Button,
  Icon,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonProps,
} from 'Atoms';
import { Box, Link, styled } from '@mui/material';
import {
  faChevronRight,
  faHome,
  faSearch,
} from '@fortawesome/pro-light-svg-icons';
import colors from 'App/colors';
import {
  DataGridTemplate,
  DataGridTemplateProps,
} from 'Templates/DataGridTemplate';
import { FormattedMessage, useIntl } from 'react-intl';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import dbFilter from 'Icons/dbFilter';

export type OverviewDataGridPageTemplateProps = DataGridTemplateProps & {
  actions?: ToggleButtonProps[];
  breadCrumbs?: ReactElement[];
  onSearch: (value: string | undefined) => void | Promise<void>;
  children?: ReactNode;
};

const OverviewDataGridPageTemplate = ({
  actions,
  className,
  breadCrumbs,
  onSearch,
  children,
  ...props
}: OverviewDataGridPageTemplateProps): ReactElement => {
  const { formatMessage } = useIntl();

  const apiRef = useGridApiRef();

  return (
    <Box className={className}>
      <Box className="Db-OverviewDataGridPageTemplate-TopBar">
        <Box className="Db-OverviewDataGridPageTemplate-TopBar-Actions">
          <ToggleButtonGroup exclusive value="overview">
            {(actions || []).map((action, index) => (
              <ToggleButton {...action} key={index} />
            ))}
          </ToggleButtonGroup>
        </Box>
        <Box>
          <TextField
            placeholder={formatMessage({ id: 'label.search' })}
            InputProps={{
              startAdornment: <Icon icon={faSearch} />,
            }}
            onChange={(event) => onSearch(event.target.value)}
            variant="outlined"
          />
        </Box>
      </Box>
      <Box className="Db-OverviewDataGridPageTemplate-SecondBar">
        <Breadcrumbs separator={<Icon icon={faChevronRight} />}>
          <Link href="/">
            <Icon icon={faHome} />
          </Link>
          {breadCrumbs}
        </Breadcrumbs>
        <Box className="Db-OverviewDataGridPageTemplate-SecondBar-Actions">
          <Button
            onClick={() => apiRef.current?.showFilterPanel()}
            variant="text"
          >
            <span style={{ fontSize: 12 }}>
              <Icon icon={dbFilter} />
            </span>
            <FormattedMessage id="button.filter" />
          </Button>
        </Box>
      </Box>
      <Box className="Db-OverviewDataGridPageTemplate-DataGrid">
        <DataGridTemplate apiRef={apiRef} {...props} />
      </Box>
      {children}
    </Box>
  );
};

export default styled(OverviewDataGridPageTemplate)(
  ({ theme }) => `
  display: flex;
  flex: 1;
  flex-direction: column;
  
  .Db-OverviewDataGridPageTemplate-TopBar {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 28px;
  
    .MuiFormLabel-root {
      display: none;
    }
    
    .MuiInputBase-root {
      margin-top: 0px;
      border: 1px solid ${colors.gray[300]};
      border-radius: 8px;
      background: ${colors.coolGray[50]};
      padding: 0px 18px;
      height: 40px;
      box-sizing: border-box;
      
      svg {
        color: ${colors.gray[700]};
      }
      
      input {
        color: ${colors.gray[700]};
        background: none;
        border: none;
        height: 40px;
        box-sizing: border-box;
        ::placeholder {
          color: ${colors.gray[700]};
          opacity: 1;
        }
        
        :-ms-input-placeholder {
          color: ${colors.gray[700]};
        }
        
        ::-ms-input-placeholder {
          color: ${colors.gray[700]};
        }
      }
    }
  }

  .Db-OverviewDataGridPageTemplate-SecondBar {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 10px;
    width: 100%;
    background: ${colors.white};
    border-radius: 2px 2px 0px 0px;
    .Db-OverviewDataGridPageTemplate-SecondBar-Actions {
      button {
        svg {
            margin-right: 15px;
        }
        text-transform: uppercase;
        color: ${colors.gray[100]};
      }
    }
  }

  .Db-OverviewDataGridPageTemplate-DataGrid {
    margin-top: 5px;
    padding: 30px 39px;
    background: ${colors.white};
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.1);
    margin-bottom: ${theme.spacing(6)};
  }
`
);

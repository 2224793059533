import { Uuid } from 'ValueObjects';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { User } from 'Models';
import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

export type UserByUserIdRequestProps = {
  userId: Uuid;
};

export const UserByUserIdRequest = async ({
  userId,
}: UserByUserIdRequestProps): Promise<AxiosResponse<User>> =>
  axios.get(`api/users/${userId}`);

export const useUserByUserIdRequestQuery = (
  request: UserByUserIdRequestProps,
  options: UseQueryOptions<
    AxiosResponse<User>,
    AxiosError,
    AxiosResponse<User>
  > = {}
): UseQueryResult<AxiosResponse<User>, AxiosError> =>
  useQuery<AxiosResponse<User>, AxiosError, AxiosResponse<User>>({
    ...options,
    queryFn: async () => UserByUserIdRequest(request),
    queryKey: ['users', 'detail', request.userId],
  });

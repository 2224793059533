import { ReactElement, useEffect } from 'react';
import { CenteredLayoutTemplate } from 'Templates';
import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const SuccessfullyConnectedToOAuthPage = (): ReactElement => {
  useEffect(() => {
    window.close();
  }, []);

  return (
    <CenteredLayoutTemplate>
      <Typography mb={8} variant="h4">
        <FormattedMessage id="oauth.SuccessfullyConnected.title" />
      </Typography>
      <Typography>
        <FormattedMessage id="oauth.SuccessfullyConnected.description" />
      </Typography>
    </CenteredLayoutTemplate>
  );
};

export default SuccessfullyConnectedToOAuthPage;

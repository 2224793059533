import React, { ReactElement, useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCredentialByCredentialIdRequestQuery } from 'Requests';
import { Button, Icon, ReactHookForm } from 'Atoms';
import { Box, Grid, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { getCredentialFormByPackageType } from 'Utils/CredentialInputMapper';
import CredentialDetailsPageTemplate from '../../../Templates/CredentialDetailsPageTemplate/CredentialDetailsPageTemplate';
import {
  AbstractCredentialDto,
  useDeleteCredentialMutation,
  useUpdateCredentialMutation,
} from '../../../Mutations';
import {
  CredentialDetailsInputType,
  CredentialDetailsInputTypeDefaultValues,
  CredentialDetailsInputTypeValidationBuilder,
} from '../../../InputTypes/App/Credential/CredentialDetailsInputType';
import { ConfirmDialog } from '../../../Organisms/ConfirmDialog';

const CredentialDetailsPage = (): ReactElement => {
  const navigate = useNavigate();
  const { credentialId } = useParams();
  const updateCredential = useUpdateCredentialMutation();
  const { mutateAsync: removeCredential } = useDeleteCredentialMutation();

  const [isDeletingCredential, setIsDeletingCredential] =
    useState<boolean>(false);

  const { data: credentialData, refetch } =
    useCredentialByCredentialIdRequestQuery(
      {
        credentialId: credentialId || '',
      },
      {
        enabled: !!credentialId,
      }
    );

  const handleUpdateCredential = useCallback(
    async (values: CredentialDetailsInputType<AbstractCredentialDto>) => {
      await updateCredential.mutateAsync({
        credentialId: values.credentialId,
        package: {
          packageType: values.package.packageType ?? '',
          credentialRequest: null,
          credential: values.package.credential,
          settings: [],
        },
      });

      await refetch();
    },
    [refetch, updateCredential]
  );

  const handleDeleteCredential = useCallback(async () => {
    if (credentialData?.data.credentialId) {
      await removeCredential({
        credentialId: credentialData?.data.credentialId,
      });

      setIsDeletingCredential(false);

      navigate('/credentials');
    }
  }, [credentialData, navigate, removeCredential]);

  if (credentialData?.data && credentialData.data.packageType) {
    return (
      <ReactHookForm<CredentialDetailsInputType<AbstractCredentialDto>>
        defaultValues={{
          ...CredentialDetailsInputTypeDefaultValues(
            credentialData.data.credentialId,
            credentialData.data.packageType,
            credentialData.data
          ),
        }}
        validationSchema={CredentialDetailsInputTypeValidationBuilder(
          credentialData.data.packageType
        )}
        hideBackendErrors
        useProvider
        name="credential"
        onSubmit={handleUpdateCredential}
      >
        <CredentialDetailsPageTemplate
          credential={credentialData.data}
          currentPage="overview"
          actions={
            <>
              <Grid item>
                <Button
                  onClick={() => setIsDeletingCredential(true)}
                  variant="contained"
                  color="error"
                >
                  <FormattedMessage id="button.removeCredential" />
                </Button>
              </Grid>
              <Grid item>
                <Button type="submit" variant="contained" color="secondary">
                  <Icon icon={faCheck} />
                  &nbsp;
                  <FormattedMessage id="button.save" />
                </Button>
              </Grid>
            </>
          }
        >
          <Box className="Db-CredentialDetailsPage-Block">
            <Typography variant="subtitle2">
              <FormattedMessage id="label.authenticationInformation" />
            </Typography>
            <Box maxWidth={816} width="100%" pt={3}>
              <Grid container spacing={2}>
                {getCredentialFormByPackageType(
                  credentialData.data.packageType,
                  'package.credential'
                )}
              </Grid>
            </Box>
          </Box>
        </CredentialDetailsPageTemplate>
        {isDeletingCredential && (
          <ConfirmDialog
            open={isDeletingCredential}
            onCancel={() => setIsDeletingCredential(false)}
            onClose={() => setIsDeletingCredential(false)}
            onConfirm={handleDeleteCredential}
            dangerous
            title={
              <FormattedMessage
                id="label.deleteCredential"
                values={{ name: credentialData.data.name }}
              />
            }
            content={
              <FormattedMessage id="label.areYouSureYouWantToDeleteThisCredential" />
            }
          />
        )}
      </ReactHookForm>
    );
  }

  return <span />;
};

export default CredentialDetailsPage;

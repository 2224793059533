import axios, { AxiosError, AxiosResponse } from 'axios';
import { AbstractTask, isAbstractTask } from 'Models';
import { Pagination } from 'Requests/Pagination';
import { Collection } from 'Requests/Collection';
import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { ErrorResponse, Uuid } from 'ValueObjects';
import Qs from 'qs';
import { Filterable } from 'Requests/Filterable';
import { QueryInvalidator } from 'Requests/QueryInvalidator';

export type TasksByConnectionIdRequestProps = Pagination &
  Filterable & {
    connectionId: Uuid;
  };

type Response = AxiosResponse<Collection<AbstractTask>>;

export const TasksByConnectionIdRequest = async ({
  connectionId,
  ...params
}: TasksByConnectionIdRequestProps): Promise<Response> =>
  axios.get(`api/${connectionId}/tasks`, {
    params: { ...params },
    paramsSerializer: {
      encode: (param) => Qs.parse(param),
      serialize: (param) => Qs.stringify(param, { arrayFormat: 'brackets' }),
    },
  });

export const useTasksByConnectionIdRequest = (
  request: TasksByConnectionIdRequestProps,
  options: UseQueryOptions<Response, AxiosError<ErrorResponse>, Response> = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => TasksByConnectionIdRequest(request),
    queryKey: [
      'tasks',
      'list',
      request.connectionId,
      ...Object.values(request),
    ],
  });

export const TasksByConnectionIdRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isAbstractTask(data)) {
    queryClient.invalidateQueries(['tasks', 'list', data.connectionId]);
  }
};

import { Uuid } from 'ValueObjects';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { AbstractCredential, isAbstractCredential } from 'Models';
import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { QueryInvalidator } from 'Requests/QueryInvalidator';

export type CredentialByCredentialIdRequestProps = {
  credentialId: Uuid;
};

export const CredentialByCredentialIdRequest = async ({
  credentialId,
}: CredentialByCredentialIdRequestProps): Promise<
  AxiosResponse<AbstractCredential>
> => axios.get(`api/credentials/${credentialId}`);

export const useCredentialByCredentialIdRequestQuery = (
  request: CredentialByCredentialIdRequestProps,
  options: UseQueryOptions<
    AxiosResponse<AbstractCredential>,
    AxiosError,
    AxiosResponse<AbstractCredential>
  > = {}
): UseQueryResult<AxiosResponse<AbstractCredential>, AxiosError> =>
  useQuery<
    AxiosResponse<AbstractCredential>,
    AxiosError,
    AxiosResponse<AbstractCredential>
  >({
    ...options,
    queryFn: async () => CredentialByCredentialIdRequest(request),
    queryKey: ['credentials', 'detail', request.credentialId],
  });

export const CredentialByCredentialIdRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isAbstractCredential(data)) {
    queryClient.setQueryData<Response | undefined>(
      ['credentials', 'detail', data.credentialId],
      (previous) => (previous ? { ...previous, data } : undefined)
    );
  }
};

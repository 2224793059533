import { Chip as MuiChip, ChipProps as MuiChipProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { forwardRef, ReactElement } from 'react';
import clsx from 'clsx';
import colors from 'App/colors';

export type ChipProps = MuiChipProps & {
  disableMinWidth?: boolean;
};

const Chip = forwardRef<HTMLDivElement, ChipProps>(
  ({ disableMinWidth, className, ...props }: ChipProps, ref): ReactElement => (
    <MuiChip
      {...props}
      ref={ref}
      className={clsx(className, {
        'Db-Chip-DisableMinWidth': disableMinWidth,
      })}
    />
  )
);

export default styled(Chip)(
  () => `
  text-transform: lowercase;
  border-radius: 6px;
  padding: 2px 10px;
  height: auto;
  background: ${colors.coolGray[100]};
  color: ${colors.coolGray[900]};
  min-width: 72px;

  .MuiChip-label {
    font-weight: 500;
  }
  
  &.MuiChip-filledSuccess {
      background: ${colors.green[100]};
      color: ${colors.green[800]};
  }
  
  &.MuiChip-filledWarning {
      background: ${colors.orange[100]};
      color: ${colors.orange[800]};
  }
  
  &.MuiChip-filledError {
      background: ${colors.red[100]};
      color: ${colors.red[800]};
  }
  
  &.Db-Chip-DisableMinWidth {
    min-width: inherit;
  }
`
);

import * as Yup from 'yup';

export interface DisableTwoFactorAuthenticationInputType {
  password: string;
  code?: string;
}

export const DisableTwoFactorAuthenticationInputTypeValidationBuilder = (
  asSuperAdmin: boolean
): Yup.SchemaOf<DisableTwoFactorAuthenticationInputType> =>
  Yup.object({
    password: Yup.string().required().label('label.password'),
    code: asSuperAdmin
      ? Yup.string().label('label.twoFactorCode')
      : Yup.string().required().label('label.twoFactorCode'),
  });

export const DisableTwoFactorAuthenticationInputTypeDefaultValues =
  (): DisableTwoFactorAuthenticationInputType => ({
    password: '',
    code: '',
  });

import { Uuid } from 'ValueObjects';
import { isObjectResponse } from 'Requests';
import { Response } from './Response';
import { Request } from './Request';
import { Exception } from './Exception';

export interface RequestAndResponse {
  requestId: Uuid;
  connectionId?: Uuid;
  credentialId?: Uuid;
  taskId?: Uuid;
  method: string;
  url: string;
  options?: Request;
  response?: Response;
  exception?: Exception;
  createdAt?: Date;
  updatedAt?: Date;
  mongoId?: string;
  moduleName?: string;
  packageType?: string;
}

export function isRequestAndResponse(
  object: unknown
): object is RequestAndResponse {
  return (
    !!object &&
    isObjectResponse(object) &&
    object['@type'] === 'request-and-response'
  );
}

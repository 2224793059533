import * as Yup from 'yup';

export interface ActivateTwoFactorAuthenticationInputType {
  password: string;
  code?: string;
}

export const ActivateTwoFactorAuthenticationInputTypeValidationBuilder = (
  hasQrCode: boolean
): Yup.SchemaOf<ActivateTwoFactorAuthenticationInputType> =>
  Yup.object({
    password: Yup.string().required().label('label.password'),
    code: Yup.string()
      .when('password', {
        is: (val?: string) => hasQrCode,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema,
      })
      .label('label.twoFactorCode'),
  });

export const ActivateTwoFactorAuthenticationInputTypeDefaultValues =
  (): ActivateTwoFactorAuthenticationInputType => ({
    password: '',
    code: '',
  });

import {
  DefaultValues,
  FieldValues,
  FormProvider,
  useForm,
  SubmitHandler,
} from 'react-hook-form';
import { AnyObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReactElement, ReactNode, useMemo } from 'react';
import { Form } from 'Atoms/Form';
import { useFormBackendHandler } from 'Utils';
import { Typography } from '@mui/material';
import { DevTool } from '@hookform/devtools';

export type ReactHookFormProps<TFieldValues extends FieldValues> = {
  defaultValues?: DefaultValues<TFieldValues>;
  validationSchema?: AnyObjectSchema;
  useProvider?: boolean;
  name: string;
  onSubmit: SubmitHandler<TFieldValues>;
  children: ReactNode;
  hideBackendErrors?: boolean;
};

const ReactHookForm = <TFieldValues extends FieldValues = FieldValues>({
  defaultValues,
  validationSchema,
  children,
  useProvider,
  name,
  onSubmit,
  hideBackendErrors,
}: ReactHookFormProps<TFieldValues>): ReactElement => {
  let resolver;
  if (validationSchema) {
    resolver = yupResolver(validationSchema);
  }

  const form = useForm<TFieldValues>({
    resolver,
    mode: 'all',
    defaultValues,
  });

  let content = children;
  if (useProvider) {
    content = <FormProvider {...form}>{content}</FormProvider>;
  }

  const handleSubmitWithError = useFormBackendHandler(onSubmit, form);

  const backendErrors = useMemo(
    () => form.formState.errors?.backendErrors,
    [form.formState.errors?.backendErrors]
  );

  // TODO remove when go to production
  // eslint-disable-next-line no-console
  console.log(form.formState.errors);

  return (
    <>
      <Form
        style={{ display: 'flex', width: '100%', flexDirection: 'column' }}
        name={name}
        onSubmit={
          form.formState.isSubmitting
            ? undefined
            : form.handleSubmit(handleSubmitWithError)
        }
        noValidate
      >
        {backendErrors &&
          backendErrors.message &&
          Array.isArray(backendErrors.message) &&
          !hideBackendErrors &&
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          backendErrors.message.map((message: any, index: number) => (
            <Typography color="error" key={index}>
              {message}
            </Typography>
          ))}
        {content}
      </Form>
      {/* <DevTool control={form.control} /> */}
    </>
  );
};

export default ReactHookForm;

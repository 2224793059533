import { RecrasCredential } from 'Models/Synchronizer/Credential/RecrasCredential';
import { RecrasCredentialDto } from 'Mutations';
import * as Yup from 'yup';

export type RecrasCredentialInputType = RecrasCredentialDto;

export const RecrasCredentialInputTypeValidationBuilder =
  (): Yup.SchemaOf<RecrasCredentialInputType> =>
    Yup.object({
      name: Yup.string().required().label('label.name'),
      subDomain: Yup.string().required().label('label.subDomain'),
      token: Yup.string().required().label('label.token'),
    });

export const RecrasCredentialInputTypeDefaultValues = (
  credential?: RecrasCredential
): RecrasCredentialInputType => {
  if (credential) {
    return {
      name: credential.name,
      subDomain: credential.extraData.subDomain,
      token: credential.extraData.token,
    };
  }

  return {
    name: '',
    subDomain: '',
    token: '',
  };
};

import { ReactElement, ReactNode } from 'react';
import { Box, styled } from '@mui/material';
import clsx from 'clsx';
import watermark from 'Images/Background/watermark.svg';

export type WatermarkedTemplateProps = {
  className?: string;
  children: ReactNode | ReactNode[];
};

const WatermarkedTemplate = ({
  children,
  className,
}: WatermarkedTemplateProps): ReactElement => (
  <Box className={clsx('Db-WatermarkedTemplate', className)}>
    <Box className="Db-WatermarkedTemplate-Content">{children}</Box>
    <Box className="Db-WatermarkedTemplate-Watermark" />
  </Box>
);

export default styled(WatermarkedTemplate)(
  ({ theme }) => `
  height: 100%;
  background: ${theme.palette.background.default};
  position: relative;
  overflow: auto;
  .Db-WatermarkedTemplate-Content {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
  }

  .Db-WatermarkedTemplate-Watermark {
    opacity: 0.4;
    background: url(${watermark});
    background-repeat: no-repeat; 
    background-size: cover;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 50%;
    z-index: 1;
  }
`
);

import { EventSourcePolyfill } from 'event-source-polyfill';
import { Uuid } from 'ValueObjects';

// eslint-disable-next-line import/prefer-default-export
export const subscribeToMercure = (
  mercureToken: string,
  organizationIds: Uuid[]
): EventSource => {
  if (!process.env.REACT_APP_MERCURE_URL) {
    throw Error('No mercure url set');
  }

  const url = new URL(process.env.REACT_APP_MERCURE_URL);

  organizationIds.forEach((organizationId) => {
    url.searchParams.append('topic', organizationId);
  });

  return new EventSourcePolyfill(url.toString(), {
    headers: {
      Authorization: `Bearer ${mercureToken}`,
    },
  });
};

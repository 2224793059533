import { ReactElement, useMemo } from 'react';
import { TaskStatus } from 'Models';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faHourglassClock,
  faPauseCircle,
  faClockRotateLeft,
} from '@fortawesome/pro-light-svg-icons';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { Chip, ChipProps } from 'Atoms';

export type TaskStatusChipProps = ChipProps & {
  status: TaskStatus;
  className?: string;
};

const colorMapping: {
  [key: string]:
    | 'default'
    | 'success'
    | 'secondary'
    | 'error'
    | 'warning'
    | 'primary'
    | 'info'
    | undefined;
} = {
  [TaskStatus.COMPLETE]: 'success',
  [TaskStatus.QUEUED]: 'default',
  [TaskStatus.FAILED]: 'error',
  [TaskStatus.CANCELLED]: 'info',
  [TaskStatus.PENDING]: 'info',
  [TaskStatus.PROCESSING]: 'info',
  [TaskStatus.TIMEOUT]: 'warning',
};
const TaskStatusChip = ({
  status,
  className,
  ...props
}: TaskStatusChipProps): ReactElement => {
  const icon = useMemo(() => {
    if (status === TaskStatus.COMPLETE) {
      return <FontAwesomeIcon icon={faCheckCircle} />;
    }
    if (status === TaskStatus.QUEUED) {
      return <FontAwesomeIcon icon={faPauseCircle} />;
    }
    if (status === TaskStatus.FAILED) {
      return <FontAwesomeIcon icon={faExclamationCircle} />;
    }
    if (status === TaskStatus.CANCELLED) {
      return <FontAwesomeIcon icon={faExclamationTriangle} />;
    }
    if (status === TaskStatus.PENDING) {
      return <FontAwesomeIcon icon={faClockRotateLeft} />;
    }
    if (status === TaskStatus.PROCESSING) {
      return <FontAwesomeIcon icon={faSpinnerThird} spin />;
    }
    if (status === TaskStatus.TIMEOUT) {
      return <FontAwesomeIcon icon={faHourglassClock} />;
    }

    return undefined;
  }, [status]);
  return (
    <Chip
      label={<FormattedMessage id={`taskStatus.${status}`} />}
      variant="filled"
      color={colorMapping[status] || 'default'}
      icon={icon}
      className={className}
      {...props}
    />
  );
};

export default TaskStatusChip;

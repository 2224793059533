import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from 'Molecules';
import { DialogContentText } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'Atoms';

export type ConfirmDialogProps = Omit<DialogProps, 'title'> & {
  onCancel: () => void | Promise<void>;
  onConfirm: () => void | Promise<void>;
  title: ReactNode | string;
  content: ReactNode | string;
  extraActions?: ReactNode;
  confirmLabel?: ReactNode | string;
  dangerous?: boolean;
};

const ConfirmDialog = ({
  title,
  content,
  onCancel,
  onConfirm,
  dangerous,
  extraActions,
  confirmLabel,
  ...args
}: ConfirmDialogProps): ReactElement => (
  <Dialog
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    fullWidth
    maxWidth="sm"
    {...args}
  >
    <DialogTitle
      id="alert-dialog-title"
      sx={{
        color: (theme) =>
          dangerous ? `${theme.palette.error.main} !important` : undefined,
      }}
    >
      {title}
    </DialogTitle>
    <DialogContent>
      <DialogContentText>{content}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel} autoFocus variant="outlined" color="secondary">
        <FormattedMessage id="button.cancel" />
      </Button>
      {extraActions}
      <Button
        onClick={onConfirm}
        autoFocus
        color={dangerous ? 'error' : 'primary'}
      >
        {confirmLabel || <FormattedMessage id="button.confirm" />}
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmDialog;

import * as Yup from 'yup';
import { AbstractCredential, Package, PackageType } from 'Models';
import {
  getCredentialInputTypeDefaultValuesByPackageType,
  getCredentialInputTypeValidationBuilderByPackageType,
} from 'Utils/CredentialInputMapper';
import { AbstractCredentialDto } from '../../../Mutations';

export interface CredentialDetailsInputType<
  CredentialType extends AbstractCredentialDto
> {
  credentialId: string;
  package: Package<CredentialType>;
}

export const CredentialDetailsInputTypeValidationBuilder = (
  packageType: PackageType | undefined
): Yup.SchemaOf<CredentialDetailsInputType<AbstractCredentialDto>> =>
  Yup.object({
    credentialId: Yup.string().required(),
    package: Yup.object()
      .shape({
        credentialId: Yup.string().required(),
        packageType: Yup.string().oneOf(Object.values(PackageType)).required(),
        credential:
          getCredentialInputTypeValidationBuilderByPackageType(packageType),
      })
      .required(),
  });

export const CredentialDetailsInputTypeDefaultValues = (
  credentialId: string,
  packageType: PackageType,
  credential: AbstractCredential
): CredentialDetailsInputType<AbstractCredentialDto> => ({
  credentialId,
  package: {
    credentialId,
    packageType,
    credential: getCredentialInputTypeDefaultValuesByPackageType(
      packageType,
      credential
    ),
  },
});

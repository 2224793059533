import { ExactOnlineCredential } from 'Models';
import { ExactOnlineCredentialDto } from 'Mutations';
import * as Yup from 'yup';

export type ExactOnlineCredentialInputType = ExactOnlineCredentialDto;

// TODO this can be ENUM
export const ExactOnlineSupportedCountries = ['NL', 'BE', 'DE', 'UK'];

export const ExactOnlineCredentialInputTypeValidationBuilder =
  (): Yup.SchemaOf<ExactOnlineCredentialInputType> =>
    Yup.object({
      name: Yup.string().required().label('label.name'),
      country: Yup.string()
        .required()
        .label('label.country')
        .oneOf(ExactOnlineSupportedCountries),
    });

export const ExactOnlineCredentialInputTypeDefaultValues = (
  credential?: ExactOnlineCredential
): ExactOnlineCredentialInputType => {
  if (credential) {
    return {
      name: credential.name,
      country: credential.extraData.country || 'NL',
    };
  }

  return {
    name: '',
    country: 'NL',
  };
};

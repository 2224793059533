import {
  MenuList as MuiMenuList,
  MenuListProps as MuiMenuListProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { forwardRef, ReactElement } from 'react';

export type MenuListProps = MuiMenuListProps;

const MenuList = forwardRef<HTMLUListElement, MenuListProps>(
  ({ ...props }: MenuListProps, ref): ReactElement => (
    <MuiMenuList {...props} ref={ref} />
  )
);

MenuList.defaultProps = {};

export default styled(MenuList)(
  () => `
  padding: 0;
`
);

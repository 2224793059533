import { ReactElement, ReactNode, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  useConnectionByConnectionIdRequestQuery,
  useRequestAndResponseListRequest,
} from 'Requests';
import {
  Box,
  BoxProps,
  Grid,
  IconButton,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import { Icon } from 'Atoms';
import {
  faCheck,
  faCopy,
  faInfoCircle,
} from '@fortawesome/pro-light-svg-icons';
import colors from 'App/colors';
import { FormattedDate, FormattedMessage, FormattedNumber } from 'react-intl';
import { DataGridTemplate } from 'Templates/DataGridTemplate';
import useDataGridDataWithReactQuery from 'Utils/useDataGridDataWithReactQuery';
import { RequestAndResponse } from 'Models';
import { Uuid } from 'ValueObjects';
import dayjs from 'dayjs';
import {
  ConnectionDetailsHeader,
  RequestAndResponseDetailsModal,
} from 'Organisms';
import { useConnectionAlerts } from 'Utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ConnectionRequestsPage = ({ ...props }: BoxProps): ReactElement => {
  const { connectionId } = useParams();
  const [openRequestAndResponse, setShowRequestAndResponse] =
    useState<RequestAndResponse>();
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const { data, isInitialLoading } = useConnectionByConnectionIdRequestQuery(
    {
      connectionId: connectionId || '',
    },
    {
      enabled: !!connectionId,
    }
  );

  const handleCopyContentToClipboard = useCallback(async (content: unknown) => {
    await navigator.clipboard.writeText(JSON.stringify(content, null, 2));
    setIsCopied(true);
    await new Promise((r) => {
      setTimeout(r, 1050);
    });
    setIsCopied(false);
  }, []);

  const renderToolTipCopyMessage = useCallback(
    (title: ReactNode) => {
      if (isCopied) {
        return (
          <span>
            <FontAwesomeIcon icon={faCheck} />
            <FormattedMessage id="label.copied" />
          </span>
        );
      }

      return title;
    },
    [isCopied]
  );

  const tasksGridData = useDataGridDataWithReactQuery<
    RequestAndResponse,
    { connectionId: Uuid }
  >({
    overviewPage: true,
    request: {
      connectionId: connectionId || '',
    },
    requestOptions: {
      enabled: !!connectionId,
    },
    reactQuery: useRequestAndResponseListRequest,
    autoHeight: false,
    getRowId: (row) => row.requestId,
    columns: [
      {
        field: 'taskId',
        headerName: 'label.taskId',
        minWidth: 350,
        flex: 1,
      },
      {
        field: 'moduleName',
        headerName: 'label.module',
        minWidth: 350,
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (
          <FormattedMessage
            id={`moduleTypes.${row.packageType}.${row.moduleName}`}
            defaultMessage={row.moduleName}
          />
        ),
      },
      {
        field: 'createdAt',
        headerName: 'label.requestedAt',
        minWidth: 150,
        flex: 0.4,
        renderCell: ({ row: { createdAt } }) =>
          createdAt ? (
            <FormattedDate
              value={dayjs(createdAt).toDate()}
              dateStyle="short"
              timeStyle="short"
            />
          ) : (
            <FormattedMessage id="label.unknown" />
          ),
      },
      {
        field: 'method',
        headerName: 'label.method',
        minWidth: 75,
        flex: 0.2,
      },
      {
        field: 'url',
        headerName: 'label.url',
        minWidth: 450,
        flex: 1,
      },
      {
        field: 'response.statusCode',
        headerName: 'label.responseCode',
        minWidth: 150,
        flex: 0.2,
        renderCell: ({ row: { response } }) => response?.statusCode,
      },
      {
        field: 'response.info.total_time',
        headerName: 'label.requestDuration',
        minWidth: 175,
        flex: 0.2,
        renderCell: ({ row: { response } }) =>
          response?.info?.total_time && (
            <FormattedNumber
              value={response.info.total_time}
              style="unit"
              unit="second"
              unitDisplay="long"
            />
          ),
      },
      {
        field: 'actions',
        headerName: 'label.actions',
        type: 'actions',
        headerAlign: 'left',
        minWidth: 150,
        sortable: false,

        flex: 0.2,
        renderCell: ({ row }) => (
          <Grid container spacing={2}>
            <Grid item>
              <Tooltip
                title={renderToolTipCopyMessage(
                  <FormattedMessage id="button.copyRequest" />
                )}
              >
                <IconButton
                  onClick={() => handleCopyContentToClipboard(row.options)}
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  size="small"
                  sx={{ fontSize: 13.5 }}
                >
                  <Icon icon={faCopy} />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip
                title={renderToolTipCopyMessage(
                  <FormattedMessage id="button.copyResponse" />
                )}
              >
                <IconButton
                  onClick={() => handleCopyContentToClipboard(row.response)}
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  size="small"
                  sx={{ fontSize: 13.5 }}
                >
                  <Icon icon={faCopy} />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip
                title={<FormattedMessage id="button.showRequestAndResponse" />}
              >
                <IconButton
                  onClick={() => setShowRequestAndResponse(row)}
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  size="small"
                  sx={{ fontSize: 13.5 }}
                >
                  <Icon icon={faInfoCircle} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        ),
      },
    ],
  });

  const connection = useMemo(() => data?.data, [data]);
  const alerts = useConnectionAlerts({ connection });

  if (!connection || isInitialLoading) {
    return <div />;
  }

  return (
    <Box {...props}>
      <ConnectionDetailsHeader
        connection={connection}
        alerts={alerts}
        currentPage="requests"
      />
      <Box className="Db-ConnectionRequestsPage-Content">
        <Grid
          container
          spacing={{
            xs: 3,
            md: 9,
          }}
        >
          <Grid item xs={12}>
            <Typography variant="h6">
              <FormattedMessage id="label.connection" />
              &nbsp;
              <span className="Db-ConnectionRequestsPage-Content-Bold">
                <FormattedMessage id="label.requests" />
              </span>
            </Typography>
            <DataGridTemplate {...tasksGridData} />
          </Grid>
        </Grid>
      </Box>
      <Box className="Db-ConnectionRequestsPage-Footer" />
      {openRequestAndResponse && (
        <RequestAndResponseDetailsModal
          requestAndResponse={openRequestAndResponse}
          open={!!openRequestAndResponse}
          onClose={() => setShowRequestAndResponse(undefined)}
        />
      )}
    </Box>
  );
};

export default styled(ConnectionRequestsPage)(`
display: flex;
flex-direction: column;
width: 100%;

.Db-ConnectionRequestsPage-Content {
  background: ${colors.white};
  margin-top: 32px;
  padding: 16px 32px;
  padding-bottom: 64px;

  & > .MuiGrid-container {
    height: 750px;
    .MuiDataGrid-main {
      margin-bottom: 16px;
    }
  }

  .MuiTypography-h6 {
    margin-left: 10px;
    margin-bottom: 18px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
  }

  .Db-ConnectionRequestsPage-Content-Bold {
    font-weight: bold;
  }
}

.Db-ConnectionRequestsPage-Footer { 
  height: 32px;
}

.MuiButtonGroup-root .MuiButton-sizeLarge {
  background: ${colors.white};
}
`);

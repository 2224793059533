import { ReactElement, ReactNode } from 'react';
import {
  Breadcrumbs,
  Button,
  Icon,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonProps,
} from 'Atoms';
import { Box, Link, styled } from '@mui/material';
import {
  faChevronRight,
  faHome,
  faSearch,
} from '@fortawesome/pro-light-svg-icons';
import colors from 'App/colors';
import {
  DataGridTemplate,
  DataGridTemplateProps,
} from 'Templates/DataGridTemplate';
import { FormattedMessage, useIntl } from 'react-intl';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import dbFilter from 'Icons/dbFilter';

export type DetailsWithActionsPageTemplateProps = {
  className?: string;
  actions?: ToggleButtonProps[];
  children?: ReactNode;
  currentPage: string;
};

const DetailsWithActionsPageTemplate = ({
  actions,
  className,
  children,
  currentPage,
}: DetailsWithActionsPageTemplateProps): ReactElement => (
  <Box className={className}>
    {(actions?.length || 0) > 0 && (
      <Box className="Db-DetailsWithActionsPageTemplate-TopBar">
        <Box className="Db-DetailsWithActionsPageTemplate-TopBar-Actions">
          <ToggleButtonGroup exclusive value={currentPage}>
            {(actions || []).map((action, index) => (
              <ToggleButton {...action} key={index} />
            ))}
          </ToggleButtonGroup>
        </Box>
      </Box>
    )}
    <Box className="Db-DetailsWithActionsPageTemplate-Content">{children}</Box>
  </Box>
);

export default styled(DetailsWithActionsPageTemplate)(
  ({ theme }) => `
  display: flex;
  flex: 1;
  flex-direction: column;
  
  .Db-DetailsWithActionsPageTemplate-TopBar {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: ${theme.spacing(3)};
  }

  .Db-DetailsWithActionsPageTemplate-Content {
  }
`
);

import { FieldValues } from 'react-hook-form';
import { cloneElement, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactHookWizardStep } from 'Atoms';
import { PackageType } from 'Models';
import { getFinishOnboardingForm } from 'Utils/FinishingOnboardingMapper';
import { getSettingsForm } from './SettingsFormMapper';
import { getCredentialStepsByPackageType } from './CredentialFormMapper';

export type UseDynamicStepsForConnectionResult<
  FormInput extends FieldValues = FieldValues
> = {
  credentialSteps: ReactHookWizardStep<FormInput>[];
  settingsSteps: ReactHookWizardStep<FormInput>[];
  finishingSteps: ReactHookWizardStep<FormInput>[];
};

export type UseDynamicStepsForConnectionProps = {
  packages: PackageType[];
};

export const useDynamicStepsForConnection = <
  FormInput extends FieldValues = FieldValues
>({
  packages,
}: UseDynamicStepsForConnectionProps): UseDynamicStepsForConnectionResult<FormInput> => {
  const settingsSteps: ReactHookWizardStep<FormInput>[] = useMemo(
    () =>
      getSettingsForm(packages).map((mapping) => ({
        title: <FormattedMessage id="onboarding.steps.settings" />,
        categoryTitle: <FormattedMessage id="onboarding.steps.settings" />,
        category: 'settings',
        render: ({ ...args }) => cloneElement(mapping.form, args),
        validationSchema: mapping.validation(),
      })),
    [packages]
  );

  const credentialSteps: ReactHookWizardStep<FormInput>[] = useMemo(() => {
    const result: ReactHookWizardStep<FormInput>[] = [];
    packages.forEach((packageType) => {
      result.push(...getCredentialStepsByPackageType<FormInput>(packageType));
    });

    return result;
  }, [packages]);

  const finishingSteps: ReactHookWizardStep<FormInput>[] = useMemo(
    () =>
      getFinishOnboardingForm(packages).map((mapping) => ({
        title: <FormattedMessage id="onboarding.steps.runConnection" />,
        categoryTitle: <FormattedMessage id="onboarding.steps.runConnection" />,
        category: 'runConnection',
        render: ({ ...args }) => cloneElement(mapping.element, args),
        validationSchema: mapping.validation ? mapping.validation() : undefined,
      })),
    [packages]
  );

  return {
    credentialSteps,
    settingsSteps,
    finishingSteps,
  };
};

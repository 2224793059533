import { ErrorResponse, Uuid } from 'ValueObjects';
import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
} from '@tanstack/react-query';

export type AddAccessToOrganizationsMutationProps = {
  userId: Uuid;
  organizationsIds: Uuid[];
};

export const AddAccessToOrganizationsMutation = async ({
  ...values
}: AddAccessToOrganizationsMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/users/add-access`, {
    ...values,
  });

export const useAddAccessToOrganizationsMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddAccessToOrganizationsMutationProps
  > = {}
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  AddAccessToOrganizationsMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddAccessToOrganizationsMutationProps
  >(AddAccessToOrganizationsMutation, {
    useErrorBoundary: false,
    ...options,
  });

import {
  styled,
  ToggleButtonProps as MuiToggleButtonProps,
  ToggleButton as MuiToggleButton,
} from '@mui/material';
import { ReactElement, useCallback, MouseEvent } from 'react';
import colors from 'App/colors';
import { useNavigate } from 'react-router-dom';

export type ToggleButtonProps = MuiToggleButtonProps & {
  to?: string;
};

const ToggleButton = ({
  children,
  to,
  onClick,
  ...props
}: ToggleButtonProps): ReactElement => {
  const navigate = useNavigate();
  const handleClick = useCallback(
    (event: MouseEvent<HTMLElement>, value: unknown) => {
      if (to) {
        navigate(to);
        return;
      }
      onClick?.(event, value);
    },
    [navigate, onClick, to]
  );

  return (
    <MuiToggleButton onClick={handleClick} {...props}>
      {children}
    </MuiToggleButton>
  );
};

ToggleButton.defaultProps = {};

export default styled(ToggleButton)(
  () => `
  cursor: pointer;
  background: ${colors.white};
  box-sizing: border-box;
  border: none;
  height: 40px;
  padding: 0px 20px;
  box-sizing: border-box;
  text-transform: lowercase; 
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.87);
  margin-left: 1px !important;
    
  &.Mui-selected {
    color: ${colors.primary.main};
    background: #EAF2F9;
  }
  
  &:hover {
    color: ${colors.primary.main};
    background: #EAF2F9;
  }
`
);

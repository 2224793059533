import axios, { AxiosError, AxiosResponse } from 'axios';
import { EmailAddress, ErrorResponse, Uuid } from 'ValueObjects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';

export type LoginMutationProps = {
  emailAddress: EmailAddress;
  password: string;
};

export type LoginMutationResponse = {
  userId: Uuid;
  token: string;
  mercureToken: string;
  twoFactorNeeded: boolean;
};

export const LoginMutation = async ({
  emailAddress,
  password,
}: LoginMutationProps): Promise<AxiosResponse<LoginMutationResponse>> =>
  axios.post(`api/auth/login`, { email: emailAddress, password });

export const useLoginMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    LoginMutationProps
  >
): UseMutationResult<
  AxiosResponse<LoginMutationResponse>,
  AxiosError<ErrorResponse>,
  LoginMutationProps
> =>
  useMutation<
    AxiosResponse<LoginMutationResponse>,
    AxiosError<ErrorResponse>,
    LoginMutationProps
  >(LoginMutation, {
    ...options,
    useErrorBoundary: (error) => (error?.response?.status || 500) >= 500,
  });

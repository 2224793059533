import { ReactElement } from 'react';
import { Grid } from '@mui/material';
import { Controller } from 'react-hook-form';
import { MenuItem, ReactHookFormTextField } from 'Atoms';
import { FormattedMessage, useIntl } from 'react-intl';
import { Role } from 'Models';

export type UserProfileFormProps = {
  hidePassword?: boolean;
  allowEditingRole?: boolean;
};

const UserProfileForm = ({
  hidePassword,
  allowEditingRole,
}: UserProfileFormProps): ReactElement => {
  const intl = useIntl();

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Controller
          name="firstName"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={intl.formatMessage({ id: 'label.firstName' })}
            />
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          name="middleName"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={intl.formatMessage({ id: 'label.middleName' })}
            />
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          name="lastName"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={intl.formatMessage({ id: 'label.lastName' })}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="emailAddress"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              type="email"
              label={intl.formatMessage({ id: 'label.emailAddress' })}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        {allowEditingRole && (
          <Controller
            name="role"
            render={(registered) => (
              <ReactHookFormTextField
                {...registered}
                fullWidth
                label={intl.formatMessage({ id: 'label.role' })}
                select
              >
                {Object.values(Role).map((role) => (
                  <MenuItem key={role} value={role}>
                    <FormattedMessage id={`userRoles.${role}`} />
                  </MenuItem>
                ))}
              </ReactHookFormTextField>
            )}
          />
        )}
      </Grid>
      {!hidePassword && (
        <>
          <Grid item xs={6}>
            <Controller
              name="oldPassword"
              render={(registered) => (
                <ReactHookFormTextField
                  {...registered}
                  fullWidth
                  type="password"
                  label={intl.formatMessage({ id: 'label.oldPassword' })}
                  placeholder="••••••••"
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="newPassword"
              render={(registered) => (
                <ReactHookFormTextField
                  {...registered}
                  fullWidth
                  type="password"
                  label={intl.formatMessage({ id: 'label.password' })}
                  placeholder="••••••••"
                />
              )}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
export default UserProfileForm;

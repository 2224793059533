import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material';
import { FunctionComponent } from 'react';

export type IconProps = FontAwesomeIconProps;

const Icon: FunctionComponent<IconProps> = ({ icon, ...args }: IconProps) => (
  <FontAwesomeIcon {...args} icon={icon} />
);

Icon.defaultProps = {
  className: undefined,
};

export default styled(Icon)(() => ``);

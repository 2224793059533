import { ReactElement, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Grid, Link, styled, Typography } from '@mui/material';
import { Alert, Button, Form } from 'Atoms';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import axios from 'axios';
import { AuthState } from 'State';
import {
  LoginForm,
  LoginFormInitialValues,
  LoginFormValidationSchemaBuilder,
  LoginInput,
} from 'Forms';
import {
  LoginMutationResponse,
  useLoginMutation,
  useTwoFactorLoginMutation,
} from 'Mutations';
import { AuthenticationTemplate } from 'Templates';
import { reactHookFormBackendErrorHandler } from 'Utils';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import colors from 'App/colors';
import logoBlue from 'Images/logo-blue.svg';

export type LoginPageProps = {
  className?: string;
};

const LoginPage = ({ className }: LoginPageProps): ReactElement => {
  const navigate = useNavigate();

  const [requireTwoFactorCode, setRequireTwoFactorCode] =
    useState<boolean>(false);

  const [{ initialPath }, setAuthState] = useRecoilState(AuthState);

  const handleAuthenticated = async (data: LoginMutationResponse) => {
    console.log(data);
    setAuthState(data);
    await new Promise((r) => {
      setTimeout(r, 150);
    });
    navigate(initialPath || '/');
  };

  const loginUser = useLoginMutation({
    onSuccess: async ({ data }) => {
      if (data.twoFactorNeeded) {
        setRequireTwoFactorCode(true);

        return;
      }

      await handleAuthenticated(data);
    },
  });

  const handleTwoFactorCode = useTwoFactorLoginMutation({
    onSuccess: async ({ data }) => {
      await handleAuthenticated(data);
    },
  });

  const form = useForm<LoginInput>({
    resolver: yupResolver(
      LoginFormValidationSchemaBuilder({
        requireTwoFactorCode,
      })
    ),
    mode: 'all',
    defaultValues: LoginFormInitialValues,
    shouldFocusError: true,
  });

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
  } = form;

  const handleLogin = useCallback(
    async (values: LoginInput) => {
      try {
        const loginResponse = await loginUser.mutateAsync(values);

        if (values.twoFactorCode) {
          await handleTwoFactorCode.mutateAsync({
            ...values,
            token: loginResponse.data.token,
            code: values.twoFactorCode,
          });
        }
      } catch (exception) {
        if (axios.isAxiosError(exception)) {
          reactHookFormBackendErrorHandler<LoginInput>(exception, form);
          return;
        }

        throw exception;
      }
    },
    [form, handleTwoFactorCode, loginUser]
  );

  return (
    <AuthenticationTemplate>
      <Box className={className}>
        <Box className="Db-LoginPage-Header">
          <Typography variant="h3" className="Db-LoginPage-Title">
            <FormattedMessage id="login.title" />
          </Typography>
          <Typography className="Db-LoginPage-Subtitle">
            <FormattedMessage id="login.subtitle" />
          </Typography>
        </Box>
        <Box className="Db-LoginPage-Form">
          <Form name="login" noValidate onSubmit={handleSubmit(handleLogin)}>
            <FormProvider {...form}>
              <Grid container rowSpacing={2} alignItems="center">
                {errors.status && (
                  <Grid item xs={12}>
                    <Alert severity="warning">
                      <FormattedMessage id={errors.status?.message} />
                    </Alert>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <LoginForm requireTwoFactorCode={requireTwoFactorCode} />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    loading={isSubmitting}
                    disableRipple
                    className="Db-LoginPage-Login"
                    fullWidth
                    color="primary"
                  >
                    <FormattedMessage id="button.login" />
                  </Button>
                </Grid>

                <Grid item xs={12} justifyContent="center" container>
                  <span className="Db-LoginPage-Signup-Question">
                    <FormattedMessage id="signup-questions" />
                  </span>
                  <Link
                    className="Db-LoginPage-Signup"
                    component={RouterLink}
                    to="/sign-up"
                  >
                    <FormattedMessage id="button.signup" />
                  </Link>
                </Grid>
              </Grid>
            </FormProvider>
          </Form>
        </Box>
      </Box>
    </AuthenticationTemplate>
  );
};

export default styled(LoginPage)(
  ({ theme }) => `
  .Db-LoginPage-Header {
      margin-bottom: ${theme.spacing(4)};
      .Db-LoginPage-Title {
        color: ${colors.gray[900]};
        margin-bottom: 12px;
      }
      .Db-LoginPage-Subtitle {
        color: ${colors.gray[500]};
      }
  }
  
  .Db-LoginPage-Signup-Question {
    color: ${colors.gray[500]};
    font-size: 14px;
    line-height: 20px;
  }
  
  .Db-LoginPage-Signup {
    color: ${colors.primary[700]};
    margin-left: 4px;
    font-size: 14px;
    line-height: 20px;
    text-decoration: none;
    font-weight: 500;
  }
 
  .Db-LoginPage-Form {
    max-width: 360px;
    
    .Db-LoginPage-Login {
      border-radius: 8px;
      height: 52px;
    }
  }
  `
);

import { ReactElement, useCallback, useState } from 'react';
import { UserProfilePageTemplate } from 'Templates';
import { useRecoilState } from 'recoil';
import { UserState } from 'State';
import { ReactHookForm } from 'Atoms';
import {
  DisableTwoFactorAuthenticationInputType,
  UserProfileInputType,
  UserProfileInputTypeDefaultValues,
  UserProfileInputTypeValidationBuilder,
  ActivateTwoFactorAuthenticationInputType,
  UploadProfilePictureInputType,
} from 'InputTypes';
import {
  useDisableTwoFactorMutation,
  useEnableTwoFactorMutation,
  useUpdateProfileEmailMutation,
  useUpdateProfileMutation,
  useUpdateProfilePasswordMutation,
  useUpdateProfilePictureMutation,
} from 'Mutations';
import {
  ActivateTwoFactorAuthenticationFormDialog,
  DisableTwoFactorAuthenticationFormDialog,
} from 'Dialogs';
import { useNavigate } from 'react-router-dom';
import { UploadProfilePictureFormDialog } from 'Organisms';
import { UserByUserIdRequest } from 'Requests';

const ProfilePage = (): ReactElement => {
  const [updatingProfileImage, setUpdatingProfileImage] =
    useState<boolean>(false);
  const [activatingTwoFactor, setActivatingTwoFactor] =
    useState<boolean>(false);
  const [disablingTwoFactor, setDisablingTwoFactor] = useState<boolean>(false);

  const [userState, setUserState] = useRecoilState(UserState);

  const { mutateAsync: updateUserProfile } = useUpdateProfileMutation();
  const { mutateAsync: updateProfilePicture } =
    useUpdateProfilePictureMutation();
  const { mutateAsync: updateEmail } = useUpdateProfileEmailMutation();
  const { mutateAsync: updatePassword } = useUpdateProfilePasswordMutation();
  const { mutateAsync: enableTwoFactor } = useEnableTwoFactorMutation();
  const { mutateAsync: disableTwoFactor } = useDisableTwoFactorMutation();

  const navigate = useNavigate();

  const handleUpdateProfile = useCallback(
    async (values: UserProfileInputType) => {
      if (!userState) {
        return;
      }

      let logout = false;
      if (values.emailAddress !== userState.emailAddress) {
        await updateEmail({
          userId: userState.userId,
          emailAddress: values.emailAddress,
        });
        logout = true;
      }

      if (values.newPassword && values.oldPassword) {
        await updatePassword({
          userId: userState.userId,
          newPassword: values.newPassword,
          oldPassword: values.oldPassword,
        });
        logout = true;
      }

      if (
        values.firstName !== userState.personName.firstName ||
        values.middleName !== userState.personName.middleName ||
        values.lastName !== userState.personName.lastName
      ) {
        await updateUserProfile({
          userId: userState.userId,
          firstName: values.firstName,
          middleName: values.middleName,
          lastName: values.lastName,
        });
      }

      setUserState((currValue) =>
        currValue
          ? {
              ...currValue,
              emailAddress: values.emailAddress,
              personName: {
                firstName: values.firstName,
                middleName: values.middleName,
                lastName: values.lastName,
                fullName: values.middleName
                  ? `${values.firstName} ${values.middleName} ${values.lastName}`
                  : `${values.firstName} ${values.lastName}`,
              },
            }
          : undefined
      );

      if (logout) {
        navigate('/logout');
      }
    },
    [
      navigate,
      setUserState,
      updateEmail,
      updatePassword,
      updateUserProfile,
      userState,
    ]
  );

  const handleUpdateProfilePicture = useCallback(
    async ({ fileId }: UploadProfilePictureInputType) => {
      await updateProfilePicture({
        userId: userState?.userId || '',
        profilePictureId: fileId,
      });
      if (userState?.userId) {
        const { data } = await UserByUserIdRequest({
          userId: userState?.userId,
        });

        setUserState(data);
      }

      setUpdatingProfileImage(false);
    },
    [setUserState, updateProfilePicture, userState?.userId]
  );

  const handleEnableTwoFactor = useCallback(
    async (values: ActivateTwoFactorAuthenticationInputType) => {
      await enableTwoFactor({
        userId: userState?.userId || '',
        code: values.code || '',
      });

      setUserState((currValue) =>
        currValue
          ? {
              ...currValue,
              totpAuthenticationEnabled: true,
            }
          : undefined
      );
      setActivatingTwoFactor(false);
    },
    [enableTwoFactor, setUserState, userState?.userId]
  );

  const handleDisableTwoFactor = useCallback(
    async (values: DisableTwoFactorAuthenticationInputType) => {
      await disableTwoFactor({
        userId: userState?.userId || '',
        code: values.code || '',
        password: values.password || '',
      });

      setUserState((currValue) =>
        currValue
          ? {
              ...currValue,
              totpAuthenticationEnabled: false,
            }
          : undefined
      );
      setDisablingTwoFactor(false);
    },
    [disableTwoFactor, setUserState, userState?.userId]
  );

  if (!userState) {
    return <span />;
  }

  return (
    <ReactHookForm<UserProfileInputType>
      defaultValues={{
        ...UserProfileInputTypeDefaultValues(),
        firstName: userState.personName.firstName,
        middleName: userState.personName.middleName,
        lastName: userState.personName.lastName,
        emailAddress: userState.emailAddress,
      }}
      validationSchema={UserProfileInputTypeValidationBuilder()}
      hideBackendErrors
      useProvider
      name="profile"
      onSubmit={handleUpdateProfile}
    >
      <UserProfilePageTemplate
        user={userState}
        onUpdateProfileImage={() => setUpdatingProfileImage(true)}
        onChangeTwoFactorAuthentication={() =>
          userState?.totpAuthenticationEnabled
            ? setDisablingTwoFactor(true)
            : setActivatingTwoFactor(true)
        }
        allowLogout
      />
      {activatingTwoFactor && (
        <ActivateTwoFactorAuthenticationFormDialog
          open={activatingTwoFactor}
          onClose={() => setActivatingTwoFactor(false)}
          onSubmit={handleEnableTwoFactor}
        />
      )}
      {disablingTwoFactor && (
        <DisableTwoFactorAuthenticationFormDialog
          open={disablingTwoFactor}
          onClose={() => setDisablingTwoFactor(false)}
          onSubmit={handleDisableTwoFactor}
        />
      )}
      {updatingProfileImage && (
        <UploadProfilePictureFormDialog
          open={updatingProfileImage}
          onSubmit={handleUpdateProfilePicture}
          onClose={() => setUpdatingProfileImage(false)}
        />
      )}
    </ReactHookForm>
  );
};

export default ProfilePage;

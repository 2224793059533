import axios, { AxiosError, AxiosResponse } from 'axios';
import { AbstractCredential, isAbstractCredential } from 'Models';
import { Pagination } from 'Requests/Pagination';
import { Collection } from 'Requests/Collection';
import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { ErrorResponse } from 'ValueObjects';
import Qs from 'qs';
import { Filterable } from 'Requests/Filterable';
import { QueryInvalidator } from 'Requests/QueryInvalidator';

export type CredentialsListRequestProps = Pagination & Filterable;
type Response = AxiosResponse<Collection<AbstractCredential>>;

export const CredentialsListRequest = async ({
  ...params
}: CredentialsListRequestProps): Promise<Response> =>
  axios.get(`api/credentials`, {
    params: { ...params },
    paramsSerializer: {
      encode: (param) => Qs.parse(param),
      serialize: (param) => Qs.stringify(param, { arrayFormat: 'brackets' }),
    },
  });

export const useCredentialsListRequest = (
  request: CredentialsListRequestProps,
  options: UseQueryOptions<Response, AxiosError<ErrorResponse>, Response> = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => CredentialsListRequest(request),
    queryKey: ['credentials', 'list', ...Object.values(request)],
  });

export const CredentialsListRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isAbstractCredential(data)) {
    queryClient.invalidateQueries(['credentials', 'list']);
  }
};

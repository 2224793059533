import { Autocomplete, AutocompleteProps } from 'Molecules/Autocomplete';
import { SyntheticEvent, ReactElement } from 'react';
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  UseFormStateReturn,
} from 'react-hook-form';

export interface ReactHookFormAutocompleteProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Omit<AutocompleteProps, 'name' | 'value' | 'error'> {
  identifier?: string;
  field: ControllerRenderProps<TFieldValues, TName>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<FieldValues>;
  overrideValue?: unknown;
}

type Option = {
  [identifier: string]: unknown;
};

function isOption(object: unknown, idField: string): object is Option {
  return Object.prototype.hasOwnProperty.call(object, idField);
}

const isOptionEqualToValue =
  (identifier: string) =>
  (option: Option, currentValue: Option | unknown | undefined): boolean => {
    if (
      typeof option[identifier] !== 'undefined' &&
      currentValue &&
      isOption(currentValue, identifier)
    ) {
      return option[identifier] === currentValue[identifier];
    }

    if (typeof option[identifier] !== 'undefined' && currentValue) {
      return option[identifier] === currentValue;
    }

    return false;
  };

export const fieldToAutocomplete = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  disabled,
  identifier,
  field: { onChange, onBlur, value },
  fieldState: { error, invalid },
  formState: { isSubmitting },
  helperText,
  multiple,
  overrideValue,
  ...props
}: ReactHookFormAutocompleteProps<TFieldValues, TName>): AutocompleteProps => {
  const fieldError = error?.message;
  const showError = invalid;

  let handleChange = onChange;
  if (identifier) {
    handleChange = (e: SyntheticEvent, newValue: Option | null) => {
      onChange(newValue ? newValue[identifier] : '');
    };
  } else if (multiple) {
    handleChange = (e: SyntheticEvent, newValue) => {
      onChange(newValue);
    };
  } else {
    handleChange = (e: SyntheticEvent, newValue) => {
      onChange(newValue);
    };
  }
  let resultingValue = overrideValue || value;
  if (multiple && !resultingValue) {
    resultingValue = [];
  }

  return {
    variant: props.variant,
    error: showError,
    helperText: showError ? fieldError : helperText,
    disabled: disabled ?? isSubmitting,
    onBlur,
    value: resultingValue,
    multiple,
    isOptionEqualToValue: isOptionEqualToValue(identifier || ''),
    onChange: handleChange,
    ...props,
  };
};

const ReactHookFormAutocomplete = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  children,
  ...args
}: ReactHookFormAutocompleteProps<TFieldValues, TName>): ReactElement => (
  <Autocomplete {...fieldToAutocomplete(args)}>{children}</Autocomplete>
);

export default ReactHookFormAutocomplete;

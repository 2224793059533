import { Uuid } from 'ValueObjects';
import { Organization, Package } from 'Models';
import { isObjectResponse } from 'Requests';
import { ConnectionStatus } from './ConnectionStatus';

export interface Connection {
  connectionId: Uuid;
  connectionName: string;
  active: boolean;
  organization: Organization;
  packages: Package[];
  createdAt: Date;
  synchronizationStartDate: Date;
  connectionStatus: ConnectionStatus;
}

export function isConnection(object: unknown): object is Connection {
  return (
    !!object && isObjectResponse(object) && object['@type'] === 'Connection'
  );
}

import { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField/TextField';
import {
  FormControl,
  getTextFieldUtilityClass,
  Select,
  InputBase,
  InputLabel,
  styled,
  unstable_composeClasses as composeClasses,
  useThemeProps,
  TextFieldClasses,
  FormHelperText,
} from '@mui/material';
import { forwardRef, ReactElement, Ref } from 'react';
import { unstable_useId as useId } from '@mui/utils';
import clsx from 'clsx';
import colors from 'App/colors';

const useUtilityClasses = (classes?: Partial<TextFieldClasses>) => {
  const slots = {
    root: ['root'],
  };

  return composeClasses(slots, getTextFieldUtilityClass, classes);
};

export type BootstrapTextFieldProps = MuiTextFieldProps;

const BootstrapInput = styled(InputBase)(
  ({ theme }) => `
  label + & {
    margin-top: 20px;
  }
  & .MuiInputBase-input {
    border-radius: 8px;
    position: relative;
    background-color: ${colors.white};
    border: 1px solid ${colors.gray[300]};
    color: ${colors.coolGray[900]};
    font-size: 16px;
    line-height: 150%;
    padding: 10px 14px;
    transition: ${theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ])};
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${colors.gray[500]};
      opacity: 1; /* Firefox */
    }
    
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: ${colors.gray[500]};
    }
    
    ::-ms-input-placeholder { /* Microsoft Edge */
      color: ${colors.gray[500]};
    }

    &:focus {
        border-color: ${colors.blue[600]};
    }
}`
);

const BootstrapSelect = styled(Select)(
  ({ theme }) => `
  label + & {
    margin-top: 20px;
  }
  
  &:before, &:after {
    border: none !important;
  }
  
  & .MuiInputBase-input {
    border-radius: 8px;
    position: relative;
    background-color: ${colors.white};
    border: 1px solid ${colors.gray[300]};
    color: ${colors.coolGray[900]};
    font-size: 16px;
    line-height: 150%;
    padding: 10px 14px;
    transition: ${theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ])};
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${colors.gray[500]};
      opacity: 1; /* Firefox */
    }
    
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: ${colors.gray[500]};
    }
    
    ::-ms-input-placeholder { /* Microsoft Edge */
      color: ${colors.gray[500]};
    }

    &:focus {
        border-color: ${colors.blue[600]};
    }
}`
);

const BootstrapTextField = forwardRef(
  (
    inProps: BootstrapTextFieldProps,
    ref: Ref<HTMLDivElement>
  ): ReactElement => {
    const props = useThemeProps({
      props: inProps,
      name: 'MuiTextField',
    });
    const {
      autoComplete,
      autoFocus = false,
      children,
      className,
      color = 'primary',
      defaultValue,
      disabled = false,
      error = false,
      FormHelperTextProps,
      fullWidth = false,
      helperText,
      id: idOverride,
      InputLabelProps,
      inputProps,
      InputProps,
      inputRef,
      label,
      maxRows,
      minRows,
      multiline = false,
      name,
      onBlur,
      onChange,
      onFocus,
      placeholder,
      required = false,
      rows,
      select = false,
      SelectProps,
      type,
      value,
      variant = 'outlined',
      ...other
    } = props;

    const ownerState = {
      ...props,
      autoFocus,
      color,
      disabled,
      error,
      fullWidth,
      multiline,
      required,
      select,
      variant,
    };

    const classes = useUtilityClasses(ownerState.classes);

    const id = useId(idOverride);
    const helperTextId = helperText && id ? `${id}-helper-text` : undefined;
    const inputLabelId = label && id ? `${id}-label` : undefined;

    if (process.env.NODE_ENV !== 'production') {
      if (select && !children) {
        console.error(
          'MUI: `children` must be passed when using the `TextField` component with `select`.'
        );
      }
    }

    return (
      <FormControl
        variant="standard"
        className={clsx(classes.root, className)}
        disabled={disabled}
        error={error}
        fullWidth={fullWidth}
        required={required}
        color={color}
        ref={ref}
        {...other}
      >
        <InputLabel shrink htmlFor={id} id={inputLabelId} {...InputLabelProps}>
          {label}
        </InputLabel>
        {select ? (
          <BootstrapSelect
            aria-describedby={helperTextId}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            defaultValue={defaultValue}
            fullWidth={fullWidth}
            multiline={multiline}
            name={name}
            rows={rows}
            maxRows={maxRows}
            minRows={minRows}
            type={type}
            value={value}
            id={id}
            inputRef={inputRef}
            onBlur={onBlur}
            onFocus={onFocus}
            placeholder={placeholder}
            inputProps={inputProps}
            {...InputProps}
            onChange={(e) => {
              if (onChange) {
                // @ts-ignore
                onChange(e);
              }
            }}
          >
            {children}
          </BootstrapSelect>
        ) : (
          <BootstrapInput
            aria-describedby={helperTextId}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            defaultValue={defaultValue}
            fullWidth={fullWidth}
            multiline={multiline}
            name={name}
            rows={rows}
            maxRows={maxRows}
            minRows={minRows}
            type={type}
            value={value}
            id={id}
            inputRef={inputRef}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            placeholder={placeholder}
            inputProps={inputProps}
            {...InputProps}
          />
        )}
        <FormHelperText {...FormHelperTextProps}>{helperText}</FormHelperText>
      </FormControl>
    );
  }
);

export default BootstrapTextField;

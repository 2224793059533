import { AbstractTask } from 'Models/Synchronizer/Task/AbstractTask';
import { Uuid } from 'ValueObjects';
import { isObjectResponse } from 'Requests';

export interface ModuleTask extends AbstractTask {
  moduleId: Uuid;
}

export function isModuleTask(object: unknown): object is ModuleTask {
  return (
    !!object && isObjectResponse(object) && object['@type'] === 'ModuleTask'
  );
}

import { ErrorResponse, Uuid } from 'ValueObjects';
import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
} from '@tanstack/react-query';
import { Organization } from 'Models';

export type CreateOrganizationMutationProps = {
  organizationId: Uuid;
  resellerId?: Uuid;
  organizationName: string;
  organizationCode: string;
  address: {
    street?: string;
    houseNumberWithAddition?: string;
    city?: string;
    postalCode?: string;
    country?: string;
  };
  vatNumber?: string;
  invoiceContactName?: string;
  invoiceEmailAddress?: string;
  reference?: string;
};

export const CreateOrganizationMutation = async ({
  ...values
}: CreateOrganizationMutationProps): Promise<AxiosResponse<Organization>> =>
  axios.post(`api/organizations`, {
    ...values,
  });

export const useCreateOrganizationMutation = (
  options: UseMutationOptions<
    AxiosResponse<Organization>,
    AxiosError<ErrorResponse>,
    CreateOrganizationMutationProps
  > = {}
): UseMutationResult<
  AxiosResponse<Organization>,
  AxiosError<ErrorResponse>,
  CreateOrganizationMutationProps
> =>
  useMutation<
    AxiosResponse<Organization>,
    AxiosError<ErrorResponse>,
    CreateOrganizationMutationProps
  >(CreateOrganizationMutation, {
    useErrorBoundary: false,
    ...options,
  });

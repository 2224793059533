import * as Yup from 'yup';

export type UploadCompanyLogoInputType = {
  fileId?: string | null;
  uploading?: boolean;
};

export const UploadCompanyLogoInputTypeDefaultValues =
  (): UploadCompanyLogoInputType => ({
    fileId: '',
    uploading: false,
  });

export const UploadCompanyLogoInputTypeValidationBuilder =
  (): Yup.SchemaOf<UploadCompanyLogoInputType> =>
    Yup.object({
      fileId: Yup.string().nullable().label('label.fileId'),
      uploading: Yup.boolean().isFalse().label('label.uploading'),
    });

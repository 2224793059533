import { ReactElement, useCallback, useState } from 'react';
import { FormDialog } from 'Organisms';
import { FormattedMessage } from 'react-intl';
import {
  ActivateTwoFactorAuthenticationInputType,
  ActivateTwoFactorAuthenticationInputTypeDefaultValues,
  ActivateTwoFactorAuthenticationInputTypeValidationBuilder,
} from 'InputTypes/App/ActivateTwoFactorAuthenticationInputType';
import { Breadcrumbs, Button, Icon, ReactHookFormTextField } from 'Atoms';
import { faArrowRight, faCheck } from '@fortawesome/pro-solid-svg-icons';
import { Grid, styled, Typography } from '@mui/material';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { Controller } from 'react-hook-form';
import {
  useEnableTwoFactorMutation,
  useSetupTwoFactorMutation,
} from 'Mutations';
import { useRecoilState } from 'recoil';
import { UserState } from 'State';

export type ActivateTwoFactorAuthenticationFormDialogProps = {
  className?: string;
  open: boolean;
  onClose: () => void;
  onSubmit: (
    values: ActivateTwoFactorAuthenticationInputType
  ) => void | Promise<void>;
};

const ActivateTwoFactorAuthenticationFormDialog = ({
  onSubmit,
  ...props
}: ActivateTwoFactorAuthenticationFormDialogProps): ReactElement => {
  const [qrCode, setQrCode] = useState<string>('');
  const { mutateAsync: setupTwoFactor } = useSetupTwoFactorMutation();
  const [userState] = useRecoilState(UserState);

  const handleSubmit = useCallback(
    async (values: ActivateTwoFactorAuthenticationInputType) => {
      if (!userState) {
        return;
      }

      if (!qrCode) {
        const newQrCode = await setupTwoFactor({
          userId: userState.userId,
          password: values.password,
          twoFactorMethod: 'totp',
        });

        setQrCode(newQrCode?.data?.qrCodeImage);
        return;
      }

      await onSubmit(values);
    },
    [onSubmit, qrCode, setupTwoFactor, userState]
  );

  return (
    <FormDialog<ActivateTwoFactorAuthenticationInputType>
      name="activate-two-factor"
      onSubmit={handleSubmit}
      title={<FormattedMessage id="label.setupTwoFactorAuthentication" />}
      defaultValues={{
        ...ActivateTwoFactorAuthenticationInputTypeDefaultValues(),
      }}
      validationSchema={ActivateTwoFactorAuthenticationInputTypeValidationBuilder(
        !!qrCode
      )}
      closeButtonLast
      dialogActions={
        qrCode
          ? [
              <Button type="submit" key="save">
                <Icon icon={faCheck} />
                &nbsp;
                <FormattedMessage id="button.save" />
              </Button>,
            ]
          : [
              <Button type="submit" key="next">
                <Icon icon={faArrowRight} />
                &nbsp;
                <FormattedMessage id="button.next" />
              </Button>,
            ]
      }
      fullWidth
      maxWidth="sm"
      useProvider
      {...props}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Breadcrumbs separator={<Icon icon={faChevronRight} />}>
            <Typography className="active">
              1. <FormattedMessage id="label.password" />
            </Typography>
            <Typography className={qrCode ? 'active' : ''}>
              2. <FormattedMessage id="label.enableTwoFactorAuthentication" />
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12}>
          {qrCode && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormattedMessage id="label.twoFactorAuthenticationDescription" />
              </Grid>
              <Grid item xs={12}>
                <img src={qrCode} alt="qr" />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="code"
                  render={(registered) => (
                    <ReactHookFormTextField
                      {...registered}
                      fullWidth
                      label={<FormattedMessage id="label.twoFactorCode" />}
                    />
                  )}
                />
              </Grid>
            </Grid>
          )}
          {!qrCode && (
            <Controller
              name="password"
              render={(registered) => (
                <ReactHookFormTextField
                  {...registered}
                  fullWidth
                  type="password"
                  label={<FormattedMessage id="label.password" />}
                />
              )}
            />
          )}
        </Grid>
      </Grid>
    </FormDialog>
  );
};

export default styled(ActivateTwoFactorAuthenticationFormDialog)`
  .MuiBreadcrumbs-root {
    .MuiBreadcrumbs-ol :nth-of-type(2) {
      display: block;
    }

    .MuiBreadcrumbs-li {
      p {
        text-transform: initial;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: rgba(68, 73, 80, 0.6);
      }

      p.active {
        color: rgba(0, 0, 0, 0.87);
      }
    }
  }
`;

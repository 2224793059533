import {
  UsersListRequestInvalidator,
  OrganizationsListRequestInvalidator,
  ConnectionsListRequestInvalidator,
  ModulesByConnectionIdRequestInvalidator,
  RequestAndResponseListRequestInvalidator,
  CredentialByCredentialIdRequestInvalidator,
  CredentialsListRequestInvalidator,
  LogsByConnectionIdRequestInvalidator,
  TasksByConnectionIdRequestInvalidator,
} from 'Requests';
import { QueryInvalidator } from 'Requests/QueryInvalidator';

// eslint-disable-next-line import/prefer-default-export
export const queryInvalidators: QueryInvalidator[] = [
  CredentialByCredentialIdRequestInvalidator,
  UsersListRequestInvalidator,
  OrganizationsListRequestInvalidator,
  ConnectionsListRequestInvalidator,
  ModulesByConnectionIdRequestInvalidator,
  RequestAndResponseListRequestInvalidator,
  CredentialByCredentialIdRequestInvalidator,
  CredentialsListRequestInvalidator,
  LogsByConnectionIdRequestInvalidator,
  TasksByConnectionIdRequestInvalidator,
];

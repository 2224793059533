import * as Yup from 'yup';
import { EmailAddress, Uuid } from 'ValueObjects';
import { Organization } from 'Models';

export interface OrganizationDetailsInputType {
  organizationName: string;
  address: {
    street?: string;
    houseNumberWithAddition?: string;
    city?: string;
    postalCode?: string;
    country?: string;
  };
  vatNumber?: string;
  invoiceContactName?: string;
  reference?: string;
  invoiceEmailAddress?: EmailAddress;
  reseller?: { organizationId?: Uuid } | null;
}

export const OrganizationDetailsInputTypeValidationBuilder =
  (): Yup.SchemaOf<OrganizationDetailsInputType> =>
    Yup.object({
      organizationName: Yup.string().required().label('label.organizationName'),
      vatNumber: Yup.string().label('label.vatNumber'),
      invoiceContactName: Yup.string().label('label.invoiceContactName'),
      reference: Yup.string().label('label.reference'),
      invoiceEmailAddress: Yup.string().email().label('label.reference'),
      reseller: Yup.object({
        organizationId: Yup.string().required().label('label.organization'),
      }),
      address: Yup.object({
        street: Yup.string().ensure().label('label.street'),
        houseNumberWithAddition: Yup.string()

          .ensure()
          .label('label.houseNumberWithAddition'),
        city: Yup.string().ensure().label('label.city'),
        postalCode: Yup.string().ensure().label('label.postalCode'),
        country: Yup.string().ensure().label('label.country'),
      }),
    });

export const OrganizationDetailsInputTypeDefaultValues =
  (): OrganizationDetailsInputType => ({
    organizationName: '',
    vatNumber: '',
    invoiceContactName: '',
    invoiceEmailAddress: '',
    reference: '',
    reseller: null,
    address: {
      street: '',
      houseNumberWithAddition: '',
      city: '',
      postalCode: '',
      country: '',
    },
  });

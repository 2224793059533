import { styled, Tab as MuiTab, TabProps as MuiTabProps } from '@mui/material';
import { forwardRef, ReactElement } from 'react';
import clsx from 'clsx';

export type TabProps = MuiTabProps & {
  active?: boolean;
  inactive?: boolean;
  approved?: boolean;
};

const Tab = forwardRef<HTMLDivElement, TabProps>(
  (
    // eslint-disable-next-line react/prop-types
    { value, active, inactive, approved, className, ...args }: TabProps,
    ref
  ): ReactElement => (
    <MuiTab
      {...args}
      ref={ref}
      value={value}
      iconPosition="end"
      className={clsx(
        className,
        { 'Db-Tab-inactive': inactive },
        { 'Db-Tab-active': active },
        { 'Db-Tab-approved': approved }
      )}
    />
  )
);

export default styled(Tab)(
  ({ theme }) => `
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.5px;
  padding: 12px 10px;
  min-width: unset;
  color: #C4C4C4;
 
  &.Db-Tab-inactive, &.Db-Tab-active {
    border: 1px solid #C4C4C4;
    border-top: 4px solid #C4C4C4;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  
  &.Db-Tab-inactive.Mui-selected {
    color: #C4C4C4;
  }
  
  &:hover {
    color: ${theme.palette.primary.main};
  }
  
  &.Db-Tab-approved {
    color: ${theme.palette.success.main};
  }
`
);

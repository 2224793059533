import { ReactElement, useCallback, useState } from 'react';
import { OverviewDataGridPageTemplate } from 'Templates/OverviewDataGridPageTemplate';
import { useCredentialsListRequest } from 'Requests';
import useDataGridDataWithReactQuery from 'Utils/useDataGridDataWithReactQuery';
import {
  AbstractCredential,
  generateOAuth2CredentialConnectUrl,
  isOAuth2Credential,
  isOAuth2CredentialOrExtendsOauth2Credential,
  PackageType,
} from 'Models';
import {
  FormattedDate,
  FormattedMessage,
  FormattedRelativeTime,
} from 'react-intl';
import { Link, Grid, IconButton, Typography } from '@mui/material';
import {
  faLinkHorizontal,
  faPencil,
  faTrashCan,
} from '@fortawesome/pro-light-svg-icons';
import { Icon } from 'Atoms';
import { CredentialStatusChip } from 'Molecules';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import colors from 'App/colors';
import dayjs from 'dayjs';
import {
  AbstractCredentialDto,
  AbstractCredentialRequestDto,
  ExactOnlineCredentialDto,
  ExactOnlineCredentialRequestDto,
  isExactOnlineCredentialDto,
  isPayPalCredentialDto,
  isRecrasCredentialDto,
  PayPalCredentialDto,
  RecrasCredentialDto,
  useCreateCredentialMutation,
  useDeleteCredentialMutation,
} from 'Mutations';
import ConfirmDialog from 'Organisms/ConfirmDialog/ConfirmDialog';
import { v4 } from 'uuid';
import { CreateCredentialFromDialog } from '../../../Dialogs/CreateCredentialFromDialog';
import { CreateCredentialInputType } from '../../../InputTypes/App/Credential/CreateCredentialInputType';

const CredentialOverviewPage = (): ReactElement => {
  const navigate = useNavigate();

  const { mutateAsync: deleteCredentialMutation } =
    useDeleteCredentialMutation();
  const [deleteCredential, setDeletingCredential] =
    useState<AbstractCredential>();
  const [isCreatingCredential, setIsCreatingCredential] =
    useState<boolean>(false);

  const createNewCredential = useCreateCredentialMutation();

  const isOathCredentialConnected = (
    credential: AbstractCredential
  ): boolean => {
    if (!isOAuth2Credential(credential)) {
      return true;
    }

    return !credential.credentialRequest;
  };

  const handleClickConnect = useCallback((credential: AbstractCredential) => {
    if (
      isOAuth2CredentialOrExtendsOauth2Credential(credential) &&
      !isOathCredentialConnected(credential)
    ) {
      window.open(generateOAuth2CredentialConnectUrl(credential), '_blank');
    }
  }, []);

  const gridData = useDataGridDataWithReactQuery<AbstractCredential>({
    overviewPage: true,
    reactQuery: useCredentialsListRequest,
    getRowId: (row) => row.credentialId,
    columns: [
      {
        field: 'name',
        headerName: 'label.credential',
        flex: 1,
        renderCell: ({ row, value }) => (
          <Link
            component={RouterLink}
            to={`/credentials/${row.credentialId}`}
            underline="none"
            color={colors.coolGray[900]}
          >
            {value}
          </Link>
        ),
      },
      {
        field: 'organization.organizationName',
        headerName: 'label.organization',
        valueGetter: (params) => params.row.organization.organizationName,
        flex: 1,
      },
      {
        field: 'packageType.value',
        headerName: 'label.package',
        flex: 1,
        renderCell: ({ row }) => (
          <FormattedMessage id={`packageTypes.${row.packageType}`} />
        ),
      },
      {
        field: 'credentialStatus.value',
        headerName: 'label.status',
        flex: 0.7,
        renderCell: (params) => (
          <CredentialStatusChip
            credentialStatus={params.row.credentialStatus}
          />
        ),
      },
      {
        field: 'expiresAt',
        headerName: 'label.expiresAt',
        flex: 0.7,
        renderCell: ({ row }) =>
          isOAuth2Credential(row) && row.accessTokenExpires ? (
            <FormattedRelativeTime
              value={dayjs(row.accessTokenExpires).diff(dayjs(), 'second')}
              unit="second"
              numeric="auto"
              updateIntervalInSeconds={60}
            />
          ) : null,
      },
      {
        field: 'createdAt',
        headerName: 'label.connectedAt',
        flex: 0.7,
        renderCell: ({ row }) => <FormattedDate value={row.createdAt} />,
      },
      {
        field: 'actions',
        headerName: 'label.actions',
        type: 'actions',
        headerAlign: 'left',
        flex: 0.7,
        sortable: false,
        renderCell: ({ row }) => (
          <Grid container spacing={2}>
            <Grid item>
              <IconButton
                onClick={() => {
                  handleClickConnect(row);
                }}
                disableRipple
                disableFocusRipple
                disableTouchRipple
                disabled={isOathCredentialConnected(row)}
                size="small"
                sx={{ fontSize: 13.5 }}
              >
                <Icon icon={faLinkHorizontal} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                onClick={() => setDeletingCredential(row)}
                disableRipple
                disableFocusRipple
                disableTouchRipple
                size="small"
                sx={{ fontSize: 13.5 }}
              >
                <Icon icon={faTrashCan} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                onClick={() => navigate(`${row.credentialId}`)}
                disableRipple
                disableFocusRipple
                disableTouchRipple
                size="small"
                sx={{ fontSize: 13.5 }}
              >
                <Icon icon={faPencil} />
              </IconButton>
            </Grid>
          </Grid>
        ),
      },
    ],
  });

  const handleStartDeleting = useCallback(async () => {
    if (deleteCredential?.credentialId) {
      await deleteCredentialMutation({
        credentialId: deleteCredential.credentialId,
      });
      setDeletingCredential(undefined);
      gridData.refetch();
    }
  }, [deleteCredential?.credentialId, deleteCredentialMutation, gridData]);

  // TODO Extract this one so it can used inside multiple components
  const getNewCredentialByPackageType = <
    CredentialType extends AbstractCredentialDto
  >(
    credential: CredentialType,
    packageType: string
  ): AbstractCredentialDto => {
    let newCredential: AbstractCredentialDto;

    if (
      packageType === PackageType.PAYPAL &&
      isPayPalCredentialDto(credential)
    ) {
      newCredential = {
        name: credential.name,
        clientId: credential.clientId,
        clientSecret: credential.clientSecret,
      } as PayPalCredentialDto;
    } else if (
      packageType === PackageType.EXACT_ONLINE &&
      isExactOnlineCredentialDto(credential)
    ) {
      newCredential = {
        name: credential.name,
        country: credential.country,
      } as ExactOnlineCredentialDto;
    } else if (
      packageType === PackageType.RECRAS &&
      isRecrasCredentialDto(credential)
    ) {
      newCredential = {
        name: credential.name,
        token: credential.subDomain,
        subDomain: credential.token,
      } as RecrasCredentialDto;
    } else {
      throw new Error(`Invalid credential type ${packageType}`);
    }

    return newCredential;
  };

  // TODO Extract this one so it can used inside multiple components
  const getNewCredentialRequestByPackageType = <
    CredentialType extends AbstractCredentialDto
  >(
    credential: CredentialType,
    packageType: string,
    newCredentialId: string
  ): AbstractCredentialRequestDto | null => {
    let newCredentialRequest: AbstractCredentialRequestDto | null;

    if (
      packageType === PackageType.EXACT_ONLINE &&
      isExactOnlineCredentialDto(credential)
    ) {
      newCredentialRequest = {
        successUrl: `${window.location.origin}/credential/oauth/success/${newCredentialId}`,
        failureUrl: `${window.location.origin}/credential/oauth/error/${newCredentialId}`,
        country: credential.country,
      } as ExactOnlineCredentialRequestDto;
    } else {
      newCredentialRequest = null;
    }

    return newCredentialRequest;
  };

  const handleCreateCredential = useCallback(
    async <CredentialType extends AbstractCredentialDto>(
      values: CreateCredentialInputType<CredentialType>
    ) => {
      if (
        values.organization &&
        values.package.packageType &&
        values.package &&
        values.package.credential
      ) {
        const { organizationId } = values.organization;

        const credentialId = v4();

        const credential = getNewCredentialByPackageType(
          values.package.credential,
          values.package.packageType
        );

        const credentialRequest = getNewCredentialRequestByPackageType(
          values.package.credential,
          values.package.packageType,
          credentialId
        );

        await createNewCredential.mutateAsync({
          credentialId,
          organizationId,
          package: {
            packageType: values.package.packageType,
            credentialRequest,
            credential,
            settings: [],
          },
        });

        setIsCreatingCredential(false);
        gridData.refetch();
      }
    },
    [createNewCredential, gridData]
  );

  return (
    <OverviewDataGridPageTemplate
      actions={[
        { value: 'overview', children: 'overview', to: '/credentials' },
        {
          value: 'create',
          children: 'create a new credential',
          onClick: () => setIsCreatingCredential(true),
        },
      ]}
      onSearch={gridData.handleSearch}
      breadCrumbs={[
        <Typography key="credentials" color="text.primary">
          <FormattedMessage id="label.credentials" />
        </Typography>,
        <Link component={RouterLink} key="overview" to="/credentials">
          <FormattedMessage id="label.overview" />
        </Link>,
      ]}
      {...gridData}
    >
      {deleteCredential && (
        <ConfirmDialog
          open={!!deleteCredential}
          onCancel={() => setDeletingCredential(undefined)}
          onClose={() => setDeletingCredential(undefined)}
          onConfirm={handleStartDeleting}
          dangerous
          title={
            <FormattedMessage
              id="label.deleteCredential"
              values={{ name: deleteCredential.name }}
            />
          }
          content={
            <FormattedMessage id="label.areYouSureYouWantToDeleteThisCredential" />
          }
        />
      )}

      {isCreatingCredential && (
        <CreateCredentialFromDialog
          open={isCreatingCredential}
          onClose={() => setIsCreatingCredential(false)}
          onSubmit={handleCreateCredential}
        />
      )}
    </OverviewDataGridPageTemplate>
  );
};

export default CredentialOverviewPage;

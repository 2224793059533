import { Alert as MuiAlert, AlertProps as MuiAlertProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FunctionComponent } from 'react';
import colors from 'App/colors';
import { Icon } from 'Atoms/Icon';
import {
  faCheckCircle,
  faExclamationCircle,
  faXmarkCircle,
  faInfoCircle,
} from '@fortawesome/pro-light-svg-icons';

export type AlertProps = MuiAlertProps;

const Alert: FunctionComponent<AlertProps> = ({ ...props }: AlertProps) => (
  <MuiAlert
    iconMapping={{
      success: <Icon icon={faCheckCircle} />,
      warning: <Icon icon={faExclamationCircle} />,
      info: <Icon icon={faInfoCircle} />,
      error: <Icon icon={faXmarkCircle} />,
    }}
    {...props}
  />
);

Alert.defaultProps = {};

export default styled(Alert)(
  ({ theme }) => `
  padding: 8px 16px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: ${colors.grey[900]};
  
  .MuiAlert-icon {
    height: 36px;
    width: 36px;
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        height: 16.67px;
        width: 16p.67x;
    }
  }
  
  &.MuiAlert-standardSuccess {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), ${colors.success[500]};
    .MuiAlert-icon {
        color: white;
        background: ${colors.success[500]};
    }
  }

  &.MuiAlert-standardError {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), ${colors.error[500]};
    .MuiAlert-icon {
        color: white;
        background: ${colors.error[500]};
    }
  }
  
  &.MuiAlert-standardWarning {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), ${colors.warning[500]};
    .MuiAlert-icon {
        color: white;
        background: ${colors.warning[500]};
    }
  }
  
  &.MuiAlert-standardInfo {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), ${colors.info[500]};
    .MuiAlert-icon {
        color: white;
        background: ${colors.info[500]};
    }
  }
`
);

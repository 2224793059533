import { ErrorResponse, Uuid } from 'ValueObjects';
import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
} from '@tanstack/react-query';
import { CredentialPackage } from 'Mutations';
import { PackageType } from '../../../../Models';

export const CredentialsThatCanBeCreated = [
  PackageType.PAYPAL,
  PackageType.RECRAS,
  PackageType.EXACT_ONLINE,
];

export type CreateCredentialMutationProps = {
  credentialId: Uuid;
  organizationId: Uuid;
  package: CredentialPackage;
};

type Response = AxiosResponse<{ credentialId: Uuid }>;

export const CreateCredentialMutation = async ({
  ...values
}: CreateCredentialMutationProps): Promise<Response> =>
  axios.post(`api/credentials`, {
    ...values,
  });

export const useCreateCredentialMutation = (
  options: UseMutationOptions<
    Response,
    AxiosError<ErrorResponse>,
    CreateCredentialMutationProps
  > = {}
): UseMutationResult<
  Response,
  AxiosError<ErrorResponse>,
  CreateCredentialMutationProps
> =>
  useMutation<
    Response,
    AxiosError<ErrorResponse>,
    CreateCredentialMutationProps
  >(CreateCredentialMutation, {
    useErrorBoundary: false,
    ...options,
  });

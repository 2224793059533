import { ListItem, ListItemProps, ListItemButton, styled } from '@mui/material';
import { FunctionComponent } from 'react';
import {
  useMatch,
  useResolvedPath,
  Link as RouterLink,
} from 'react-router-dom';
import clsx from 'clsx';
import colors from 'App/colors';

export type SidebarListItemProps = Omit<ListItemProps, 'onClick'> & {
  active?: boolean;
  className?: string;
  to: string;
  exact?: boolean;
  closedMenu?: boolean;
};

const SidebarListItem: FunctionComponent<SidebarListItemProps> = ({
  children,
  active,
  to,
  className,
  exact,
  closedMenu,
  ...args
}: SidebarListItemProps) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: exact });

  return (
    <ListItem
      disableGutters
      disablePadding
      aria-current={!!match || active}
      className={clsx(
        className,
        {
          'Db-SidebarListItem-Active': !!match || active,
        },
        {
          'Db-SidebarListItem-Closed': closedMenu,
        }
      )}
      {...args}
    >
      <ListItemButton component={RouterLink} to={to} disableRipple>
        {children}
      </ListItemButton>
    </ListItem>
  );
};

SidebarListItem.defaultProps = {
  active: false,
  exact: false,
};

export default styled(SidebarListItem)(
  ({ theme }) => `
  &.Db-SidebarListItem-Closed {
      width: 44px;
      height: 44px;
      justify-content: center;
      
      .MuiListItemIcon-root {
        color: ${colors.white};
        min-width: unset;
      }
      
      &[aria-current="true"] {
        background: #5A2CD1;
        border-radius: 4px;
      }
      
      .MuiListItemButton-root {
        padding-left: 0;
        padding-right: 0;
        justify-content: center;
      }
  }
  
  &:not(.Db-SidebarListItem-Closed) {
    width: 100%;
    background: ${colors.gray[50]};
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    cursor: pointer;
    display: flex;
    
    svg {
     color: #7986CB;
     margin-right: ${theme.spacing(3)};
     width: 20px;
    }
    
    
    .MuiButtonBase-root {
      padding: 18px 23px;
      &:hover {
        background: transparent;
      }
    }
    
    &.Db-SidebarListItem-Active {
      color: #5A2CD1;
      background: #EAF2F9;
      
      &:before {
        position: absolute;
        display: block;
        content: ' ';
        background: #5A2CD1;
        border-radius: 0px 10px 10px 0px;
        height: 28px;
        width: 4px;
        left: 0;
      }
      
      svg {
        color: #5A2CD1;
      }
    }
    
    &:not(.Db-SidebarListItem-Active):hover {
      background: #F2F2F2;
    }

  }
`
);

import { ReactElement, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Grid, styled, Typography } from '@mui/material';
import { Alert, Button, Form } from 'Atoms';
import {
  RequestResetPasswordForm,
  RequestResetPasswordInitialValues,
  RequestResetPasswordInput,
  RequestResetPasswordValidation,
} from 'Forms';
import { useRequestResetPasswordMutation } from 'Mutations';
import { AuthenticationTemplate } from 'Templates';
import { reactHookFormBackendErrorHandler } from 'Utils';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';

export type RequestResetPasswordPageProps = {
  className?: string;
};

const RequestResetPasswordPage = ({
  className,
}: RequestResetPasswordPageProps): ReactElement => {
  const [requestResetPasswordSucceed, setRequestResetPasswordSucceed] =
    useState<boolean>(false);

  const form = useForm<RequestResetPasswordInput>({
    resolver: yupResolver(RequestResetPasswordValidation()),
    mode: 'all',
    defaultValues: RequestResetPasswordInitialValues,
    shouldFocusError: true,
  });

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
  } = form;

  const handleRequestResetPasswordSucceeded = () => {
    setRequestResetPasswordSucceed(true);
  };

  const requestResetPassword = useRequestResetPasswordMutation({
    onSuccess: () => {
      handleRequestResetPasswordSucceeded();
    },
  });

  const handleRequestResetPassword = useCallback(
    async (values: RequestResetPasswordInput) => {
      setRequestResetPasswordSucceed(false);

      try {
        await requestResetPassword.mutateAsync(values);
      } catch (exception) {
        if (axios.isAxiosError(exception)) {
          reactHookFormBackendErrorHandler<RequestResetPasswordInput>(
            exception,
            form
          );

          return;
        }

        throw exception;
      }
    },
    [form, requestResetPassword]
  );

  return (
    <AuthenticationTemplate>
      <Box className={className}>
        <Box className="Db-RequestResetPasswordPage-Title">
          <Typography variant="h3">
            <FormattedMessage id="requestResetPassword.title" />
          </Typography>
          <Typography variant="h6">
            <FormattedMessage id="requestResetPassword.subtitle" />
          </Typography>
        </Box>
        <Box className="Db-RequestResetPasswordPage-Form">
          <Form
            name="requestResetPassword"
            noValidate
            onSubmit={handleSubmit(handleRequestResetPassword)}
          >
            <FormProvider {...form}>
              <Grid container rowSpacing={2} alignItems="center">
                <Grid item xs={12}>
                  {errors.status && (
                    <Alert severity="warning">
                      <FormattedMessage id={errors.status?.message} />
                    </Alert>
                  )}
                </Grid>

                {requestResetPasswordSucceed && (
                  <Grid item xs={12}>
                    <Alert severity="success">
                      <FormattedMessage id="requestResetPassword.succeedMessage" />
                    </Alert>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <RequestResetPasswordForm />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    loading={isSubmitting}
                    fullWidth
                    color={requestResetPasswordSucceed ? 'success' : 'primary'}
                    disabled={requestResetPasswordSucceed}
                  >
                    <FormattedMessage id="button.send" />
                  </Button>
                </Grid>
              </Grid>
            </FormProvider>
          </Form>
        </Box>
      </Box>
    </AuthenticationTemplate>
  );
};

export default styled(RequestResetPasswordPage)(
  ({ theme }) => `
    .Db-RequestResetPasswordPage-Title {
      margin-bottom: 30px;
      max-width: 500px;

      .MuiTypography-h6 {
        color: #667085;
        margin-top: 10px;
      }
  }
  
  .Db-RequestResetPasswordPage-Form {
    width: 500px;

    .MuiTypography-h6 {
      color: #667085;
      margin-top: 10px;
    }
    
    .MuiButton-root {
      border-radius: 8px;
      height: 52px;
    }

    .MuiButton-text {
      color: ${theme.palette.primary.main};
    }
  }
  `
);

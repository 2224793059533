import { ReactHookWizardStep } from 'Atoms';
import { PackageType } from 'Models';
import { FieldValues } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import {
  ConnectWithExactOnlineStep,
  ConnectWithExactOnlineStepValidation,
  ExactOnlineCredentialSettingsStepForm,
  ConnectWithPaypalStep,
  ConnectWithPaypalStepValidation,
  ExactOnlineCredentialSettingsStepFormValidation,
} from 'Forms';

const credentialsStepsMapping = <
  FormInput extends FieldValues = FieldValues
>(): {
  [key: string]: ReactHookWizardStep<FormInput>[];
} => ({
  [PackageType.EXACT_ONLINE]: [
    {
      title: <FormattedMessage id="label.account" />,
      categoryTitle: (
        <FormattedMessage
          id="onboarding.steps.connect"
          values={{
            packageType: (
              <FormattedMessage
                id={`packageTypes.${PackageType.EXACT_ONLINE}`}
              />
            ),
          }}
        />
      ),
      category: PackageType.EXACT_ONLINE,
      render: ({ ...args }) => <ConnectWithExactOnlineStep {...args} />,
      validationSchema: ConnectWithExactOnlineStepValidation(),
    },
    {
      title: <FormattedMessage id="label.division" />,
      categoryTitle: (
        <FormattedMessage
          id="onboarding.steps.connect"
          values={{
            packageType: (
              <FormattedMessage
                id={`packageTypes.${PackageType.EXACT_ONLINE}`}
              />
            ),
          }}
        />
      ),
      category: PackageType.EXACT_ONLINE,
      render: ({ ...args }) => (
        <ExactOnlineCredentialSettingsStepForm {...args} />
      ),
      validationSchema: ExactOnlineCredentialSettingsStepFormValidation(),
    },
  ],
  [PackageType.PAYPAL]: [
    {
      title: <FormattedMessage id="onboarding.steps.settings" />,
      categoryTitle: (
        <FormattedMessage
          id="onboarding.steps.connect"
          values={{
            packageType: (
              <FormattedMessage id={`packageTypes.${PackageType.PAYPAL}`} />
            ),
          }}
        />
      ),
      category: PackageType.PAYPAL,
      render: ({ ...args }) => <ConnectWithPaypalStep {...args} />,
      validationSchema: ConnectWithPaypalStepValidation(),
    },
  ],
});

// eslint-disable-next-line import/prefer-default-export
export const getCredentialStepsByPackageType = <
  FormInput extends FieldValues = FieldValues
>(
  packageType: PackageType
): ReactHookWizardStep<FormInput>[] => {
  const mapping = credentialsStepsMapping<FormInput>();

  return mapping[packageType] || [];
};

import { styled } from '@mui/material/styles';
import {
  FormControlLabel as MuiFormControlLabel,
  FormControlLabelProps as MuiFormControlLabelProps,
} from '@mui/material';
import { FunctionComponent } from 'react';
import colors from 'App/colors';

export type FormControlLabelProps = MuiFormControlLabelProps;

const FormControlLabel: FunctionComponent<FormControlLabelProps> = ({
  ...props
}: FormControlLabelProps) => <MuiFormControlLabel {...props} />;

export default styled(FormControlLabel)(
  () => `
  & .MuiFormControlLabel-label {
    font-family: 'Inter';
    color: ${colors.gray[700]};
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
`
);

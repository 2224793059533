import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { BoxProps, Typography } from '@mui/material';
import { CenteredLayoutTemplate } from 'Templates';

const ErrorPage: FunctionComponent = ({ ...args }: BoxProps) => (
  <CenteredLayoutTemplate {...args}>
    <Typography mb={8} variant="h4">
      <FormattedMessage id="error.title" />
    </Typography>
    <Typography>
      <FormattedMessage id="error.description" />
    </Typography>
  </CenteredLayoutTemplate>
);

export default ErrorPage;

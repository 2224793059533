import { useState } from 'react';

export type UsePaginationResult = {
  currentPage: number;
  rowsPerPage: number;
  changePage: (newPage: number) => void | Promise<void>;
  changeRowsPerPage: (newRowsPerPage: number) => void | Promise<void>;
};

export type UsePaginationProps = {
  defaultRowsPerPage?: number;
};

export const usePagination = ({
  defaultRowsPerPage,
}: UsePaginationProps = {}): UsePaginationResult => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    defaultRowsPerPage || 10
  );

  return {
    currentPage,
    rowsPerPage,
    changePage: (newPage) => setCurrentPage(newPage),
    changeRowsPerPage: (newRowsPerPage) => setRowsPerPage(newRowsPerPage),
  };
};

import * as Yup from 'yup';
import { Organization, Package, PackageType } from 'Models';
import {
  getCredentialInputTypeDefaultValuesByPackageType,
  getCredentialInputTypeValidationBuilderByPackageType,
} from 'Utils/CredentialInputMapper';
import { AbstractCredentialDto } from '../../../Mutations';

export interface CreateCredentialInputType<
  CredentialType extends AbstractCredentialDto
> {
  organization?: Organization | null;
  package: Package<CredentialType>;
}

export const CreateCredentialInputTypeValidationBuilder = (
  packageType: PackageType | undefined
): Yup.SchemaOf<CreateCredentialInputType<AbstractCredentialDto>> =>
  // @ts-ignore
  Yup.object({
    organization: Yup.object({
      organizationId: Yup.string().required().label('label.organization'),
    }).required(),
    // packageType: Yup.string().required().label('label.packageType'),
    package: Yup.object()
      .shape({
        packageType: Yup.string().oneOf(Object.values(PackageType)).required(),
        credential:
          getCredentialInputTypeValidationBuilderByPackageType(packageType),
      })
      .required(),
  });

export const CreateCredentialInputTypeDefaultValues = (
  packageType: PackageType | undefined
): CreateCredentialInputType<AbstractCredentialDto> => ({
  organization: null,
  package: {
    packageType: undefined,
    credential: getCredentialInputTypeDefaultValuesByPackageType(packageType),
  },
});

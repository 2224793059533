// eslint-disable-next-line import/prefer-default-export
export enum SyncStatus {
  PENDING = 'pending',
  PROCESSING = 'processing',
  COMPLETE = 'complete',
  FAILED = 'failed',
  REMOVE = 'remove',
  IGNORED = 'ignored',
  UNKNOWN = 'unknown',
}

export function isSyncStatus(object: unknown): object is SyncStatus {
  return (
    // @ts-ignore
    typeof object === 'string' && Object.values(SyncStatus).includes(object)
  );
}

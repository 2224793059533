import {
  Box,
  ButtonGroup,
  Grid,
  Link,
  styled,
  Typography,
} from '@mui/material';
import { Alert, AlertProps, Breadcrumbs, Button, Icon } from 'Atoms';
import {
  faBrowser,
  faChevronRight,
  faCirclePlay,
  faFolderMagnifyingGlass,
  faHome,
  faInfoCircle,
  faWrench,
} from '@fortawesome/pro-light-svg-icons';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { useCallback, useMemo } from 'react';
import { Connection } from 'Models';
import {
  useConnectionStatusReportByConnectionIdRequestQuery,
  useSyncStatusByConnectionIdRequestQuery,
} from 'Requests';
import colors from 'App/colors';
import { useForceRunConnectionMutation } from 'Mutations';
import { Uuid } from 'ValueObjects';

export type ConnectionDetailsHeaderProps = {
  className?: string;
  connection: Connection;
  currentPage: 'details' | 'requests' | 'insights' | 'settings';
  alerts: AlertProps[];
  showSyncStatus?: boolean;
};

const ConnectionDetailsHeader = ({
  className,
  connection,
  currentPage,
  alerts,
  showSyncStatus,
}: ConnectionDetailsHeaderProps) => {
  const navigate = useNavigate();
  const { mutateAsync: forceRunConnectionMutation } =
    useForceRunConnectionMutation();

  const { data: syncStatusData } = useSyncStatusByConnectionIdRequestQuery(
    {
      connectionId: connection.connectionId,
    },
    {
      enabled: showSyncStatus,
    }
  );

  const { data: connectionStatusReportData } =
    useConnectionStatusReportByConnectionIdRequestQuery({
      connectionId: connection.connectionId,
    });

  const syncStatusItems = useMemo(
    () => syncStatusData?.data,
    [syncStatusData?.data]
  );

  const connectionStatusReport = useMemo(
    () => connectionStatusReportData?.data,
    [connectionStatusReportData?.data]
  );

  const handleForceRun = useCallback(
    async (connectionId: Uuid) => {
      await forceRunConnectionMutation({
        connectionId,
      });
    },
    [forceRunConnectionMutation]
  );

  return (
    <Box className={className}>
      <Breadcrumbs separator={<Icon icon={faChevronRight} />}>
        <Link component={RouterLink} to="/">
          <Icon icon={faHome} />
        </Link>
        <Link component={RouterLink} to="/connections">
          <FormattedMessage id="label.connections" />
        </Link>
        {currentPage !== 'details' ? (
          <Link
            component={RouterLink}
            to={`/connections/${connection.connectionId}`}
          >
            <FormattedMessage id="label.details" />
          </Link>
        ) : (
          <FormattedMessage id="label.details" />
        )}
        {currentPage !== 'details' && (
          <FormattedMessage id={`label.${currentPage}`} />
        )}
      </Breadcrumbs>
      <Box className="Db-ConnectionDetailsHeader-Title">
        <Typography variant="h5">{connection.connectionName}</Typography>
        <Box className="Db-ConnectionDetailsHeader-ActionButtons">
          <ButtonGroup>
            <Button
              size="large"
              variant="contained"
              color="tertiary"
              onClick={() => handleForceRun(connection.connectionId)}
            >
              <Icon icon={faCirclePlay} />
              &nbsp;
              <FormattedMessage id="button.run" />
            </Button>
            {currentPage !== 'details' && (
              <Button
                size="large"
                variant="contained"
                color="tertiary"
                onClick={() =>
                  navigate(`/connections/${connection.connectionId}`)
                }
              >
                <Icon icon={faFolderMagnifyingGlass} />
                &nbsp;
                <FormattedMessage id="button.details" />
              </Button>
            )}
            {currentPage !== 'insights' && (
              <Button
                size="large"
                variant="contained"
                color="tertiary"
                onClick={() =>
                  navigate(`/connections/${connection.connectionId}/insights`)
                }
              >
                <Icon icon={faInfoCircle} />
                &nbsp;
                <FormattedMessage id="button.insights" />
              </Button>
            )}
            {currentPage !== 'requests' && (
              <Button
                size="large"
                variant="contained"
                color="tertiary"
                onClick={() =>
                  navigate(`/connections/${connection.connectionId}/requests`)
                }
              >
                <Icon icon={faBrowser} />
                &nbsp;
                <FormattedMessage id="button.requests" />
              </Button>
            )}
            {currentPage !== 'settings' && (
              <Button
                size="large"
                variant="contained"
                color="tertiary"
                onClick={() =>
                  navigate(`/connections/${connection.connectionId}/settings`)
                }
              >
                <Icon icon={faWrench} />
                &nbsp;
                <FormattedMessage id="button.settings" />
              </Button>
            )}{' '}
          </ButtonGroup>
        </Box>
      </Box>

      <Grid container columnSpacing={4} rowSpacing={3} alignItems="center">
        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="caption">
                <FormattedMessage
                  id="label.organizationWithValue"
                  values={{
                    organizationName: connection.organization.organizationName,
                  }}
                />
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">
                <FormattedMessage
                  id="label.synchronizationStartDateWithValue"
                  values={{
                    date: (
                      <FormattedDate
                        value={connection.synchronizationStartDate}
                        dateStyle="short"
                      />
                    ),
                  }}
                />
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">
                <FormattedMessage
                  id="label.createdAt"
                  values={{
                    date: (
                      <FormattedDate
                        value={connection.createdAt}
                        dateStyle="short"
                      />
                    ),
                  }}
                />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* TODO refactor this */}
      {/* <Grid */}
      {/*  container */}
      {/*  className="Db-ConnectionDetailsHeader-Status" */}
      {/*  columnSpacing={3} */}
      {/*  rowSpacing={2} */}
      {/* > */}
      {/*  {showSyncStatus && */}
      {/*    syncStatusItems?.collectionSyncStatusReports.map( */}
      {/*      ({ collectionName, syncStatusReportItems }) => ( */}
      {/*        <Grid */}
      {/*          item */}
      {/*          key={collectionName} */}
      {/*          className="Db-ConnectionDetailsHeader-Status-Block" */}
      {/*        > */}
      {/*          <Typography> */}
      {/*            <FormattedMessage */}
      {/*              id={`collectionNames.${collectionName}`} */}
      {/*              defaultMessage={collectionName} */}
      {/*            /> */}
      {/*            {': '} */}
      {/*          </Typography> */}
      {/*          <Tooltip */}
      {/*            title={<SyncStatusReportList items={syncStatusReportItems} />} */}
      {/*          > */}
      {/*            <span> */}
      {/*              <SyncStatusReportChip items={syncStatusReportItems} /> */}
      {/*            </span> */}
      {/*          </Tooltip> */}
      {/*        </Grid> */}
      {/*      ) */}
      {/*    )} */}
      {/* </Grid> */}
      <Box className="Db-ConnectionDetailsHeader-Alerts">
        {alerts.map((alert, index) => (
          <Alert key={index} {...alert} />
        ))}
      </Box>
    </Box>
  );
};

export default styled(ConnectionDetailsHeader)(`
  background: ${colors.white};
  padding: 24px 24px 24px;
  .MuiBreadcrumbs-root {
    width: fit-content;
  }

  .Db-ConnectionDetailsHeader-Title {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 34px;

    .MuiTypography-h5 {
      white-space: nowrap;
    }

    .Db-ConnectionDetailsHeader-ActionButtons {
      margin-left: auto;
    }
  }

  // .Db-ConnectionDetailsHeader-Status {
  //   display: flex;
  //   flex-direction: row;
  //   align-items: center;
  //   padding-top: 32px;
  //   flex-wrap: wrap;
  //
  //   .Db-ConnectionDetailsHeader-Status-Block {
  //     display: flex;
  //     flex-direction: row;
  //     align-items: center;
  //
  //     .MuiChip-root {
  //       margin-left: 4px;
  //     }
  //   }
  // }

  .Db-ConnectionDetailsHeader-Alerts {
    display: flex;
    flex: 1;
    flex-direction: column;

    width: 100%;
    padding-top: 8px;
    .MuiAlert-root {
      flex: 1;
      margin-top: 16px;
      box-sizing: border-box;
      width: 100%;
    }
  }
`);

import { isAbstractCredential, AbstractCredential } from 'Models';

export interface RecrasCredential extends AbstractCredential {
  extraData: {
    subDomain: string;
    token: string;
  };
}

export function isRecrasCredential(
  object: unknown
): object is RecrasCredential {
  return isAbstractCredential(object) && object['@type'] === 'RecrasCredential';
}

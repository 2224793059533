import { Uuid } from 'ValueObjects';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Connection } from 'Models';
import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

export type ConnectionByConnectionIdRequestProps = {
  connectionId: Uuid;
};

export const ConnectionByConnectionIdRequest = async ({
  connectionId,
}: ConnectionByConnectionIdRequestProps): Promise<AxiosResponse<Connection>> =>
  axios.get(`api/connections/${connectionId}`);

export const useConnectionByConnectionIdRequestQuery = (
  request: ConnectionByConnectionIdRequestProps,
  options: UseQueryOptions<
    AxiosResponse<Connection>,
    AxiosError,
    AxiosResponse<Connection>
  > = {}
): UseQueryResult<AxiosResponse<Connection>, AxiosError> =>
  useQuery<AxiosResponse<Connection>, AxiosError, AxiosResponse<Connection>>({
    ...options,
    queryFn: async () => ConnectionByConnectionIdRequest(request),
    queryKey: ['connections', 'detail', request.connectionId],
  });

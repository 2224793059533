import { ErrorResponse, Uuid } from 'ValueObjects';
import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
} from '@tanstack/react-query';
import { User } from 'Models';

export type UpdateUserMutationProps = {
  userId: Uuid;
  emailAddress?: string;
  firstName?: string;
  middleName?: string;
  lastName: string;
};

export const UpdateUserMutation = async ({
  ...values
}: UpdateUserMutationProps): Promise<AxiosResponse<User>> =>
  axios.put(`api/users/${values.userId}`, {
    ...values,
  });

export const useUpdateUserMutation = (
  options: UseMutationOptions<
    AxiosResponse<User>,
    AxiosError<ErrorResponse>,
    UpdateUserMutationProps
  > = {}
): UseMutationResult<
  AxiosResponse<User>,
  AxiosError<ErrorResponse>,
  UpdateUserMutationProps
> =>
  useMutation<
    AxiosResponse<User>,
    AxiosError<ErrorResponse>,
    UpdateUserMutationProps
  >(UpdateUserMutation, {
    useErrorBoundary: false,
    ...options,
  });

import { ReactElement } from 'react';
import { DataGridProps, DataGrid } from 'Molecules';

export type DataGridTemplateProps = DataGridProps;

const DataGridTemplate = ({
  ...props
}: DataGridTemplateProps): ReactElement => <DataGrid {...props} />;

export default DataGridTemplate;

import { Uuid } from 'ValueObjects';
import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { Collection, Pagination } from 'Requests';
import { ExactOnlineDivision } from 'Models/Synchronizer/Package/ExactOnline';

type Response = AxiosResponse<Collection<ExactOnlineDivision>>;

export type ExactOnlineDivisionsByCredentialIdRequestProps = Pagination & {
  credentialId: Uuid;
};

export const ExactOnlineDivisionsByCredentialIdRequest = async ({
  credentialId,
}: ExactOnlineDivisionsByCredentialIdRequestProps): Promise<Response> =>
  axios.get(`api/${credentialId}/data/exact-online/divisions`);

export const useExactOnlineDivisionsByCredentialIdRequestQuery = (
  request: ExactOnlineDivisionsByCredentialIdRequestProps,
  options: UseQueryOptions<Response, AxiosError, Response> = {}
): UseQueryResult<Response, AxiosError> =>
  useQuery<Response, AxiosError, Response>({
    ...options,
    queryFn: async () => ExactOnlineDivisionsByCredentialIdRequest(request),
    queryKey: [
      'exact-online',
      'divisions',
      'list',
      request.credentialId,
      JSON.stringify({ perPage: request.perPage, page: request.page }),
    ],
  });

import { TabContext, TabPanel } from '@mui/lab';
import { Box, BoxProps, Grid, styled } from '@mui/material';
import { Icon, Tab, TabList, TabProps } from 'Atoms';
import {
  MultiSelectMenu,
  MultiSelectMenuValueItem,
  RemovableTab,
} from 'Molecules';
import {
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { faChevronDown, faPlus } from '@fortawesome/pro-light-svg-icons';
import clsx from 'clsx';

export type TabGroupItem = TabProps & {
  id?: string;
  key: string;
  content: ReactNode;
};

export type TabGroupProps = BoxProps & {
  keyName: string;
  defaultContent: ReactNode;
  options: MultiSelectMenuValueItem[];
  tabs: TabGroupItem[];
  onAddTab: (value: string, label: string | ReactElement) => void | undefined;
  onRemoveTab: (value: string) => void | undefined;
};

const TabGroup = ({
  keyName,
  defaultContent,
  options,
  tabs,
  onAddTab,
  onRemoveTab,
  className,
  ...args
}: TabGroupProps): ReactElement => {
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [activeTabKey, setActiveTabKey] = useState<string>(
    tabs.length > 0 ? tabs[0].key : 'add'
  );
  const multiSelectMenuAnchorEl = useRef<HTMLDivElement | null>(null);

  const multiSelectOptions = useMemo(
    () =>
      options.map((option) => ({
        ...option,
        checked: !!tabs.find(({ key }) => key === option.key),
      })),
    [tabs, options]
  );

  const handleRemoveTab = useCallback(
    (key: string) => {
      setActiveTabKey((current) => {
        if (current !== key) {
          return current;
        }

        return tabs.find((tab) => tab.key !== key)?.key || 'add';
      });
      onRemoveTab(key);
    },
    [onRemoveTab, tabs]
  );

  return (
    <Box
      className={clsx(className, {
        'Db-TabGroup-IsInactive': tabs.length === 0 && !openMenu,
      })}
      {...args}
    >
      <MultiSelectMenu
        items={multiSelectOptions}
        onChangeItem={(option, checked) => {
          if (checked) {
            onAddTab(option.key, option.label);
            setActiveTabKey(option.key);
          } else {
            handleRemoveTab(option.key);
          }
        }}
        anchorEl={multiSelectMenuAnchorEl.current}
        open={openMenu}
        onClose={() => setOpenMenu(false)}
      />

      <TabContext value={openMenu ? 'add' : activeTabKey}>
        <TabList
          onChange={(e, newActiveTabKey) => {
            if (
              e.target instanceof HTMLElement &&
              !e.target.classList.contains('Db-RemoveableTab-Remove')
            ) {
              setActiveTabKey(newActiveTabKey);
            }
          }}
        >
          {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
          {tabs.map(({ content, ...tabItem }) => (
            <RemovableTab
              {...tabItem}
              key={tabItem.id || tabItem.key}
              value={tabItem.key}
              active={!openMenu && activeTabKey === tabItem.key}
              onRemoveTab={() => handleRemoveTab(tabItem.key)}
              disableRipple
            />
          ))}

          <Tab
            sx={{
              display: tabs.length > 0 && !openMenu ? 'none' : 'flex',
            }}
            ref={multiSelectMenuAnchorEl}
            disableRipple
            onClick={() => setOpenMenu((current) => !current)}
            value="add"
            label={
              <Grid container columnSpacing={1}>
                <Grid item>{keyName}</Grid>
                <Grid item sx={{ fontSize: '7px' }}>
                  <Icon icon={faChevronDown} />
                </Grid>
              </Grid>
            }
            active
            inactive={!openMenu}
          />
          <Box className="Db-TabGroup-AddIcon-wrapper">
            <Icon
              icon={faPlus}
              className="Db-TabGroup-AddIcon"
              onClick={() => setOpenMenu(true)}
            />
          </Box>
        </TabList>

        <TabPanel value="add" className="Db-TabGroup-TablePanel">
          {defaultContent}
        </TabPanel>

        {tabs.map((tabItem) => (
          <TabPanel
            key={tabItem.key}
            value={tabItem.key}
            className="Db-TabGroup-TablePanel"
          >
            {tabItem.content}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
};

TabGroup.defaultProps = {
  tabs: [],
};

export default styled(TabGroup)(
  ({ theme }) => `
  .Db-TabGroup-AddIcon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-size: 12px;
    cursor: pointer;
  }
  
  .MuiTabs-indicator {
    color: ${theme.palette.primary.main};
  }
  
  &.Db-TabGroup-IsInactive {
    .MuiTabs-indicator {
      color: #C4C4C4;
    }
  }
  
  .Db-TabGroup-AddIcon {
    color: ${theme.palette.secondary.main};
  }
  
  .Db-TabGroup-TablePanel {
    border: 1px solid #C4C4C4;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: 0;
  }
  
  .MuiTabs-flexContainer {
    border-bottom: 1px solid #C4C4C4;
  }
  
  .MuiTabs-root, .MuiTab-root {
    min-height: 35px;
    height: 35px;
  }
 
  .MuiTabs-flexContainer {
    min-height: 34px;
    height: 34px;
  }    
`
);

import { ReactElement, useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { FieldValues } from 'react-hook-form';
import { ReactHookWizardStepProps, Step, StepLabel } from 'Atoms';
import * as Yup from 'yup';
import { ExactOnlineDivisionSettingsForm } from 'Forms';
import { WizardStepper } from 'Molecules';

export type ExactOnlineCredentialSettingsFormInput = {
  division: number;
};

export type ExactOnlineCredentialSettingsStepFormInput = {
  settings: {
    'exact-online': {
      division: number;
    };
  };
};

export const ExactOnlineCredentialSettingsValidation =
  (): Yup.SchemaOf<ExactOnlineCredentialSettingsFormInput> =>
    Yup.object({
      division: Yup.number().required().label('label.division'),
    });

export const ExactOnlineCredentialSettingsStepFormValidation =
  (): Yup.SchemaOf<ExactOnlineCredentialSettingsStepFormInput> =>
    Yup.object({
      settings: Yup.object({
        'exact-online': ExactOnlineCredentialSettingsValidation(),
      }),
    });

const ExactOnlineCredentialSettingsStepForm = <
  FormInput extends FieldValues = FieldValues
>({
  currentStep,
  wizardSteps,
}: ReactHookWizardStepProps<FormInput>): ReactElement => {
  const subSteps = useMemo(
    () =>
      wizardSteps.filter(({ category }) => category === currentStep.category),
    [currentStep.category, wizardSteps]
  );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h4" color="primary">
          <FormattedMessage
            id="onboarding.steps.credentialSettings"
            values={{
              packageType: <FormattedMessage id="packageTypes.exact-online" />,
            }}
          />
        </Typography>
      </Grid>
      <Grid item>
        <WizardStepper
          activeStep={subSteps.indexOf(currentStep)}
          orientation="horizontal"
        >
          {subSteps.map(({ title }, index) => (
            <Step key={index}>
              <StepLabel>{title}</StepLabel>
            </Step>
          ))}
        </WizardStepper>
      </Grid>
      <Grid item xs={12}>
        <ExactOnlineDivisionSettingsForm />
      </Grid>
    </Grid>
  );
};

export default ExactOnlineCredentialSettingsStepForm;

import CountryService from 'i18n-iso-countries';
import { useMemo } from 'react';
import enCountries from 'i18n-iso-countries/langs/en.json';

CountryService.registerLocale(enCountries);

export type CountryOption = {
  code: string;
  label: string;
};

export const useGetCountryOptions = (locale = 'en'): CountryOption[] =>
  useMemo(
    () =>
      Object.keys(CountryService.getAlpha2Codes()).map((code) => ({
        code,
        label: CountryService.getName(code, locale),
      })),
    [locale]
  );

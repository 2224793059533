import { ReactElement, useMemo } from 'react';
import { ConnectionStatus } from 'Models';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faPauseCircle,
} from '@fortawesome/pro-light-svg-icons';
import { Chip } from 'Atoms';
import { styled } from '@mui/material';

export type ConnectionStatusChipProps = {
  connectionStatus: ConnectionStatus;
  className?: string;
};

const colorMapping: {
  [key: string]:
    | 'default'
    | 'success'
    | 'secondary'
    | 'error'
    | 'warning'
    | 'primary'
    | 'info'
    | undefined;
} = {
  [ConnectionStatus.ACTIVE]: 'success',
  [ConnectionStatus.PAUSED]: 'default',
  [ConnectionStatus.ALERT]: 'error',
  [ConnectionStatus.ERROR]: 'error',
  [ConnectionStatus.WARNING]: 'warning',
};
const ConnectionStatusChip = ({
  connectionStatus,
  className,
}: ConnectionStatusChipProps): ReactElement => {
  const icon = useMemo(() => {
    if (connectionStatus === ConnectionStatus.ACTIVE) {
      return <FontAwesomeIcon icon={faCheckCircle} />;
    }
    if (connectionStatus === ConnectionStatus.PAUSED) {
      return <FontAwesomeIcon icon={faPauseCircle} />;
    }
    if (
      connectionStatus === ConnectionStatus.ALERT ||
      connectionStatus === ConnectionStatus.ERROR
    ) {
      return <FontAwesomeIcon icon={faExclamationCircle} />;
    }
    if (connectionStatus === ConnectionStatus.WARNING) {
      return <FontAwesomeIcon icon={faExclamationTriangle} />;
    }

    return undefined;
  }, [connectionStatus]);
  return (
    <Chip
      label={<FormattedMessage id={`connectionStatus.${connectionStatus}`} />}
      variant="filled"
      color={colorMapping[connectionStatus] || 'default'}
      icon={icon}
      className={className}
    />
  );
};

export default styled(ConnectionStatusChip)(`
`);

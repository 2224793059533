import { ReactElement, useCallback, useState } from 'react';
import { OverviewDataGridPageTemplate } from 'Templates/OverviewDataGridPageTemplate';
import { useConnectionsListRequest } from 'Requests';
import useDataGridDataWithReactQuery from 'Utils/useDataGridDataWithReactQuery';
import { Connection } from 'Models';
import { FormattedList, FormattedMessage } from 'react-intl';
import { Link, Grid, IconButton, Typography } from '@mui/material';
import {
  faPencil,
  faTrashCan,
  faCirclePlay,
} from '@fortawesome/pro-light-svg-icons';
import { Icon } from 'Atoms';
import { ConnectionStatusChip } from 'Molecules';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import colors from 'App/colors';
import ConfirmDialog from 'Organisms/ConfirmDialog/ConfirmDialog';
import {
  useDeleteConnectionMutation,
  useForceRunConnectionMutation,
} from 'Mutations';

const ConnectionOverviewPage = (): ReactElement => {
  const { mutateAsync: forceRunConnectionMutation } =
    useForceRunConnectionMutation();
  const { mutateAsync: deleteConnectionMutation } =
    useDeleteConnectionMutation();
  const [startRunningForConnection, setStartRunningDialogForConnection] =
    useState<Connection>();
  const [deleteConnection, setDeletingConnection] = useState<Connection>();

  const handleStartRunning = useCallback(async () => {
    if (startRunningForConnection?.connectionId) {
      await forceRunConnectionMutation({
        connectionId: startRunningForConnection.connectionId,
      });
      setStartRunningDialogForConnection(undefined);
    }
  }, [forceRunConnectionMutation, startRunningForConnection?.connectionId]);

  const handleStartDeleting = useCallback(async () => {
    if (deleteConnection?.connectionId) {
      await deleteConnectionMutation({
        connectionId: deleteConnection.connectionId,
      });
      setDeletingConnection(undefined);
    }
  }, [deleteConnection?.connectionId, deleteConnectionMutation]);

  const navigate = useNavigate();

  const gridData = useDataGridDataWithReactQuery<Connection>({
    overviewPage: true,
    reactQuery: useConnectionsListRequest,
    getRowId: (row) => row.connectionId,
    columns: [
      {
        field: 'connectionName',
        headerName: 'label.connection',
        flex: 1,
        renderCell: ({ row, value }) => (
          <Link
            component={RouterLink}
            to={`/connections/${row.connectionId}`}
            underline="none"
            color={colors.coolGray[900]}
          >
            {value}
          </Link>
        ),
      },
      {
        field: 'organization.organizationName',
        headerName: 'label.organization',
        valueGetter: (params) => params.row.organization.organizationName,
        flex: 1,
      },
      {
        field: 'packages',
        headerName: 'label.packages',
        sortable: false,
        flex: 1,
        renderCell: (params) => (
          <FormattedList
            type="unit"
            value={params.row.packages.map((p) => (
              <FormattedMessage id={`packageTypes.${p.packageType}`} />
            ))}
          />
        ),
      },
      {
        field: 'connectionStatus.value',
        headerName: 'label.status',
        flex: 0.5,
        renderCell: (params) => (
          <ConnectionStatusChip
            connectionStatus={params.row.connectionStatus}
          />
        ),
      },
      {
        field: 'actions',
        headerName: 'label.actions',
        type: 'actions',
        headerAlign: 'left',
        renderCell: ({ row }) => (
          <Grid container spacing={2}>
            <Grid item>
              <IconButton
                onClick={() => setStartRunningDialogForConnection(row)}
                disableRipple
                disableFocusRipple
                disableTouchRipple
                size="small"
                sx={{ fontSize: 13.5 }}
              >
                <Icon icon={faCirclePlay} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                onClick={() => setDeletingConnection(row)}
                disableRipple
                disableFocusRipple
                disableTouchRipple
                size="small"
                sx={{ fontSize: 13.5 }}
              >
                <Icon icon={faTrashCan} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                onClick={() => navigate(`${row.connectionId}/settings`)}
                disableRipple
                disableFocusRipple
                disableTouchRipple
                size="small"
                sx={{ fontSize: 13.5 }}
              >
                <Icon icon={faPencil} />
              </IconButton>
            </Grid>
          </Grid>
        ),
        flex: 0.5,
      },
    ],
  });

  return (
    <OverviewDataGridPageTemplate
      actions={[
        {
          value: 'overview',
          children: <FormattedMessage id="button.overview" />,
        },
        {
          value: 'create',
          children: <FormattedMessage id="button.createANewConnection" />,
        },
      ]}
      onSearch={gridData.handleSearch}
      breadCrumbs={[
        <Typography key="connections" color="text.primary">
          <FormattedMessage id="label.connections" />
        </Typography>,
        <Link component={RouterLink} key="overview" to="/connections">
          <FormattedMessage id="label.overview" />
        </Link>,
      ]}
      {...gridData}
    >
      {startRunningForConnection && (
        <ConfirmDialog
          open={!!startRunningForConnection}
          onCancel={() => setStartRunningDialogForConnection(undefined)}
          onClose={() => setStartRunningDialogForConnection(undefined)}
          onConfirm={handleStartRunning}
          title={
            <FormattedMessage
              id="label.startRunningConnection"
              values={{ name: startRunningForConnection.connectionName }}
            />
          }
          content={
            <FormattedMessage id="label.areYouSureYouWantToStartRunningForThisConnection" />
          }
        />
      )}
      {deleteConnection && (
        <ConfirmDialog
          open={!!deleteConnection}
          onCancel={() => setDeletingConnection(undefined)}
          onClose={() => setDeletingConnection(undefined)}
          onConfirm={handleStartDeleting}
          dangerous
          title={
            <FormattedMessage
              id="label.deleteConnection"
              values={{ name: deleteConnection.connectionName }}
            />
          }
          content={
            <FormattedMessage id="label.areYouSureYouWantToDeleteThisConnection" />
          }
        />
      )}
    </OverviewDataGridPageTemplate>
  );
};

export default ConnectionOverviewPage;

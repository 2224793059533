import { Box, BoxProps, Grid, styled } from '@mui/material';
import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { ReactHookFormTextField } from 'Atoms';
import { Controller } from 'react-hook-form';

export type VerifyResetPasswordFormProps = BoxProps;

export type VerifyResetPasswordInput = {
  token: string;
  newPassword: string;
  newPasswordConfirmation: string;
  status?: string;
};

export const VerifyResetPasswordValidation =
  (): Yup.SchemaOf<VerifyResetPasswordInput> =>
    Yup.object({
      token: Yup.string().required(),
      newPassword: Yup.string().required(),
      newPasswordConfirmation: Yup.string()
        .required()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
      status: Yup.string().optional(),
    });

export const VerifyResetPasswordInitialValues: VerifyResetPasswordInput = {
  token: '',
  newPassword: '',
  newPasswordConfirmation: '',
};

const VerifyResetPasswordFrom: FunctionComponent<
  VerifyResetPasswordFormProps
> = ({ ...args }: VerifyResetPasswordFormProps) => {
  const intl = useIntl();

  return (
    <Box {...args}>
      <Grid container rowSpacing={3}>
        <Grid container item xs={12}>
          <Controller
            name="newPassword"
            render={(registered) => (
              <ReactHookFormTextField
                {...registered}
                fullWidth
                type="password"
                placeholder={intl.formatMessage({
                  id: 'placeholder.newPassword',
                })}
                autoFocus
              />
            )}
          />
        </Grid>
        <Grid container item xs={12}>
          <Controller
            name="newPasswordConfirmation"
            render={(registered) => (
              <ReactHookFormTextField
                {...registered}
                fullWidth
                type="password"
                placeholder={intl.formatMessage({
                  id: 'placeholder.newPasswordConfirmation',
                })}
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

VerifyResetPasswordFrom.defaultProps = {};

export default styled(VerifyResetPasswordFrom)(
  () => `
`
);

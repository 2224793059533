import { Uuid } from 'ValueObjects';
import { isObjectResponse } from 'Requests';
import { PackageType } from '../Connection';

export interface Log {
  mongoId: string;
  connectionId: Uuid;
  logLevel: number;
  moduleId?: Uuid;
  taskId?: Uuid;
  message: string;
  packageType?: PackageType;
  solution?: string;
  wiki?: string;
  internal?: [];
  request?: [];
  response?: [];
  extraData?: [];
  notified: boolean;
  archived: boolean;
  expiresAt: Date;
  createdAt: Date;
  updatedAt: Date;
  method: string;
}

export function isLog(object: unknown): object is Log {
  return !!object && isObjectResponse(object) && object['@type'] === 'LogEntry';
}

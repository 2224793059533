import { ReactElement, useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useIsSuperAdmin } from 'Utils';
import { useOrganizationByOrganizationIdRequestQuery } from 'Requests';
import { OrganizationDetailsPageTemplate } from 'Templates';
import { Box, Grid, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Button, Icon, ReactHookForm } from 'Atoms';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import {
  OrganizationDetailsInputType,
  OrganizationDetailsInputTypeDefaultValues,
  OrganizationDetailsInputTypeValidationBuilder,
  UploadCompanyLogoInputType,
} from 'InputTypes';
import { OrganizationDetailsForm } from 'Forms';
import {
  useChangeOrganizationLogoMutation,
  useDeleteOrganizationMutation,
  useUpdateOrganizationMutation,
} from 'Mutations';
import { ConfirmDialog } from 'Organisms/ConfirmDialog';
import { UploadCompanyLogoFormDialog } from 'Organisms';

const OrganizationDetailsPage = (): ReactElement => {
  const { organizationId } = useParams();
  const isSuperAdmin = useIsSuperAdmin();
  const navigate = useNavigate();
  const [uploadImage, setUploadImage] = useState<boolean>(false);
  const [isRemovingOrganization, setIsRemovingOrganization] =
    useState<boolean>(false);

  const { mutateAsync: updateOrganization } = useUpdateOrganizationMutation();
  const { mutateAsync: removeOrganization } = useDeleteOrganizationMutation();
  const { mutateAsync: changeLogo } = useChangeOrganizationLogoMutation();

  const { data, refetch } = useOrganizationByOrganizationIdRequestQuery(
    {
      organizationId: organizationId || '',
    },
    {
      enabled: !!organizationId,
    }
  );
  const organization = useMemo(() => data?.data, [data]);

  const handleRemoveOrganization = useCallback(async () => {
    await removeOrganization({ organizationId: organizationId || '' });
    navigate('/organizations');
  }, [navigate, organizationId, removeOrganization]);

  const handleUpdateCompanyLogo = useCallback(
    async ({ fileId }: UploadCompanyLogoInputType) => {
      await changeLogo({
        organizationId: organizationId || '',
        logoId: fileId,
      });
      await refetch();
      setUploadImage(false);
    },
    [changeLogo, organizationId, refetch]
  );

  const handleUpdateOrganization = useCallback(
    async (values: OrganizationDetailsInputType) => {
      await updateOrganization({
        organizationId: organizationId || '',
        resellerId: values.reseller?.organizationId,
        organizationName: values.organizationName,
        address: values.address,
        vatNumber: values.vatNumber,
        invoiceContactName: values.invoiceContactName,
        invoiceEmailAddress: values.invoiceEmailAddress,
        reference: values.reference,
      });

      await refetch();
    },
    [organizationId, refetch, updateOrganization]
  );

  if (!organization) {
    return <span />;
  }

  return (
    <ReactHookForm<OrganizationDetailsInputType>
      defaultValues={{
        ...OrganizationDetailsInputTypeDefaultValues(),
        organizationName: organization.organizationName,
        reference: organization.reference,
        address: organization.address,
        invoiceContactName: organization.invoiceContactName,
        invoiceEmailAddress: organization.invoiceEmailAddress,
        reseller: organization.reseller,
        vatNumber: organization.vatNumber?.vatNumber,
      }}
      validationSchema={OrganizationDetailsInputTypeValidationBuilder()}
      hideBackendErrors
      useProvider
      name="organization"
      onSubmit={handleUpdateOrganization}
    >
      <OrganizationDetailsPageTemplate
        currentPage="overview"
        actions={
          <>
            {isSuperAdmin && (
              <Grid item>
                <Button
                  onClick={() => setIsRemovingOrganization(true)}
                  variant="contained"
                  color="error"
                >
                  <FormattedMessage id="button.removeOrganization" />
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button type="submit" variant="contained" color="secondary">
                <Icon icon={faCheck} />
                &nbsp;
                <FormattedMessage id="button.save" />
              </Button>
            </Grid>
          </>
        }
        onUpdateCompanyLogo={() => setUploadImage(true)}
        organization={organization}
      >
        <Box className="Db-OrganizationDetailsPage-Block">
          <Typography variant="subtitle2">
            <FormattedMessage id="label.generalInformation" />
          </Typography>
          <Box maxWidth={816} width="100%" pt={3}>
            <OrganizationDetailsForm allowEditingReseller={isSuperAdmin} />
          </Box>
        </Box>
      </OrganizationDetailsPageTemplate>
      {isRemovingOrganization && (
        <ConfirmDialog
          open={isRemovingOrganization}
          onCancel={() => setIsRemovingOrganization(false)}
          onClose={() => setIsRemovingOrganization(false)}
          onConfirm={handleRemoveOrganization}
          dangerous
          title={
            <FormattedMessage
              id="label.deleteOrganization"
              values={{ name: organization.organizationName }}
            />
          }
          content={
            <FormattedMessage id="label.areYouSureYouWantToDeleteThisOrganization" />
          }
        />
      )}
      {uploadImage && (
        <UploadCompanyLogoFormDialog
          open={uploadImage}
          onSubmit={handleUpdateCompanyLogo}
          onClose={() => setUploadImage(false)}
        />
      )}
    </ReactHookForm>
  );
};

export default OrganizationDetailsPage;

import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { TextField, TextFieldProps, AutocompletePopper } from 'Atoms';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export type AutocompleteProps = MuiAutocompleteProps & {
  textField: TextFieldProps;
};

const Autocomplete = ({ textField, ...props }: AutocompleteProps) => (
  <MuiAutocomplete
    PopperComponent={AutocompletePopper}
    renderInput={(params) => <TextField {...params} {...textField} />}
    {...props}
  />
);

Autocomplete.defaultProps = {};

export default styled(Autocomplete)(() => ``);

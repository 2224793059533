import {
  styled,
  ToggleButtonGroupProps as MuiToggleButtonGroupProps,
  ToggleButtonGroup as MuiToggleButtonGroup,
} from '@mui/material';
import { ReactElement } from 'react';

export type ToggleButtonGroupProps = MuiToggleButtonGroupProps;

const ToggleButtonGroup = ({
  children,
  ...props
}: ToggleButtonGroupProps): ReactElement => (
  <MuiToggleButtonGroup {...props}>{children}</MuiToggleButtonGroup>
);

ToggleButtonGroup.defaultProps = {};

export default styled(ToggleButtonGroup)(
  () => `
`
);

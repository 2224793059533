import { ReactElement, useState, useRef } from 'react';
import { IconButton, styled } from '@mui/material';
import { Icon, MenuPopper, MenuPopperProps } from 'Atoms';
import { faQuestion, faTimes } from '@fortawesome/pro-light-svg-icons';
import clsx from 'clsx';

export type HelpTooltipProps = Omit<MenuPopperProps, 'open' | 'onClose'> & {
  forceOpen?: boolean;
};

const HelpTooltip = ({
  className,
  forceOpen,
  children,
  ...args
}: HelpTooltipProps): ReactElement => {
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState<boolean>(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isOpen = open || forceOpen || false;

  return (
    <span
      className={clsx(
        className,
        { 'Db-HelpTooltip-Open': isOpen },
        { 'Db-HelpTooltip-Closed': !isOpen }
      )}
    >
      <IconButton
        onClick={handleClose}
        className="Db-HelpTooltip-CloseButton"
        ref={closeButtonRef}
      >
        <Icon icon={faTimes} />
      </IconButton>
      <IconButton onClick={handleClick} className="Db-HelpTooltip-OpenButton">
        <Icon icon={faQuestion} />
      </IconButton>
      <MenuPopper
        open={isOpen}
        anchorEl={closeButtonRef.current}
        onClose={handleClose}
        placement="top-start"
        sx={{ marginBottom: '10px !important' }}
        {...args}
      >
        {children}
      </MenuPopper>
    </span>
  );
};

export default styled(HelpTooltip)(
  ({ theme }) => `
  position: fixed;
  right: 20px;
  bottom: 20px;
  
  .Db-HelpTooltip-CloseButton {
    opacity: 0;
    margin-right: 23px;
    background: ${theme.palette.secondary.main};
    color: ${theme.palette.common.white};
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    height: 40px;
    width: 40px;
    font-size: 22px;
    filter: drop-shadow(0px 8px 30px rgba(0, 0, 0, 0.36));
    transition: opacity 284ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 189ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    &:hover {
      background: ${theme.palette.secondary.main};
      color: ${theme.palette.common.white};
    }
  }    
  
  .Db-HelpTooltip-OpenButton {
    background: ${theme.palette.common.white};
    color: #C4C4C4;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    height: 40px;
    width: 40px;
    font-size: 22px;
    box-shadow: 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  }    
  
  &.Db-HelpTooltip-Open {
    .Db-HelpTooltip-CloseButton {
      opacity: 1;
    }    
  }
`
);
